import React from "react";

import {
    Box,
    Button,
    Modal,
    Stack
} from "@mui/material";

import "./full-screen-test-html.css";

const FullScreenTestHTML = ({
    selectedActivity,
    testHTML,
    openModal,
    formatToShortDate,
    onClickClosePreview,
    onCloseModal
}) => {
    const handleClickClosePreview = () => {
        onClickClosePreview();
    };

    const handleCloseModal = () => {
        onCloseModal();
    };

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="card-recent-testing-activity-iframe-full-screen"
            aria-describedby="card-recent-testing-activity-iframe-full-screen"
            sx={{
                padding: "28px"
            }}
        >
            <Stack direction="column" width="100%" height="100%">
                <Box display="flex" justifyContent="center">
                    <Button
                        variant="outlined"
                        className="dune-button-outlined-white"
                        onClick={handleClickClosePreview}
                    >
                        Close preview
                    </Button>
                </Box>
                <Box paddingTop="40px" height="100%">
                    {
                        (selectedActivity && testHTML) &&
                        <iframe
                            className="card-recent-testing-activity-main-iframe-test-html"
                            title={`${formatToShortDate(selectedActivity.date)} test`}
                            srcdoc={testHTML.replace("card-recent-testing-activity-div-main-iframe-test-html-zoom-out", "card-recent-testing-activity-div-main-iframe-test-html-normal")}
                            height="100%"
                            width="100%"
                        ></iframe>
                    }
                </Box>
            </Stack>
        </Modal>
    );
};

export default FullScreenTestHTML;