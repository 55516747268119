import React from 'react';
import { Button } from '@mui/material';

import './button.css';

const DuneButton = ({ text }) => {
  return (
    <Button variant="contained" type="submit" className="button" fullWidth>
      {text}
    </Button>
  );
};

export default DuneButton;
