import React from "react";

const BlueEnvelopeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
            <path d="M20.0775 4.14551H4.38839C3.30976 4.14551 2.43705 5.02802 2.43705 6.10665L2.42725 17.8735C2.42725 18.9521 3.30976 19.8346 4.38839 19.8346H20.0775C21.1561 19.8346 22.0387 18.9521 22.0387 17.8735V6.10665C22.0387 5.02802 21.1561 4.14551 20.0775 4.14551ZM20.0775 8.06779L12.233 12.9706L4.38839 8.06779V6.10665L12.233 11.0095L20.0775 6.10665V8.06779Z" fill="#007AD3" />
        </svg>
    );
};

export default BlueEnvelopeIcon;