const formatPercentagesOfUsers = (percentages) => {
    const percentagesOfUsersResult = {
        name: "Percentages",
        lowRisk: 0,
        moderateRisk: 0,
        highRisk: 0,
        severeRisk: 0
    };

    percentagesOfUsersResult.lowRisk = percentages["Low risk"];
    percentagesOfUsersResult.moderateRisk = percentages["Moderate risk"];
    percentagesOfUsersResult.highRisk = percentages["High risk"];
    percentagesOfUsersResult.severeRisk = percentages["Severe risk"];

    return [percentagesOfUsersResult];
};

export default formatPercentagesOfUsers;