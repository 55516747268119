import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Box, Typography } from "@mui/material";

import { Descope, useSession, useUser } from "@descope/react-sdk";

import { LogoItem } from "../../components";

import "./login.css";

function Login() {
  // Loading
  const [isLoading, setIsLoading] = useState(true);

  // Roles
  const [isSuperadmin, setIsSuperadmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);

  const { isAuthenticated, isSessionLoading } = useSession();
  const { user, isUserLoading } = useUser();

  useEffect(() => {
    const updateComponent = async () => {
      const { roleNames, userTenants } = user;

      let tenantRoles = [];

      if (userTenants && userTenants.length > 0 && userTenants[0].roleNames) {
        tenantRoles = userTenants[0].roleNames;
      }

      if (
        roleNames.includes("Superadmin") ||
        tenantRoles.includes("Superadmin")
      ) {
        setIsSuperadmin(true);
      }

      if (roleNames.includes("Admin") || tenantRoles.includes("Admin")) {
        setIsAdmin(true);
      }

      if (roleNames.includes("Manager") || tenantRoles.includes("Manager")) {
        setIsManager(true);
      }
    };

    if (isAuthenticated && !isSessionLoading && !isUserLoading) {
      setIsLoading(true);

      updateComponent().finally(() => {
        setIsLoading(false);
      });
    }
  }, [isAuthenticated, isUserLoading, isSessionLoading, user]);

  if (!isLoading && isAuthenticated) {
    return (
      <Navigate
        to={
          isSuperadmin || isAdmin
            ? "/insights/organization/"
            : isManager
            ? "/insights/your-reports/"
            : // : "/dashboard/insights/")
              "/dashboard/trainings/"
        }
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Dune Security | AI-Powered Employee Risk Management</title>
        <meta
          name="description"
          content="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies."
        />
      </Helmet>
      <Box
        className="login-box-main"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Box
          className="login-box-login"
          maxWidth="400px"
          width="100%"
          padding="60px 30px"
          maxHeight="409.08px"
          height="100%"
        >
          <Box display="flex" justifyContent="center">
            <LogoItem height="40px" />
          </Box>
          <Box marginTop="10px">
            <Typography variant="h4" component="p" textAlign="center">
              Employee Risk Management
              <br />
              <Typography component="span" className="dune-text-italic">
                Optimized by AI
              </Typography>
            </Typography>
          </Box>
          <Box marginTop="50px" maxHeight="227.09px" height="100%">
            <Descope
              flowId="sign-in"
              onSuccess={(e) => console.log(e)}
              onError={(e) => console.log("Could not log in!")}
              redirectUrl={process.env.REACT_APP_REDIRECT_URL}
            />
          </Box>
          <Box marginTop="10px">
            <Typography variant="detailed" component="p" textAlign="center">
              Copyright © {new Date().getFullYear()} Dune Security
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Login;
