import React from "react";

const DescopeLogoIcon = () => {
    return (
        <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Descope_RGB_Icon-ForDarkBackground 1" clip-path="url(#clip0_15607_4159)">
                <g id="Group">
                    <path id="Vector" d="M12.6714 17.6618C13.4812 17.4913 14.1632 17.1503 14.7173 16.6282C15.5377 15.8504 15.6549 14.8061 15.6549 13.4955V7.64558C15.6549 6.33494 15.5377 5.2907 14.7173 4.51284C14.1632 3.99072 13.4812 3.64974 12.6714 3.47925V0.6875C14.0033 0.8793 15.1115 1.38011 15.9746 2.20059C17.1041 3.26614 18.0951 4.70464 18.0951 6.51609V14.5078C18.0951 16.3192 17.1041 17.7577 15.9746 18.8233C15.1115 19.6437 14.0033 20.1445 12.6714 20.3363V17.6618Z" fill="url(#paint0_linear_15607_4159)" />
                    <path id="Vector_2" d="M2.45306 2.20059C3.31616 1.38011 4.42434 0.8793 5.75628 0.6875V3.48991C4.94646 3.6604 4.2645 4.00138 3.71042 4.5235C2.88994 5.30135 2.77273 6.33494 2.77273 7.63492V13.5061C2.77273 14.8168 2.88994 15.861 3.71042 16.6389C4.2645 17.161 4.94646 17.502 5.75628 17.6725V20.347C4.42434 20.1552 3.31616 19.6544 2.45306 18.8339C1.32357 17.7684 0.332605 16.3299 0.332605 14.5184V6.48412C0.321949 4.69399 1.31292 3.26614 2.45306 2.20059Z" fill="url(#paint1_linear_15607_4159)" />
                    <g id="Group_2">
                        <path id="Vector_3" d="M11.649 8.40186L12.5547 6.94205L10.658 5.94043L9.85886 7.45352C9.74165 7.67729 9.52854 7.80515 9.27281 7.80515C9.02773 7.80515 8.80396 7.67729 8.68675 7.45352L7.89824 5.94043L6.01221 6.9314L7.32284 9.03054L11.649 8.40186Z" fill="url(#paint2_linear_15607_4159)" />
                        <path id="Vector_4" d="M14.0143 9.45703L4.65869 9.54228V11.4283L7.58897 11.4177L6.01195 13.9537L7.89798 14.9446L8.69715 13.4316C8.81436 13.2078 9.02747 13.0799 9.2832 13.0799C9.52828 13.0799 9.75205 13.2078 9.86926 13.4316L10.6684 14.9446L12.5651 13.943L11.2864 11.8865L8.82501 11.407L14.0143 11.3857V9.45703Z" fill="url(#paint3_linear_15607_4159)" />
                    </g>
                </g>
            </g>
            <defs>
                <linearGradient id="paint0_linear_15607_4159" x1="6.12801" y1="22.7182" x2="18.5561" y2="3.41814" gradientUnits="userSpaceOnUse">
                    <stop offset="1.48144e-07" stop-color="#0083B5" />
                    <stop offset="0.4173" stop-color="#00FFFF" />
                    <stop offset="0.9952" stop-color="#6FF12D" />
                </linearGradient>
                <linearGradient id="paint1_linear_15607_4159" x1="-0.622452" y1="18.3711" x2="11.8056" y2="-0.928979" gradientUnits="userSpaceOnUse">
                    <stop offset="1.48144e-07" stop-color="#0083B5" />
                    <stop offset="0.4173" stop-color="#00FFFF" />
                    <stop offset="0.9952" stop-color="#6FF12D" />
                </linearGradient>
                <linearGradient id="paint2_linear_15607_4159" x1="1.1882" y1="19.5366" x2="13.6163" y2="0.236587" gradientUnits="userSpaceOnUse">
                    <stop offset="1.48144e-07" stop-color="#0083B5" />
                    <stop offset="0.4173" stop-color="#00FFFF" />
                    <stop offset="0.9952" stop-color="#6FF12D" />
                </linearGradient>
                <linearGradient id="paint3_linear_15607_4159" x1="2.88615" y1="20.6305" x2="15.3142" y2="1.3304" gradientUnits="userSpaceOnUse">
                    <stop offset="1.48144e-07" stop-color="#0083B5" />
                    <stop offset="0.4173" stop-color="#00FFFF" />
                    <stop offset="0.9952" stop-color="#6FF12D" />
                </linearGradient>
                <clipPath id="clip0_15607_4159">
                    <rect width="17.7948" height="19.7128" fill="white" transform="translate(0.33252 0.646484)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default DescopeLogoIcon;