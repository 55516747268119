import {
    AnalyticsOutlined,
    PersonOutlined
} from "@mui/icons-material";

const pagesPlatformManager = {
    YOUR_REPORTS: {
        name: "Your reports",
        path: "/insights/your-reports/",
        icon: <AnalyticsOutlined />,
        tooltip: "Go to the insights page."
    },
    MY_DASHBOARD: {
        name: "My dashboard",
        path: "/dashboard/insights/",
        icon: <PersonOutlined />,
        tooltip: "Go to your dashboard page.",
        subpages: {
            INSIGHTS: {
                name: "Insights",
                path: "/dashboard/insights/",
                tooltip: "Go to the insights page.",
            },
            TRAININGS: {
                name: "Trainings",
                path: "/dashboard/trainings/",
                tooltip: "Go to the trainings page."
            }
        }
    }
};

export default pagesPlatformManager;