import {
  Apartment,
  AssessmentOutlined,
  BarChart,
  Dashboard,
  GppGoodOutlined,
  Insights,
  PersonOutlined,
  School,
} from "@mui/icons-material";

import { NetworkIcon } from "../images";

const pagesPlatform = {
  INSIGHTS: {
    name: "Insights",
    path: "/insights/organization/",
    icon: <BarChart />,
    tooltip: "Go to the insights page.",
    subpages: {
      ORGANIZATION: {
        name: "Organization",
        path: "/insights/organization/",
        icon: <Apartment />,
        tooltip: "Go to the organization insights page.",
      },
      DEPARTMENTS: {
        name: "Departments",
        path: "/insights/departments/",
        icon: <NetworkIcon />,
        tooltip: "Go to the department insights page.",
      },
      USERS: {
        name: "Users",
        path: "/insights/search-user/",
        icon: <PersonOutlined />,
        tooltip: "Go to the users insights page.",
      },
    },
  },
  MY_DASHBOARD: {
    name: "My dashboard",
    path: "/dashboard/insights/",
    icon: <Dashboard />,
    tooltip: "Go to your dashboard page.",
    subpages: {
      INSIGHTS: {
        name: "Insights",
        path: "/dashboard/insights/",
        icon: <Insights />,
        tooltip: "Go to the insights page.",
      },
      TRAININGS: {
        name: "Trainings",
        path: "/dashboard/trainings/",
        icon: <School />,
        tooltip: "Go to the trainings page.",
      },
    },
  },
  COMPLIANCE: {
    name: "Compliance",
    path: "/compliance/",
    icon: <GppGoodOutlined />,
    tooltip: "Go to the compliance page.",
  },
  REPORTING: {
    name: "Reporting",
    path: "/reporting/",
    icon: <AssessmentOutlined />,
    tooltip: "Go to the reporting page.",
  },
};

export default pagesPlatform;
