import { getResponseGet } from "../utils";

const getUsersByInteraction = async (interaction, days) => {
    let usersData = {
        result: {
            users: []
        },
        error: {}
    };

    try {
        const response = await getResponseGet(`/organization_sankey_incidents/?sankey_bucket=${interaction}${days ? `&time_window=${days}` : ""}`);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        if (data[`list_${interaction}_users`] && Array.isArray(data[`list_${interaction}_users`])) {
            const uniqueUsers = data[`list_${interaction}_users`].map((user) => {
                return {
                    firstName: user.first_name,
                    lastName: user.last_name,
                    email: user.email
                };
            }).filter((user, index, self) =>
                index === self.findIndex((u) => (
                    u.firstName === user.firstName && u.lastName === user.lastName && u.email === user.email
                ))
            );

            uniqueUsers.sort((a, b) => a.email.localeCompare(b.email));

            const users = [];

            for (let i = 0; i < uniqueUsers.length; i++) {
                const testsData = data[`list_${interaction}_users`].filter((data) => data.email === uniqueUsers[i].email);

                // Sort the tests data from latest to earliest.
                testsData.sort((a, b) => {
                    const dateA = new Date(a.report_timestamp);
                    const dateB = new Date(b.report_timestamp);

                    return dateB - dateA;
                });

                let riskScore = 0;

                if (testsData.length > 0 && testsData[0].risk_score) {
                    riskScore = testsData[0].risk_score;
                }

                const user = {
                    firstName: uniqueUsers[i].firstName,
                    lastName: uniqueUsers[i].lastName,
                    email: uniqueUsers[i].email,
                    riskScore,
                    tests: testsData.map((data) => {
                        return {
                            timestamp: data.report_timestamp,
                            content_flags: data.content_flags
                        };
                    })
                };

                users.push(user);
            }

            for (let i = 0; i < data[`list_${interaction}_users`].length; i++) {
                const userData = data[`list_${interaction}_users`][i];

                if (userData.risk_score) {
                    // Only keep users who have a risk score (which means they have went through the BRA).
                    const user = {
                        firstName: userData.first_name,
                        lastName: userData.last_name,
                        email: userData.email,
                        department: userData.department,
                        role: userData.position_title,
                        compliant: userData.compliant,
                        riskScore: userData.risk_score ? +userData.risk_score.toFixed() : 0
                    };

                    usersData.result.users.push(user);
                }
            }
        }
    } catch (error) {
        usersData.error = error;
    }

    return usersData;
};

export default getUsersByInteraction;