import React from "react";

import { Box } from "@mui/material";

const AntlerLogo = () => {
    return (
        <Box
            component="img"
            src="https://d3oo9a669kwmx7.cloudfront.net/logos/antler-logo-white.png"
            width="100%"
            height="100%"
        />
    );
};

export default AntlerLogo;