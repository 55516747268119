import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ContentCopyOutlined } from "@mui/icons-material";

import { useUser } from "@descope/react-sdk";

import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  getSettingsSSO,
  postAccessKey,
  postSettingsSSOSAMLByManual,
  postSettingsSSOSAMLByMetadata,
} from "../../../api";

import { ButtonCTA } from "../../../components";

import "./sso-settings.css";

function SSOSettings({ isInitialLoading, email }) {
  // Loading
  const [isLoading, setIsLoading] = useState(true);

  // Application details
  const [acsURL, setACSURL] = useState("");
  const [entityIDDune, setEntityIDDune] = useState("");

  // Identity provider details
  const [metadataURL, setMetadataURL] = useState("");
  const [ssoURL, setSSOURL] = useState("");
  const [entityIDIDP, setEntityIDIDP] = useState("");
  const [certificate, setCertificate] = useState("");
  const [domains, setDomains] = useState([]);
  const [isManual, setIsManual] = useState(false);

  // SCIM provisioning details
  const [secretToken, setSecretToken] = useState("");
  // const [hasSecretToken, setHasSecretToken] = useState(false);
  const [isLoadingSecretToken, setIsLoadingSecretToken] = useState(false);

  // Success
  const [successMessageIDP, setSuccessMessageIDP] = useState("");

  // Errors
  const [errorMessageIDP, setErrorMessageIDP] = useState("");
  const [errorMessageSCIM, setErrorMessageSCIM] = useState("");
  const [isErrorMetadataURL, setIsErrorMetadataURL] = useState(false);
  const [isErrorSSOURL, setIsErrorSSOURL] = useState(false);
  const [isErrorEntityIDIDP, setIsErrorEntityIDIDP] = useState(false);
  const [isErrorCertificate, setIsErrorCertificate] = useState(false);
  const [isErrorDomains, setIsErrorDomains] = useState(false);

  // SAML SSO check
  const [isSAMLSetUp, setIsSAMLSetUp] = useState(null);

  const tenantURL = "https://auth.dunesecurity.io/scim/v2";

  const { user, isUserLoading } = useUser();

  const handleChangeCertificate = (event) => {
    setCertificate(event.target.value);

    if (isErrorCertificate) {
      setIsErrorCertificate(false);
      setErrorMessageIDP("");
    }
  };

  const handleChangeDomains = (event, newValue) => {
    setDomains(newValue);
  };

  const handleChangeEntityIDIDP = (event) => {
    setEntityIDIDP(event.target.value);

    if (isErrorEntityIDIDP) {
      setIsErrorEntityIDIDP(false);
      setErrorMessageIDP("");
    }
  };

  const handleChangeMetadataURL = (event) => {
    setMetadataURL(event.target.value);

    if (isErrorMetadataURL) {
      setIsErrorMetadataURL(false);
      setErrorMessageIDP("");
    }
  };

  const handleChangeSSOURL = (event) => {
    setSSOURL(event.target.value);

    if (isErrorSSOURL) {
      setIsErrorSSOURL(false);
      setErrorMessageIDP("");
    }
  };

  const handleClickManualConnection = () => {
    if (!isManual) {
      setIsManual(true);
    }
  };

  const handleClickMetadataURL = () => {
    if (isManual) {
      setIsManual(false);
    }
  };

  const handleClickGetNewToken = async () => {
    setIsLoadingSecretToken(true);

    const accessKeyData = await postAccessKey();

    if (Object.keys(accessKeyData.error).length > 0) {
      console.error(accessKeyData.error.message);
      setErrorMessageSCIM(accessKeyData.error.message);
    } else {
      const { accessKey } = accessKeyData.result;
      setSecretToken(accessKey);
    }

    setIsLoadingSecretToken(false);
  };

  const handleClickSubmit = async (event) => {
    event.preventDefault();

    setSuccessMessageIDP("");
    setErrorMessageIDP("");
    setIsErrorMetadataURL(false);
    setIsErrorSSOURL(false);
    setIsErrorEntityIDIDP(false);
    setIsErrorCertificate(false);
    setIsErrorDomains(false);

    let isError = false;

    if (!isManual) {
      if (!metadataURL) {
        setIsErrorMetadataURL(true);
        setErrorMessageIDP("Metadata URL is required.");
        isError = true;
      }

      if (domains.length === 0) {
        setIsErrorDomains(true);
        setErrorMessageIDP("Domains are required.");
        isError = true;
      }

      if (!isError) {
        setIsLoading(true);

        const requestBody = {
          metadataURL,
          domains,
        };

        const settingsSSO = await postSettingsSSOSAMLByMetadata(requestBody);

        if (Object.keys(settingsSSO.error).length > 0) {
          console.error(settingsSSO.error.message);
          setErrorMessageIDP(settingsSSO.error.message);
        } else {
          setSuccessMessageIDP("Details saved successfully");
        }

        setIsLoading(false);
      }
    } else {
      if (!ssoURL) {
        setIsErrorSSOURL(true);
        setErrorMessageIDP("SSO URL is required.");
        isError = true;
      }

      if (!entityIDIDP) {
        setIsErrorEntityIDIDP(true);
        setErrorMessageIDP("Entity ID is required.");
        isError = true;
      }

      if (!certificate) {
        setIsErrorCertificate(true);
        setErrorMessageIDP("Certificate is required.");
        isError = true;
      }

      if (domains.length === 0) {
        setIsErrorDomains(true);
        setErrorMessageIDP("Domains are required.");
        isError = true;
      }

      if (!isError) {
        setIsLoading(true);

        const requestBody = {
          ssoURL,
          entityIDIDP,
          certificate,
          domains,
        };

        const settingsSSO = await postSettingsSSOSAMLByManual(requestBody);

        if (Object.keys(settingsSSO.error).length > 0) {
          console.error(settingsSSO.error.message);
          setErrorMessageIDP(settingsSSO.error.message);
        } else {
          setSuccessMessageIDP("Details saved successfully");
        }

        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const updateComponent = async () => {
      setIsLoading(true);

      const { SAML } = user;
      setIsSAMLSetUp(SAML);

      const settingsSSO = await getSettingsSSO();

      if (Object.keys(settingsSSO.error).length > 0) {
        console.error(settingsSSO.error.message);
        setErrorMessageIDP(settingsSSO.error.message);
      } else {
        const {
          acsURL,
          entityIDDune,
          metadataURL,
          ssoURL,
          entityIDIDP,
          certificate,
          domains,
        } = settingsSSO.result;

        setACSURL(acsURL);
        setEntityIDDune(entityIDDune);
        setMetadataURL(metadataURL);
        setSSOURL(ssoURL);
        setEntityIDIDP(entityIDIDP);
        setCertificate(certificate);
        setDomains(domains);

        if (metadataURL) {
          setIsManual(false);
        } else if (ssoURL || entityIDIDP || certificate) {
          setIsManual(true);
        }
      }

      setIsLoading(false);
    };

    if (
      !isInitialLoading &&
      email &&
      !isUserLoading &&
      user &&
      isSAMLSetUp === null
    ) {
      updateComponent();
    }
  }, [isInitialLoading, email, isUserLoading, user, isSAMLSetUp]);

  return isLoading ? (
    <Box display="flex" justifyContent="center" marginTop="100px">
      <CircularProgress
        className="loading-spinner-circular-progress"
        size="5vh"
      />
    </Box>
  ) : (
    <Box>
      <Box marginTop="60px">
        <Typography component="p">
          Ensure seamless and secure access for your users by configuring Single
          Sign-On (SSO).
        </Typography>
      </Box>
      <Box marginTop="60px">
        <Typography component="h2" className="dune-text-header-dashboard">
          Application details
        </Typography>
        <Typography component="p" marginTop="20px">
          Copy and paste the ACS URL and entity ID into your identity provider.
        </Typography>
        <Box marginTop="60px">
          <Typography component="p" className="sso-settings-text-label">
            ACS URL (Assertion Consumer Service URL)
          </Typography>
          <Stack direction="row" alignItems="center" marginTop="8px">
            <TextField
              variant="filled"
              className="dune-text-field-filled"
              value={acsURL}
              fullWidth
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
            />
            <Box marginLeft="20px">
              <CopyToClipboard text={acsURL}>
                <IconButton title="Copy the ACS URL.">
                  <ContentCopyOutlined className="sso-settings-icon-copy" />
                </IconButton>
              </CopyToClipboard>
            </Box>
          </Stack>
        </Box>
        <Box marginTop="20px">
          <Typography component="p" className="sso-settings-text-label">
            Entity ID from Dune Security
          </Typography>
          <Stack direction="row" alignItems="center" marginTop="8px">
            <TextField
              variant="filled"
              className="dune-text-field-filled"
              value={entityIDDune}
              fullWidth
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
            />
            <Box marginLeft="20px">
              <CopyToClipboard text={entityIDDune}>
                <IconButton title="Copy the entity ID.">
                  <ContentCopyOutlined className="sso-settings-icon-copy" />
                </IconButton>
              </CopyToClipboard>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box marginTop="60px">
        <Typography component="h2" className="dune-text-header-dashboard">
          Identity provider details
        </Typography>
        <Typography component="p" marginTop="20px">
          Tell us how to connect to your identity provider.
        </Typography>
        <Grid container marginTop="60px">
          <Grid item xs={6} paddingRight="10px">
            <Button
              variant="contained"
              className={
                !isManual
                  ? "dune-button-contained-gray sso-settings-button-connection-choice sso-settings-button-connection-choice-selected"
                  : "dune-button-contained-gray sso-settings-button-connection-choice"
              }
              fullWidth
              aria-label="Retrieve the connection details dynamically using a metadata URL."
              title="Retrieve the connection details dynamically using a metadata URL."
              onClick={handleClickMetadataURL}
            >
              Retrieve the connection details dynamically using a metadata URL.
            </Button>
          </Grid>
          <Grid item xs={6} paddingLeft="10px">
            <Button
              variant="contained"
              className={
                isManual
                  ? "dune-button-contained-gray sso-settings-button-connection-choice sso-settings-button-connection-choice-selected"
                  : "dune-button-contained-gray sso-settings-button-connection-choice"
              }
              fullWidth
              aria-label="Enter the connection details manually."
              title="Enter the connection details manually."
              onClick={handleClickManualConnection}
            >
              Enter the connection details manually.
            </Button>
          </Grid>
        </Grid>
        <Box component="form" onSubmit={handleClickSubmit}>
          {!isManual ? (
            <>
              <Box marginTop="20px">
                <Typography component="p" className="sso-settings-text-label">
                  Metadata URL
                </Typography>
                <Box marginTop="8px">
                  <TextField
                    variant="filled"
                    className="dune-text-field-filled"
                    value={metadataURL}
                    name="metadata-url"
                    type="text"
                    error={isErrorMetadataURL}
                    required
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onChange={handleChangeMetadataURL}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box marginTop="20px">
                <Typography component="p" className="sso-settings-text-label">
                  SSO URL
                </Typography>
                <Box marginTop="8px">
                  <TextField
                    variant="filled"
                    className="dune-text-field-filled"
                    value={ssoURL}
                    name="sso-url"
                    type="text"
                    error={isErrorSSOURL}
                    required
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onChange={handleChangeSSOURL}
                  />
                </Box>
              </Box>
              <Box marginTop="20px">
                <Typography component="p" className="sso-settings-text-label">
                  Entity ID from your identity provider
                </Typography>
                <Box marginTop="8px">
                  <TextField
                    variant="filled"
                    className="dune-text-field-filled"
                    value={entityIDIDP}
                    name="entity-id-idp"
                    type="text"
                    error={isErrorEntityIDIDP}
                    required
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onChange={handleChangeEntityIDIDP}
                  />
                </Box>
              </Box>
              <Box marginTop="20px">
                <Typography component="p" className="sso-settings-text-label">
                  Certificate
                </Typography>
                <Box marginTop="8px">
                  <TextField
                    variant="filled"
                    className="dune-text-field-filled dune-text-field-filled-multiline"
                    value={certificate}
                    name="response"
                    type="text"
                    error={isErrorCertificate}
                    multiline
                    rows={4}
                    required
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onChange={handleChangeCertificate}
                  />
                </Box>
              </Box>
            </>
          )}
          <Box marginTop="20px">
            <Typography component="p" className="sso-settings-text-label">
              Email domains
            </Typography>
            <Box marginTop="8px">
              <Autocomplete
                className="sso-settings-autocomplete-domains"
                value={domains}
                options={[]}
                multiple
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    className="dune-text-field-filled"
                    placeholder="Example: dunesecurity.io"
                    type="text"
                    error={isErrorDomains}
                    fullWidth
                  />
                )}
                onChange={handleChangeDomains}
              />
            </Box>
          </Box>
          <Box marginTop="20px">
            <ButtonCTA
              text="Submit"
              tooltip="Submit identity provider details."
              isSubmit={true}
            />
          </Box>
          {successMessageIDP && (
            <Box marginTop="8px">
              <Typography component="p" className="sso-settings-text-success">
                {successMessageIDP}
              </Typography>
            </Box>
          )}
          {errorMessageIDP && (
            <Box marginTop="8px">
              <Typography component="p" className="sso-settings-text-error">
                {errorMessageIDP}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box marginTop="60px">
        <Typography component="h2" className="dune-text-header-dashboard">
          SCIM provisioning details
        </Typography>
        <Typography component="p" marginTop="20px">
          Copy and paste the tenant URL and secret token into your identity
          provider to implement SCIM automatic provisioning.
        </Typography>
        <Box marginTop="60px">
          <Typography component="p" className="sso-settings-text-label">
            Tenant URL
          </Typography>
          <Stack direction="row" alignItems="center" marginTop="8px">
            <TextField
              variant="filled"
              className="dune-text-field-filled"
              value={tenantURL}
              fullWidth
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
            />
            <Box marginLeft="20px">
              <CopyToClipboard text={tenantURL}>
                <IconButton title="Copy the tenant URL.">
                  <ContentCopyOutlined className="sso-settings-icon-copy" />
                </IconButton>
              </CopyToClipboard>
            </Box>
          </Stack>
        </Box>
        <Box marginTop="20px">
          <Typography component="p" className="sso-settings-text-label">
            Secret token
          </Typography>
          <Box marginTop="8px">
            {!secretToken ? (
              !isLoadingSecretToken ? (
                <Button
                  variant="outlined"
                  className="dune-button-outlined-white sso-settings-button-get-new-token"
                  fullWidth
                  onClick={handleClickGetNewToken}
                >
                  Get new token
                </Button>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  className="sso-settings-box-loading"
                  maxWidth="158px"
                  width="100%"
                  padding="5px 0"
                >
                  <CircularProgress
                    className="sso-settings-circular-progress"
                    size="19px"
                  />
                </Box>
              )
            ) : (
              <Stack direction="row" alignItems="center" marginTop="8px">
                <TextField
                  variant="filled"
                  className="dune-text-field-filled"
                  value={secretToken}
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
                <Box marginLeft="20px">
                  <CopyToClipboard text={secretToken}>
                    <IconButton title="Copy the secret token.">
                      <ContentCopyOutlined className="sso-settings-icon-copy" />
                    </IconButton>
                  </CopyToClipboard>
                </Box>
              </Stack>
            )}
            {errorMessageSCIM && (
              <Box marginTop="8px">
                <Typography component="p" className="sso-settings-text-error">
                  {errorMessageSCIM}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SSOSettings;
