import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

import { BannerNotification, Footer, NavigationWebsite } from "../../layout";

function WebsiteContainer({
  mainPage,
  title = "Dune Security | AI-Powered Employee Risk Management",
  description = "Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk employees against social engineering, ensuring personalized, effective defense strategies.",
}) {
  const { pathname } = useLocation();

  useEffect(() => {
    const loadFingerprintJS = async () => {
      const fpPromise = FingerprintJS.load({
        apiKey: process.env.REACT_APP_FINGERPRINTJS_KEY,
      });

      fpPromise
        .then((fp) =>
          fp.get({
            linkedId: "Anonymous user",
          })
        )
        .catch((error) => {
          console.error("Error tagging user with FingerprintJS:", error);
        });
    };

    loadFingerprintJS();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        maxWidth="100vw"
        width="100%"
        paddingBottom="120px"
      >
        <BannerNotification />
        <NavigationWebsite />
        <Box role="main">{mainPage}</Box>
        <Footer />
      </Box>
    </>
  );
}

export default WebsiteContainer;
