import { getResponseGet } from "../utils";

const getTestHTML = async (uuid, targetEmail = null) => {
    let testHTML = {
        result: {
            testHTMLCode: ""
        },
        error: {}
    };

    try {
        const response = await getResponseGet(`/past-attack-template/?uuid=${uuid}${targetEmail ? `&target_email=${targetEmail}` : ""}`);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        testHTML.result.testHTMLCode = `
            <style>
                .card-recent-testing-activity-div-main-iframe-test-html-zoom-out {
                    transform: scale(0.5);
                    transform-origin: 0 0;
                    width: 200%;
                    height: 0; /* Remove any extra height space. */
                }

                .card-recent-testing-activity-div-main-iframe-test-html-normal a,
                .card-recent-testing-activity-div-main-iframe-test-html-zoom-out a {
                    pointer-events: none; /* Disable clicks on links */
                }
            </style>
            <div class="card-recent-testing-activity-div-main-iframe-test-html-zoom-out">
                ${data}
            </div>`;
    } catch (error) {
        testHTML.error = error;
    }

    return testHTML;
};

export default getTestHTML;