import React, { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";

import { createClient } from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { spacing } from "../../../styles";

import "./privacy-policy.css";

function PrivacyPolicy() {
  const [bodyContent, setBodyContent] = useState([]);

  useEffect(() => {
    const updateComponent = async () => {
      if (process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
        const client = createClient({
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
          accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        });

        await client
          .getEntries({
            content_type: "privacyPolicy",
            limit: 1,
          })
          .then((response) => {
            const fetchedBodyItem = response.items.map((item) => {
              const { fields } = item;

              return {
                body: fields.body,
              };
            });

            setBodyContent(fetchedBodyItem[0].body.content);
          })
          .catch(console.error);
      }
    };

    updateComponent();
  }, []);

  return (
    <Box>
      <Box
        className="privacy-policy-box-header"
        display="flex"
        justifyContent="center"
        marginTop="30px"
        padding="0 30px"
      >
        <Box maxWidth={spacing.MAX_WIDTH_WEBSITE} width="100%" padding="40px 0">
          <Typography variant="h1" component="h1">
            Privacy Policy
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        marginTop="40px"
        padding="0 30px"
      >
        <Box maxWidth={spacing.MAX_WIDTH_WEBSITE} width="100%">
          <Box className="privacy-policy-box-body-content">
            {bodyContent.map((contentItem) => {
              return documentToReactComponents(contentItem);
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PrivacyPolicy;
