import React from "react";
import { Link } from "react-router-dom";

import {
    Box,
    Card,
    CardContent,
    Grid,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";
import {
    AccountBalanceOutlined,
    CreditCardOutlined,
    LocalHospitalOutlined,
    PrivacyTipOutlined,
    ScaleOutlined,
    SecurityOutlined,
    ShieldOutlined,
    StarBorder
} from "@mui/icons-material";

import {
    pagesPlatform,
    pagesPlatformManager
} from "../../../constants";

import { formatToPercentage } from "../../../constants/functions";

import { ButtonTooltip } from "../../common";

import "./card-compliance-frameworks.css";

function CardComplianceFrameworks({
    complianceFrameworks,
    selectedDepartment,
    pageTitle,
    tooltip,
    color
}) {
    const classNameIcon = "card-compliance-framworks-icon";
    const icons = {
        "GDPR": <PrivacyTipOutlined className={classNameIcon} sx={{ color }} />,
        "GLBA": <AccountBalanceOutlined className={classNameIcon} sx={{ color }} />,
        "HIPAA": <LocalHospitalOutlined className={classNameIcon} sx={{ color }} />,
        "ISO27001": <SecurityOutlined className={classNameIcon} sx={{ color }} />,
        "NIST": <ScaleOutlined className={classNameIcon} sx={{ color }} />,
        "PSI DSS": <CreditCardOutlined className={classNameIcon} sx={{ color }} />,
        "SOC 2 Type 2": <ShieldOutlined className={classNameIcon} sx={{ color }} />
    };

    return (
        <Card className="dune-card-dashboard card-compliance-frameworks-card">
            <CardContent className="card-compliance-frameworks-card-content">
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                    <Box width="100%" padding="28px 0 40px 28px">
                        <Typography component="h3" className="dune-text-header-card-dashboard">
                            Compliance training completed
                        </Typography>
                    </Box>
                    <ButtonTooltip
                        tooltip={tooltip}
                        ariaLabel={tooltip}
                    />
                </Stack>
                <Box
                    display="flex"
                    flexDirection="column"
                    padding="0 28px"
                    height="100%"
                >
                    {
                        (complianceFrameworks && Array.isArray(complianceFrameworks) && color) ?
                            <Grid container>
                                {
                                    complianceFrameworks.map((complianceFramework, index) =>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            lg={3}
                                            marginTop={{
                                                xs: index >= 1 ? "40px" : 0,
                                                md: index >= 2 ? "40px" : 0,
                                                lg: index >= 4 ? "40px" : 0
                                            }}
                                            paddingLeft={{
                                                xs: 0,
                                                md: index % 2 === 1 ? "30px" : 0,
                                                lg: index % 4 === 1 ? `${(60 / 4)}px`
                                                    : (index % 4 === 2 ? `${(60 / 4) * 2}px`
                                                        : (index % 4 === 3 ? `${(60 / 4) * 3}px`
                                                            : 0))
                                            }}
                                            paddingRight={{
                                                xs: 0,
                                                md: index % 2 === 0 ? "30px" : 0,
                                                lg: index % 4 === 2 ? `${(60 / 4)}px`
                                                    : (index % 4 === 1 ? `${(60 / 4) * 2}px`
                                                        : (index % 4 === 0 ? `${(60 / 4) * 3}px`
                                                            : 0))
                                            }}
                                            key={`compliance-framework-${index}`}
                                        >
                                            <Link
                                                to={pageTitle === pagesPlatformManager.YOUR_REPORTS ? "#" : "/compliance/"}
                                                className="card-compliance-framework-link-compliance-framework"
                                                title="Go to the compliance page."
                                                state={{
                                                    complianceFramework,
                                                    department: selectedDepartment
                                                }}
                                            >
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                >
                                                    <Box
                                                        className="card-compliance-frameworks-box-icon"
                                                        display="flex"
                                                    >
                                                        {
                                                            icons[complianceFramework.name] ||
                                                            <StarBorder className={classNameIcon} sx={{ color }} />
                                                        }
                                                    </Box>
                                                    <Box marginLeft="20px">
                                                        <Typography component="p" className="card-compliance-frameworks-text-small">
                                                            {complianceFramework.name}
                                                        </Typography>
                                                        <Typography component="p" className="card-compliance-frameworks-text-small" marginTop="12px">
                                                            {typeof complianceFramework.progress === "number" ? formatToPercentage(complianceFramework.progress) : (complianceFramework.progress ? "Yes" : "No")}{(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION || pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS || pageTitle === pagesPlatformManager.YOUR_REPORTS) && " of users"}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            </Link>
                                        </Grid>
                                    )
                                }
                            </Grid>
                            :
                            <Box display="flex" flexDirection="column" flexGrow={1} height="100%">
                                <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                            </Box>
                    }
                </Box>
            </CardContent>
        </Card>
    );
}

export default CardComplianceFrameworks;