import React from 'react';

const ReduceLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 19 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.55914 0.596328L1.55914 3.70633C0.839141 4.02633 0.369141 4.74633 0.369141 5.53633V10.2363C0.369141 15.7863 4.20914 20.9763 9.36914 22.2363C14.5291 20.9763 18.3691 15.7863 18.3691 10.2363V5.53633C18.3691 4.74633 17.8991 4.02633 17.1791 3.70633L10.1791 0.596328C9.66914 0.366328 9.06914 0.366328 8.55914 0.596328ZM6.65914 15.5263L4.06914 12.9363C3.97656 12.8437 3.90312 12.7338 3.85301 12.6129C3.80291 12.4919 3.77712 12.3623 3.77712 12.2313C3.77712 12.1004 3.80291 11.9707 3.85301 11.8498C3.90312 11.7288 3.97656 11.6189 4.06914 11.5263C4.16172 11.4337 4.27163 11.3603 4.3926 11.3102C4.51356 11.2601 4.64321 11.2343 4.77414 11.2343C4.90507 11.2343 5.03472 11.2601 5.15568 11.3102C5.27665 11.3603 5.38656 11.4337 5.47914 11.5263L7.36914 13.4063L13.2491 7.52633C13.3417 7.43375 13.4516 7.36031 13.5726 7.3102C13.6936 7.2601 13.8232 7.23431 13.9541 7.23431C14.0851 7.23431 14.2147 7.2601 14.3357 7.3102C14.4566 7.36031 14.5666 7.43375 14.6591 7.52633C14.7517 7.61891 14.8252 7.72882 14.8753 7.84978C14.9254 7.97075 14.9512 8.1004 14.9512 8.23133C14.9512 8.36226 14.9254 8.49191 14.8753 8.61287C14.8252 8.73384 14.7517 8.84375 14.6591 8.93633L8.06914 15.5263C7.97663 15.619 7.86674 15.6926 7.74576 15.7428C7.62479 15.7929 7.49511 15.8188 7.36414 15.8188C7.23317 15.8188 7.10349 15.7929 6.98252 15.7428C6.86154 15.6926 6.75165 15.619 6.65914 15.5263Z"
        fill="#00FF82"
      />
    </svg>
  );
};

export default ReduceLogo;
