import React from "react";

import {
    Box,
    Dialog,
    Skeleton,
    Typography
} from "@mui/material";

import { UsersTable } from "../../../../common";

import "./users-dialog.css";

const headers = [
    {
        id: "name",
        label: "Name",
        isSortable: true,
        isSearchable: true,
        align: "left",
        width: "252px"
    },
    {
        id: "department",
        label: "Department",
        isSortable: true,
        isSearchable: true,
        align: "left",
        width: "208px"
    },
    {
        id: "compliant",
        label: "Compliance",
        isSortable: true,
        isSearchable: false,
        align: "left",
        width: "156px"
    },
    {
        id: "riskScore",
        label: "Risk score",
        isSortable: true,
        isSearchable: false,
        align: "left",
        width: "156px"
    },
    {
        id: "riskLevel",
        label: "Risk level",
        isSortable: false,
        isSearchable: false,
        align: "left",
        width: "164px"
    }
];

function UsersDialog({
    users,
    header,
    openDialog,
    email,
    onCloseDialog
}) {
    const handleCloseDialog = () => {
        onCloseDialog();
    };

    return (
        <Dialog
            className="users-dialog-dialog"
            open={openDialog}
            onClose={handleCloseDialog}
            fullWidth
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {
                header ?
                    <Typography component="h4" className="users-dialog-text-header">
                        {header}
                    </Typography>
                    :
                    <Skeleton variant="text" width="100%" sx={{ background: "#232221", fontSize: "20px" }} />
            }
            {
                users ?
                    Array.isArray(users) && users.length > 0 ?
                        <UsersTable
                            data={users}
                            headers={headers}
                            defaultOrderBy="name"
                            isInModal={true}
                            fixedCount={
                                email === "david@dune.demo"
                                    ? (header === "List of users who clicked on links" ? 3705
                                        : (header === "List of users who entered credentials" ? 855
                                            : 44))
                                    : null
                            }
                        />
                        :
                        <Typography component="p" marginTop="20px">
                            No users found.
                        </Typography>
                    :
                    <Box
                        display="flex"
                        flexDirection="column"
                        marginTop="40px"
                        minHeight="584px"
                        height="100%"
                    >
                        <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                    </Box>
            }
        </Dialog>
    );
}

export default UsersDialog;