
import React, {
    useState
} from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    CardContent,
    Stack,
    Typography
} from "@mui/material";
import {
    CheckOutlined,
    CircleOutlined,
    ExpandMore
} from "@mui/icons-material";

import HalfCircleIcon from "./utils/half-circle-icon/half-circle-icon";

import "./card-learning-modules-activity.css";

function CardLearningModulesActivity({
    learningModulesActivity
}) {
    const [expanded, setExpanded] = useState(false);

    const handleChangePanel = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const formatDate = (date) => {
        const givenDate = new Date(date);

        const options = {
            year: "numeric",
            month: "numeric",
            day: "numeric"
        };
        const formattedDate = givenDate.toLocaleDateString("en-US", options);

        return formattedDate;
    };

    return (
        <Card className="dune-card-dashboard">
            <CardContent className="card-learning-modules-activity-card-content">
                <Typography component="h3" className="dune-text-header-card-dashboard">
                    Learning modules activity
                </Typography>
                {
                    (learningModulesActivity && Array.isArray(learningModulesActivity)) ?
                        <Box marginTop="40px">
                            {
                                learningModulesActivity.map((learningModule, index) => {
                                    if (learningModule.status.toLowerCase() === "completed" && learningModule.userAnswer) {
                                        return (
                                            <Box marginTop={index > 0 ? "20px" : 0}>
                                                <Accordion
                                                    className="card-learning-modules-activity-accordion"
                                                    expanded={expanded === `panel-${learningModule.id}`}
                                                    onChange={handleChangePanel(`panel-${learningModule.id}`)}
                                                    key={`learning-module-activity-${index}`}
                                                >
                                                    <AccordionSummary
                                                        id={`card-learning-modules-activity-accordion-summary-${learningModule.id}-content`}
                                                        className="card-learning-modules-activity-accordion-summary"
                                                        expandIcon={<ExpandMore className="card-learning-modules-activity-icon-expand-more" />}
                                                        aria-controls={`${learningModule.id}-content`}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            width="100%"
                                                            paddingRight="20px"
                                                        >
                                                            <Stack direction="row" alignItems="center">
                                                                {
                                                                    learningModule.status.toLowerCase() === "completed" ?
                                                                        <CheckOutlined className="card-learning-modules-activity-icon-completed" />
                                                                        : (learningModule.status.toLowerCase() === "in progress" ?
                                                                            <HalfCircleIcon />
                                                                            :
                                                                            <CircleOutlined className="card-learning-modules-activity-icon-not-started" />
                                                                        )
                                                                }
                                                                <Typography
                                                                    component="h4"
                                                                    className="card-learning-modules-activity-text-header-module"
                                                                    marginLeft="20px"
                                                                >
                                                                    {learningModule.name}
                                                                </Typography>
                                                            </Stack>
                                                            <Typography
                                                                component="p"
                                                                className="card-learning-modules-activity-text-status"
                                                                sx={{
                                                                    color:
                                                                        learningModule.status.toLowerCase() === "completed" ? "#00FF82 !important"
                                                                            : (learningModule.status.toLowerCase() === "in progress" ? "#FFEA00 !important"
                                                                                : "#FFFFFF !important")
                                                                }}
                                                            >
                                                                {learningModule.status.charAt(0).toUpperCase() + learningModule.status.slice(1)}
                                                            </Typography>
                                                        </Stack>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="card-learning-modules-activity-accordion-details">
                                                        <Stack
                                                            className="card-learning-modules-activity-stack-scoring"
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            padding="16px 40px"
                                                        >
                                                            <Typography component="p" className="card-learning-modules-activity-text-details">
                                                                Overall score:<Typography component="span" className="card-learning-modules-activity-text-details-span" marginLeft="12px" sx={{ color: learningModule.scoreOverall ? (learningModule.scoreOverall >= 7 ? "#00FF82 !important" : (learningModule.scoreOverall >= 4 ? "#FFEA00 !important" : "##FF2B00 !important")) : "#FFFFFF !important" }}>{learningModule.scoreOverall}/10</Typography>
                                                            </Typography>
                                                            {
                                                                (learningModule.scoreSentiment !== null && learningModule.scoreSentiment !== undefined) &&
                                                                <Typography component="p" className="card-learning-modules-activity-text-details">
                                                                    User sentiment:<Typography component="span" className="card-learning-modules-activity-text-details-span" marginLeft="12px" sx={{ color: learningModule.scoreSentiment >= 7 ? "#00FF82 !important" : (learningModule.scoreSentiment <= 3 ? "#FF2B00 !important" : "#C2BFB8 !important") }}>{learningModule.scoreSentiment >= 7 ? "Positive" : (learningModule.scoreSentiment <= 3 ? "Negative" : "Neutral")}</Typography>
                                                                </Typography>
                                                            }
                                                            {
                                                                learningModule.completionDate &&
                                                                <Typography component="p" className="card-learning-modules-activity-text-details">
                                                                    Date completed: <Typography component="span" className="card-learning-modules-activity-text-details-span" marginLeft="12px" sx={{ color: "#C2BFB8 !important" }}>{formatDate(learningModule.completionDate)}</Typography>
                                                                </Typography>
                                                            }
                                                        </Stack>
                                                        <Box marginTop="20px">
                                                            <Typography
                                                                component="p" className="card-learning-modules-activity-text-details"
                                                            >
                                                                User response:
                                                            </Typography>
                                                            <Typography
                                                                component="p" className="card-learning-modules-activity-text-details"
                                                                marginTop="4px"
                                                            >
                                                                {learningModule.userAnswer}
                                                            </Typography>
                                                        </Box>
                                                        <Box marginTop="20px">
                                                            <Typography
                                                                component="p" className="card-learning-modules-activity-text-details"
                                                            >
                                                                Score analysis:
                                                            </Typography>
                                                            <Typography
                                                                component="p" className="card-learning-modules-activity-text-details"
                                                                marginTop="4px"
                                                            >
                                                                {learningModule.justificationUnderstanding}
                                                            </Typography>
                                                        </Box>
                                                        <Box marginTop="20px">
                                                            <Typography
                                                                component="p" className="card-learning-modules-activity-text-details"
                                                            >
                                                                Sentiment justification:
                                                            </Typography>
                                                            <Typography
                                                                component="p" className="card-learning-modules-activity-text-details"
                                                                marginTop="4px"
                                                            >
                                                                {learningModule.justificationSentiment}
                                                            </Typography>
                                                        </Box>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                        );
                                    }

                                    return (
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            marginTop={index > 0 ? "20px" : 0}
                                            key={`learning-module-activity-${index}`}
                                        >
                                            <Stack direction="row" alignItems="center">
                                                {
                                                    learningModule.status.toLowerCase() === "completed" ?
                                                        <CheckOutlined className="card-learning-modules-activity-icon-completed" />
                                                        : (learningModule.status.toLowerCase() === "in progress" ?
                                                            <HalfCircleIcon />
                                                            :
                                                            <CircleOutlined className="card-learning-modules-activity-icon-not-started" />
                                                        )
                                                }
                                                <Typography
                                                    component="h4"
                                                    className="card-learning-modules-activity-text-header-module"
                                                    marginLeft="20px"
                                                >
                                                    {learningModule.name}
                                                </Typography>
                                            </Stack>
                                            <Typography
                                                component="p"
                                                className="card-learning-modules-activity-text-status"
                                                sx={{
                                                    color:
                                                        learningModule.status.toLowerCase() === "completed" ? "#00FF82 !important"
                                                            : (learningModule.status.toLowerCase() === "in progress" ? "#FFEA00 !important"
                                                                : "#FFFFFF !important")
                                                }}
                                            >
                                                {learningModule.status.charAt(0).toUpperCase() + learningModule.status.slice(1)}
                                            </Typography>
                                        </Stack>
                                    );
                                })
                            }
                        </Box>
                        :
                        <Box className="dune-box-no-data">
                            <Typography>No assigned training</Typography>
                        </Box>
                }
            </CardContent>
        </Card >
    );
}

export default CardLearningModulesActivity;