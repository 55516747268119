import {
    AnalyticsOutlined,
    ModelTrainingOutlined
} from "@mui/icons-material";

const pagesPlatformEndUser = {
    INSIGHTS: {
        name: "Insights",
        path: "/dashboard/insights/",
        icon: <AnalyticsOutlined />,
        tooltip: "Go to the insights page.",
    },
    TRAININGS: {
        name: "Trainings",
        path: "/dashboard/trainings/",
        icon: <ModelTrainingOutlined />,
        tooltip: "Go to the trainings page."
    }
};

export default pagesPlatformEndUser;