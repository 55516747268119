import React from "react";

const HalfCircleIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_12428_39673" maskUnits="userSpaceOnUse" x="2" y="2" width="21" height="20">
                <path d="M12.5048 2C6.74224 2 2.08423 6.47 2.08423 12C2.08423 17.53 6.74224 22 12.5048 22C18.2674 22 22.9255 17.53 22.9255 12C22.9255 6.47 18.2674 2 12.5048 2ZM12.5048 20C7.89893 20 4.16835 16.42 4.16835 12C4.16835 7.58 7.89893 4 12.5048 4C17.1107 4 20.8413 7.58 20.8413 12C20.8413 16.42 17.1107 20 12.5048 20Z" fill="#00FF82" />
            </mask>
            <g mask="url(#mask0_12428_39673)">
                <rect width="10.4206" height="20" transform="matrix(-1 0 0 1 12.5046 2)" fill="#FFEA00" />
            </g>
            <mask id="mask1_12428_39673" maskUnits="userSpaceOnUse" x="2" y="2" width="21" height="20">
                <path d="M12.5047 2C18.2673 2 22.9253 6.47 22.9253 12C22.9253 17.53 18.2673 22 12.5047 22C6.74208 22 2.08407 17.53 2.08407 12C2.08407 6.47 6.74208 2 12.5047 2ZM12.5047 20C17.1106 20 20.8412 16.42 20.8412 12C20.8412 7.58 17.1106 4 12.5047 4C7.89877 4 4.16819 7.58 4.16819 12C4.16819 16.42 7.89877 20 12.5047 20Z" fill="white" />
            </mask>
            <g mask="url(#mask1_12428_39673)">
                <rect x="12.5049" y="2" width="10.4206" height="20" fill="#232221" />
            </g>
        </svg>
    );
};

export default HalfCircleIcon;