const formatProfileData = (userInformation) => {
    let profileData = {
        firstName: "",
        lastName: "",
        email: "",
        department: "",
        role: "",
        directManager: ""
    };

    if (userInformation) {
        profileData.firstName = userInformation.first_name || "";
        profileData.lastName = userInformation.last_name || "";
        profileData.email = userInformation.email || "";
        profileData.department = userInformation.department || "";
        profileData.role = userInformation.role || "";
        profileData.directManager = userInformation.manager || "";
    }

    return profileData;
};

export default formatProfileData;