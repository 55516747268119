import React from "react";

const OrganizationChartIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 25" fill="none">
            <g clip-path="url(#clip0_15607_4283)">
                <path d="M12.688 17.3935L12.688 10.4814L11.9676 10.4814L11.9676 17.3935L12.688 17.3935Z" fill="#CCCCCC" />
                <path d="M3.79694 17.3942L3.79694 13.583L20.8574 13.583L20.8574 17.3942L20.137 17.3942L20.137 14.3034L4.51732 14.3034L4.51732 17.3942L3.79694 17.3942Z" fill="#CCCCCC" />
                <path d="M14.9512 24.0262L14.9512 18.7793L9.70426 18.7793L9.70426 24.0262L14.9512 24.0262Z" fill="#BBBBBB" />
                <path d="M23.8535 24.0262L23.8535 18.7793L18.6066 18.7793L18.6066 24.0262L23.8535 24.0262Z" fill="#5D5D5D" />
                <path d="M6.04932 24.0262L6.04932 18.7793L0.802402 18.7793L0.802402 24.0262L6.04932 24.0262Z" fill="#00FF82" />
                <path d="M16.3862 9.09333L16.3862 0.974609L8.26751 0.974609L8.26751 9.09333L16.3862 9.09333Z" fill="#00FF82" />
            </g>
            <defs>
                <clipPath id="clip0_15607_4283">
                    <rect width="23.0512" height="23.0512" fill="white" transform="translate(23.8535 0.974609) rotate(90)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default OrganizationChartIcon;