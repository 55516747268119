import React from 'react';

const AccessPermissionLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.9997 14.8382C24.933 14.8382 25.8263 14.9715 26.6663 15.2249V13.5049C26.6663 12.0382 25.4663 10.8382 23.9997 10.8382H22.6663V8.17155C22.6663 4.49155 19.6797 1.50488 15.9997 1.50488C12.3197 1.50488 9.33301 4.49155 9.33301 8.17155V10.8382H7.99967C6.53301 10.8382 5.33301 12.0382 5.33301 13.5049V26.8382C5.33301 28.3049 6.53301 29.5049 7.99967 29.5049H16.3463C15.3697 28.1063 14.7953 26.4668 14.6856 24.7645C14.5759 23.0622 14.9352 21.3625 15.7244 19.8502C16.5135 18.3379 17.7023 17.071 19.1613 16.1873C20.6204 15.3036 22.2939 14.837 23.9997 14.8382ZM11.8663 8.17155C11.8663 5.89155 13.7197 4.03822 15.9997 4.03822C18.2797 4.03822 20.133 5.89155 20.133 8.17155V10.8382H11.8663V8.17155Z"
        fill="#00FF82"
      />
      <path
        d="M23.9997 17.5049C20.3197 17.5049 17.333 20.4915 17.333 24.1715C17.333 27.8515 20.3197 30.8382 23.9997 30.8382C27.6797 30.8382 30.6663 27.8515 30.6663 24.1715C30.6663 20.4915 27.6797 17.5049 23.9997 17.5049ZM23.9997 20.1715C25.1063 20.1715 25.9997 21.0649 25.9997 22.1715C25.9997 23.2782 25.1063 24.1715 23.9997 24.1715C22.893 24.1715 21.9997 23.2782 21.9997 22.1715C21.9997 21.0649 22.893 20.1715 23.9997 20.1715ZM23.9997 28.1715C22.6263 28.1715 21.413 27.4782 20.693 26.4115C21.6663 25.8515 22.7863 25.5049 23.9997 25.5049C25.213 25.5049 26.333 25.8515 27.3063 26.4115C26.5863 27.4782 25.373 28.1715 23.9997 28.1715Z"
        fill="#00FF82"
      />
    </svg>
  );
};

export default AccessPermissionLogo;
