import React from 'react';

const QuantifyLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3691 0.398438H2.36914C1.26914 0.398438 0.369141 1.29844 0.369141 2.39844V16.3984C0.369141 17.4984 1.26914 18.3984 2.36914 18.3984H16.3691C17.4691 18.3984 18.3691 17.4984 18.3691 16.3984V2.39844C18.3691 1.29844 17.4691 0.398438 16.3691 0.398438ZM6.36914 14.3984H4.36914V7.39844H6.36914V14.3984ZM10.3691 14.3984H8.36914V4.39844H10.3691V14.3984ZM14.3691 14.3984H12.3691V10.3984H14.3691V14.3984Z"
        fill="#00FF82"
      />
    </svg>
  );
};

export default QuantifyLogo;
