import React, {
    useEffect,
    useState
} from "react";
import { Link } from "react-router-dom";

import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    IconButton,
    Stack,
    TextField,
    Typography
} from "@mui/material";

import { CloseOutlined } from "@mui/icons-material";

import { postKnowledgeCheck } from "../../../../../api";

import { QuizDialogIllustration } from "../../../../../images";

import "./quiz-dialog.css";

function QuizDialog({
    openDialog,
    selectedVideo,
    email,
    onCloseDialogQuiz
}) {
    // Error
    const [isErrorResponse, setIsErrorResponse] = useState(false);

    // Response
    const [isScorePassed, setIsScorePassed] = useState(false);
    const [response, setResponse] = useState("");
    const [step, setStep] = useState(0);
    const [tips, setTips] = useState([]);

    const demoResponse = "I learned that domain spoofing can trick me into thinking fake emails are from trusted sources. I will watch out for unusual email domains, misspelled domain names, unexpected attachments or links, and emails urging urgent action. I'll also always verify the sender's email address.";

    const handleChangeResponse = (event) => {
        setResponse(event.target.value);

        if (isErrorResponse) {
            setIsErrorResponse(false);
        }
    };

    const handleClickCloseDialogQuiz = () => {
        handleCloseDialogQuiz();
    };

    const handleClickKeepWatching = () => {
        handleCloseDialogQuiz();
    };

    const handleClickSubmitResponse = async (event) => {
        event.preventDefault();

        if (email === "duncan.idaho@dune.demo") { // Demo account
            setStep(1);

            setTimeout(() => {
                setStep(2);
            }, 2000);

            if (response === demoResponse) {
                setIsScorePassed(true);
            } else {
                setIsScorePassed(false);
                setTips([
                    "Review the concept of spear phishing and how it differs from regular phishing.",
                    "Understand the importance of verifying unexpected or suspicious emails through alternative means.",
                    "Remember the key strategies to counter spear phishing attacks.",
                    "Remember the significance of reporting any suspected phishing attempts to the IT team promptly."
                ]);
            }
        } else {
            if (!response) {
                setIsErrorResponse(true);
            } else {
                setStep(1);

                const data = new FormData(event.currentTarget);
                const response = data.get("response");

                const requestBody = {
                    user_answer: response,
                    assigned_training_id: selectedVideo.id
                };

                const knowledgeCheck = await postKnowledgeCheck(requestBody);

                if (knowledgeCheck && knowledgeCheck.result && knowledgeCheck.result.understanding_score !== null) {
                    if (knowledgeCheck.result.understanding_score >= 6) {
                        setIsScorePassed(true);
                    } else {
                        setIsScorePassed(false);
                        setTips(knowledgeCheck.result.tips);
                    }

                    setStep(2);
                }
            }
        }
    };

    const handleCloseDialogQuiz = () => {
        onCloseDialogQuiz();
        setIsErrorResponse(false);
        setIsScorePassed(false);
        setResponse("");
        setTips([]);
        setStep(0);
    };

    useEffect(() => {
        if (openDialog && email === "duncan.idaho@dune.demo") {
            setResponse(demoResponse);
        }
    }, [openDialog, email]);

    return (
        <Dialog
            className="quiz-dialog-dialog"
            open={openDialog}
            onClose={handleCloseDialogQuiz}
            aria-labelledby="quiz-dialog-title"
            aria-describedby="quiz-dialog-description"
        >
            <Box>
                <IconButton
                    className="quiz-dialog-icon-button-close"
                    aria-label="Close dialog"
                    title="Close the quiz."
                    onClick={handleClickCloseDialogQuiz}
                >
                    <CloseOutlined className="quiz-dialog-icon-close" />
                </IconButton>
            </Box>
            {
                step === 0 &&
                <>
                    <Typography component="p" className="quiz-dialog-text-prompt" marginTop="16px">
                        What are a few things you learned?
                    </Typography>
                    <Typography component="p" className="quiz-dialog-text-prompt" marginTop="4px">
                        Give 2-3 points.
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleClickSubmitResponse}
                    >
                        <Typography component="p" className="quiz-dialog-text-small" marginTop="28px">
                            Response <Typography component="span" className="quiz-dialog-text-required">*</Typography>
                        </Typography>
                        <Box marginTop="16px">
                            <TextField
                                variant="filled"
                                className="dune-text-field-filled dune-text-field-filled-multiline quiz-dialog-text-field-response"
                                value={response}
                                name="response"
                                placeholder="Your response here..."
                                type="text"
                                error={isErrorResponse}
                                multiline
                                rows={8}
                                required
                                fullWidth
                                onChange={handleChangeResponse}
                                InputProps={{
                                    disableUnderline: true
                                }}
                                inputProps={{
                                    maxLength: 400
                                }}
                            />
                        </Box>
                        <Typography component="p" className="quiz-dialog-text-small" marginTop="12px">
                            {response.length}/400 characters
                        </Typography>
                        <Box display="flex" justifyContent="center" marginTop="24px">
                            <Button
                                variant="contained"
                                className="dune-button-contained-green quiz-dialog-button-submit"
                                type="submit"
                                aria-label="Submit your response."
                                title="Submit your response."
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </>
            }
            {
                step === 1 &&
                <>
                    <Typography
                        component="p"
                        className="quiz-dialog-text-prompt"
                        textAlign="center"
                        marginTop="16px"
                    >
                        Reading your answer...
                    </Typography>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        width="100%"
                    >
                        <CircularProgress className="quiz-dialog-circular-progress" size="80px" />
                    </Box>
                </>
            }
            {
                (step === 2 && isScorePassed) &&
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography
                        component="p"
                        className="quiz-dialog-text-prompt"
                        textAlign="center"
                        marginTop="16px"
                    >
                        Awesome response! You're an expert. 🤓
                    </Typography>
                    <Box
                        maxWidth="280px"
                        width="100%"
                        marginTop="60px"
                    >
                        <QuizDialogIllustration />
                    </Box>
                    <Stack
                        direction={{
                            xs: "column",
                            sm: "row"
                        }}
                        marginTop="80px"
                    >
                        <Box>
                            <Button
                                variant="contained"
                                component={Link}
                                className="dune-button-contained-gray quiz-dialog-button-trainings"
                                to="/dashboard/trainings/"
                                aria-label="Go to the trainings page."
                                title="Go to the trainings page."
                            >
                                Go to trainings
                            </Button>
                        </Box>
                        <Box
                            marginLeft={{
                                xs: 0,
                                sm: "12px"
                            }}
                        >
                            <Button
                                variant="contained"
                                className="dune-button-contained-green quiz-dialog-button-trainings"
                                aria-label="Keep watching training modules."
                                title="Keep watching training modules."
                                onClick={handleClickKeepWatching}
                            >
                                Keep watching
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            }
            {
                (step === 2 && !isScorePassed) &&
                <Box display="flex" flexDirection="column" alignItems="center" height="100%">
                    <Stack justifyContent="space-between" height="100%">
                        <Box>
                            <Typography
                                component="p"
                                className="quiz-dialog-text-prompt"
                                textAlign="center"
                                marginTop="16px"
                            >
                                Almost there. Here's a few reminders.
                            </Typography>
                            <Box marginTop="28px">
                                <ul className="quiz-dialog-list-tips">
                                    {
                                        tips.map((tips, index) =>
                                            <li className="quiz-dialog-list-item-tips" key={`tip-${index}`}>{tips}</li>
                                        )
                                    }
                                </ul>
                            </Box>
                        </Box>
                        <Stack
                            direction={{
                                xs: "column",
                                sm: "row"
                            }}
                            justifyContent="center"
                            marginTop="28px"
                        >
                            <Box>
                                <Button
                                    variant="contained"
                                    component={Link}
                                    className="dune-button-contained-gray quiz-dialog-button-trainings"
                                    to="/dashboard/trainings/"
                                    aria-label="Go to the trainings page."
                                    title="Go to the trainings page."
                                >
                                    Go to trainings
                                </Button>
                            </Box>
                            <Box
                                marginLeft={{
                                    xs: 0,
                                    sm: "12px"
                                }}
                            >
                                <Button
                                    variant="contained"
                                    className="dune-button-contained-green quiz-dialog-button-trainings"
                                    aria-label="Keep watching training modules."
                                    title="Keep watching training modules."
                                    onClick={handleClickKeepWatching}
                                >
                                    Keep watching
                                </Button>
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
            }
        </Dialog>
    );
}

export default QuizDialog;