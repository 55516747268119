import React from "react";

import {
    Box,
    Card,
    CardContent,
    Grid,
    Skeleton,
    Typography
} from "@mui/material";

import "./card-profile.css";

function CardProfile({
    profileData
}) {
    return (
        <Card className="dune-card-dashboard card-profile-card">
            <CardContent className="card-profile-card-content">
                {
                    (profileData && (profileData.firstName || profileData.lastName) && profileData.email) ?
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                paddingRight={{
                                    xs: 0,
                                    md: "30px",
                                }}
                            >
                                <Typography component="p" className="card-profile-text-small">
                                    Name
                                </Typography>
                                <Typography component="p" className="card-profile-text-name" marginTop="12px">
                                    {profileData.firstName ? profileData.firstName : ""}{profileData.lastName ? ` ${profileData.lastName}` : ""}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                marginTop={{
                                    xs: "40px",
                                    md: 0
                                }}
                                paddingLeft={{
                                    xs: 0,
                                    md: "30px",
                                }}
                            >
                                <Typography component="p" className="card-profile-text-small">
                                    Email
                                </Typography>
                                <Typography component="p" className="card-profile-text-small" marginTop="12px">
                                    {profileData.email}
                                </Typography>
                            </Grid>
                            {
                                profileData.role &&
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    marginTop="40px"
                                    paddingRight={{
                                        xs: 0,
                                        md: "30px",
                                    }}
                                >
                                    <Typography component="p" className="card-profile-text-small">
                                        Title
                                    </Typography>
                                    <Typography component="p" className="card-profile-text-small" marginTop="12px">
                                        {profileData.role}
                                    </Typography>
                                </Grid>
                            }
                            {
                                profileData.directManager &&
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    marginTop="40px"
                                    paddingLeft={{
                                        xs: 0,
                                        md: "30px",
                                    }}
                                >
                                    <Typography component="p" className="card-profile-text-small">
                                        Manager
                                    </Typography>
                                    <Typography component="p" className="card-profile-text-small" marginTop="12px">
                                        {profileData.directManager}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                        :
                        <Box display="flex" flexDirection="column" flexGrow={1} height="100%">
                            <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                        </Box>
                }
            </CardContent>
        </Card >
    );
}

export default CardProfile;