import React from "react";

import {
    IconButton,
    Tooltip
} from "@mui/material";
import { HelpOutlineOutlined } from "@mui/icons-material";

import "./button-tooltip.css";

function ButtonTooltip({
    tooltip,
    ariaLabel
}) {
    return (
        <Tooltip
            className="button-tooltip-tooltip"
            title={tooltip}
            componentsProps={{
                tooltip: {
                    sx: {
                        background: "#0C0C0B",
                        borderRadius: "4px",
                        fontFamily: "General Sans",
                        fontSize: "16px",
                        fontWeight: 400,
                        padding: "20px"
                    }
                },
            }}
        >
            <IconButton className="button-tooltip-icon-button" aria-label={ariaLabel}>
                <HelpOutlineOutlined className="button-tooltip-icon-question" />
            </IconButton>
        </Tooltip>
    );
}

export default ButtonTooltip;