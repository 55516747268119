import React from 'react';

const EcosystemSingleLineSVG = ({ isMobile }) => {
  return (
    <svg
      width={isMobile ? '10' : '20'}
      height={isMobile ? '345' : '700'}
      viewBox="0 0 20 700"
      xmlns="http://www.w3.org/2000/svg"
      fill="none">
      <defs>
        <linearGradient id="patch-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="rgba(0, 255, 130, 0)" />
          <stop offset="40%" stopColor="#00FF82" />
          <stop offset="60%" stopColor="#00FF82" />
          <stop offset="100%" stopColor="rgba(0, 255, 130, 0)" />
        </linearGradient>

        <linearGradient id="line-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="rgba(0, 255, 130, 0)" />
          <stop offset="50%" stopColor="#00FF82">
            <animate
              attributeName="offset"
              values="0.25; 0.75"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="100%" stopColor="rgba(0, 255, 130, 0)">
            <animate
              attributeName="offset"
              values="0.5; 1"
              dur="3s"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>

      <path
        d="M10 2 L10 688"
        stroke="#C2BFB8"
        strokeOpacity="0.35"
        strokeWidth="4.6"
        fill="none"
      />

      <path
        d="M10 2 L10 700"
        stroke="url(#line-gradient)"
        strokeWidth="4.6"
        fill="none"
      />

      <rect x="7.7" y="0" width="4.6" height="50" fill="url(#patch-gradient)">
        <animate
          attributeName="y"
          from="0"
          to="650"
          dur="4s"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  );
};

export default EcosystemSingleLineSVG;
