import React from 'react';

const AnomalousRiskyActivityLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.96049 22.6718H23.0405C25.0938 22.6718 26.3738 20.4451 25.3472 18.6718L15.3072 1.32512C14.2805 -0.448216 11.7205 -0.448216 10.6938 1.32512L0.65382 18.6718C-0.372846 20.4451 0.907153 22.6718 2.96049 22.6718ZM13.0005 13.3385C12.2672 13.3385 11.6672 12.7385 11.6672 12.0051V9.33845C11.6672 8.60512 12.2672 8.00512 13.0005 8.00512C13.7338 8.00512 14.3338 8.60512 14.3338 9.33845V12.0051C14.3338 12.7385 13.7338 13.3385 13.0005 13.3385ZM14.3338 18.6718H11.6672V16.0051H14.3338V18.6718Z"
        fill="#00FF82"
      />
    </svg>
  );
};

export default AnomalousRiskyActivityLogo;
