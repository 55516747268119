import React, {
    useEffect,
    useState
} from "react";
import { Navigate } from "react-router-dom";

import {
    Box,
    Card,
    CardContent,
    Typography
} from "@mui/material";
import { ErrorOutlineOutlined } from "@mui/icons-material";

import "./error.css";

function Error() {
    const [isRedirecting, setIsRedirecting] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsRedirecting(true);
        }, 5000);
    }, []);

    if (isRedirecting) {
        return (
            <Navigate to="/" />
        );
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            maxHeight={{
                xs: "100%",
                sm: "50vh"
            }}
            height="100%"
            padding="80px 28px"
        >
            <Card className="error-card">
                <CardContent className="error-card-content">
                    <ErrorOutlineOutlined className="error-icon" />
                    <Typography component="h1" className="error-text-header" marginTop="40px">Error 404</Typography>
                    <Typography component="p" marginTop="40px">We couldn't find the page you're looking for. Try again. </Typography>
                </CardContent>
            </Card>
        </Box>
    );
}

export default Error;