import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import {
  getComplianceFrameworks,
  getUsers,
  getUsersByComplianceFramework,
} from "../../../api";

import { ButtonTooltip, UsersTable } from "../../../components";

import { formatToPercentage } from "../../../constants/functions";

import "./compliance.css";

const headers = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },
  {
    id: "department",
    label: "Department",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "208px",
  },
  {
    id: "compliant",
    label: "Compliance",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "156px",
  },
  {
    id: "riskScore",
    label: "Risk score",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "156px",
  },
  {
    id: "riskLevel",
    label: "Risk level",
    isSortable: false,
    isSearchable: false,
    align: "left",
    width: "164px",
  },
];

function Compliance({ isInitialLoading, email }) {
  // Dashboard
  const [compliantUsers, setCompliantUsers] = useState(null);
  const [currentComplianceFrameworks, setCurrentComplianceFrameworks] =
    useState([]);
  const [isShowAll, setIsShowAll] = useState(true);
  const [percentageCompliantUsers, setPercentageCompliantUsers] =
    useState(null);
  const [selectedComplianceFramework, setSelectedComplianceFramework] =
    useState({
      name: "",
    });
  const [users, setUsers] = useState([]);

  // Filters
  const [complianceFrameworkFilter, setComplianceFrameworkFilter] = useState(
    {}
  );
  const [departmentFilter, setDepartmentFilter] = useState("");

  const { state } = useLocation();

  // State to cache compliance data
  const [complianceDataCache, setComplianceDataCache] = useState({});

  const handleClickComplianceFramework = (complianceFramework) => {
    if (selectedComplianceFramework.name !== complianceFramework.name) {
      setIsShowAll(false);
      setSelectedComplianceFramework(complianceFramework);

      loadUsersByComplianceFramework(complianceFramework);
    }
  };

  const handleClickComplianceFrameworkAll = () => {
    if (!isShowAll) {
      setIsShowAll(true);
      setSelectedComplianceFramework({
        name: "",
      });

      const { compliantUsers, percentageCompliantUsers, users } =
        complianceDataCache.all;

      setCompliantUsers(compliantUsers);
      setPercentageCompliantUsers(percentageCompliantUsers);
      setUsers(users);
    }
  };

  const loadUsersByComplianceFramework = async (complianceFramework) => {
    // Check if data for this compliance framework is already cached.
    if (complianceDataCache[complianceFramework.name]) {
      const { compliantUsers, percentageCompliantUsers, users } =
        complianceDataCache[complianceFramework.name];

      setCompliantUsers(compliantUsers);
      setPercentageCompliantUsers(percentageCompliantUsers);
      setUsers(users);

      return; // Return early as we have cached data.
    }

    if (email === "david@dune.demo") {
      let updatedCompliantUsers = 0;
      let updatedPercentageCompliantUsers = 0;

      if (complianceFramework === "SOC_2_Type_2") {
        updatedCompliantUsers = 9644;
        updatedPercentageCompliantUsers = 0.92;
      } else if (complianceFramework === "HIPAA") {
        updatedCompliantUsers = 9854;
        updatedPercentageCompliantUsers = 0.94;
      } else if (complianceFramework === "ISO27001") {
        updatedCompliantUsers = 9540;
        updatedPercentageCompliantUsers = 0.91;
      }

      setCompliantUsers(updatedCompliantUsers);
      setPercentageCompliantUsers(updatedPercentageCompliantUsers);
      setUsers(users);

      const updatedComplianceDataCache = { ...complianceDataCache };
      updatedComplianceDataCache[complianceFramework.name] = {
        compliantUsers: updatedCompliantUsers,
        percentageCompliantUsers,
        updatedPercentageCompliantUsers,
        users,
      };
    } else {
      const usersData = await getUsersByComplianceFramework(
        complianceFramework
      );

      if (Object.keys(usersData.error).length > 0) {
        console.error(usersData.error.message);
      } else {
        const { compliantUsers, percentageCompliantUsers, users } =
          usersData.result;

        setCompliantUsers(compliantUsers);
        setPercentageCompliantUsers(percentageCompliantUsers);
        setUsers(users);

        const updatedComplianceDataCache = { ...complianceDataCache };
        updatedComplianceDataCache[complianceFramework.name] = {
          compliantUsers,
          percentageCompliantUsers,
          users,
        };
      }
    }
  };

  useEffect(() => {
    const loadComplianceFrameworks = async () => {
      const complianceFrameworksData = await getComplianceFrameworks();

      if (Object.keys(complianceFrameworksData.error).length > 0) {
        console.error(complianceFrameworksData.error.message);
      } else {
        const { complianceFrameworks } = complianceFrameworksData.result;

        setCurrentComplianceFrameworks(complianceFrameworks);
      }
    };

    const loadUsers = async () => {
      const usersData = await getUsers();

      if (Object.keys(usersData.error).length > 0) {
        console.error(usersData.error.message);
      } else {
        const users = usersData.result;

        if (currentComplianceFrameworks.length > 0) {
          let compliantUsers = 0;

          for (let i = 0; i < users.length; i++) {
            if (users.compliant) {
              compliantUsers++;
            }
          }

          let percentageCompliantUsers = compliantUsers / users.length;

          if (email === "david@dune.demo") {
            // Demo account
            compliantUsers = 9644;
            percentageCompliantUsers = 0.92;
          }

          setCompliantUsers(compliantUsers);
          setPercentageCompliantUsers(percentageCompliantUsers);
          setUsers(users);

          setComplianceDataCache({
            all: {
              compliantUsers,
              percentageCompliantUsers,
              users,
            },
          });
        } else {
          for (let i = 0; i < users.length; i++) {
            users[i].compliant = "N/A";
          }

          setCompliantUsers("N/A");
          setPercentageCompliantUsers("N/A");
          setUsers(users);
        }
      }
    };

    const updateComponent = async () => {
      await loadComplianceFrameworks();
      await loadUsers();
    };

    if (!isInitialLoading) {
      if (state) {
        if (state.complianceFramework) {
          // Filter by compliance framework if it exists in state.
          setComplianceFrameworkFilter(state.complianceFramework);
        }

        if (state.department) {
          // Filter by department if it exists in state.
          setDepartmentFilter(state.department);
        }
      }

      updateComponent();
    }
  }, [isInitialLoading, email, currentComplianceFrameworks.length, state]);

  // if (isReadyForNextStep) {
  //     return (
  //         <Navigate to="/employees/" />
  //     );
  // }

  return (
    <Box marginTop="80px">
      <Typography component="h2" className="dune-text-header-dashboard">
        Compliance frameworks
      </Typography>
      <Typography component="p" marginTop="20px">
        If you need to update your compliance standards, email{" "}
        <Link
          className="dune-link-white"
          href="mailto:support@dunesecurity.io"
          title="Email support@dunesecurity.io."
        >
          support@dunesecurity.io
        </Link>
        .
      </Typography>
      <Box marginTop="80px">
        <ButtonGroup variant="contained">
          <Button
            className={
              isShowAll
                ? "dune-button-contained-green compliance-button-compliance-framework"
                : "compliance-button-not-selected compliance-button-compliance-framework"
            }
            onClick={handleClickComplianceFrameworkAll}
          >
            All
          </Button>
          {currentComplianceFrameworks.map((complianceFramework, index) => (
            <Button
              className={
                selectedComplianceFramework.name === complianceFramework.name
                  ? "dune-button-contained-green compliance-button-compliance-framework"
                  : "compliance-button-not-selected compliance-button-compliance-framework"
              }
              onClick={() =>
                handleClickComplianceFramework(complianceFramework)
              }
              key={`compliance-framework-${index}`}
            >
              {complianceFramework.name}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      <Grid container marginTop="80px">
        <Grid
          item
          xs={12}
          md={6}
          paddingRight={{
            xs: 0,
            md: "10px",
          }}
        >
          <Card className="dune-card-dashboard">
            <CardContent className="card-risk-score-card-content">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Box width="100%" padding="28px 0 12px 28px">
                  <Typography
                    component="h3"
                    className="dune-text-header-card-dashboard"
                  >
                    Compliant users
                  </Typography>
                </Box>
                <ButtonTooltip tooltip="" ariaLabel="" />
              </Stack>
              <Box padding="0 28px">
                {compliantUsers !== null ? (
                  <Box marginTop="4px">
                    <Typography className="card-risk-score-text-number">
                      {compliantUsers}
                    </Typography>
                  </Box>
                ) : (
                  <Box marginTop="4px">
                    <Skeleton
                      variant="text"
                      width="100%"
                      sx={{ background: "#232221", fontSize: "96px" }}
                    />
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          paddingLeft={{
            xs: 0,
            md: "10px",
          }}
        >
          <Card className="dune-card-dashboard">
            <CardContent className="card-risk-score-card-content">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Box width="100%" padding="28px 0 12px 28px">
                  <Typography
                    component="h3"
                    className="dune-text-header-card-dashboard"
                  >
                    Percent of total users compliant
                  </Typography>
                </Box>
                <ButtonTooltip tooltip="" ariaLabel="" />
              </Stack>
              <Box padding="0 28px">
                {percentageCompliantUsers !== null ? (
                  <Box marginTop="4px">
                    <Typography className="card-risk-score-text-number">
                      {typeof percentageCompliantUsers === "number"
                        ? formatToPercentage(percentageCompliantUsers)
                        : percentageCompliantUsers}
                    </Typography>
                  </Box>
                ) : (
                  <Box marginTop="4px">
                    <Skeleton
                      variant="text"
                      width="100%"
                      sx={{ background: "#232221", fontSize: "96px" }}
                    />
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {users.length > 0 && (
        <Box marginTop="60px">
          <UsersTable
            data={users}
            headers={headers}
            defaultOrderBy={complianceFrameworkFilter ? "compliant" : "name"}
            defaultFilters={{
              complianceFramework: complianceFrameworkFilter,
              department: departmentFilter,
            }}
            fixedCount={email === "david@dune.demo" ? 10483 : null}
          />
        </Box>
      )}
    </Box>

    //     !isBRACompleted || currentComplianceFrameworks.length === 0 ?
    //         <Grid container>
    //             <Grid item xs={12} marginTop="80px">
    //                 <Typography component="h2" className="dune-text-header-dashboard">Choose compliance frameworks</Typography>
    //                 <Typography component="p" marginTop="20px">
    //                     Add compliance standards relevant to your organization. Once saved, additions are possible but deletions aren't.
    //                 </Typography>
    //             </Grid>
    //             <Grid item xs={12} marginTop="80px">
    //                 <FormGroup className="compliance-onboarding-form-group">
    //                     {
    //                         complianceFrameworks.map((framework, index) => {
    //                             const isSelected = selectedComplianceFrameworks.includes(framework);
    //                             const isCurrent = currentComplianceFrameworks.includes(framework);

    //                             return (
    //                                 <Button
    //                                     variant="contained"
    //                                     className={
    //                                         (isSelected || isCurrent)
    //                                             ? "dune-button-contained-gray compliance-onboarding-button-compliance compliance-onboarding-button-compliance-selected"
    //                                             : "dune-button-contained-gray compliance-onboarding-button-compliance"
    //                                     }
    //                                     disabled={isCurrent}
    //                                     onClick={() => handleClickComplianceFramework(framework)}
    //                                     key={`compliance-framework-${index}`}
    //                                 >
    //                                     <FormControlLabel
    //                                         control={
    //                                             <Checkbox checked={isSelected || isCurrent} />
    //                                         }
    //                                         label={framework}
    //                                         onClick={() => handleClickComplianceFramework(framework)}
    //                                     />
    //                                 </Button>
    //                             );
    //                         })
    //                     }
    //                     {
    //                         currentComplianceFrameworks.length !== complianceFrameworks.length &&
    //                         <Button
    //                             variant="contained"
    //                             className={
    //                                 isSelectAll
    //                                     ? "dune-button-contained-gray compliance-onboarding-button-compliance compliance-onboarding-button-compliance-selected"
    //                                     : "dune-button-contained-gray compliance-onboarding-button-compliance"
    //                             }
    //                             onClick={handleClickSelectAll}
    //                         >
    //                             <FormControlLabel
    //                                 control={
    //                                     <Checkbox checked={isSelectAll} />
    //                                 }
    //                                 label="Select all"
    //                                 onClick={handleClickSelectAll}
    //                             />
    //                         </Button>
    //                     }
    //                     {
    //                         currentComplianceFrameworks.length === 0 &&
    //                         <Button
    //                             variant="contained"
    //                             className={
    //                                 isSelectNone
    //                                     ? "dune-button-contained-gray compliance-onboarding-button-compliance compliance-onboarding-button-compliance-selected"
    //                                     : "dune-button-contained-gray compliance-onboarding-button-compliance"
    //                             }
    //                             onClick={handleClickSelectNone}
    //                         >
    //                             <FormControlLabel
    //                                 control={
    //                                     <Checkbox checked={isSelectNone} />
    //                                 }
    //                                 label="None"
    //                                 onClick={handleClickSelectNone}
    //                             />
    //                         </Button>
    //                     }
    //                     {
    //                         selectedComplianceFrameworks.length > 0 &&
    //                         <Button
    //                             variant="outlined"
    //                             className="dune-button-outlined-white compliance-onboarding-button-save"
    //                             onClick={handleClickSave}
    //                         >
    //                             Save
    //                         </Button>
    //                     }
    //                 </FormGroup>
    //             </Grid>
    //         </Grid>
    //         :
    //         <Grid
    //             container
    //             columnSpacing={{
    //                 xs: 0,
    //                 md: 3
    //             }}
    //         >
    //             <GridItemHeader item xs={12}>
    //                 <Typography component="h2" className="dune-text-header-dashboard">
    //                     Compliance frameworks
    //                 </Typography>
    //             </GridItemHeader>
    //             <GridItemContent item xs={12}>
    //                 <Select
    //                     variant="standard"
    //                     className="dune-select-gray compliance-select"
    //                     value={selectedComplianceFramework}
    //                     displayEmpty
    //                     fullWidth
    //                     disableUnderline
    //                     onChange={handleChangeComplianceType}
    //                 >
    //                     {
    //                         currentComplianceFrameworks.map((framework, index) =>
    //                             <MenuItem
    //                                 className="dune-menu-item"
    //                                 value={framework}
    //                                 key={`framework-${index}`}
    //                             >
    //                                 {framework}
    //                             </MenuItem>
    //                         )
    //                     }
    //                 </Select>
    //             </GridItemContent>
    //             <GridItemHeader item xs={12}>
    //                 <Typography component="h2" className="dune-text-header-dashboard">
    //                     Organization compliance
    //                 </Typography>
    //             </GridItemHeader>
    //             <GridItemContent item xs={12} md={6}>
    //                 {/* <CardNumber
    //                     header="Employees compliant"
    //                     number={formatToPercentage(employeesCompliant)}
    //                     noBorder={true}
    //                 /> */}
    //             </GridItemContent>
    //             {/* <GridItemContent item xs={12} md={6}>
    //     <CardNumber
    //         header="Progress"
    //         number={formatToPercentage(progressWithin30Days)}
    //         noBorder={true}
    //     />
    // </GridItemContent> */}
    //             <GridItemHeader item xs={12}>
    //                 <Typography component="h2" className="dune-text-header-dashboard">
    //                     User compliance
    //                 </Typography>
    //             </GridItemHeader>
    //             <Grid item xs={12}>
    //                 <UsersTable
    //                     data={users}
    //                     headers={headers}
    //                     defaultOrderBy="name"
    //                     enableSearchBar={false} />
    //             </Grid>
    //         </Grid>
  );
}

export default Compliance;
