import React from "react";

import "./sankey-chart.css";

const SankeyChart = ({
    totalInteractionsResponded,
    totalInteractionsClicked,
    totalInteractionsKeyDown,
    totalInteractionsDataEntered,
    totalInteractionsNoEngagement,
    totalInteractionsReported,
    onClickInteraction
}) => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 948 352" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="168" width="8" height="102" rx="4" fill="#EFECE3" />

            {/* Replied to test (responded) */}
            <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M12 178.518V184C152 184 152 36.479 292 36.479V30C152 30 152 178.518 12 178.518Z" fill="url(#paint4_linear_1758_18108)" />
            <circle
                cx="300"
                cy="11"
                r="2"
                fill="#FFEA00"
            />
            <rect
                className="sankey-chart-rect-interaction"
                x="296"
                y="26"
                width="8"
                height="14"
                rx="4"
                fill="#FFEA00"
                onClick={() => onClickInteraction("smtp_reply")}
            />
            <text
                x="308"
                y="16"
                className="sankey-chart-text-number"
                fill="#FFFFFF"
                onClick={() => onClickInteraction("smtp_reply")}
            >
                {totalInteractionsResponded} tests responded to
            </text>

            {/* Clicked on test (clicked) */}
            <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M12 186.467V196C152 196 152 108.712 292 108.712V95C152 95 152 186.467 12 186.467Z" fill="url(#paint5_linear_1758_18108)" />
            <circle
                cx="300"
                cy="75"
                r="2"
                fill="#FFEA00"
            />
            <rect
                className="sankey-chart-rect-interaction"
                x="296"
                y="90"
                width="8"
                height="22"
                rx="4"
                fill="#FFEA00"
                onClick={() => onClickInteraction("phished")}
            />
            <text
                x="308"
                y="80"
                className="sankey-chart-text-number"
                fill="#FFFFFF"
                onClick={() => onClickInteraction("phished")}
            >
                {totalInteractionsClicked} links clicked
            </text>

            {/* Entered credentials (key down) */}
            <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M308 97V105C408 105 408 105 508 105V97C408 97 408 97 308 97Z" fill="url(#paint0_linear_1758_18108)" />
            <circle
                cx="516"
                cy="75"
                r="2"
                fill="#FFA200"
            />
            <rect
                className="sankey-chart-rect-interaction"
                x="512"
                y="93"
                width="8"
                height="16"
                rx="4"
                fill="#FFA200"
                onClick={() => onClickInteraction("key_down")}
            />
            <text
                x="524"
                y="80"
                className="sankey-chart-text-number"
                fill="#FFFFFF"
                onClick={() => onClickInteraction("key_down")}
            >
                {totalInteractionsKeyDown} credentials entered
            </text>

            {/* Submitted credentials (data entered) */}
            <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M524 99V104C624 104 624 104 724 104V99C624 99 624 99 524 99Z" fill="url(#paint1_linear_1758_18108)" />
            <circle
                cx="732"
                cy="75"
                r="2"
                fill="#FF2B00"
            />
            <rect
                className="sankey-chart-rect-interaction"
                x="728"
                y="96"
                width="8"
                height="10"
                rx="4"
                fill="#FF2B00"
                onClick={() => onClickInteraction("data_entered")}
            />
            <text
                x="740"
                y="80"
                className="sankey-chart-text-number"
                fill="#FFFFFF"
                onClick={() => onClickInteraction("data_entered")}
            >
                {totalInteractionsDataEntered} credentials submitted
            </text>

            {/* Ignored test (no engagement) */}
            <path opacity="0.8" fill-rule="evenodd" clip-rule="evenodd" d="M12 200V240C152 240 152 240 292 240V200C152 200 152 200 12 200Z" fill="url(#paint3_linear_1758_18108)" />
            <circle
                cx="300"
                cy="174"
                r="2"
                fill="#EFECE3"
            />
            <rect
                className="sankey-chart-rect-interaction"
                x="296"
                y="189"
                width="8"
                height="60"
                rx="4"
                fill="#EFECE3"
                onClick={() => onClickInteraction("no_action")}
            />
            <text
                x="308"
                y="178"
                className="sankey-chart-text-number"
                fill="#FFFFFF"
                onClick={() => onClickInteraction("no_action")}
            >
                {totalInteractionsNoEngagement} tests ignored
            </text>

            {/* Reported to Watchtower (reported) */}
            <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M12 244V257.043C152 257.043 152 344 292 344V327.696C152 327.696 152 244 12 244Z" fill="url(#paint2_linear_1758_18108)" />
            <circle
                cx="300"
                cy="306"
                r="2"
                fill="#00FF82"
            />
            <rect
                className="sankey-chart-rect-interaction"
                x="296"
                y="321"
                width="8"
                height="30"
                rx="4"
                fill="#00FF82"
                onClick={() => onClickInteraction("watchtower_reported")}
            />
            <text
                x="308"
                y="310"
                className="sankey-chart-text-number"
                fill="#FFFFFF"
                onClick={() => onClickInteraction("watchtower_reported")}
            >
                {totalInteractionsReported} reported to Watchtower
            </text>

            <defs>
                <linearGradient id="paint0_linear_1758_18108" x1="308" y1="106.5" x2="508" y2="106.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFEA00" />
                    <stop offset="1" stop-color="#FFA200" />
                </linearGradient>
                <linearGradient id="paint1_linear_1758_18108" x1="524" y1="101.5" x2="724" y2="101.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFA200" />
                    <stop offset="1" stop-color="#FF2B00" />
                </linearGradient>
                <linearGradient id="paint2_linear_1758_18108" x1="12" y1="244" x2="292" y2="244" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EFECE3" />
                    <stop offset="1" stop-color="#00FF82" />
                </linearGradient>
                <linearGradient id="paint3_linear_1758_18108" x1="12" y1="220" x2="292" y2="220" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EFECE3" stop-opacity="0.2" />
                    <stop offset="1" stop-color="#EFECE3" />
                </linearGradient>
                <linearGradient id="paint4_linear_1758_18108" x1="292" y1="30" x2="4.17391" y2="30" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFEA00" />
                    <stop offset="1" stop-color="#EFECE3" />
                </linearGradient>
                <linearGradient id="paint5_linear_1758_18108" x1="292" y1="95" x2="12" y2="95" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFEA00" />
                    <stop offset="1" stop-color="#EFEBE3" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default SankeyChart;