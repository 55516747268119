import React from 'react';

const UserSpecificTestingLogo = () => {
  return (
    // <svg
    //   width="32"
    //   height="32"
    //   viewBox="0 0 32 33"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M22.6667 15.0065C23.12 15.0065 23.56 15.0598 24 15.1265V8.69984L14 4.33984L4 8.69984V15.2465C4 21.2998 8.26667 26.9665 14 28.3398C14.7333 28.1665 15.44 27.9132 16.1333 27.6065C15.2133 26.2998 14.6667 24.7132 14.6667 23.0065C14.6667 18.5932 18.2533 15.0065 22.6667 15.0065Z"
    //     fill="#00FF82"
    //   />
    //   <path
    //     d="M22.6673 17.6729C19.7207 17.6729 17.334 20.0595 17.334 23.0062C17.334 25.9529 19.7207 28.3395 22.6673 28.3395C25.614 28.3395 28.0007 25.9529 28.0007 23.0062C28.0007 20.0595 25.614 17.6729 22.6673 17.6729ZM22.6673 19.5129C23.494 19.5129 24.1607 20.1929 24.1607 21.0062C24.1607 21.8195 23.4807 22.4995 22.6673 22.4995C21.854 22.4995 21.174 21.8195 21.174 21.0062C21.174 20.1929 21.8407 19.5129 22.6673 19.5129ZM22.6673 26.6729C21.4273 26.6729 20.3473 26.0595 19.6807 25.1129C19.7473 24.1529 21.694 23.6729 22.6673 23.6729C23.6407 23.6729 25.5873 24.1529 25.654 25.1129C24.9873 26.0595 23.9073 26.6729 22.6673 26.6729Z"
    //     fill="#00FF82"
    //   />
    // </svg>
    <svg
      width="32"
      height="32"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6667 11.0065C19.12 11.0065 19.56 11.0598 20 11.1265V4.69984L10 0.339844L0 4.69984V11.2465C0 17.2998 4.26667 22.9665 10 24.3398C10.7333 24.1665 11.44 23.9132 12.1333 23.6065C11.2133 22.2998 10.6667 20.7132 10.6667 19.0065C10.6667 14.5932 14.2533 11.0065 18.6667 11.0065Z"
        fill="#00FF82"
      />
      <path
        d="M18.6673 13.6729C15.7207 13.6729 13.334 16.0595 13.334 19.0062C13.334 21.9529 15.7207 24.3395 18.6673 24.3395C21.614 24.3395 24.0007 21.9529 24.0007 19.0062C24.0007 16.0595 21.614 13.6729 18.6673 13.6729ZM18.6673 15.5129C19.494 15.5129 20.1607 16.1929 20.1607 17.0062C20.1607 17.8195 19.4807 18.4995 18.6673 18.4995C17.854 18.4995 17.174 17.8195 17.174 17.0062C17.174 16.1929 17.8407 15.5129 18.6673 15.5129ZM18.6673 22.6729C17.4273 22.6729 16.3473 22.0595 15.6807 21.1129C15.7473 20.1529 17.694 19.6729 18.6673 19.6729C19.6407 19.6729 21.5873 20.1529 21.654 21.1129C20.9873 22.0595 19.9073 22.6729 18.6673 22.6729Z"
        fill="#00FF82"
      />
    </svg>
  );
};

export default UserSpecificTestingLogo;
