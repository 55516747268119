import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";
import { CheckOutlined, CircleOutlined } from "@mui/icons-material";

import { getTrainings, postTrainingCompleted } from "../../../api";

import InProgressIcon from "./utils/in-progress-icon/in-progress-icon";
import QuizDialog from "./utils/quiz-dialog/quiz-dialog";
import TrainingVideo from "./utils/training-video/training-video";

import "./trainings-video-player.css";

function TrainingsVideoPlayer({ isInitialLoading, email }) {
  // Modules data
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [modules, setModules] = useState([]);
  const [category, setCategory] = useState(null);

  // Boolean data
  const [isClickedTakeQuiz, setIsClickedTakeQuiz] = useState(false);
  const [isComplianceModulesExisting, setIsComplianceModulesExisting] =
    useState(false);
  const [isCompletedModulesExisting, setIsCompletedModulesExisting] =
    useState(false);
  const [isQuizLocked, setIsQuizLocked] = useState(true);

  // Quiz dialog data
  const [openDialogQuiz, setOpenDialogQuiz] = useState(false);

  const complianceFrameworks = [
    {
      name: "GDPR",
    },
    {
      name: "GLBA",
    },
    {
      name: "HIPAA",
    },
    {
      name: "ISO 27001",
    },
    {
      name: "NIST",
    },
    {
      name: "SOC 2 Type 2",
    },
  ];

  const { pathname, state } = useLocation();

  const handleClickModule = (module) => {
    setSelectedVideo(module);
    setIsClickedTakeQuiz(false);

    if (email === "duncan.idaho@dune.demo") {
      // Demo account
      setIsQuizLocked(false);
    } else {
      setIsQuizLocked(module.status !== "in progress");
    }
  };

  const handleClickTakeQuiz = () => {
    if (!isClickedTakeQuiz) {
      setIsClickedTakeQuiz(true);
    }

    if (!isQuizLocked) {
      setOpenDialogQuiz(true);
    }
  };

  const handleCloseDialogQuiz = async () => {
    setOpenDialogQuiz(false);
    await loadTrainings();
  };

  const handleEndedVideo = async () => {
    if (selectedVideo.status !== "completed") {
      if (
        complianceFrameworks.some(
          (complianceFramework) =>
            complianceFramework.name === selectedVideo.category
        )
      ) {
        const requestBody = {
          training_id: selectedVideo.id,
        };

        const videoTrainingCompletion = await postTrainingCompleted(
          requestBody
        );

        if (Object.keys(videoTrainingCompletion.error).length > 0) {
          console.error(videoTrainingCompletion.error.message);
        } else {
          const updatedSelectedVideo = { ...selectedVideo };
          updatedSelectedVideo.status = "completed";
          setSelectedVideo(updatedSelectedVideo);

          const updatedModules = [...modules];
          const selectedVideoIndex = updatedModules.findIndex(
            (module) => module.id === selectedVideo.id
          );

          if (selectedVideoIndex !== -1) {
            updatedModules[selectedVideoIndex].status = "completed";
          }

          setModules(updatedModules);
        }
      } else {
        setOpenDialogQuiz(true);
        setIsQuizLocked(false);
      }
    }
  };

  const loadTrainings = useCallback(async (isFirstTime = false) => {
    const trainings = await getTrainings();

    if (Object.keys(trainings.error).length > 0) {
      console.error(trainings.error.message);
    } else {
      const { learningModules, complianceModules, completedModules } =
        trainings.result;

      if (pathname === "/dashboard/trainings/compliance/") {
        // Compliance
        if (
          !(
            complianceModules &&
            Array.isArray(complianceModules) &&
            complianceModules.length > 0
          )
        ) {
          setIsComplianceModulesExisting(true);
        } else {
          setModules(complianceModules[0].videos);

          if (isFirstTime) {
            // Use the first assigned compliance framework.
            setSelectedVideo(complianceModules[0].videos[0]);
            setCategory(`${complianceModules[0].name} compliance`);
            setIsQuizLocked(
              complianceModules[0].videos[0].status !== "in progress"
            );
          }
        }
      } else if (pathname === "/dashboard/trainings/completed/") {
        // Completed
        if (
          !(
            completedModules &&
            Array.isArray(completedModules) &&
            completedModules.length > 0
          )
        ) {
          setIsCompletedModulesExisting(true);
        } else {
          setModules(completedModules);

          if (isFirstTime) {
            setSelectedVideo(completedModules[0]);
            setCategory("Completed modules");
            setIsQuizLocked(completedModules[0].status !== "in progress");
          }
        }
      } else {
        // Learning
        setModules(learningModules);

        if (isFirstTime) {
          setSelectedVideo(learningModules[0]);
          setCategory("Learning modules");
          setIsQuizLocked(learningModules[0].status !== "in progress");
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInitialLoading && email) {
      if (state && state.selectedVideo && state.modules && state.category) {
        setSelectedVideo(state.selectedVideo);
        setModules(state.modules);
        setCategory(state.category);

        if (email === "duncan.idaho@dune.demo") {
          // Demo account
          setIsQuizLocked(false);
        } else {
          setIsQuizLocked(state.selectedVideo.status !== "in progress");
        }
      } else {
        if (email === "duncan.idaho@dune.demo") {
          const modules = [
            {
              id: "0",
              status: "Not started",
              title: "Domain Spoofing",
              fileName: "domain-spoofing.mp4",
              startDate: "2024-06-18T00:00:00",
            },
            {
              id: "1",
              status: "Not started",
              title: "Malicious Attachments",
              fileName: "malicious-attachments.mp4",
              startDate: "2024-06-18T00:00:00",
            },
            {
              id: "2",
              status: "Not started",
              title: "Business Email Compromise",
              fileName: "business-email-compromise.mp4",
              startDate: "2024-06-18T00:00:00",
            },
          ];

          setSelectedVideo(modules[0]);
          setModules(modules);
          setCategory("Learning modules");
          setIsQuizLocked(false);
        } else {
          loadTrainings(true);
        }
      }
    }
  }, [isInitialLoading, email, loadTrainings, state]);

  if (isComplianceModulesExisting || isCompletedModulesExisting) {
    return <Navigate to="/error/" />;
  }

  return (
    <>
      <Box marginTop="80px">
        <Box
          display="flex"
          flexDirection="column"
          minHeight="393px"
          height="100%"
          width="100%"
        >
          {selectedVideo ? (
            <Box>
              <Box
                height={{
                  xs: "100%",
                  lg: "500px",
                }}
              >
                <TrainingVideo
                  selectedVideo={selectedVideo}
                  pathname={pathname}
                  onEndedVideo={handleEndedVideo}
                />
              </Box>
              <Stack
                direction={{
                  xs: "column",
                  md: "row",
                }}
                justifyContent="space-between"
                marginTop="40px"
              >
                <Typography
                  component="h2"
                  className="trainings-video-player-text-header-title"
                >
                  {selectedVideo.title}
                </Typography>
                <Box
                  height="85px"
                  marginTop={{
                    xs: "20px",
                    md: 0,
                  }}
                >
                  {!complianceFrameworks.some(
                    (complianceFramework) =>
                      complianceFramework.name === selectedVideo.category
                  ) &&
                    selectedVideo.status !== "completed" && (
                      <>
                        <Box
                          display="flex"
                          justifyContent={{
                            xs: "flex-start",
                            md: "flex-end",
                          }}
                        >
                          <Button
                            variant="contained"
                            className="dune-button-contained-green trainings-video-player-button-take-quiz"
                            title="Take the quiz for this module."
                            aria-label="Take the quiz for this module."
                            onClick={handleClickTakeQuiz}
                          >
                            Take quiz
                          </Button>
                        </Box>
                        {isQuizLocked && isClickedTakeQuiz && (
                          <Typography
                            component="p"
                            className="trainings-video-player-text-green"
                            marginTop="12px"
                          >
                            Complete the video to take the quiz.
                          </Typography>
                        )}
                      </>
                    )}
                </Box>
              </Stack>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              height="100%"
            >
              <Skeleton
                variant="rectangular"
                className="dune-skeleton-rectangular"
                width="100%"
                height="100%"
              />
            </Box>
          )}
        </Box>
        <Box marginTop="40px">
          {modules && Array.isArray(modules) && category ? (
            <Box>
              <Box
                className="trainings-video-player-box-modules-title"
                padding="20px"
              >
                <Typography
                  component="p"
                  className="trainings-video-player-text-small"
                >
                  {category}
                </Typography>
                <Typography
                  component="p"
                  className="trainings-video-player-text-small"
                  marginTop="8px"
                >
                  1 of {modules.length}
                </Typography>
              </Box>
              <Box marginTop="8px">
                {modules.map((module, index) => (
                  <Stack
                    className="trainings-video-player-stack-video-list-item"
                    direction="row"
                    alignItems="center"
                    marginTop="4px"
                    padding="20px"
                    onClick={() => handleClickModule(module)}
                    sx={{
                      borderTop:
                        module.id === selectedVideo.id
                          ? "1px solid #00FF82"
                          : "none",
                    }}
                    key={`modules-list-item-${index}`}
                  >
                    {module.status === "in progress" ? (
                      <InProgressIcon />
                    ) : module.status === "completed" ? (
                      <CheckOutlined className="trainings-video-player-icon-completed" />
                    ) : (
                      <CircleOutlined className="trainings-video-player-icon-not-started" />
                    )}
                    <Box width="100%" marginLeft="20px">
                      <Typography
                        component="p"
                        className="trainings-video-player-text-small"
                      >
                        {module.title}
                      </Typography>
                    </Box>
                  </Stack>
                ))}
              </Box>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              height="100%"
            >
              <Skeleton
                variant="rectangular"
                className="dune-skeleton-rectangular"
                width="100%"
                height="100%"
              />
            </Box>
          )}
        </Box>
      </Box>
      <QuizDialog
        openDialog={openDialogQuiz}
        selectedVideo={selectedVideo}
        email={email}
        onCloseDialogQuiz={handleCloseDialogQuiz}
      />
    </>
  );
}

export default TrainingsVideoPlayer;
