import React, { useMemo, useRef } from 'react';
import { Box, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import BlueEnvelopeIcon from '../blue-envelope-icon/blue-envelope-icon';
import DescopeLogoIcon from '../descope-logo-icon/descope-logo-icon';
import DuneSecurityLogo from '../dune-security-logo/dune-security-logo';
import OktaLogoIcon from '../okta-logo-icon/okta-logo-icon';
import MicrosoftEntraIDIcon from '../microsoft-entra-id-icon/microsoft-entra-id-icon';
import OrganizationChartIcon from '../organization-chart-icon/organization-chart-icon';
import PhoneIcon from '../phone-icon/phone-icon';
import SMSIcon from '../sms-icon/sms-icon';
import StarIcon from '../star-icon/star-icon';
import ZoomLogoIcon from '../zoom-logo-icon/zoom-logo-icon';
import './ecosystem-diagram.css';
import EcosystemLinearSVG from './ecosystem-linear-svg';
import EcosystemEclipseSVG from './ecosystem-eclipse-svg';

import { spacing } from '../../../../../styles';
import EcosystemSingleLineSVG from './ecosystem-single-line-svg';
import {
  BasicUserDataLogo,
  BlastRadiusAnalysisLogo,
  UserSpecificTestingLogo,
  AnomalousRiskyActivityLogo,
  AccessPermissionLogo,
  AdaptLogo,
} from './logos';
import ReduceLogo from './logos/reduce-logo/reduce-logo';
import QuantifyLogo from './logos/quantify-logo/quantify-logo';
import GoogleIcon from '../google-icon/google-icon';
import MicrosoftDefenderIcon from '../microsoft-defender-icon/microsoft-defender-icon';
import VirusTotalIcon from '../virus-total-icon/virus-total-icon';

const EcosystemDiagram = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLinearView = useMediaQuery('(min-width: 1250px)');
  const domRef = useRef();
  const topBoxesRefs = useRef([]);
  const box1Ref = useRef(null);
  const box2Ref = useRef(null);
  const box3Ref = useRef(null);

  const ecosystemInputs = useMemo(
    () => [
      {
        title: 'Basic User Data',
        icon: <BasicUserDataLogo />,
        logos: [
          <MicrosoftEntraIDIcon />,
          <OktaLogoIcon />,
          <GoogleIcon />,
          <DescopeLogoIcon />,
        ],
      },
      {
        title: 'Blast Radius Analysis',
        icon: <BlastRadiusAnalysisLogo />,
        logos: [<StarIcon />, <OrganizationChartIcon />],
      },
      {
        title: 'User Specific Testing',
        icon: <UserSpecificTestingLogo />,
        logos: [
          <BlueEnvelopeIcon />,
          <SMSIcon />,
          <PhoneIcon />,
          <ZoomLogoIcon />,
        ],
      },
      {
        title: 'Anomalous & Risky Activity',
        icon: <AnomalousRiskyActivityLogo />,
        logos: [
          <MicrosoftEntraIDIcon />,
          <OktaLogoIcon />,
          <GoogleIcon />,
          <DescopeLogoIcon />,
          <MicrosoftDefenderIcon />,
          <Box
            component="img"
            src="https://d3oo9a669kwmx7.cloudfront.net/logos/urlscan-icon.png"
            alt="URLScan"
            width="100%"
            height="100%"
          />,
          <VirusTotalIcon />,
        ],
      },
      {
        title: 'Access & Permssions',
        icon: <AccessPermissionLogo />,
        logos: [
          <MicrosoftEntraIDIcon />,
          <OktaLogoIcon />,
          <GoogleIcon />,
          <DescopeLogoIcon />,
          <MicrosoftDefenderIcon />,
          <Box
            component="img"
            src="https://d3oo9a669kwmx7.cloudfront.net/logos/urlscan-icon.png"
            alt="URLScan"
            width="100%"
            height="100%"
          />,
          <VirusTotalIcon />,
        ],
      },
    ],
    []
  );

  return (
    <Box position="relative" className={`fade-in`} ref={domRef}>
      <Box
        display="flex"
        justifyContent="center"
        marginTop={{
          xs: '80px',
          md: '120px',
        }}
        padding="0 30px">
        <Box
          className="home-box-arrows"
          maxWidth={{
            xs: '100%',
            md: spacing.MAX_WIDTH_WEBSITE,
          }}
          width="100%">
          <Box>
            <Typography
              variant="h2"
              component="h2"
              className="home-text-header-secondary"
              textAlign="center">
              Welcome to the{' '}
              <Typography
                component="span"
                className="home-text-header-title-italic">
                future
              </Typography>{' '}
              of employee risk management
            </Typography>
            <Typography textAlign="center" paddingTop="10px" variant="body1">
              Quantify individual user risk, target training to users’
              vulnerabilities, and adapt security controls for high-risk users.
            </Typography>
          </Box>
          <Box zIndex={2} position="relative">
            <Box marginTop="45px">
              <Stack
                direction="row"
                justifyContent="space-around"
                flexWrap="wrap">
                {ecosystemInputs.map((input, inputIndex) => (
                  <div
                    className="home-box-ecosystem-input"
                    ref={(el) => (topBoxesRefs.current[inputIndex] = el)}
                    key={`ecosystem-input-${inputIndex}`}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {input.icon}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '12px',
                      }}>
                      <Typography
                        variant="body-emphasized-gray"
                        component="span"
                        textAlign="center">
                        {input.title}
                      </Typography>
                    </div>
                    <Box marginTop="12px">
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        {input.logos.map((logo, logoIndex) => (
                          <Box
                            display="flex"
                            alignItems="center"
                            width="20px"
                            height="20px"
                            marginLeft={logoIndex > 0 ? '8px' : 0}
                            key={`ecosystem-input-${inputIndex}-logo-${logoIndex}`}>
                            {logo}
                          </Box>
                        ))}
                      </Stack>
                    </Box>
                  </div>
                ))}
              </Stack>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            marginTop={isLinearView ? '-16px' : '-600px'}
            zIndex={-1}>
            {isLinearView ? (
              <EcosystemLinearSVG isMobile={isMobile} />
            ) : (
              <EcosystemSingleLineSVG />
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop="-22px"
            zIndex={2}
            position="relative">
            <DuneSecurityLogo />
          </Box>

          <Box
            zIndex={2}
            position="relative"
            display={{
              xs: 'flex',
            }}
            flexDirection="column"
            flexWrap="wrap">
            <Box display="flex" justifyContent="center" marginTop="12px">
              <Stack
                direction={isMobile ? 'column' : 'row'}
                justifyContent="space-between"
                maxWidth={isMobile ? '400px' : '700px'}
                width="100%">
                <Box
                  ref={box1Ref}
                  className="ecosystem-diagram-box-background-gray"
                  padding="8px"
                  marginBottom={isMobile ? '8px' : '0'}>
                  <Stack direction="row" alignItems="center">
                    <Box display="flex" alignItems="center">
                      <QuantifyLogo />
                    </Box>
                    <Box display="flex" alignItems="center" marginLeft="8px">
                      <Typography
                        variant="body-emphasized-gray"
                        component="span"
                        textAlign="center">
                        Quantify
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
                <Box
                  ref={box2Ref}
                  className="ecosystem-diagram-box-background-gray"
                  padding="8px"
                  marginBottom={isMobile ? '8px' : '0'}>
                  <Stack direction="row" alignItems="center">
                    <Box display="flex" alignItems="center">
                      <AdaptLogo />
                    </Box>
                    <Box display="flex" alignItems="center" marginLeft="8px">
                      <Typography
                        variant="body-emphasized-gray"
                        component="span"
                        textAlign="center">
                        Adapt
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
            <Box display="flex" justifyContent="center" marginTop="8px">
              <Box
                ref={box3Ref}
                marginTop={{ xs: '-8px' }}
                className="ecosystem-diagram-box-background-gray"
                padding="8px"
                maxWidth={isMobile ? '400px' : '700px'}
                width={{
                  xs: '100%',
                  md: 'auto',
                }}>
                <Stack direction="row" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <ReduceLogo />
                  </Box>
                  <Box display="flex" alignItems="center" marginLeft="8px">
                    <Typography
                      variant="body-emphasized-gray"
                      component="span"
                      textAlign="center">
                      Reduce
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex={1}
            marginTop={isMobile ? '-200px' : '-180px'}
            marginBottom={isMobile ? '80px' : '0'}
            position="relative">
            <Box>
              <EcosystemEclipseSVG isMobile={isMobile} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EcosystemDiagram;
