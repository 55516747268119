import React from "react";

import {
    Box,
    Card,
    CardContent,
    Grow,
    IconButton,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";
import {
    Circle,
    CloseOutlined
} from "@mui/icons-material";

import "./card-test-details.css";

const CardTestDetails = ({
    selectedActivity,
    testHTML,
    firstName,
    openTestDetailsSpace,
    showTestDetails,
    formatToShortDate,
    onClickCloseTestDetails,
    onClickTestSnippet
}) => {
    const getCopyForImpersonator = (selectedActivity) => {
        const invalidImpersonators = [
            "any",
            "null"
        ];

        if (!(selectedActivity.impersonator && !invalidImpersonators.includes(selectedActivity.impersonator))) {
            return "";
        }

        return `impersonating ${selectedActivity.impersonator.toUpperCase()} `
    };

    const getCopyForMotivationFactor = (selectedActivity) => {
        if (!(selectedActivity && selectedActivity.threats)) {
            return ".";
        } else if (selectedActivity.threats.includes("authority")) {
            return "to assert authority.";
        } else if (selectedActivity.threats.includes("curiosity")) {
            return "to prompt curiosity.";
        } else if (selectedActivity.threats.includes("fear")) {
            return "to stir fear.";
        } else if (selectedActivity.threats.includes("rewards")) {
            return "to encourage action through potential rewards.";
        } else if (selectedActivity.threats.includes("trust")) {
            return "to nurture trust.";
        } else if (selectedActivity.threats.includes("urgency")) {
            return "to spark urgency.";
        }

        return ".";
    };

    const getCopyForInteractions = (selectedActivity) => {
        if (!(selectedActivity && selectedActivity.source && selectedActivity.imposterPortal
            && selectedActivity.source.isURLClicked !== undefined
            && selectedActivity.imposterPortal.isKeyDown !== undefined
            && selectedActivity.imposterPortal.isDataEntered !== undefined
            && selectedActivity.hasReplied !== undefined)) {
            return "";
        }

        if (!selectedActivity.source.isURLClicked && !selectedActivity.hasReplied) {
            return "No credentials were entered or submitted."
        }

        let copyForInteractions = "";

        if (selectedActivity.imposterPortal.isKeyDown) {
            if (selectedActivity.imposterPortal.isDataEntered) { // Entered and submitted credentials
                if (firstName) { // For admin
                    copyForInteractions = `${firstName} entered and submitted their credentials after clicking on the link.`;
                } else { // For end user
                    copyForInteractions = "You entered and submitted your credentials after clicking on the link.";
                }
            } else { // Only entered credentials
                if (firstName) { // For admin
                    copyForInteractions = `${firstName} entered their credentials after clicking on the link.`;
                } else { // For end user
                    copyForInteractions = "You entered your credentials after clicking on the link.";
                }
            }
        } else if (selectedActivity.source.isURLClicked) { // Just clicked on the link
            if (firstName) { // For admin
                copyForInteractions = `${firstName} clicked on the link.`
            } else {
                copyForInteractions = "You clicked on the link."
            }
        }

        // Check replies.
        if (selectedActivity.hasReplied) {
            if (copyForInteractions) { // If they did other interactions including clicking and credentials
                if (firstName) { // For admin
                    copyForInteractions += " They also responded to the message.";
                } else { // For end user
                    copyForInteractions += " You also responded to the message.";
                }
            } else {
                if (firstName) { // For admin
                    copyForInteractions = `${firstName} responded to the message.`;
                } else { // For end user
                    copyForInteractions = "You responded to the message.";
                }
            }
        }

        return copyForInteractions;
    };

    const formatToLongDate = (date) => {
        const givenDate = new Date(date);

        return givenDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    const handleClickClose = () => {
        onClickCloseTestDetails();
    };

    if (!openTestDetailsSpace) {
        return (
            <></>
        );
    }

    return (
        <Grow in={showTestDetails}>
            <Card className="dune-card-dashboard">
                <CardContent className="card-test-details-card-content">
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                        <Stack width="100%" padding="28px 0 40px 28px">
                            <Stack direction="row" alignItems="center">
                                <Typography component="h3" className="dune-text-header-card-dashboard">
                                    {formatToShortDate(selectedActivity.date)} test details
                                </Typography>
                                <Stack direction="row" justifyContent="center" alignItems="center" marginLeft="40px">
                                    <Circle
                                        className={
                                            selectedActivity.passed
                                                ? "card-recent-testing-activity-main-icon-circle-pass"
                                                : "card-recent-testing-activity-main-icon-circle-fail"
                                        }
                                    />
                                    <Typography
                                        component="span"
                                        className={
                                            selectedActivity.passed
                                                ? "card-recent-testing-activity-main-text-pass"
                                                : "card-recent-testing-activity-main-text-fail"
                                        }
                                    >
                                        {selectedActivity.passed ? "Pass" : "Fail"}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <IconButton
                            className="card-test-details-icon-button-close"
                            title="Close test details."
                            aria-label="Close test details."
                            onClick={handleClickClose}
                        >
                            <CloseOutlined className="card-test-details-icon-close" />
                        </IconButton>
                    </Stack>
                    <Stack
                        direction={{
                            xs: "column",
                            lg: "row"
                        }}
                        justifyContent={{
                            xs: "flex-start",
                            lg: "space-between"
                        }}
                    >
                        <Box
                            maxWidth={{
                                xs: "100%",
                                lg: "500px"
                            }}
                            paddingLeft="28px"
                            paddingRight={{
                                xs: 0,
                                lg: "30px"
                            }}
                        >
                            <Stack direction="column" justifyContent="space-between" height="100%">
                                <Box>
                                    <Typography component="p" className="card-test-details-text-details">
                                        On {formatToLongDate(selectedActivity.date)}, a social engineering test {getCopyForImpersonator(selectedActivity)}was administered.
                                    </Typography>
                                    <Typography component="p" className="card-test-details-text-details" marginTop="20px">
                                        The attack used {selectedActivity.domain ? `the domain "${selectedActivity.domain}"` : "a domain associated with a phishing simulation email"}{getCopyForMotivationFactor(selectedActivity)} {getCopyForInteractions(selectedActivity)}
                                    </Typography>
                                    {
                                        (!firstName
                                            && selectedActivity && selectedActivity.source && selectedActivity.source.isURLClicked) &&
                                        <Typography component="p" className="card-test-details-text-details" marginTop="20px">
                                            Don't click on links or attachments unless you know and trust the sender. Also, this appears to be an unexpected request from an unknown sender. Be cautious!
                                        </Typography>
                                    }
                                </Box>
                                {
                                    testHTML &&
                                    <Box>
                                        <Typography
                                            component="p"
                                            className="card-recent-testing-activity-main-text-tip card-test-details-text-tip"
                                            paddingTop="32px"
                                            onClick={onClickTestSnippet}
                                        >
                                            See a snippet of the test to the right.
                                        </Typography>
                                    </Box>
                                }
                            </Stack>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            padding={{
                                xs: "40px 0 0 0",
                                lg: "0 28px 0 30px"
                            }}
                        >
                            {
                                testHTML !== null ?
                                    testHTML === "" ?
                                        <Typography component="p" className="card-test-details-text-preview">
                                            Preview not available
                                        </Typography>
                                        :
                                        <iframe
                                            className="card-recent-testing-activity-main-iframe-test-html"
                                            title={`${formatToShortDate(selectedActivity.date)} test`}
                                            srcdoc={testHTML}
                                            height="312px"
                                            style={{
                                                border: "none",
                                                borderRadius: "4px"
                                            }}
                                        ></iframe>
                                    :
                                    <Box minHeight="312px" width="100%" height="100%">
                                        <Box display="flex" flexDirection="column" flexGrow={1} height="100%">
                                            <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                                        </Box>
                                    </Box>
                            }
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
        </Grow>
    );
};

export default CardTestDetails;