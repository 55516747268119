import React from 'react';

const BlastRadiusAnalysisLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.3193 22.6122C26.2691 20.2317 27.3339 17.2492 27.3327 14.1722C27.3327 6.8122 21.3594 0.838867 13.9993 0.838867C6.63935 0.838867 0.666016 6.8122 0.666016 14.1722C0.666016 21.5322 6.63935 27.5055 13.9993 27.5055C15.7631 27.5064 17.5093 27.1567 19.1368 26.4769C20.7642 25.7971 22.2403 24.8007 23.4794 23.5455C23.5194 23.5055 23.546 23.4655 23.5727 23.4389C23.8393 23.1722 24.0927 22.8922 24.3193 22.6122ZM13.9993 24.8389C8.11935 24.8389 3.33268 20.0522 3.33268 14.1722C3.33268 8.2922 8.11935 3.50553 13.9993 3.50553C19.8793 3.50553 24.666 8.2922 24.666 14.1722C24.666 16.6389 23.826 18.8922 22.4127 20.7055L20.506 18.7989C21.426 17.4922 21.9727 15.9055 21.9727 14.1855C21.9727 9.7722 18.386 6.18553 13.9727 6.18553C9.55935 6.18553 5.97268 9.7722 5.97268 14.1855C5.97268 18.5989 9.55935 22.1855 13.9727 22.1855C15.706 22.1855 17.3193 21.6255 18.626 20.6789L20.5193 22.5722C18.6663 24.0459 16.367 24.8453 13.9993 24.8389ZM16.5593 14.8522C16.786 13.9722 16.586 13.0122 15.906 12.3189L15.8793 12.2922C14.8527 11.2655 13.2127 11.2522 12.1727 12.2389C12.1593 12.2522 12.1327 12.2655 12.106 12.2922C11.066 13.3322 11.066 15.0255 12.106 16.0655L12.1327 16.0922C12.826 16.7722 13.7993 16.9855 14.6793 16.7455L16.6927 18.7589C15.8927 19.2389 14.9727 19.5322 13.9727 19.5322C11.026 19.5322 8.63935 17.1455 8.63935 14.1989C8.63935 11.2522 11.026 8.86553 13.9727 8.86553C16.9193 8.86553 19.306 11.2522 19.306 14.1989C19.306 15.1722 19.026 16.0789 18.5593 16.8655L16.5593 14.8522Z"
        fill="#00FF82"
      />
    </svg>
  );
};

export default BlastRadiusAnalysisLogo;
