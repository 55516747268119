import React from 'react';

const AdaptLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3691 0.398438C4.84914 0.398438 0.369141 4.87844 0.369141 10.3984C0.369141 15.9184 4.84914 20.3984 10.3691 20.3984C15.8891 20.3984 20.3691 15.9184 20.3691 10.3984C20.3691 4.87844 15.8891 0.398438 10.3691 0.398438ZM11.2791 16.5484C11.2093 16.617 11.1208 16.6635 11.0247 16.6822C10.9287 16.7009 10.8292 16.691 10.7387 16.6538C10.6482 16.6165 10.5706 16.5535 10.5156 16.4725C10.4606 16.3916 10.4305 16.2963 10.4291 16.1984V15.3984H10.3691C9.08914 15.3984 7.80914 14.9084 6.82914 13.9384C6.15017 13.2578 5.68159 12.396 5.47942 11.4562C5.27726 10.5163 5.35007 9.53804 5.68914 8.63844C5.87914 8.12844 6.54914 7.99844 6.92914 8.38844C7.14914 8.60844 7.19914 8.92844 7.09914 9.20844C6.63914 10.4484 6.89914 11.8884 7.89914 12.8884C8.59914 13.5884 9.51914 13.9184 10.4391 13.8984V12.9584C10.4391 12.5084 10.9791 12.2884 11.2891 12.6084L12.9091 14.2284C13.1091 14.4284 13.1091 14.7384 12.9091 14.9384L11.2791 16.5484ZM13.8091 12.4184C13.7058 12.3121 13.6349 12.1784 13.6048 12.0332C13.5747 11.8879 13.5866 11.7371 13.6391 11.5984C14.0991 10.3584 13.8391 8.91844 12.8391 7.91844C12.1391 7.21844 11.2191 6.87844 10.3091 6.89844V7.83844C10.3091 8.28844 9.76914 8.50844 9.45914 8.18844L7.82914 6.57844C7.62914 6.37844 7.62914 6.06844 7.82914 5.86844L9.44914 4.24844C9.51903 4.17992 9.60748 4.13341 9.70355 4.11469C9.79962 4.09597 9.89906 4.10586 9.98956 4.14312C10.0801 4.18039 10.1576 4.24339 10.2127 4.32433C10.2677 4.40527 10.2978 4.50057 10.2991 4.59844V5.40844C11.5991 5.38844 12.9091 5.85844 13.8991 6.85844C14.5781 7.53906 15.0467 8.40084 15.2489 9.34072C15.451 10.2806 15.3782 11.2588 15.0391 12.1584C14.8491 12.6784 14.1891 12.8084 13.8091 12.4184Z"
        fill="#00FF82"
      />
    </svg>
  );
};

export default AdaptLogo;
