import { getResponseGet } from "../utils";

const getInteractionsOrganization = async (days) => {
    let interactionsOrganization = {
        result: {
            totalInteractions: 0,
            totalInteractionsEngaged: 0,
            totalInteractionsResponded: 0,
            totalInteractionsClicked: 0,
            totalInteractionsKeyDown: 0,
            totalInteractionsDataEntered: 0,
            totalInteractionsNoEngagement: 0,
            totalInteractionsReported: 0
        },
        error: {}
    };

    try {
        const response = await getResponseGet(`/organization_sankey_incidents/${days !== null ? `?time_window=${days}` : ""}`);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        let totalInteractionsEngaged = 0;

        if (data.total_smtp_reply_incidents) {
            interactionsOrganization.result.totalInteractionsResponded = data.total_smtp_reply_incidents;
            totalInteractionsEngaged += data.total_smtp_reply_incidents;
        }

        if (data.total_phished_incidents) {
            interactionsOrganization.result.totalInteractionsClicked = data.total_phished_incidents;
            totalInteractionsEngaged += data.total_phished_incidents;
        }

        interactionsOrganization.result.totalInteractions = data.total_incidents;
        interactionsOrganization.result.totalInteractionsEngaged = totalInteractionsEngaged;
            interactionsOrganization.result.totalInteractionsKeyDown = data.total_key_down_incidents;
            interactionsOrganization.result.totalInteractionsDataEntered = data.total_data_entered_incidents;
            interactionsOrganization.result.totalInteractionsNoEngagement = data.total_no_action;
        interactionsOrganization.result.totalInteractionsReported = data.total_watchtower_reported_incidents;
    } catch (error) {
        interactionsOrganization.error = error;
    }

    return interactionsOrganization;
};

export default getInteractionsOrganization;