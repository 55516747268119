import { createTheme } from "@mui/material/styles";

const grayLight = "#FFFFFF";
const white = "#FFFFFF";

const theme = createTheme({
  typography: {
    h1: {
      color: white,
      fontFamily: "General Sans",
      fontSize: "3rem",
      fontWeight: 500,
      lineHeight: "normal",
    },
    h2: {
      color: white,
      fontFamily: "General Sans",
      fontSize: "2rem",
      fontWeight: 500,
      lineHeight: "normal",
    },
    h3: {
      color: white,
      fontFamily: "General Sans",
      fontSize: "1.625rem",
      fontWeight: 500,
      lineHeight: "normal",
    },
    h4: {
      color: white,
      fontSize: "1.125rem",
      fontWeight: 500,
      fontFamily: "General Sans",
      lineHeight: "normal",
    },
    "spaced-header": {
      color: white,
      fontSize: "1.125rem",
      fontWeight: 500,
      letterSpacing: "0.6rem",
      lineHeight: "120%",
      textTransform: "uppercase",
    },
    body: {
      color: white,
      fontSize: "1rem",
      fontWeight: 400,
      fontFamily: "General Sans",
      lineHeight: "normal",
    },
    "body-gray": {
      color: grayLight,
      fontSize: "1rem",
      fontWeight: 400,
      fontFamily: "General Sans",
      lineHeight: "normal",
    },
    "body-emphasized": {
      color: white,
      fontFamily: "General Sans",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "normal",
    },
    "body-emphasized-gray": {
      color: grayLight,
      fontFamily: "General Sans",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "normal",
    },
    description: {
      color: white,
      fontFamily: "General Sans",
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "normal",
    },
    "description-gray": {
      color: grayLight,
      fontFamily: "General Sans",
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "normal",
    },
    "description-emphasized": {
      color: white,
      fontFamily: "General Sans",
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: "normal",
    },
    "description-emphasized-gray": {
      color: grayLight,
      fontFamily: "General Sans",
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: "normal",
    },
    eyebrow: {
      color: white,
      fontFamily: "General Sans",
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: "normal",
    },
    detailed: {
      color: white,
      fontFamily: "General Sans",
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "normal",
    },
    "detailed-emphasized": {
      color: white,
      fontFamily: "General Sans",
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: "normal",
    },
  },
});

export default theme;
