import React, {
    useEffect,
    useRef,
    useState
} from "react";
import { Link } from "react-router-dom";

import {
    Box,
    Button,
    Checkbox,
    InputAdornment,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography
} from "@mui/material";
import {
    Check,
    CheckBoxOutlined,
    FileDownloadOutlined,
    Search
} from "@mui/icons-material";

import Filters from "./utils/filters/filters";

import "./users-table.css";

function UsersTable({
    data,
    defaultOrderBy,
    defaultFilters,
    disableFilters,
    disableProfilePage,
    headers,
    fixedCount,
    isInModal,
    onExportSelectedReports
}) {
    // Table data
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState(defaultOrderBy);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState("");

    // Selected data
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [selectedData, setSelectedData] = useState([]);

    // Filters
    const [selectedRiskLevel, setSelectedRiskLevel] = useState("");
    const [selectedCompliant, setSelectedCompliant] = useState("");
    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [selectedComplianceFramework, setSelectedComplianceFramework] = useState("");
    const [departments, setDepartments] = useState([]);
    const [complianceFrameworks, setComplianceFrameworks] = useState([]);

    const tableRef = useRef(null);

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }

        if (b[orderBy] > a[orderBy]) {
            return 1;
        }

        return 0;
    };

    const getComparator = (order, orderBy) => {
        let updatedOrderBy = orderBy;

        if (updatedOrderBy === "name") { // Sort by first name if name is selected.
            updatedOrderBy = "firstName";
        }

        return order === "desc"
            ? (a, b) => descendingComparator(a, b, updatedOrderBy)
            : (a, b) => -descendingComparator(a, b, updatedOrderBy);
    };

    const handleChangeComplianceFramework = (event) => {
        setSelectedComplianceFramework(event.target.value);
        setPage(0);
    };

    const handleChangeCompliant = (event) => {
        setSelectedCompliant(event.target.value);
        setPage(0);
    };

    const handleChangeDepartment = (event) => {
        setSelectedDepartment(event.target.value);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRiskLevel = (event) => {
        setSelectedRiskLevel(event.target.value);
        setPage(0);
    };

    const handleChangeRowsPerPage = (event) => {
        tableRef.current && tableRef.current.scrollIntoView();

        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSearch = (event) => {
        setSearch(event.target.value);
        setPage(0);
    };

    const handleClickCheckbox = (user) => {
        const updatedSelectedData = [...selectedData];
        const index = selectedData.findIndex((element) => element.email === user.email);

        if (index > -1) {
            updatedSelectedData.splice(index, 1);
        } else {
            updatedSelectedData.push(user);
        }

        setSelectedData([...updatedSelectedData]);
    };

    const handleClickCheckboxSelectAll = () => {
        if (isSelectAll) {
            setIsSelectAll(false);
            setSelectedData(selectedData.filter(row => !visibleRows.includes(row)));

        } else {
            setIsSelectAll(true);
            setSelectedData([...new Set([...selectedData, ...visibleRows])]);
        }
    };

    const handleClickExportSelectedReports = () => {
        onExportSelectedReports(selectedData, "user-reports.csv");
    };

    const handleClickSelectAllUsers = () => {
        if (selectedData.length === filteredRows.length) {
            setSelectedData([]);
        } else {
            setSelectedData([...filteredRows]);
        }
    };

    const handleSortRequest = (property) => {
        if (orderBy !== property) {
            setPage(0);
        }

        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);

        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);

            if (order !== 0) {
                return order;
            }

            return a[1] - b[1];
        });

        return stabilizedThis.map((el) => el[0]);
    };

    let filteredRows = [];

    if (search !== "") {
        for (let i = 0; i < data.length; i++) {
            let isMatch = false;

            for (let j = 0; j < headers.length; j++) {
                if (headers[j].isSearchable) {
                    if (headers[j].id === "name") {
                        if (data[i].name && data[i].name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                            isMatch = true;
                            break;
                        } else if ((data[i].firstName && data[i].firstName.toLowerCase().indexOf(search.toLowerCase()) > -1)
                            || (data[i].lastName && data[i].lastName.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                            isMatch = true;
                            break;
                        }
                    } else if (data[i][headers[j].id].toLowerCase().indexOf(search.toLowerCase()) > -1) {
                        isMatch = true;
                        break;
                    }
                }
            }

            // Check for the user's email even if it is not part of the table.
            if (!isMatch && data[i].email && data[i].email.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                isMatch = true;
            }

            if (isMatch) {
                filteredRows.push(data[i]);
            }
        }
    } else {
        filteredRows = [...data];
    }

    if (selectedRiskLevel === "Severe") {
        filteredRows = filteredRows.filter((row) => row.riskScore && row.riskScore > 75);
    } else if (selectedRiskLevel === "High") {
        filteredRows = filteredRows.filter((row) => row.riskScore && row.riskScore > 50 && row.riskScore <= 75);
    } else if (selectedRiskLevel === "Moderate") {
        filteredRows = filteredRows.filter((row) => row.riskScore && row.riskScore > 25 && row.riskScore <= 50);
    } else if (selectedRiskLevel === "Low") {
        filteredRows = filteredRows.filter((row) => row.riskScore && row.riskScore > 0 && row.riskScore <= 25);
    }

    if (selectedCompliant === "Yes") {
        filteredRows = filteredRows.filter((row) => row.compliant === true);
    } else if (selectedCompliant === "No") {
        filteredRows = filteredRows.filter((row) => row.compliant === false);
    }

    if (selectedDepartment) {
        filteredRows = filteredRows.filter((row) => row.department === selectedDepartment);
    }

    if (selectedComplianceFramework) {
        filteredRows = filteredRows.filter((row) =>
            row.complianceFrameworks && row.complianceFrameworks[selectedComplianceFramework] === true
        );
    }

    const visibleRows = stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    useEffect(() => {
        const updateComponent = async () => {
            const allDepartments = data.map((user) => user.department);
            const departmentNames = new Set(allDepartments);

            const departments = [...departmentNames].map((name) => {
                return {
                    name
                };
            })
            departments.sort((a, b) => a.name.localeCompare(b.name));

            setDepartments(departments);

            let complianceFrameworks = data[0].complianceFrameworks;

            if (complianceFrameworks && Array.isArray(complianceFrameworks) && complianceFrameworks.length > 0) {
                complianceFrameworks = complianceFrameworks.map((complianceFramework) => {
                    return {
                        name: complianceFramework.name
                    };
                });

                complianceFrameworks.sort((a, b) => a.name.localeCompare(b.name));

                setComplianceFrameworks(complianceFrameworks);
            }
        };

        if (data && Array.isArray(data) && data.length > 0) {
            updateComponent().finally(() => {
                if (defaultFilters) {
                    if (defaultFilters.complianceFramework && defaultFilters.complianceFramework.name) {
                        setSelectedComplianceFramework(defaultFilters.complianceFramework.name);
                    }

                    if (defaultFilters.department) {
                        setSelectedDepartment(defaultFilters.department);
                    }

                    if (defaultFilters.riskLevel && defaultFilters.riskLevel.name) {
                        setSelectedRiskLevel(defaultFilters.riskLevel.name);
                    }
                }
            });
        }
    }, [data, defaultFilters]);

    useEffect(() => {
        // Check select all
        let isSelectAll = true;

        for (let i = 0; i < visibleRows.length; i++) {
            if (!selectedData.includes(visibleRows[i])) {
                isSelectAll = false;
                break;
            }
        }

        setIsSelectAll(isSelectAll);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleRows]);

    useEffect(() => {
        const newSelectedData = selectedData.filter((selectedRow) =>
            filteredRows.some((filteredRow) => filteredRow.email === selectedRow.email)
        );

        setSelectedData(newSelectedData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRiskLevel, selectedCompliant, selectedDepartment, selectedComplianceFramework, search]);

    return (
        <>
            <Box
                className="users-table-box-sticky-toolbar"
                sx={{
                    top: isInModal ? "28px" : "132px"
                }}
            >
                <Box className="user-box-text-field">
                    <TextField
                        variant="filled"
                        className="dune-text-field-filled"
                        name="search"
                        placeholder="Search for names"
                        value={search}
                        type="text"
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment>
                                    <Search className="users-table-icon-search" />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleChangeSearch}
                    />
                </Box>
                {
                    !disableFilters &&
                    <Box marginTop="20px">
                        <Filters
                            selectedRiskLevel={selectedRiskLevel}
                            selectedCompliant={selectedCompliant}
                            selectedDepartment={selectedDepartment}
                            selectedComplianceFramework={selectedComplianceFramework}
                            departments={departments}
                            complianceFrameworks={complianceFrameworks}
                            onChangeRiskLevel={handleChangeRiskLevel}
                            onChangeCompliant={handleChangeCompliant}
                            onChangeDepartment={handleChangeDepartment}
                            onChangeComplianceFramework={handleChangeComplianceFramework}
                        />
                    </Box>
                }
                <Box className="users-table-box-select-all">
                    {
                        selectedData.length &&
                        <Stack direction="row" alignItems="center">
                            <Stack direction="row" alignItems="center" minWidth="180px">
                                <CheckBoxOutlined className="users-table-icon-check-box-outlined" />
                                <Stack direction="column" marginLeft="8px">
                                    <Typography component="p" className="users-table-text-selected">
                                        {((selectedData.length === filteredRows.length) && fixedCount) ? fixedCount : selectedData.length} of {fixedCount ? fixedCount : filteredRows.length} selected
                                    </Typography>
                                    <Button
                                        variant="text"
                                        className="dune-button-text-white users-table-button-select-all"
                                        title={
                                            selectedData.length === filteredRows.length
                                                ? "Clear selection"
                                                : `Select all ${fixedCount ? fixedCount : filteredRows.length} users`
                                        }
                                        aria-label={
                                            selectedData.length === filteredRows.length
                                                ? "Clear selection"
                                                : `Select all ${fixedCount ? fixedCount : filteredRows.length} users`
                                        }
                                        onClick={handleClickSelectAllUsers}
                                    >
                                        {
                                            selectedData.length === filteredRows.length
                                                ? "Clear selection"
                                                : `Select all ${fixedCount ? fixedCount : filteredRows.length} users`
                                        }
                                    </Button>
                                </Stack>
                            </Stack>
                            <Box marginLeft="20px">
                                <Button
                                    variant="contained"
                                    className="dune-button-contained-white"
                                    title="Export selected reports."
                                    aria-label="Export selected reports."
                                    endIcon={
                                        <FileDownloadOutlined />
                                    }
                                    onClick={handleClickExportSelectedReports}
                                >
                                    Export selected reports
                                </Button>
                            </Box>
                        </Stack>
                    }
                </Box>
                <Box marginTop="28px">
                    <TablePagination
                        className="users-table-pagination"
                        component="div"
                        count={fixedCount ? fixedCount : filteredRows.length}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        classes={{ menuItem: "dune-menu-item users-table-pagination-menu-item" }}
                    />
                </Box>
            </Box>
            <Box className="user-box-table">
                <TableContainer className="users-table-table-container" ref={tableRef}>
                    <Table className="users-table-table">
                        <colgroup>
                            {
                                headers.map((header, index) =>
                                    <col style={{ width: header.width }} key={`col-${index}`} />
                                )
                            }
                        </colgroup>
                        <TableHead>
                            <TableRow className="users-table-table-row-headers" >
                                {
                                    headers.map((header, index) => {
                                        if (header.id === "select") {
                                            return (
                                                <TableCell
                                                    align={header.align}
                                                    key={`head-cell-${index}`}
                                                >
                                                    <Checkbox
                                                        className="dune-checkbox-white users-table-checkbox-select"
                                                        checked={isSelectAll}
                                                        onClick={handleClickCheckboxSelectAll}
                                                    />
                                                </TableCell>
                                            );
                                        }

                                        return header.isSortable ?
                                            <TableCell
                                                align={header.align}
                                                sortDirection={orderBy === header.id ? order : false}
                                                key={`head-cell-${index}`}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === header.id}
                                                    direction={orderBy === header.id ? order : "asc"}
                                                    onClick={() => handleSortRequest(header.id)}
                                                >
                                                    {header.label}
                                                </TableSortLabel>
                                            </TableCell>
                                            :
                                            <TableCell align={header.align} key={`head-cell-${index}`}>{header.label}</TableCell>
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                visibleRows.length > 0 ?
                                    visibleRows.map((user, userIndex) => {
                                        return (
                                            <TableRow key={`row-${userIndex}`}>
                                                {
                                                    headers.map((header, headerIndex) => {
                                                        if (header.id === "select") { // Select checkbox
                                                            const isSelected = selectedData.some((element) => element.email === user.email);

                                                            return (
                                                                <TableCell
                                                                    align={header.align}
                                                                    key={`user-${userIndex}-data-${headerIndex}`}
                                                                >
                                                                    <Checkbox
                                                                        className="dune-checkbox-white users-table-checkbox-select"
                                                                        checked={isSelected}
                                                                        onClick={() => handleClickCheckbox(user)}
                                                                    />
                                                                </TableCell>
                                                            );
                                                        }

                                                        if (header.id === "name") {
                                                            return (
                                                                <TableCell
                                                                    align={header.align}
                                                                    key={`user-${userIndex}-data-${headerIndex}`}
                                                                >
                                                                    {
                                                                        !disableProfilePage ?
                                                                            <Link
                                                                                className="users-table-link-name"
                                                                                title={`Go to ${(user.firstName && user.lastName) ? `${user.firstName} ${user.lastName}'s ` : ""}insights.`}
                                                                                aria-label={`Go to ${user[header.id] ? `${user[header.id]} ` : " "}insights.`}
                                                                                to="/insights/users/"
                                                                                state={{
                                                                                    email: user.email
                                                                                }}
                                                                            >
                                                                                {
                                                                                    user[header.id] &&
                                                                                    <Typography>{user[header.id]}</Typography>
                                                                                }
                                                                                {
                                                                                    (user.firstName && user.lastName) ?
                                                                                        <Typography>{user.firstName} {user.lastName}</Typography>
                                                                                        :
                                                                                        <Typography>{user.firstName || ""}{user.lastName || ""}</Typography>
                                                                                }
                                                                            </Link>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    user[header.id] &&
                                                                                    <Typography>{user[header.id]}</Typography>
                                                                                }
                                                                                {
                                                                                    (user.firstName && user.lastName) ?
                                                                                        <Typography>{user.firstName} {user.lastName}</Typography>
                                                                                        :
                                                                                        <Typography>{user.firstName || ""}{user.lastName || ""}</Typography>
                                                                                }
                                                                            </>
                                                                    }
                                                                </TableCell>
                                                            );
                                                        }

                                                        if (header.id === "compliant") { // Compliant: Yes, No, or N/A
                                                            return (
                                                                <TableCell
                                                                    align={header.align}
                                                                    className={
                                                                        (!header.hasColor || user[header.id] === "N/A") ? ""
                                                                            : (user[header.id] ? "users-table-text-compliant-yes"
                                                                                : "users-table-text-compliant-no")
                                                                    }
                                                                    key={`user-${userIndex}-data-${headerIndex}`}
                                                                >
                                                                    {
                                                                        user[header.id] !== "N/A"
                                                                            ? (user[header.id] ? "Yes" : "No")
                                                                            : user[header.id]
                                                                    }
                                                                </TableCell>
                                                            );
                                                        }

                                                        if (header.id === "riskLevel") { // Risk level
                                                            if (user.riskScore) {
                                                                return (
                                                                    <TableCell
                                                                        align={header.align}
                                                                        sx={{
                                                                            color:
                                                                                user.riskScore > 75 ? `#FF2B00 !important`
                                                                                    : (user.riskScore > 50 ? `#FFA100 !important`
                                                                                        : (user.riskScore > 25 ? `#FDFF00 !important`
                                                                                            : `#00FF82 !important`))
                                                                        }}
                                                                        key={`user-${userIndex}-data-${headerIndex}`}
                                                                    >
                                                                        {
                                                                            user.riskScore > 75 ? "Severe"
                                                                                : (user.riskScore > 50 ? "High"
                                                                                    : (user.riskScore > 25 ? "Moderate"
                                                                                        : "Low"))
                                                                        }
                                                                    </TableCell>
                                                                );
                                                            } else {
                                                                return (
                                                                    <TableCell
                                                                        align={header.align}
                                                                        key={`user-${userIndex}-data-${headerIndex}`}
                                                                    >
                                                                        N/A
                                                                    </TableCell>
                                                                );
                                                            }
                                                        }

                                                        if (header.id === "riskScore") { // Risk score
                                                            if (user.riskScore) {
                                                                return (
                                                                    <TableCell
                                                                        align={header.align}
                                                                        key={`user-${userIndex}-data-${headerIndex}`}
                                                                    >
                                                                        {user[header.id]}
                                                                    </TableCell>
                                                                );
                                                            } else {
                                                                return (
                                                                    <TableCell
                                                                        align={header.align}
                                                                        key={`user-${userIndex}-data-${headerIndex}`}
                                                                    >
                                                                        N/A
                                                                    </TableCell>
                                                                );
                                                            }
                                                        }

                                                        if (header.id === "isAdmin" || header.id === "isSuperadmin") { // Admin or Superadmin
                                                            return (
                                                                <TableCell
                                                                    align={header.align}
                                                                    key={`user-${userIndex}-data-${headerIndex}`}
                                                                >
                                                                    {
                                                                        user[header.id] &&
                                                                        <Check />
                                                                    }
                                                                </TableCell>
                                                            );
                                                        }

                                                        return ( // Default
                                                            <TableCell
                                                                align={header.align}
                                                                key={`user-${userIndex}-data-${headerIndex}`}
                                                            >
                                                                <Typography>{user[header.id]}</Typography>
                                                            </TableCell>
                                                        );
                                                    })
                                                }
                                            </TableRow>
                                        )
                                    })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={headers.length} align="center">No data found</TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box className="users-table-box-bottom"></Box>
            </Box>
        </>
    );
}

export default UsersTable;