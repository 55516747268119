import React, { useEffect, useState } from "react";

import Papa from "papaparse";

import {
  getInsightsOrganizationAreasToImprove,
  getInsightsOrganizationComplianceFrameworks,
  getInsightsOrganizationIndustryRiskScore,
  getInsightsOrganizationPercentagesOfUsers,
  getInsightsOrganizationRiskScore,
  getInteractionsOrganization,
  getUsers,
  getUsersByInteraction,
} from "../../../api";

import { Dashboard } from "../../../components";

import { pagesPlatform } from "../../../constants";

/**
 * Component for displaying organization-level insights.
 * Fetches relevant insights data of an organization.
 */
function InsightsOrganization({ isInitialLoading, email }) {
  // Insights
  const [riskScore, setRiskScore] = useState(null);
  const [comparedRiskScore, setComparedRiskScore] = useState(null);
  const [riskScoreOverTime, setRiskScoreOverTime] = useState(null);
  const [percentagesOfUsers, setPercentagesOfUsers] = useState(null);
  const [areasToImprove, setAreasToImprove] = useState(null);
  const [interactions, setInteractions] = useState(null);
  const [usersInteraction, setUsersInteraction] = useState(null);
  const [complianceFrameworks, setComplianceFrameworks] = useState(null);

  // Cache
  const [riskScoreOverTimeCache, setRiskScoreOverTimeCache] = useState({});
  const [interactionsCache, setInteractionsCache] = useState({});
  const [usersCache, setUsersCache] = useState({});

  const handleChangeSelectedDays = async (days) => {
    // For risk score over time and interactions cards
    if (riskScoreOverTimeCache[days]) {
      setRiskScoreOverTime(riskScoreOverTimeCache[days].riskScoreOverTime);
    } else if (
      days !== "All time" &&
      riskScoreOverTimeCache[90] &&
      riskScoreOverTimeCache[90].riskScoreOverTime
    ) {
      // For day numbers, use the 90 days data and filter it.
      const today = new Date();

      const cutOffDate = new Date();
      cutOffDate.setDate(today.getDate() - days);

      const filteredRiskScoreOverTime =
        riskScoreOverTimeCache[90].riskScoreOverTime.filter((element) => {
          const [month, day] = element.date.split(" ");
          let year = today.getFullYear();
          const dateForYearCheck = new Date(`${month} ${day}, ${year}`);

          if (dateForYearCheck > today) {
            year--;
          }

          const date = new Date(`${month} ${day}, ${year}`);

          return date >= cutOffDate;
        });

      setRiskScoreOverTime(filteredRiskScoreOverTime);

      // Store the risk score over time data in the cache.
      updateRiskScoreOverTimeCache(days, filteredRiskScoreOverTime);
    } else {
      await loadRiskScore(days);
    }

    if (interactionsCache[days]) {
      const { interactions } = interactionsCache[days];

      setInteractions(interactions);
    } else {
      await loadInteractions(days);
    }
  };

  const handleClickInteraction = async (interaction, days) => {
    // For interactions card
    if (email !== "david@dune.demo") {
      setUsersInteraction(null);

      if (usersCache[interaction] && usersCache[interaction][days]) {
        const { users } = usersCache[interaction][days];

        setUsersInteraction(users);
      } else {
        let usersData = {};

        if (days === "All time") {
          usersData = await getUsersByInteraction(interaction);
        } else {
          usersData = await getUsersByInteraction(interaction, days);
        }

        if (Object.keys(usersData.error).length > 0) {
          console.error(usersData.error.message);
        } else {
          const { users } = usersData.result;

          setUsersInteraction(users);

          // Store the users data in the cache.
          const updatedUsersCache = { ...usersCache };

          let updatedUsersCacheInteraction = {};

          if (updatedUsersCache[interaction]) {
            updatedUsersCacheInteraction = {
              ...updatedUsersCache[interaction],
            };
          }

          updatedUsersCacheInteraction[days] = {
            users,
          };

          updatedUsersCache[interaction] = updatedUsersCacheInteraction;

          setUsersCache(updatedUsersCache);
        }
      }
    }
  };

  const handleClickShareReport = async () => {
    // For share report button
    const usersData = await getUsers();

    if (Object.keys(usersData.error).length > 0) {
      console.error(usersData.error.message);
    } else {
      const users = usersData.result;
      const csv = Papa.unparse(
        users.map(({ compliant, complianceFrameworks, ...fields }) => {
          const row = { ...fields };

          if (
            complianceFrameworks &&
            typeof complianceFrameworks === "object" &&
            Object.keys(complianceFrameworks).length > 0
          ) {
            Object.entries(complianceFrameworks).forEach(([key, value]) => {
              row[key] = value !== null ? (value ? "yes" : "no") : "N/A";
            });
          }

          return row;
        })
      );

      const blob = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "organization-report.csv";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const updateRiskScoreOverTimeCache = (days, riskScoreOverTime) => {
    const updatedRiskScoreOverTimeCache = { ...riskScoreOverTimeCache };

    if (days === "All time") {
      updatedRiskScoreOverTimeCache["All time"] = {
        riskScoreOverTime,
      };
    } else {
      updatedRiskScoreOverTimeCache[days] = {
        riskScoreOverTime,
      };
    }

    setRiskScoreOverTimeCache(updatedRiskScoreOverTimeCache);
  };

  const loadRiskScore = async (days) => {
    if (riskScoreOverTimeCache[days]) {
      // Check the cache first.
      setRiskScoreOverTime(riskScoreOverTimeCache[days].riskScoreOverTime);
    } else {
      const insightsOrganizationRiskScore =
        await getInsightsOrganizationRiskScore(
          days && days !== "All time" ? days : null
        );

      if (Object.keys(insightsOrganizationRiskScore.error).length > 0) {
        console.error(insightsOrganizationRiskScore.error.message);
      } else {
        const { riskScore, riskScoreOverTime } =
          insightsOrganizationRiskScore.result;

        setRiskScore(riskScore);
        setRiskScoreOverTime(riskScoreOverTime);

        // Store the risk score over time data in the cache.
        updateRiskScoreOverTimeCache(days, riskScoreOverTime);
      }
    }
  };

  const loadInteractions = async (days) => {
    if (interactionsCache[days]) {
      // Check the cache first.
      setInteractions(interactionsCache[days]);
    } else {
      const interactionsOrganization = await getInteractionsOrganization(
        days && days !== "All time" ? days : null
      );

      if (Object.keys(interactionsOrganization.error).length > 0) {
        console.error(interactionsOrganization.error.message);
      } else {
        const {
          totalInteractions,
          totalInteractionsEngaged,
          totalInteractionsResponded,
          totalInteractionsClicked,
          totalInteractionsKeyDown,
          totalInteractionsDataEntered,
          totalInteractionsNoEngagement,
          totalInteractionsReported,
        } = interactionsOrganization.result;

        const interactions = {
          totalInteractions,
          totalInteractionsEngaged,
          totalInteractionsResponded,
          totalInteractionsClicked,
          totalInteractionsKeyDown,
          totalInteractionsDataEntered,
          totalInteractionsNoEngagement,
          totalInteractionsReported,
        };

        setInteractions(interactions);

        // Store the interactions data in the cache.
        const updatedInteractionsCache = { ...interactionsCache };

        if (days === "All time") {
          updatedInteractionsCache["All time"] = {
            interactions,
          };
        } else {
          updatedInteractionsCache[days] = {
            interactions,
          };
        }

        setInteractionsCache(updatedInteractionsCache);
      }
    }
  };

  useEffect(() => {
    const loadIndustryRiskScore = async () => {
      const insightsOrganizationIndustryRiskScore =
        await getInsightsOrganizationIndustryRiskScore();

      if (Object.keys(insightsOrganizationIndustryRiskScore.error).length > 0) {
        console.error(insightsOrganizationIndustryRiskScore.error.message);
      } else {
        const { industryRiskScore } =
          insightsOrganizationIndustryRiskScore.result;

        setComparedRiskScore(industryRiskScore);
      }
    };

    const loadPercentagesOfUsers = async () => {
      const insightsOrganizationPercentagesOfUsers =
        await getInsightsOrganizationPercentagesOfUsers();

      if (
        Object.keys(insightsOrganizationPercentagesOfUsers.error).length > 0
      ) {
        console.error(insightsOrganizationPercentagesOfUsers.error.message);
      } else {
        const { percentagesOfUsers } =
          insightsOrganizationPercentagesOfUsers.result;

        setPercentagesOfUsers(percentagesOfUsers);
      }
    };

    const loadAreasToImprove = async () => {
      const insightsOrganizationAreasToImprove =
        await getInsightsOrganizationAreasToImprove();

      if (Object.keys(insightsOrganizationAreasToImprove.error).length > 0) {
        console.error(insightsOrganizationAreasToImprove.error.message);
      } else {
        const { areasToImprove } = insightsOrganizationAreasToImprove.result;

        setAreasToImprove(areasToImprove);
      }
    };

    const loadComplianceFrameworks = async () => {
      const insightsOrganizationComplianceFrameworks =
        await getInsightsOrganizationComplianceFrameworks();

      if (
        Object.keys(insightsOrganizationComplianceFrameworks.error).length > 0
      ) {
        console.error(insightsOrganizationComplianceFrameworks.error.message);
      } else {
        const { complianceFrameworks } =
          insightsOrganizationComplianceFrameworks.result;

        setComplianceFrameworks(complianceFrameworks);
      }
    };

    const loadInsightsOrganization = async () => {
      if (email === "david@dune.demo") {
        // Demo account
        setRiskScore(46);

        setComparedRiskScore(60);

        let riskScoreOverTime = [];

        const currentDate = new Date(); // Start with today's date.
        currentDate.setDate(currentDate.getDate() - 90);

        let riskScore = 90;
        const targetScore = 46;
        const totalDays = 90;

        const options = {
          month: "short",
          day: "numeric",
        };

        for (let i = 0; i < totalDays; i++) {
          const formattedDate = currentDate.toLocaleDateString(
            "en-US",
            options
          );

          let stepChange = (riskScore - targetScore) / (totalDays - i);

          let variability = (Math.random() - 0.5) * stepChange;

          let fluctuation =
            Math.sin(((2 * Math.PI) / totalDays) * i) * stepChange;

          riskScore -= stepChange + variability + fluctuation;

          if (i === totalDays - 1 && riskScore > targetScore) {
            riskScore = targetScore;
          } else if (riskScore < targetScore) {
            riskScore = targetScore + Math.random();
          }

          riskScoreOverTime.push({
            date: formattedDate,
            riskScore: Math.round(riskScore),
          });

          currentDate.setDate(currentDate.getDate() + 1);
        }

        setRiskScoreOverTime(riskScoreOverTime);

        // Store the risk score over time data in the cache (for both 90 days and all time).
        updateRiskScoreOverTimeCache(90, riskScoreOverTime);
        updateRiskScoreOverTimeCache("All time", riskScoreOverTime);

        setPercentagesOfUsers([
          {
            name: "Percentages",
            lowRisk: 0.62,
            moderateRisk: 0.27,
            highRisk: 0.09,
            severeRisk: 0.02,
          },
        ]);

        setAreasToImprove([
          {
            name: "C-suite impersonation",
          },
          {
            name: "Impersonation of internal processes",
          },
          {
            name: "Link manipulation",
          },
        ]);
        setComplianceFrameworks([
          {
            name: "SOC 2 Type 2",
            progress: 0.92,
          },
          {
            name: "HIPAA",
            progress: 0.94,
          },
          {
            name: "ISO 27001",
            progress: 0.91,
          },
        ]);

        setInteractions({
          totalInteractions: 10483,
          totalInteractionsEngaged: 2885,
          totalInteractionsResponded: 150,
          totalInteractionsClicked: 2735,
          totalInteractionsKeyDown: 300,
          totalInteractionsDataEntered: 44,
          totalInteractionsNoEngagement: 5577,
          totalInteractionsReported: 2021,
        });

        const usersInteraction = [
          {
            name: "Duncan Idaho",
            email: "duncan.idaho@dune.demo",
            department: "Capital",
            compliant: false,
            riskScore: 97,
          },
          {
            name: "Stefanie Gonzalez",
            email: "stefanie.gonzalez@dune.demo",
            department: "Technology",
            compliant: true,
            riskScore: 84,
          },
          {
            name: "David Hurst",
            email: "david.hurst@dune.demo",
            department: "Early-Stage Fund Management",
            compliant: true,
            riskScore: 79,
          },
          {
            name: "David Sparks",
            email: "david.sparks@dune.demo",
            department: "Portfolio",
            compliant: true,
            riskScore: 56,
          },
          {
            name: "Eric Guzman",
            email: "eric.guzman@dune.demo",
            department: "Program",
            compliant: true,
            riskScore: 51,
          },
          {
            name: "Andrew Villegas",
            email: "andrew.villegas@dune.demo",
            department: "Program",
            compliant: true,
            riskScore: 35,
          },
          {
            name: "Thomas Lloyd",
            email: "thomas.lloyd@dune.demo",
            department: "Operations",
            compliant: true,
            riskScore: 68,
          },
          {
            name: "Dawn Thomas",
            email: "dawn.thomas@dune.demo",
            department: "Founder Scouting",
            compliant: true,
            riskScore: 49,
          },
          {
            name: "Connor Schultz",
            email: "connor.schultz@dune.demo",
            department: "Program",
            compliant: true,
            riskScore: 57,
          },
          {
            name: "John Harrell",
            email: "john.harrell@dune.demo",
            department: "Capital",
            compliant: true,
            riskScore: 74,
          },
        ];
        setUsersInteraction(usersInteraction);
      } else {
        loadRiskScore(90); // Default day value is 90.
        loadIndustryRiskScore();
        loadPercentagesOfUsers();
        loadAreasToImprove();
        loadComplianceFrameworks();
        loadInteractions(90); // Default day value is 90.
      }
    };

    if (!isInitialLoading && email) {
      loadInsightsOrganization();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoading, email]);

  return (
    <Dashboard
      pageTitle={pagesPlatform.INSIGHTS.subpages.ORGANIZATION}
      riskScore={riskScore}
      comparedRiskScore={comparedRiskScore}
      riskScoreOverTime={riskScoreOverTime}
      percentagesOfUsers={percentagesOfUsers}
      areasToImprove={areasToImprove}
      interactions={interactions}
      usersInteraction={usersInteraction}
      complianceFrameworks={complianceFrameworks}
      email={email}
      onChangeSelectedDays={handleChangeSelectedDays}
      onClickShareReport={handleClickShareReport}
      onClickInteraction={handleClickInteraction}
    />
  );
}

export default InsightsOrganization;
