import React, { useEffect, useState } from "react";

import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Label,
    LabelList,
    ResponsiveContainer,
    XAxis,
    YAxis
} from "recharts";

import {
    pagesPlatform,
    pagesPlatformManager
} from "../../../../../constants";

const CustomYAxisTick = ({ x, y, payload }) => {
    if (x !== null && y !== null && payload.value !== null) {
        return (
            <g transform={`translate(${x - 24}, ${y + 4})`}>
                <text
                    x={0}
                    y={0}
                    fill="#C2BFB8"
                    fontSize="16px"
                    fontWeight="400"
                    textAnchor="start"
                >
                    {payload.value}
                </text>
            </g>
        );
    }
    return null;
};

function RiskScoreComparison({
    riskScore,
    comparedRiskScore,
    firstName,
    pageTitle,
    color
}) {
    const [barChartData, setBarChartData] = useState([
        {
            name: "",
            score: 0
        },
        {
            name: "",
            score: 0
        }
    ]);

    const renderCustomizedLabel = (props) => {
        const { x, y, width, value } = props;

        if (!value) {
            return (
                <></>
            );
        }

        const isTop = value && value >= 85 ? "insideTop" : "top";
        const textY = isTop === "top" ? y - 20 : y + 22;
        const rectX = x + width / 2 - 18;
        const rectY = isTop === "top" ? textY - 16 : textY - 15;

        let fillRect = "#000000";
        let fillText = "#C2BFB8";

        if (value > 75) { // Severe
            fillRect = "#FF2B00";
            fillText = "#FFFFFF";
        } else if (value > 50) { // High
            fillRect = "#FFA100";
            fillText = "#000000";
        } else if (value > 25) { // Moderate
            fillRect = "#FFEA00";
            fillText = "#000000";
        } else { // Low
            fillRect = "#00FF82";
            fillText = "#000000";
        }

        return (
            <g>
                <rect
                    x={rectX}
                    y={rectY}
                    width="36"
                    height="28"
                    fill={fillRect}
                    rx="4px"
                    ry="4px"
                />
                <text
                    x={x + width / 2}
                    y={textY}
                    fill={fillText}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize="14px"
                >
                    {value}
                </text>
            </g>
        );
    };

    useEffect(() => {
        const updateBarChartData = () => {
            setBarChartData([
                {
                    name: pageTitle
                        ? (pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ? "Org risk score"
                            : (pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS ? "Department risk score"
                                : (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS ? `${firstName ? `${firstName}'s` : "Their"} risk score`
                                    : (pageTitle === pagesPlatformManager.YOUR_REPORTS ? "Your reports"
                                        : "Your risk score"))))
                        : "Your risk score",
                    score: riskScore
                },
                {
                    name: pageTitle
                        ? (pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ? "Industry average"
                            : (pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS ? "Department average"
                                : (pageTitle === pagesPlatformManager.YOUR_REPORTS ? "Org risk score"
                                    : "User average")))
                        : "User average",
                    score: comparedRiskScore
                }
            ]);
        };

        if (pageTitle) {
            if (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS) {
                if (firstName) {
                    if (riskScore !== null && riskScore !== undefined
                        && comparedRiskScore !== null && comparedRiskScore !== undefined) {
                        updateBarChartData();
                    }
                }
            } else if (riskScore !== null && riskScore !== undefined
                && comparedRiskScore !== null && comparedRiskScore !== undefined) {
                updateBarChartData();
            }
        }
    }, [riskScore, comparedRiskScore, firstName, pageTitle]);

    return (
        <ResponsiveContainer width="100%" height="100%" minHeight="268px">
            <BarChart data={barChartData}>
                <CartesianGrid
                    stroke="#232221"
                    vertical={false}
                />
                <XAxis
                    dataKey="name"
                    axisLine={false}
                    stroke="#C2BFB8"
                    tick={{
                        fontSize: "14px",
                        fontWeight: "400"
                    }}
                    tickLine={false}
                />
                <YAxis
                    axisLine={false}
                    domain={[0, 100]}
                    tick={<CustomYAxisTick />}
                    tickLine={false}
                    tickCount={5}
                >
                    <Label
                        value="Score"
                        angle={270}
                        position="left"
                        offset={-4}
                        fill="#C2BFB8"
                        style={{ textAnchor: "middle" }}
                    />
                </YAxis>
                <Bar
                    dataKey="score"
                    radius={4}
                    animationBegin={0}
                    animationDuration={1000}
                    animationEasing="ease-in-out"
                >
                    <Cell
                        fill={
                            color
                                ? (color === "#FF2B00" ? "#3D1209" // Severe
                                    : (color === "#FFA100" ? "#3D2D09" // High
                                        : (color === "#FFEA00" ? "#2F2C09" // Moderate
                                            : (color === "#00FF82" ? "#0A3D23" // Low
                                                : "#3D3D3C")))) // Other
                                : "#3D3D3C" // Other
                        }
                        stroke={color}
                        strokeWidth="2px"
                    />
                    <Cell
                        fill={
                            (comparedRiskScore !== null && comparedRiskScore !== undefined)
                                ? (comparedRiskScore > 75 ? "#3D1209" // Severe
                                    : (comparedRiskScore > 50 ? "#3D2D09" // High
                                        : (comparedRiskScore > 25 ? "#2F2C09" // Moderate
                                            : "#0A3D23"))) // Low
                                : "#FFFFFF" // Other
                        }
                        stroke={
                            (comparedRiskScore !== null && comparedRiskScore !== undefined)
                                ? (comparedRiskScore > 75 ? "#FF2B00" // Severe
                                    : (comparedRiskScore > 50 ? "#FFA100" // High
                                        : (comparedRiskScore > 25 ? "#FFEA00" // Moderate
                                            : "#00FF82"))) // Low
                                : "#FFFFFF" // Other
                        }
                        strokeWidth="2px"
                    />
                    <LabelList
                        dataKey="score"
                        content={renderCustomizedLabel}
                    />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}

export default RiskScoreComparison;