import React from "react";

import {
    Box,
    Card,
    CardContent,
    Grid,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";
import { Circle } from "@mui/icons-material";

import { ButtonTooltip } from "../../../../common";

import "./card-recent-testing-activity.css";

const CardRecentTestingActivity = ({
    recentTestingActivity,
    selectedActivity,
    tooltip,
    formatToShortDate,
    onClickRecentTest
}) => {
    return (
        <Card className="dune-card-dashboard card-recent-testing-activity-card" id="card-recent-testing-activity">
            <CardContent className="card-recent-testing-activity-card-content">
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                    <Box width="100%" padding="28px 0 40px 28px">
                        <Typography component="h3" className="dune-text-header-card-dashboard">
                            Recent testing activity
                        </Typography>
                    </Box>
                    <ButtonTooltip
                        tooltip={tooltip}
                        ariaLabel={tooltip}
                    />
                </Stack>
                <Box
                    display="flex"
                    flexDirection="column"
                    padding="0 28px"
                    height="100%"
                >
                    {
                        (recentTestingActivity && Array.isArray(recentTestingActivity)) ?
                            <>
                                <Grid container>
                                    {
                                        recentTestingActivity.map((activity, index) =>
                                            <Grid
                                                item
                                                container
                                                xs={6}
                                                sm={2.4}
                                                md
                                                className="card-recent-testing-activity-grid-item"
                                                justifyContent="center"
                                                marginTop="20px"
                                                key={`recent-testing-activity-${index}`}
                                            >
                                                <Stack
                                                    className="card-recent-testing-activity-stack-activity"
                                                    direction="column"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    padding="8px"
                                                    title="View more details on this test."
                                                    sx={{
                                                        background: activity === selectedActivity ? "#131312" : "inherit"
                                                    }}
                                                    onClick={() => onClickRecentTest(activity)}
                                                >
                                                    <Box className="card-recent-testing-activity-main-box-result">
                                                        <Stack direction="row" justifyContent="center" alignItems="center">
                                                            <Circle
                                                                className={
                                                                    activity.passed
                                                                        ? "card-recent-testing-activity-main-icon-circle-pass"
                                                                        : "card-recent-testing-activity-main-icon-circle-fail"
                                                                }
                                                            />
                                                            <Typography
                                                                component="span"
                                                                className={
                                                                    activity.passed
                                                                        ? "card-recent-testing-activity-main-text-pass"
                                                                        : "card-recent-testing-activity-main-text-fail"
                                                                }
                                                            >
                                                                {activity.passed ? "Pass" : "Fail"}
                                                            </Typography>
                                                        </Stack>
                                                    </Box>
                                                    <Typography component="p" marginTop="16px">
                                                        {formatToShortDate(activity.date)}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <Typography
                                    component="p"
                                    className="card-recent-testing-activity-main-text-tip"
                                    textAlign="center"
                                    marginTop="60px"
                                >
                                    Click on each test for more details.
                                </Typography>
                            </>
                            :
                            <Box display="flex" flexDirection="column" height="100%">
                                <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                            </Box>
                    }
                </Box>
            </CardContent>
        </Card>
    );
};

export default CardRecentTestingActivity;