import React, {
    useEffect,
    useState
} from "react";

import { Grid } from "@mui/material";

import CardRecentTestingActivity from "./utils/card-recent-testing-activity/card-recent-testing-activity";
import CardTestDetails from "./utils/card-test-details/card-test-details";
import FullScreenTestHTML from "./utils/full-screen-test-html/full-screen-test-html";

import "./card-recent-testing-activity-main.css";

function CardRecentTestingActivityMain({
    recentTestingActivity,
    showHeadsUp,
    firstName,
    tooltip,
    onLoadTestHTML
}) {
    // Test details
    const [selectedActivity, setSelectedActivity] = useState({});
    const [testHTML, setTestHTML] = useState(null);
    const [openTestDetailsSpace, setOpenTestDetailsSpace] = useState(false);
    const [showTestDetails, setShowTestDetails] = useState(false);

    // Modal
    const [openModal, setOpenModal] = useState(false);

    // Cache
    const [testHTMLCache, setTestHTMLCache] = useState({});

    const formatToShortDate = (date) => {
        const givenDate = new Date(date);

        return `${givenDate.getMonth() + 1}/${givenDate.getDate()}`;
    };

    const handleClickClosePreview = () => {
        handleCloseModal();
    };

    const handleClickCloseTestDetails = () => {
        setShowTestDetails(false);
        setSelectedActivity({});

        setTimeout(() => {
            setOpenTestDetailsSpace(false);
        }, 300);
    };

    const handleClickRecentTest = async (activity) => {
        setTestHTML(null);
        setOpenTestDetailsSpace(true);
        setShowTestDetails(false);

        setTimeout(async () => {
            setSelectedActivity(activity);
            setShowTestDetails(true);

            const id = activity.id;

            if (testHTMLCache[id] !== undefined) {
                setTestHTML(testHTMLCache[id]);
            } else {
                const testHTML = await onLoadTestHTML(id);

                if (Object.keys(testHTML.error).length > 0) {
                    console.error(testHTML.error.message);
                    setTestHTML("");

                    // Update the cache.
                    const updatedTestHTMLCache = { ...testHTMLCache };
                    updatedTestHTMLCache[id] = "";

                    setTestHTMLCache(updatedTestHTMLCache);
                } else {
                    const { testHTMLCode } = testHTML.result;

                    setTestHTML(testHTMLCode);

                    // Update the cache.
                    const updatedTestHTMLCache = { ...testHTMLCache };
                    updatedTestHTMLCache[id] = testHTMLCode;

                    setTestHTMLCache(updatedTestHTMLCache);
                }
            }
        }, 200);
    };

    const handleClickTestSnippet = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        // If the user failed a test recently, show the most recent test details.
        if (showHeadsUp && recentTestingActivity && recentTestingActivity.length > 0 && Object.keys(selectedActivity).length === 0) {
            handleClickRecentTest(recentTestingActivity[recentTestingActivity.length - 1]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showHeadsUp, recentTestingActivity, selectedActivity]);

    return (
        <>
            <Grid item xs={12} marginTop="60px">
                <CardRecentTestingActivity
                    recentTestingActivity={recentTestingActivity}
                    selectedActivity={selectedActivity}
                    tooltip={tooltip}
                    formatToShortDate={formatToShortDate}
                    onClickRecentTest={handleClickRecentTest}
                />
            </Grid>
            <Grid item xs={12} marginTop="20px">
                <CardTestDetails
                    selectedActivity={selectedActivity}
                    testHTML={testHTML}
                    firstName={firstName}
                    openTestDetailsSpace={openTestDetailsSpace}
                    showTestDetails={showTestDetails}
                    formatToShortDate={formatToShortDate}
                    onClickCloseTestDetails={handleClickCloseTestDetails}
                    onClickTestSnippet={handleClickTestSnippet}
                />
            </Grid>
            <FullScreenTestHTML
                selectedActivity={selectedActivity}
                testHTML={testHTML}
                openModal={openModal}
                formatToShortDate={formatToShortDate}
                onClickClosePreview={handleClickClosePreview}
                onCloseModal={handleCloseModal}
            />
        </>
    );
}

export default CardRecentTestingActivityMain;