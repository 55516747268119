import React, {
    useEffect,
    useState
} from "react";

import {
    Box,
    Button,
    Grid,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";
import {
    ArrowOutward,
    KeyboardArrowDownOutlined
} from "@mui/icons-material";

import {
    CardAreasToImprove,
    CardComplianceFrameworks,
    CardHeadsUp,
    CardInteractions,
    CardLearningModulesActivity,
    // CardPercentageOfUsers,
    // CardPercentageOfUsersByDepartment,
    CardProfile,
    CardRecentTestingActivityMain,
    CardRiskScore,
    CardRiskScoreOverTime
} from "../../features";

import {
    pagesPlatform,
    pagesPlatformEndUser,
    pagesPlatformManager
} from "../../../constants";

import "./dashboard.css";
import { UsersTable } from "../../common";

function Dashboard({
    // All pages
    pageTitle,
    riskScore,
    comparedRiskScore,
    riskScoreOverTime, // Excluding direct reports
    percentagesOfUsers, // Excluding direct reports
    areasToImprove, // Excluding direct reports
    complianceFrameworks,
    onChangeSelectedDays,

    // Organization
    interactions,
    usersInteraction,
    onClickShareReport, // Also for department
    onClickInteraction,
    email,

    // Departments
    departments,
    selectedDepartment,
    onChangeDepartment,

    // Users
    profileData, // Also for end user
    blastRadius, // Also for end user
    recentTestingActivity,
    learningModulesActivity,
    onLoadTestHTML, // Also for end user

    // End user
    showHeadsUp,
    descriptionData,

    // Manager
    users
}) {
    const [color, setColor] = useState(null);
    const [riskLevel, setRiskLevel] = useState("");
    const [riskInsightsCopyForUsers, setRiskInsightsCopyForUsers] = useState(null);

    // Days filter
    const [selectedDays, setSelectedDays] = useState(90); // Default value is 90.

    // Headers for user table (manager)
    const headers = [
        {
            id: "name",
            label: "Name",
            isSortable: true,
            isSearchable: true,
            align: "left",
            width: "252px"
        },
        {
            id: "department",
            label: "Department",
            isSortable: true,
            isSearchable: true,
            align: "left",
            width: "208px"
        },
        {
            id: "compliant",
            label: "Compliance",
            isSortable: true,
            isSearchable: false,
            align: "left",
            width: "156px"
        },
        {
            id: "riskScore",
            label: "Risk score",
            isSortable: true,
            isSearchable: false,
            align: "left",
            width: "156px"
        },
        {
            id: "riskLevel",
            label: "Risk level",
            isSortable: false,
            isSearchable: false,
            align: "left",
            width: "164px"
        }
    ];

    const handleChangeSelectedDays = async (event) => {
        setSelectedDays(event.target.value);
        await onChangeSelectedDays(event.target.value);
    };

    const handleChangeDepartment = async (event) => {
        await onChangeDepartment(event);
    };

    const handleClickShareReport = async () => {
        await onClickShareReport();
    };

    useEffect(() => {
        if (riskScore) {
            if (riskScore > 75) { // Severe
                setColor("#FF2B00");
                setRiskLevel("severe");
            } else if (riskScore > 50) { // High
                setColor("#FFA100");
                setRiskLevel("high");
            } else if (riskScore > 25) { // Moderate
                setColor("#FFEA00");
                setRiskLevel("moderate");
            } else { // Low
                setColor("#00FF82");
                setRiskLevel("low");
            }
        }
    }, [riskScore]);

    useEffect(() => {
        if (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS) { // User
            let riskInsightsCopyForUsers = "";

            // Check if they have a blast radius as well as role.
            if (blastRadius && blastRadius.level && blastRadius.function
                && profileData && profileData.role) {
                let isDefault = false; // Used for any other level and function.

                if (blastRadius.level === "C-Suite") {
                    switch (blastRadius.function) {
                        case "Engineering/IT":
                            riskInsightsCopyForUsers = "This C-suite technology likely has admin access and directly oversees the engineering systems/strategy, making them a high-profile target for cyber attacks.";
                            break;

                        case "Executive Management":
                            riskInsightsCopyForUsers = "This C-suite executive leader likely directs corporate governance and strategy, making them a primary target for high-stakes cyber and insider threats.";
                            break;

                        case "Finance":
                            riskInsightsCopyForUsers = "This C-suite finance executive likely oversees financial operations, making them a key target for financial fraud.";
                            break;

                        case "HR":
                            riskInsightsCopyForUsers = "This C-suite HR executive likely shapes workforce policies and manages sensitive data, making them highly vulnerable to targeted attacks.";
                            break;

                        case "Legal/Compliance":
                            riskInsightsCopyForUsers = "This C-suite executive in legal/compliance likely has admin access to everything and makes decisions on compliance strategy, increasing their exposure to cybersecurity risk.";
                            break;

                        case "Operations":
                            riskInsightsCopyForUsers = "This executive overseeing operations is likely a high-profile target for sophisticated disruptions and espionage.";
                            break;

                        case "Sales/Marketing":
                            riskInsightsCopyForUsers = "This C-suite executive in Sales accesses crucial market and customer data, likely making them highly vulnerable to targeted cyber espionage.";
                            break;

                        case "Other":
                            riskInsightsCopyForUsers = "This C-suite executive in various capacities likely oversees diverse operations, exposing them to significant cyber and operational threats.";
                            break;

                        default:
                            isDefault = true;
                    }
                } else if (blastRadius.level === "Contractor") {
                    switch (blastRadius.function) {
                        case "Engineering/IT":
                            riskInsightsCopyForUsers = "This engineering contractor likely accesses sensitive data and systems, placing them at a heightened risk for targeted cyber threats.";
                            break;

                        case "Executive Management":
                            riskInsightsCopyForUsers = "This executive management contractor likely engages with confidential strategic projects, increasing their exposure to security risks and corporate espionage.";
                            break;

                        case "Finance":
                            riskInsightsCopyForUsers = "This finance contractor likely accesses detailed financial data and reports, increasing their risk of involvement in financial irregularities.";
                            break;

                        case "HR":
                            riskInsightsCopyForUsers = "This HR contractor is likely involved with confidential HR processes and data, creating moderately high risk for privacy and security breaches.";
                            break;

                        case "Legal/Compliance":
                            riskInsightsCopyForUsers = "This legal/compliance contractor is likely involved with sensitive compliance issues, placing them at moderately high risk to the organization.";
                            break;

                        case "Operations":
                            riskInsightsCopyForUsers = "This operations contractor likely accesses critical operational processes, particularly vulnerable to targeted operational and security risks.";
                            break;

                        case "Sales/Marketing":
                            riskInsightsCopyForUsers = "This sales contractor likely has access to significant sales insights, facing specific risks related to data security breaches.";
                            break;

                        case "Other":
                            riskInsightsCopyForUsers = "This contractor in various roles likely accesses confidential information across departments, presenting moderate risk of targeted data breaches.";
                            break;

                        default:
                            isDefault = true;
                    }
                } else if (blastRadius.level === "Entry-Level") {
                    switch (blastRadius.function) {
                        case "Engineering/IT":
                            riskInsightsCopyForUsers = "This entry-level engineer likely works with end-user systems and sensitive data, posing a moderate risk of data breaches.";
                            break;

                        case "Executive Management":
                            riskInsightsCopyForUsers = "This entry-level executive management trainee is likely exposed to high-level decision-making processes, introducing a moderate risk of data breach.";
                            break;

                        case "Finance":
                            riskInsightsCopyForUsers = "This entry-level finance staff likely has access to financial records and transactions, which places them at moderate exposure to cybersecurity risk.";
                            break;

                        case "HR":
                            riskInsightsCopyForUsers = "This entry-level HR staff likely has access to personal employee data, increasing their exposure to cybersecurity risk.";
                            break;

                        case "Legal/Compliance":
                            riskInsightsCopyForUsers = "This entry-level legal/compliance officer likely handles sensitive data, posing moderate risk of cybersecurity breaches.";
                            break;

                        case "Operations":
                            riskInsightsCopyForUsers = "This entry-level operations staff likely interacts with various operational processes, exposing them to low risk of data integrity issues.";
                            break;

                        case "Sales/Marketing":
                            riskInsightsCopyForUsers = "This entry-level sales associate likely handles customer data, posing minimal risk of security threats.";
                            break;

                        case "Other":
                            riskInsightsCopyForUsers = "This entry-level staff in various roles likely accesses department-specific data, introducing minimal risks of information misuse.";
                            break;

                        default:
                            isDefault = true;
                    }
                } else if (blastRadius.level === "Mid-Level") {
                    switch (blastRadius.function) {
                        case "Engineering/IT":
                            riskInsightsCopyForUsers = "This mid-level engineer likely contributes to critical projects, their heightened exposure to sensitive data and systems increases their risk of cyber threats.";
                            break;

                        case "Executive Management":
                            riskInsightsCopyForUsers = "This mid-level executive in management likely handles strategic planning and sensitive data, increasing their vulnerability to security breaches.";
                            break;

                        case "Finance":
                            riskInsightsCopyForUsers = "This mid-level finance professional is likely involved in financial planning and transactions, increasing their risk of being targeted in financial fraud attacks.";
                            break;

                        case "HR":
                            riskInsightsCopyForUsers = "This mid-level HR professional likely manages sensitive employee information, significantly increasing their exposure to cybersecurity risk.";
                            break;

                        case "Legal/Compliance":
                            riskInsightsCopyForUsers = "This mid-level legal/compliance professional likely oversees compliance processes, slightly increasing their exposure to cybersecurity risk.";
                            break;

                        case "Operations":
                            riskInsightsCopyForUsers = "This mid-level operations manager likely handles key parts of operations, slightly increasing their exposure to operational disruptions and security breaches.";
                            break;

                        case "Sales/Marketing":
                            riskInsightsCopyForUsers = "This mid-level sales professional likely shapes strategy and access customer data, slightly increasing their threat of customer information breaches.";
                            break;

                        case "Other":
                            riskInsightsCopyForUsers = "This mid-level professional in various departments likely accesses broader sensitive information, presenting minimal risk of data breaches.";
                            break;

                        default:
                            isDefault = true;
                    }
                } else if (blastRadius.level === "Senior Management") {
                    switch (blastRadius.function) {
                        case "Engineering/IT":
                            riskInsightsCopyForUsers = "This senior engineering leader likely has extensive access to user data and core systems, significantly elevating their risk of being targeted by sophisticated cyber attacks.";
                            break;

                        case "Executive Management":
                            riskInsightsCopyForUsers = "This senior executive likely oversees strategic information and decision-making processes, significantly escalating their risk of targeted corporate espionage.";
                            break;

                        case "Finance":
                            riskInsightsCopyForUsers = "This senior finance leader likely controls financial strategies and assets, significantly raising their risk of being targeted in financial fraud attacks.";
                            break;

                        case "HR":
                            riskInsightsCopyForUsers = "This senior HR leader likely has extensive access to employee records and strategic HR decisions, increasing their exposure to cybersecurity risk.";
                            break;

                        case "Legal/Compliance":
                            riskInsightsCopyForUsers = "This senior legal/compliance leader likely has comprehensive access to sensitive legal documents and compliance data, increasing their exposure to cybersecurity risk.";
                            break;

                        case "Operations":
                            riskInsightsCopyForUsers = "This senior operations leader likely oversees comprehensive operational areas, moderately increasing their risk of strategic data losses and cyber threats.";
                            break;

                        case "Sales/Marketing":
                            riskInsightsCopyForUsers = "This senior sales leader likely handles strategic information, increasing their risk profile of customer information breaches.";
                            break;

                        case "Other":
                            riskInsightsCopyForUsers = "This senior leader in various roles likely oversees multiple departmental functions, presenting moderate risk of data and security challenges.";
                            break;

                        default:
                            isDefault = true;
                    }
                } else {
                    isDefault = true;
                }

                if (isDefault) {
                    riskInsightsCopyForUsers = "This mid-level professional in various departments likely accesses broader sensitive information, presenting minimal risk of data breaches.";
                }
            } else {
                riskInsightsCopyForUsers = "This mid-level professional in various departments likely accesses broader sensitive information, presenting minimal risk of data breaches.";
            }

            setRiskInsightsCopyForUsers(riskInsightsCopyForUsers);
        } else if (pageTitle === pagesPlatformEndUser.INSIGHTS) { // End user
            let riskInsightsCopyForUsers = "";

            if (blastRadius && blastRadius.level && blastRadius.function) {
                let isDefault = false; // Used for any other level and function.

                if (blastRadius.level === "C-Suite") {
                    switch (blastRadius.function) {
                        case "Engineering/IT":
                            riskInsightsCopyForUsers = "You likely have access and directly oversee the engineering department, elevating your security exposure.";
                            break;

                        case "Executive Management":
                            riskInsightsCopyForUsers = "You likely direct corporate governance and sensitive decisions, significantly increasing your security risk exposure.";
                            break;

                        case "Finance":
                            riskInsightsCopyForUsers = "You likely oversee financial operations, making you a primary target for economic threats.";
                            break;

                        case "HR":
                            riskInsightsCopyForUsers = "You likely shape workforce policies and manage sensitive HR operations, elevating your security exposure.";
                            break;

                        case "Legal/Compliance":
                            riskInsightsCopyForUsers = "You likely control legal and/or compliance, placing you at very high cybersecurity risk.";
                            break;

                        case "Operations":
                            riskInsightsCopyForUsers = "You are likely a high-profile target for sophisticated cyber threats, significantly increasing your security risk exposure.";
                            break;

                        case "Sales/Marketing":
                            riskInsightsCopyForUsers = "You likely have access to crucial market and customer data, significantly increasing your security risk exposure.";
                            break;

                        case "Other":
                            riskInsightsCopyForUsers = "You may oversee diverse operations, exposing you to a high level of security risk exposure.";
                            break;

                        default:
                            isDefault = true;
                    }
                } else if (blastRadius.level === "Contractor") {
                    switch (blastRadius.function) {
                        case "Engineering/IT":
                            riskInsightsCopyForUsers = "You likely have access to sensitive data and systems, elevating your security exposure.";
                            break;

                        case "Executive Management":
                            riskInsightsCopyForUsers = "You likely engage with confidential strategic information, increasing your security risk exposure.";
                            break;

                        case "Finance":
                            riskInsightsCopyForUsers = "You likely have access to detailed financial data and reports, elevating your risk level.";
                            break;

                        case "HR":
                            riskInsightsCopyForUsers = "You are likely involved with confidential HR processes, increasing your security risk exposure.";
                            break;

                        case "Legal/Compliance":
                            riskInsightsCopyForUsers = "You likely handle sensitive legal tasks, presenting a substantial risk to the organization.";
                            break;

                        case "Operations":
                            riskInsightsCopyForUsers = "You likely have access to critical operational processes, posing a considerable security risk exposure.";
                            break;

                        case "Sales/Marketing":
                            riskInsightsCopyForUsers = "You likely have access to significant sales insights, facing a heightened security risk exposure.";
                            break;

                        case "Other":
                            riskInsightsCopyForUsers = "You may have access to confidential information across departments, elevating your security risk exposure.";
                            break;

                        default:
                            isDefault = true;
                    }
                } else if (blastRadius.level === "Entry-Level") {
                    switch (blastRadius.function) {
                        case "Engineering/IT":
                            riskInsightsCopyForUsers = "You are likely to work with end-user systems and sensitive data, increasing your security risk exposure.";
                            break;

                        case "Executive Management":
                            riskInsightsCopyForUsers = "You are likely exposed to high-level decision-making information, placing you at moderate risk.";
                            break;

                        case "Finance":
                            riskInsightsCopyForUsers = "You likely have access to financial records and transactions, posing a moderate risk.";
                            break;

                        case "HR":
                            riskInsightsCopyForUsers = "You likely have access to personal employee data, increasing your security risk exposure.";
                            break;

                        case "Legal/Compliance":
                            riskInsightsCopyForUsers = "You likely handle some sensitive data, posing a low risk of cybersecurity breaches";
                            break;

                        case "Operations":
                            riskInsightsCopyForUsers = "You are likely to interact with various operational data, increasing your security risk exposure.";
                            break;

                        case "Sales/Marketing":
                            riskInsightsCopyForUsers = "You are likely to handle customer data, which increases your security risk exposure.";
                            break;

                        case "Other":
                            riskInsightsCopyForUsers = "You may have access to department-specific data, introducing a moderate risk.";
                            break;

                        default:
                            isDefault = true;
                    }
                } else if (blastRadius.level === "Mid-Level") {
                    switch (blastRadius.function) {
                        case "Engineering/IT":
                            riskInsightsCopyForUsers = "You are likely to contribute to critical projects, increasing your risk of security breaches.";
                            break;

                        case "Executive Management":
                            riskInsightsCopyForUsers = "You likely handle strategic planning and sensitive executive information, increasing your risk substantially.";
                            break;

                        case "Finance":
                            riskInsightsCopyForUsers = "You are likely involved in financial planning and management, which heightens your security risk exposure.";
                            break;

                        case "HR":
                            riskInsightsCopyForUsers = "You likely manage sensitive employee information and decisions, raising your security risk exposure.";
                            break;

                        case "Legal/Compliance":
                            riskInsightsCopyForUsers = "You are likely involved in more sensitive legal matters, moderately increasing your security risk exposure.";
                            break;

                        case "Operations":
                            riskInsightsCopyForUsers = "You are likely to handle key parts of operations, increasing your security risk exposure.";
                            break;

                        case "Sales/Marketing":
                            riskInsightsCopyForUsers = "You likely have access to customer data, slightly increasing your security risk exposure.";
                            break;

                        case "Other":
                            riskInsightsCopyForUsers = "You may have access to broader sensitive information, presenting an elevated risk.";
                            break;

                        default:
                            isDefault = true;
                    }
                } else if (blastRadius.level === "Senior Management") {
                    switch (blastRadius.function) {
                        case "Engineering/IT":
                            riskInsightsCopyForUsers = "You likely have access to user data and core systems, significantly increasing your security risk exposure.";
                            break;

                        case "Executive Management":
                            riskInsightsCopyForUsers = "You likely oversee strategic information and sensitive corporate data, greatly increasing your security risk exposure.";
                            break;

                        case "Finance":
                            riskInsightsCopyForUsers = "You likely control financial strategies and access sensitive financial information, significantly increasing your security risk exposure.";
                            break;

                        case "HR":
                            riskInsightsCopyForUsers = "You likely have access to employee records and strategic data, which significantly heightens your security risk exposure.";
                            break;

                        case "Legal/Compliance":
                            riskInsightsCopyForUsers = "You likely oversee high-stakes legal decisions, significantly elevating your security risk exposure.";
                            break;

                        case "Operations":
                            riskInsightsCopyForUsers = "You likely oversee operations, moderately increasing your risk of security breaches.";
                            break;

                        case "Sales/Marketing":
                            riskInsightsCopyForUsers = "You are likely to handle strategic information, increasing your security risk exposure.";
                            break;

                        case "Other":
                            riskInsightsCopyForUsers = "You may oversee multiple departmental functions, significantly increasing your security risk exposure.";
                            break;

                        default:
                            isDefault = true;
                    }
                } else {
                    isDefault = true;
                }

                if (isDefault) {
                    riskInsightsCopyForUsers = "You may have access to department-specific data, introducing a moderate risk.";
                }
            } else {
                riskInsightsCopyForUsers = "You may have access to department-specific data, introducing a moderate risk.";
            }

            setRiskInsightsCopyForUsers(riskInsightsCopyForUsers);
        }
    }, [blastRadius, profileData, pageTitle]);

    return (
        <Grid container paddingTop="80px">
            {
                // Select a department to view section
                pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS &&
                <Grid item xs={12} marginBottom="80px">
                    <Typography component="h2" className="dune-text-header-dashboard">
                        Select a department to view
                    </Typography>
                    <Box marginTop="40px">
                        <Select
                            variant="standard"
                            className="dune-select-gray dashboard-select-department"
                            value={selectedDepartment}
                            displayEmpty
                            fullWidth
                            disableUnderline
                            onChange={handleChangeDepartment}
                        >
                            {
                                departments.map((department, index) =>
                                    <MenuItem
                                        className="dune-menu-item"
                                        value={department.name}
                                        key={`department-${index}`}
                                    >
                                        {department.name}
                                    </MenuItem>
                                )
                            }
                        </Select>
                    </Box>
                </Grid>
            }
            {
                pageTitle === pagesPlatformEndUser.INSIGHTS && showHeadsUp &&
                <Grid item xs={12} marginBottom="60px">
                    <CardHeadsUp />
                </Grid>
            }
            {
                // Description section
                pageTitle === pagesPlatformEndUser.INSIGHTS &&
                <Grid item xs={12}>
                    <Typography component="p" className="dashboard-text-end-user-description">
                        Hey{descriptionData && descriptionData.firstName ? ` ${descriptionData.firstName}` : ""}!
                    </Typography>
                    {
                        descriptionData && descriptionData.points !== null
                        && typeof descriptionData.points === "number" && descriptionData.firstDate &&
                        (
                            descriptionData.points === 0 ?
                                <Typography component="p" className="dashboard-text-end-user-description">
                                    Your risk score has remained the same since {descriptionData.firstDate}.
                                </Typography>
                                :
                                <Typography component="p" className="dashboard-text-end-user-description">
                                    Your risk score {descriptionData.points > 0 ? "increased" : "decreased"} by <Typography component="span" className={descriptionData.points > 0 ? "dashboard-text-end-user-description-points-increased" : "dashboard-text-end-user-description-points-decreased"}>{Math.abs(descriptionData.points)} points</Typography> since {descriptionData.firstDate}.
                                </Typography>
                        )
                    }
                </Grid>
            }
            {
                // Profile card
                pageTitle === pagesPlatform.INSIGHTS.subpages.USERS &&
                <Grid item xs={12}>
                    <CardProfile
                        profileData={profileData}
                    />
                </Grid>
            }
            {
                // Risk insights and share report section (organization and departments)
                (pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                    || pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS) &&
                <>
                    <Grid item xs={12}>
                        <Stack
                            direction={{
                                xs: "column",
                                sm: "row-reverse"
                            }}
                            justifyContent="space-between"
                            alignItems={{
                                xs: "flex-start",
                                sm: "center"
                            }}
                        >
                            {/* Share report button */}
                            <Box
                                marginLeft={{
                                    xs: "-8px",
                                    sm: 0
                                }}
                                marginBottom={{
                                    xs: "20px",
                                    sm: 0
                                }}
                            >
                                <Button
                                    variant="text"
                                    className="dune-button-text-white"
                                    aria-label="Share report"
                                    title="Download the report."
                                    onClick={handleClickShareReport}
                                    endIcon={<ArrowOutward />}
                                >
                                    Share report
                                </Button>
                            </Box>
                            <Typography component="h2" className="dune-text-header-dashboard">
                                Risk insights
                            </Typography>
                        </Stack>
                    </Grid>
                </>
            }
            {
                // Risk insights section (users, end user, manager)
                ((pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && riskScore)
                    || pageTitle === pagesPlatformEndUser.INSIGHTS
                    || pageTitle === pagesPlatformManager.YOUR_REPORTS) &&
                <Grid
                    item
                    xs={12}
                    marginTop={pageTitle !== pagesPlatformManager.YOUR_REPORTS ? "60px" : 0}
                >
                    <Typography component="h2" className="dune-text-header-dashboard">
                        Risk insights
                    </Typography>
                </Grid>
            }
            {
                // Copy for risk insights (organization)
                pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
                <Grid item xs={12} marginTop="40px">
                    {
                        riskLevel ?
                            <Typography component="p">
                                {
                                    riskLevel === "low" ? "The organization is at low risk due to employees consistently demonstrating strong awareness and low vulnerability."
                                        : (riskLevel === "moderate" ? "The organization is at moderate risk due to inconsistent security practices and moderate exposure to threats among employees."
                                            : (riskLevel === "high" ? "The organization is at high risk due to employees often lacking security awareness and significant exposure to potential breaches."
                                                : "The organization is under severe risk of social engineering as many employees display critical gaps in security knowledge and are highly vulnerable to attacks."))
                                }
                            </Typography>
                            :
                            <Box display="flex" flexDirection="column" height="60px">
                                <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                            </Box>
                    }
                </Grid>
            }
            {
                // Copy for risk insights (department)
                pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS &&
                <Grid item xs={12} marginTop="40px">
                    {
                        riskLevel ?
                            <Typography component="p">
                                {
                                    riskLevel === "low" ? "The department is at low risk due to employees consistently demonstrating strong awareness and low vulnerability."
                                        : (riskLevel === "moderate" ? "The department is at moderate risk due to inconsistent security practices and moderate exposure to threats among employees."
                                            : (riskLevel === "high" ? "The department is at high risk due to employees often lacking security awareness and significant exposure to potential breaches."
                                                : "The department is under severe risk of social engineering as many employees display critical gaps in security knowledge and are highly vulnerable to attacks."))
                                }
                            </Typography>
                            :
                            <Box display="flex" flexDirection="column" height="60px">
                                <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                            </Box>
                    }
                </Grid>
            }
            {
                // Copy for risk insights (users and end user)
                ((pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && riskScore)
                    || pageTitle === pagesPlatformEndUser.INSIGHTS) &&
                <Grid item xs={12} marginTop="40px">
                    {
                        riskLevel ?
                            <Typography component="p">
                                {riskInsightsCopyForUsers}
                            </Typography>
                            :
                            <Box display="flex" flexDirection="column" height="60px">
                                <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                            </Box>
                    }
                </Grid>
            }
            {
                // Copy for risk insights (manager)
                pageTitle === pagesPlatformManager.YOUR_REPORTS &&
                <Grid item xs={12} marginTop="40px">
                    {
                        riskLevel ?
                            <Typography component="p">
                                {
                                    riskLevel === "low" ? "Your reports are at low risk due to them consistently demonstrating strong awareness and low vulnerability."
                                        : (riskLevel === "moderate" ? "Your reports are at moderate risk due to inconsistent security practices and moderate exposure to threats among them."
                                            : (riskLevel === "high" ? "Your reports are at high risk due to them often lacking security awareness and significant exposure to potential breaches."
                                                : "Your reports are under severe risk of social engineering as many of them display critical gaps in security knowledge and are highly vulnerable to attacks."))
                                }
                            </Typography>
                            :
                            <Box display="flex" flexDirection="column" height="60px">
                                <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                            </Box>
                    }
                </Grid>
            }
            {
                // Risk score card (with risk score comparison)
                (pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                    || pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS
                    || (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && riskScore)
                    || pageTitle === pagesPlatformEndUser.INSIGHTS
                    || pageTitle === pagesPlatformManager.YOUR_REPORTS) &&
                <Grid
                    item
                    xs={12}
                    marginTop="60px"
                >
                    <CardRiskScore
                        riskScore={riskScore}
                        comparedRiskScore={comparedRiskScore}
                        firstName={
                            (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && profileData && profileData.firstName)
                                ? profileData.firstName
                                : ""
                        }
                        pageTitle={pageTitle}
                        secondaryLabel={
                            pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ? "Industry average"
                                : (pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS ? "Department average"
                                    : "User average")
                        }
                        tooltip={
                            pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                                ? "The risk score indicates overall organizational risk. Higher scores signify greater risk. The risk score comparison compares your organization's risk score to other companies in your industry."
                                : (pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS
                                    ? "The risk score indicates overall departmental risk. Higher scores signify greater risk. The risk score comparison compares this department's risk score to other departments in your organization."
                                    : (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS
                                        ? "The risk score indicates overall organizational risk. Higher scores signify greater risk. The risk score comparison compares this user's risk score to other users in your organization."
                                        : "The risk score measures your cybersecurity risk based on your Blast Radius and engagement with simulated social engineering tests. The risk score comparison compares your risk score to other users in the organization."))
                        }
                        color={color}
                    />
                </Grid>
            }
            {
                // Trends section (organization)
                (pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                    || pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS
                    || (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && riskScore)
                    || pageTitle === pagesPlatformEndUser.INSIGHTS) &&
                <Grid item xs={12} marginTop="60px">
                    <Typography component="h2" className="dune-text-header-dashboard">
                        Trends
                    </Typography>
                </Grid>
            }
            {
                // Copy for trends (organization)
                pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
                <Grid item xs={12} marginTop="40px">
                    {
                        riskLevel ?
                            <Typography component="p">
                                {
                                    riskLevel === "low" ? "Monitor the risk score over time; the current low risk is a positive sign that measures are working."
                                        : (riskLevel === "moderate" ? "Monitor the risk score over time; the current moderate risk is a positive sign that measures are working."
                                            : (riskLevel === "high" ? "Monitor the risk score over time; the current high risk indicates a need for enhanced proactive measures."
                                                : "Monitor the risk score over time; the current severe risk underscores the urgency for immediate security interventions."))
                                }
                            </Typography>
                            :
                            <Box display="flex" flexDirection="column" height="60px">
                                <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                            </Box>
                    }
                </Grid>
            }
            {
                // Copy for trends (department)
                pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS &&
                <Grid item xs={12} marginTop="40px">
                    {
                        riskLevel ?
                            <Typography component="p">
                                {
                                    riskLevel === "low" ? "Monitor the risk score over time; the current low risk is a positive sign that measures are working."
                                        : (riskLevel === "moderate" ? "Monitor the risk score over time; the current moderate risk indicates a need for enhanced proactive measures."
                                            : (riskLevel === "high" ? "Monitor the risk score over time; the current high risk underscores the urgency for immediate security interventions."
                                                : "Monitor the risk score over time; the current severe risk highlights critical vulnerabilities requiring urgent action."))
                                }
                            </Typography>
                            :
                            <Box display="flex" flexDirection="column" height="60px">
                                <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                            </Box>
                    }
                </Grid>
            }
            {
                // Copy for trends (user)
                (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && riskScore) &&
                <Grid item xs={12} marginTop="40px">
                    {
                        riskLevel ?
                            <Typography component="p">
                                {
                                    riskLevel === "low" ? "Monitor the risk score over time; the current low risk is a positive sign that measures are working."
                                        : (riskLevel === "moderate" ? "Monitor the risk score over time; the current moderate risk indicates a need for enhanced proactive measures."
                                            : (riskLevel === "high" ? "Monitor the risk score over time; the current high risk highlights the urgency for immediate security interventions."
                                                : "Monitor the risk score over time; the current severe risk highlights critical vulnerabilities requiring urgent action."))
                                }
                            </Typography>
                            :
                            <Box display="flex" flexDirection="column" height="60px">
                                <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                            </Box>
                    }
                </Grid>
            }
            {
                // Copy for trends (end user)
                pageTitle === pagesPlatformEndUser.INSIGHTS &&
                <Grid item xs={12} marginTop="40px">
                    {
                        riskLevel ?
                            <Typography component="p">
                                {
                                    riskLevel === "low" ? "Monitor your risk score over time; the current low risk is a positive sign that measures are working."
                                        : (riskLevel === "moderate" ? "Monitor your risk score over time; the current moderate risk indicates a need for enhanced proactive measures."
                                            : (riskLevel === "high" ? "Monitor your risk score over time; the current high risk highlights the urgency for immediate security interventions."
                                                : "Monitor your risk score over time; the current severe risk highlights critical vulnerabilities requiring urgent action."))
                                }
                            </Typography>
                            :
                            <Box display="flex" flexDirection="column" height="60px">
                                <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                            </Box>
                    }
                </Grid>
            }
            {
                // Days filter (organization, departments, users, end user)
                (pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                    || pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS
                    || (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && riskScore)
                    || pageTitle === pagesPlatformEndUser.INSIGHTS) &&
                <Grid item xs={12} marginTop="60px">
                    <Stack direction="row" justifyContent="flex-end" alignItems="center">
                        <Typography component="p" className="dashboard-text-filtered-for">Filtered for:</Typography>
                        <Box marginLeft="20px">
                            <Select
                                variant="standard"
                                className="dune-select-white dashboard-select-days"
                                value={selectedDays}
                                disableUnderline
                                IconComponent={KeyboardArrowDownOutlined}
                                onChange={handleChangeSelectedDays}
                            >
                                <MenuItem className="dune-menu-item" value={30}>
                                    Last 30 days
                                </MenuItem>
                                <MenuItem className="dune-menu-item" value={60}>
                                    Last 60 days
                                </MenuItem>
                                <MenuItem className="dune-menu-item" value={90}>
                                    Last 90 days
                                </MenuItem>
                                <MenuItem className="dune-menu-item" value="All time">
                                    All time
                                </MenuItem>
                            </Select>
                        </Box>
                    </Stack>
                </Grid>
            }
            {
                // Risk score over time
                (pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                    || pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS
                    || (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && riskScore)
                    || pageTitle === pagesPlatformEndUser.INSIGHTS) &&
                <Grid
                    item
                    xs={12}
                    marginTop="60px"
                >
                    <CardRiskScoreOverTime
                        riskScoreOverTime={riskScoreOverTime}
                        tooltip={
                            pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                                ? "Shows the distribution of risk scores across the organization and the percentage of employees at each risk level."
                                : (pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS
                                    ? "Shows the distribution of risk scores across this department and the percentage of employees at each risk level."
                                    : (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS
                                        ? "Identifies which security threats pose the greatest risk to the user based on failed social engineering tests."
                                        : "Identifies which security threats pose the greatest risk to you based on failed social engineering tests."))
                        }
                        color={color}
                    />
                </Grid>
            }
            {
                // Percentage of users
                // pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
                // <Grid item xs={12} marginTop="60px">
                //     <CardPercentageOfUsers
                //         percentagesOfUsers={percentagesOfUsers}
                //         tooltip="Shows the distribution of risk scores across the organization and the percentage of employees at each risk level."
                //     />
                // </Grid>
            }
            {
                // Areas to improve card
                ((pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                    || pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS
                    || (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && riskScore)
                    || pageTitle === pagesPlatformEndUser.INSIGHTS)
                    && (areasToImprove === null || (Array.isArray(areasToImprove) && areasToImprove.length > 0))) &&
                <Grid
                    item
                    xs={12}
                    marginTop="60px"
                >
                    <CardAreasToImprove
                        areasToImprove={areasToImprove}
                        pageTitle={pageTitle}
                        tooltip={
                            pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                                ? "Identifies areas that need improvement from past social engineering tests where employees struggled."
                                : (pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS
                                    ? "Identifies areas that need improvement from past social engineering challenges faced by the department."
                                    : (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS
                                        ? "These areas are identified based on previous social engineering tests where the user failed and encountered challenges."
                                        : "These areas are identified based on previous social engineering tests where you failed and encountered challenges."))
                        }
                        color={color}
                    />
                </Grid>
            }
            {
                // Department comparison
                // pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS &&
                // <Grid
                //     item
                //     xs={12}
                //     marginTop="60px"
                // >
                //     <CardPercentageOfUsersByDepartment
                //         percentagesOfUsers={percentagesOfUsers}
                //         departments={departments}
                //         selectedDepartment={selectedDepartment}
                //         tooltip="Compares risk score distributions across departments in the organization."
                //     />
                // </Grid>
            }
            {
                // User interactions card
                pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
                <Grid
                    item
                    xs={12}
                    marginTop="60px"
                >
                    <CardInteractions
                        interactions={interactions}
                        usersInteraction={usersInteraction}
                        selectedDays={selectedDays}
                        onClickInteraction={onClickInteraction}
                        email={email}
                        tooltip="Details the aggregated actions taken by employees during white-hat social engineering tests."
                    />
                </Grid>
            }
            {
                // Compliance section with copy
                ((pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                    || pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS
                    || pageTitle === pagesPlatformManager.YOUR_REPORTS)
                    && (complianceFrameworks === null || (Array.isArray(complianceFrameworks) && complianceFrameworks.length > 0))) &&
                <>
                    <Grid item xs={12} marginTop="60px">
                        <Typography component="h2" className="dune-text-header-dashboard">
                            Compliance
                        </Typography>
                    </Grid>
                    <Grid item xs={12} marginTop="40px">
                        <Typography component="p">
                            {
                                pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ? "Monitor the organization's compliance training completion status. We will send reminders to those who need to complete their training."
                                    : (pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS ? "Monitor the department's compliance training completion status. We will send reminders to those who need to complete their training."
                                        : (pageTitle === pagesPlatformManager.YOUR_REPORTS ? "Monitor your reports' compliance training completion status. We will send reminders to those who need to complete their training."
                                            : "Monitor the organization's compliance training completion status. We will send reminders to those who need to complete their training."))
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} marginTop="60px">
                        <CardComplianceFrameworks
                            complianceFrameworks={complianceFrameworks}
                            selectedDepartment={selectedDepartment}
                            pageTitle={pageTitle}
                            tooltip={
                                pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                                    ? "Shows the organization's compliance training completion rates across all required frameworks that were selected during onboarding."
                                    : (pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS
                                        ? "Shows the department's compliance training completion rates across all required frameworks that were selected during onboarding."
                                        : "This displays the user's overall completion rates for compliance training across all required frameworks.")
                            }
                            color={color}
                        />
                    </Grid>
                </>
            }
            {
                // Training progress section and recent testing activity card
                ((pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && riskScore)
                    || pageTitle === pagesPlatformEndUser.INSIGHTS) &&
                <>
                    <Grid item xs={12} marginTop="60px">
                        <Typography component="h2" className="dune-text-header-dashboard">
                            Training progress
                        </Typography>
                    </Grid>
                    <CardRecentTestingActivityMain
                        recentTestingActivity={recentTestingActivity}
                        showHeadsUp={showHeadsUp}
                        firstName={
                            (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && profileData && profileData.firstName)
                                ? profileData.firstName
                                : ""
                        }
                        tooltip={
                            pageTitle === pagesPlatformEndUser.INSIGHTS
                                ? "Shows your recent testing activities, highlighting your performance and areas for improvement."
                                : "Shows the user's recent testing activities, highlighting the user's performance and areas for improvement."
                        }
                        onLoadTestHTML={onLoadTestHTML}
                    />
                </>
            }
            {
                // Learning modules activity card
                (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS
                    && (learningModulesActivity === null || (Array.isArray(learningModulesActivity) && learningModulesActivity.length > 0))) &&
                <Grid item xs={12} marginTop="60px">
                    <CardLearningModulesActivity
                        learningModulesActivity={learningModulesActivity}
                    />
                </Grid>
            }
            {
                // Users (manager)
                pageTitle === pagesPlatformManager.YOUR_REPORTS &&
                <>
                    <Grid item xs={12} marginTop="60px">
                        <Typography component="h2" className="dune-text-header-dashboard">
                            Users
                        </Typography>
                    </Grid>
                    {
                        users &&
                        <Grid item xs={12}>
                            <UsersTable
                                data={users}
                                headers={headers}
                                defaultOrderBy="name"
                            />
                        </Grid>
                    }
                </>
            }
        </Grid>
    );
}

export default Dashboard;