import {
    formatPercentagesOfUsers,
    getResponseGet
} from "../utils";

const getInsightsOrganizationPercentagesOfUsers = async () => {
    let insightsOrganizationPercentagesOfUsers = {
        result: {
            percentagesOfUsers: [{
                name: "Percentages",
                lowRisk: 0,
                moderateRisk: 0,
                highRisk: 0,
                severeRisk: 0
            }]
        },
        error: {}
    };

    try {
        const response = await getResponseGet("/organization_data/?component=percentages_users");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        insightsOrganizationPercentagesOfUsers.result = {
            percentagesOfUsers: formatPercentagesOfUsers(data.percentages_users)
        };
    } catch (error) {
        insightsOrganizationPercentagesOfUsers.error = error;
    }

    return insightsOrganizationPercentagesOfUsers;
};

export default getInsightsOrganizationPercentagesOfUsers;