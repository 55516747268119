import React, { useEffect, useState } from "react";

import { Box, Button, Stack } from "@mui/material";
import { FileDownloadOutlined } from "@mui/icons-material";

import Papa from "papaparse";

import { getUsers } from "../../../api";

import { UsersTable } from "../../../components";

const headers = [
  {
    id: "select",
    label: "Select",
    isSortable: false,
    isSearchable: false,
    align: "left",
    // width: "252px"
  },
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },
  {
    id: "department",
    label: "Department",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "208px",
  },
  {
    id: "compliant",
    label: "Compliance",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "156px",
  },
  {
    id: "riskScore",
    label: "Risk score",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "156px",
  },
  {
    id: "riskLevel",
    label: "Risk level",
    isSortable: false,
    isSearchable: false,
    align: "left",
    width: "164px",
  },
];

function Reporting({ isInitialLoading, email }) {
  // Users selector
  const [users, setUsers] = useState([]);

  const handleClickExportReport = async (users, fileName) => {
    const csv = Papa.unparse(
      users.map(({ compliant, complianceFrameworks, ...fields }) => {
        const row = { ...fields };

        if (
          complianceFrameworks &&
          typeof complianceFrameworks === "object" &&
          Object.keys(complianceFrameworks).length > 0
        ) {
          Object.entries(complianceFrameworks).forEach(([key, value]) => {
            row[complianceFrameworks[key].name] =
              value !== null ? (value ? "yes" : "no") : "N/A";
          });
        }

        return row;
      })
    );

    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const loadUsers = async () => {
      const usersData = await getUsers();

      if (Object.keys(usersData.error).length > 0) {
        console.error(usersData.error.message);
      } else {
        const users = usersData.result;

        // Remove double occurrence of Duncan Idaho demo account.
        if (email === "david@dune.demo") {
          // Demo account
          const usersDemoEndUser = users.filter(
            (user) => user.email === "duncan.idaho@dune.demo"
          );
          let updatedUsers = users.filter(
            (user) => user.email !== "duncan.idaho@dune.demo"
          );

          if (usersDemoEndUser.length > 0) {
            usersDemoEndUser[0].riskScore = 97;
            usersDemoEndUser[0].compliant = false;

            updatedUsers = [...updatedUsers, usersDemoEndUser[0]];
          }

          setUsers(updatedUsers);
        } else {
          setUsers(users);
        }
      }
    };

    const updateComponent = async () => {
      await loadUsers();
    };

    if (!isInitialLoading && email) {
      updateComponent();
    }
  }, [isInitialLoading, email]);

  return (
    <>
      <Box display="flex" justifyContent="flex-end" marginTop="60px">
        <Stack direction="row" alignItems="center">
          <Button
            variant="contained"
            className="dune-button-contained-gray"
            endIcon={<FileDownloadOutlined />}
            onClick={() =>
              handleClickExportReport(users, "organization-report.csv")
            }
          >
            Export organization report
          </Button>
        </Stack>
      </Box>
      <UsersTable
        data={users}
        headers={headers}
        defaultOrderBy="name"
        fixedCount={email === "david@dune.demo" ? 10483 : null}
        onExportSelectedReports={handleClickExportReport}
      />
    </>
  );
}

export default Reporting;
