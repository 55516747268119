import React from "react";

import {
    MenuItem,
    Select,
    Stack,
    Typography
} from "@mui/material";

import "./filters.css";

function Filters({
    selectedRiskLevel,
    selectedCompliant,
    selectedDepartment,
    selectedComplianceFramework,
    departments,
    complianceFrameworks,
    onChangeRiskLevel,
    onChangeCompliant,
    onChangeDepartment,
    onChangeComplianceFramework
}) {
    const riskLevels = [
        {
            name: "Low"
        },
        {
            name: "Moderate"
        },
        {
            name: "High"
        },
        {
            name: "Severe"
        }
    ];

    const handleChangeComplianceFramework = (event) => {
        onChangeComplianceFramework(event);
    };

    const handleChangeCompliant = (event) => {
        onChangeCompliant(event);
    };

    const handleChangeDepartment = (event) => {
        onChangeDepartment(event);
    };

    const handleChangeRiskLevel = (event) => {
        onChangeRiskLevel(event);
    };

    return (
        <Stack direction="row" alignItems="center">
            <Typography
                component="p"
                className="filters-text-filter-by"
                marginRight="16px"
            >
                Filter by:
            </Typography>
            <Select
                variant="standard"
                className={
                    selectedRiskLevel === ""
                        ? "dune-select-gray filters-select-filter"
                        : "dune-select-gray filters-select-filter filters-select-filter-selected"
                }
                value={selectedRiskLevel}
                displayEmpty
                fullWidth
                disableUnderline
                sx={{
                    maxWidth: "112px"
                }}
                onChange={handleChangeRiskLevel}
            >
                <MenuItem className="dune-menu-item" value="">All risk levels</MenuItem>
                {
                    riskLevels.map((riskLevel, index) =>
                        <MenuItem
                            className="dune-menu-item"
                            value={riskLevel.name}
                            index={`filter-risk-level-${index}`}
                        >
                            {riskLevel.name}
                        </MenuItem>
                    )
                }
            </Select>
            <Select
                variant="standard"
                className={
                    selectedCompliant === ""
                        ? "dune-select-gray filters-select-filter"
                        : "dune-select-gray filters-select-filter filters-select-filter-selected"
                }
                value={selectedCompliant}
                displayEmpty
                fullWidth
                disableUnderline
                sx={{
                    maxWidth: "132px"
                }}
                onChange={handleChangeCompliant}
            >
                <MenuItem className="dune-menu-item" value="">Compliance</MenuItem>
                <MenuItem className="dune-menu-item" value="Yes">Yes</MenuItem>
                <MenuItem className="dune-menu-item" value="No">No</MenuItem>
            </Select>
            <Select
                variant="standard"
                className={
                    selectedDepartment === ""
                        ? "dune-select-gray filters-select-filter"
                        : "dune-select-gray filters-select-filter filters-select-filter-selected"
                }
                value={selectedDepartment}
                displayEmpty
                fullWidth
                disableUnderline
                sx={{
                    maxWidth: "132px"
                }}
                onChange={handleChangeDepartment}
            >
                <MenuItem className="dune-menu-item" value="">All departments</MenuItem>
                {
                    departments.map((department, index) =>
                        <MenuItem
                            className="dune-menu-item"
                            value={department.name}
                            index={`filter-department-${index}`}
                        >
                            {department.name}
                        </MenuItem>
                    )
                }
            </Select>
            {
                complianceFrameworks && Array.isArray(complianceFrameworks) && complianceFrameworks.length > 0 &&
                <Select
                    variant="standard"
                    className={
                        selectedComplianceFramework === ""
                            ? "dune-select-gray filters-select-filter"
                            : "dune-select-gray filters-select-filter filters-select-filter-selected"
                    }
                    value={selectedComplianceFramework}
                    displayEmpty
                    fullWidth
                    disableUnderline
                    sx={{
                        maxWidth: "212px"
                    }}
                    onChange={handleChangeComplianceFramework}
                >
                    <MenuItem className="dune-menu-item" value="">All compliance frameworks</MenuItem>
                    {
                        complianceFrameworks.map((complianceFramework, index) =>
                            <MenuItem
                                className="dune-menu-item"
                                value={complianceFramework.name}
                                index={`filter-compliance-framework-${index}`}
                            >
                                {complianceFramework.name.replace("_", " ")}
                            </MenuItem>
                        )
                    }
                </Select>
            }
        </Stack>
    );
}

export default Filters;