import React from "react";

import {
    Box,
    Card,
    CardContent,
    Stack,
    Typography
} from "@mui/material";

import "./card-risk-score-over-time.css";
import { ButtonTooltip } from "../../common";
import RiskScoreLineChart from "./utils/risk-score-line-chart/risk-score-line-chart";

function CardRiskScoreOverTime({
    riskScoreOverTime,
    tooltip,
    color,
}) {
    return (
        <Card className="dune-card-dashboard card-risk-score-over-time-card">
            <CardContent className="card-risk-score-card-over-time-content">
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                    <Box width="100%" padding="28px 0 40px 28px">
                        <Typography component="h3" className="dune-text-header-card-dashboard">
                            Risk score over time
                        </Typography>
                    </Box>
                    <ButtonTooltip
                        tooltip={tooltip}
                        ariaLabel={tooltip}
                    />
                </Stack>
                <Box padding="0 28px">
                    <RiskScoreLineChart
                        riskScoreOverTime={riskScoreOverTime}
                        color={color}
                    />
                </Box>
            </CardContent>
        </Card>
    );
}

export default CardRiskScoreOverTime;