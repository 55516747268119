import React, { useCallback, useEffect, useState } from "react";

import Papa from "papaparse";

import {
  getDepartments,
  getInsightsDepartment,
  getPercentagesOfUsersByDepartment,
  getUsers,
} from "../../../api";

import { Dashboard } from "../../../components";

import { pagesPlatform } from "../../../constants";

/**
 * Component for displaying insights of a specific department.
 * Handles department selection and fetches relevant insights data.
 */
function InsightsDepartment({ isInitialLoading, email }) {
  // Insights
  const [riskScore, setRiskScore] = useState(null);
  const [comparedRiskScore, setComparedRiskScore] = useState(null);
  const [riskScoreOverTime, setRiskScoreOverTime] = useState(null);
  const [areasToImprove, setAreasToImprove] = useState(null);
  const [percentagesOfUsers, setPercentagesOfUsers] = useState(null);
  const [complianceFrameworks, setComplianceFrameworks] = useState(null);

  const [riskScoreOverAllTime, setRiskScoreOverAllTime] = useState([]); // Master set (All time)

  // Departments selector
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  // Cache
  const [departmentDataCache, setDepartmentDataCache] = useState({});

  const getDemoDepartmentName = (department) => {
    const demoDepartmentMappings = {
      Engineering: "Founder Scouting",
      Legal: "Legal & Finance",
      "Information Technology": "Technology",
    };

    return demoDepartmentMappings[department] || department;
  };

  const handleChangeSelectedDays = (days) => {
    // For risk score over time card
    updateRiskScoreOverTime(days);
  };

  const handleChangeDepartment = async (event) => {
    const selectedDepartment = event.target.value;
    setSelectedDepartment(selectedDepartment);

    // Change the selected department to the actual department name if the account is a demo account.
    const departmentToLoad =
      email === "david@dune.demo"
        ? getDemoDepartmentName(selectedDepartment)
        : selectedDepartment;

    await loadInsightsDepartment(departmentToLoad);
  };

  const handleClickShareReport = async () => {
    const usersData = await getUsers();

    if (Object.keys(usersData.error).length > 0) {
      console.error(usersData.error.message);
    } else {
      const users = usersData.result.filter(
        (user) => user.department === selectedDepartment
      );
      const csv = Papa.unparse(
        users.map(({ compliant, complianceFrameworks, ...fields }) => {
          const row = { ...fields };

          if (
            complianceFrameworks &&
            typeof complianceFrameworks === "object" &&
            Object.keys(complianceFrameworks).length > 0
          ) {
            Object.entries(complianceFrameworks).forEach(([key, value]) => {
              row[key] = value !== null ? (value ? "yes" : "no") : "N/A";
            });
          }

          return row;
        })
      );

      const blob = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "department-insights.csv";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const updateRiskScoreOverTime = useCallback(
    (days) => {
      if (days === "All time") {
        // If all time, use the master set riskScoreOverAllTime.
        setRiskScoreOverTime(riskScoreOverAllTime);
      } else {
        const today = new Date();

        const cutOffDate = new Date();
        cutOffDate.setDate(today.getDate() - days);

        const filteredRiskScoreOverTime = riskScoreOverAllTime.filter(
          (element) => {
            const [month, day] = element.date.split(" ");
            let year = today.getFullYear();
            const dateForYearCheck = new Date(`${month} ${day}, ${year}`);

            if (dateForYearCheck > today) {
              year--;
            }

            const date = new Date(`${month} ${day}, ${year}`);

            return date >= cutOffDate;
          }
        );

        setRiskScoreOverTime(filteredRiskScoreOverTime);
      }
    },
    [riskScoreOverAllTime]
  );

  const loadInsightsDepartment = useCallback(
    async (department) => {
      // Check if data for this department is already cached.
      if (departmentDataCache[department]) {
        const cachedData = departmentDataCache[department];

        setRiskScore(cachedData.riskScore);
        setComparedRiskScore(cachedData.comparedRiskScore);
        setAreasToImprove(cachedData.areasToImprove);
        setComplianceFrameworks(cachedData.complianceFrameworks);
        setRiskScoreOverAllTime(cachedData.riskScoreOverAllTime);

        updateRiskScoreOverTime(90); // Default value is 90.

        return; // Return early as we have cached data.
      }

      // Fetch new data as it is not in cache.
      const insightsDepartment = await getInsightsDepartment(department);

      if (Object.keys(insightsDepartment.error).length > 0) {
        console.error(insightsDepartment.error.message);
      } else {
        const {
          riskScore,
          departmentAverage,
          riskScoreOverTime,
          areasToImprove,
          complianceFrameworks,
        } = insightsDepartment.result;

        let updatedRiskScore = riskScore;
        let updatedDepartmentAverage = departmentAverage;

        if (email === "david@dune.demo") {
          // Demo account
          updatedDepartmentAverage = 46;

          if (department === "Capital") {
            updatedRiskScore = 61;
          } else if (department === "Founder Scouting") {
            // Engineering
            updatedRiskScore = 26;
          } else if (department === "Global Support") {
            updatedRiskScore = 9;
          } else if (department === "Technology") {
            // Information Technology
            updatedRiskScore = 43;
          } else if (department === "Investment") {
            updatedRiskScore = 36;
          } else if (department === "Legal & Finance") {
            // Legal
            updatedRiskScore = 52;
          } else if (department === "Marketing & Communications") {
            updatedRiskScore = 8;
          } else if (department === "Portfolio") {
            updatedRiskScore = 9;
          } else if (department === "Program") {
            updatedRiskScore = 15;
          }
        }

        setRiskScore(updatedRiskScore);
        setComparedRiskScore(updatedDepartmentAverage);

        let riskScoreOverAllTime = riskScoreOverTime; // Master set (All time)

        if (email === "david@dune.demo") {
          // Demo account
          let riskScoreOverTime = [];

          const currentDate = new Date(); // Start with today's date.
          currentDate.setDate(currentDate.getDate() - 90);

          let currentRiskScore = 0;
          const targetScore = updatedRiskScore;

          if (targetScore >= 50) {
            // Higher risk
            currentRiskScore = 30;
          } else {
            // Lower risk
            currentRiskScore = 75;
          }

          const totalDays = 90;

          const options = {
            month: "short",
            day: "numeric",
          };

          for (let i = 0; i < totalDays; i++) {
            const formattedDate = currentDate.toLocaleDateString(
              "en-US",
              options
            );

            let stepChange = 0;

            if (targetScore >= 50) {
              stepChange = (targetScore - currentRiskScore) / (totalDays - i);
            } else {
              stepChange = (currentRiskScore - targetScore) / (totalDays - i);
            }

            let variability = (Math.random() - 0.5) * stepChange;

            let fluctuation =
              Math.sin(((2 * Math.PI) / totalDays) * i) * stepChange;

            if (targetScore >= 50) {
              currentRiskScore += stepChange + variability + fluctuation;
            } else {
              currentRiskScore -= stepChange + variability + fluctuation;
            }

            if (targetScore >= 50) {
              if (i === totalDays - 1 && currentRiskScore < targetScore) {
                currentRiskScore = targetScore;
              } else if (updatedRiskScore > targetScore) {
                currentRiskScore = targetScore - Math.random();
              }
            } else {
              if (i === totalDays - 1 && currentRiskScore > targetScore) {
                currentRiskScore = targetScore;
              } else if (updatedRiskScore < targetScore) {
                currentRiskScore = targetScore + Math.random();
              }
            }

            riskScoreOverTime.push({
              date: formattedDate,
              riskScore: Math.round(currentRiskScore),
            });

            currentDate.setDate(currentDate.getDate() + 1);
          }

          setRiskScoreOverTime(riskScoreOverTime);
          setRiskScoreOverAllTime(riskScoreOverTime);
        } else {
          setRiskScoreOverAllTime(riskScoreOverTime);
        }

        if (email === "david@dune.demo" && areasToImprove.length <= 1) {
          // Demo account
          setAreasToImprove([
            {
              name: "Impersonation of internal processes",
            },
            {
              name: "Link manipulation",
            },
          ]);
        } else {
          setAreasToImprove(areasToImprove);
        }

        // Change compliance data for the demo account.
        if (email === "david@dune.demo") {
          setComplianceFrameworks([
            {
              name: "SOC 2 Type 2",
              progress: 0.92,
            },
            {
              name: "HIPAA",
              progress: 0.94,
            },
            {
              name: "ISO 27001",
              progress: 0.91,
            },
          ]);
        } else {
          setComplianceFrameworks(complianceFrameworks);
        }

        setDepartmentDataCache((prevCache) => ({
          ...prevCache,
          [department]: {
            riskScore: updatedRiskScore,
            comparedRiskScore: updatedDepartmentAverage,
            areasToImprove,
            complianceFrameworks:
              email === "david@dune.demo"
                ? [
                    {
                      name: "SOC 2 Type 2",
                      progress: 0.92,
                    },
                    {
                      name: "HIPAA",
                      progress: 0.94,
                    },
                    {
                      name: "ISO 27001",
                      progress: 0.91,
                    },
                  ]
                : complianceFrameworks,
            riskScoreOverAllTime,
          },
        }));
      }

      if (email === "david@dune.demo") {
        // Demo account
        setPercentagesOfUsers([
          {
            name: "Investment",
            lowRisk: 0.31,
            moderateRisk: 0.59,
            highRisk: 0.1,
            severeRisk: 0,
          },
          {
            name: "Engineering",
            lowRisk: 0.76,
            moderateRisk: 0.18,
            highRisk: 0.06,
            severeRisk: 0,
          },
          {
            name: "Legal",
            lowRisk: 0.16,
            moderateRisk: 0.33,
            highRisk: 0.51,
            severeRisk: 0,
          },
          {
            name: "Marketing & Communications",
            lowRisk: 0.79,
            moderateRisk: 0.21,
            highRisk: 0,
            severeRisk: 0,
          },
          {
            name: "Program",
            lowRisk: 0.65,
            moderateRisk: 0.22,
            highRisk: 0.13,
            severeRisk: 0,
          },
          {
            name: "Capital",
            lowRisk: 0.16,
            moderateRisk: 0.27,
            highRisk: 0.52,
            severeRisk: 0.05,
          },
          {
            name: "Portfolio",
            lowRisk: 0.68,
            moderateRisk: 0.27,
            highRisk: 0.05,
            severeRisk: 0,
          },
          {
            name: "Global Support",
            lowRisk: 0.81,
            moderateRisk: 0.19,
            highRisk: 0,
            severeRisk: 0,
          },
          {
            name: "Information Technology",
            lowRisk: 0.28,
            moderateRisk: 0.67,
            highRisk: 0.05,
            severeRisk: 0,
          },
        ]);
      } else {
        const percentagesOfUsersByDepartment =
          await getPercentagesOfUsersByDepartment();

        if (Object.keys(percentagesOfUsersByDepartment.error).length > 0) {
          console.error(percentagesOfUsersByDepartment.error.message);
        } else {
          const percentagesOfUsers = percentagesOfUsersByDepartment.result;

          setPercentagesOfUsers(percentagesOfUsers);
        }
      }
    },
    [departmentDataCache, email, updateRiskScoreOverTime]
  );

  useEffect(() => {
    const loadDepartments = async () => {
      // Change data for the demo account.
      if (email === "david@dune.demo") {
        const departments = [
          {
            name: "Capital",
          },
          {
            name: "Engineering", // Founder Scouting
          },
          {
            name: "Global Support",
          },
          {
            name: "Information Technology", // Technology
          },
          {
            name: "Investment",
          },
          {
            name: "Legal", // Legal & Finance
          },
          {
            name: "Marketing & Communications",
          },
          {
            name: "Portfolio",
          },
          {
            name: "Program",
          },
        ];
        const selectedDepartment = "Capital";

        setDepartments(departments);
        setSelectedDepartment(selectedDepartment);

        await loadInsightsDepartment(selectedDepartment);
      } else {
        const departmentsData = await getDepartments();

        if (Object.keys(departmentsData.error).length > 0) {
          console.error(departmentsData.error.message);
        } else {
          const departments = departmentsData.result;

          setDepartments(departments);

          if (departments.length > 0) {
            setSelectedDepartment(departments[0].name);
            await loadInsightsDepartment(departments[0].name);
          }
        }
      }
    };

    if (!isInitialLoading && email) {
      loadDepartments();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoading, email]);

  useEffect(() => {
    if (riskScoreOverAllTime.length > 0) {
      updateRiskScoreOverTime(90); // Default value is 90.
    }
  }, [riskScoreOverAllTime, updateRiskScoreOverTime]);

  return (
    <Dashboard
      pageTitle={pagesPlatform.INSIGHTS.subpages.DEPARTMENTS}
      departments={departments}
      selectedDepartment={selectedDepartment}
      riskScore={riskScore}
      comparedRiskScore={comparedRiskScore}
      riskScoreOverTime={riskScoreOverTime}
      areasToImprove={areasToImprove}
      percentagesOfUsers={percentagesOfUsers}
      complianceFrameworks={complianceFrameworks}
      onChangeDepartment={handleChangeDepartment}
      onChangeSelectedDays={handleChangeSelectedDays}
      onClickShareReport={handleClickShareReport}
    />
  );
}

export default InsightsDepartment;
