import {
    formatAreasToImprove,
    formatComplianceFrameworks,
    formatRiskScore,
    formatRiskScoreOverTime,
    getResponseGet
} from "../utils";

/**
 * Fetches department insights data.
 * This function retrieves data related to the department's risk score, improvement metrics,
 * risk scores over time, areas that need improvement, and compliance frameworks.
 * 
 * @returns {Object} An object containing the insights data in the 'result' field, or an error object in the 'error' field.
 *   - The 'result' object includes:
 *     - riskScore: A numerical value representing the department's risk score.
 *     - improvement: A numerical value representing the improvement in risk score over time.
 *     - riskScoreOverTime: An array of risk scores over a period of time.
 *     - areasToImprove: An array of areas that require improvement.
 *     - complianceFrameworks: An object representing the compliance status across various frameworks.
 *   - The 'error' field contains an error object if the request fails.
 */
const getInsightsDepartment = async (department) => {
    let insightsDepartment = {
        result: {
            riskScore: 0,
            departmentAverage: 0,
            riskScoreOverTime: [],
            areasToImprove: [],
            complianceFrameworks: {}
        },
        error: {}
    };

    try {
        const response = await getResponseGet(`/department_data/${department}/`);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        insightsDepartment.result = {
            riskScore: formatRiskScore(data?.average_risk_score?.average_risk_score),
            departmentAverage: formatRiskScore(data.department_average),
            riskScoreOverTime: formatRiskScoreOverTime(data?.three_month_time_series),
            areasToImprove: formatAreasToImprove(data?.points_of_failure),
            complianceFrameworks: formatComplianceFrameworks(data?.percentage_compliance)
        };
    } catch (error) {
        insightsDepartment.error = error;
    }

    return insightsDepartment;
};

export default getInsightsDepartment;
