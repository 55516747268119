import {
    formatAreasToImprove,
    formatBlastRadius,
    formatDescriptionData,
    formatProfileData,
    formatRecentTestingActivity,
    formatRiskScore,
    formatRiskScoreOverTime,
    formatShowHeadsUp,
    getResponseGet
} from "../utils";

/**
 * Fetches personalized risk data for an end user.
 * This function retrieves detailed risk data for an individual user, including descriptions,
 * profile information, risk scores, and suggested areas for improvement.
 * 
 * @returns {Object} An object containing the fetched user insights data in the 'result' field, or an error object in the 'error' field.
 *   - The 'result' object includes:
 *     - descriptionData: An object with detailed descriptions such as the user's first interaction date, name, and total points.
 *     - profileData: An object with the user's profile information including name, email, department, role, and direct manager.
 *     - riskScore: A numerical value representing the user's current risk score.
 *     - changeInRiskScore: A numerical value indicating the change in the user's risk score over a specified period.
 *     - riskScoreOverTime: An array of the user's risk scores over time to track trends.
 *     - areasToImprove: An array of specific areas where the user can improve their risk profile.
 *   - The 'error' field contains an error object if the request fails, detailing the issue encountered.
 */
const getInsightsEndUser = async () => {
    let insightsEndUser = {
        result: {
            showHeadsUp: false,
            descriptionData: {
                firstName: ""
            },
            profileData: {
                name: "",
                email: "",
                department: "",
                role: "",
                directManager: ""
            },
            blastRadius: {
                level: "",
                function: ""
            },
            riskScore: 0,
            userAverage: 0,
            riskScoreOverTime: [],
            areasToImprove: [],
            recentTestingActivity: []
        },
        error: {}
    };

    try {
        const response = await getResponseGet("/personal_user_risk_data/");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        insightsEndUser.result = {
            showHeadsUp: formatShowHeadsUp(data?.past_attacks),
            descriptionData: formatDescriptionData(data?.user_information),
            profileData: formatProfileData(data?.user_information),
            blastRadius: formatBlastRadius(data.blast_radius),
            riskScore: formatRiskScore(data?.risk_score?.current_risk_score),
            userAverage: formatRiskScore(data.user_average),
            riskScoreOverTime: formatRiskScoreOverTime(data?.time_series),
            areasToImprove: formatAreasToImprove(data?.points_of_failure),
            recentTestingActivity: formatRecentTestingActivity(data?.past_attacks)
        };
    } catch (error) {
        insightsEndUser.error = error;
    }

    return insightsEndUser;
};

export default getInsightsEndUser;