import React from "react";

const HomeIllustration = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 496 521" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M332.833 479.847L400.775 519.082L470.175 479.003L402.233 439.769L332.833 479.847Z" fill="#085016" />
            <path d="M179.5 207.713L315.676 129.061L385.076 169.139L248.9 247.791L179.5 207.713Z" fill="#085016" />
            <path d="M286.463 172.434L336.999 150.766L350.835 157.943L323.135 185.861L330.209 191.579L389.379 155.85L340.357 128.996L275.535 169.451L286.463 172.434Z" fill="black" />
            <path d="M295.72 11.7614L294.069 23.1416" stroke="#15C237" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M298.978 14.6248L297.326 26.005" stroke="#15C237" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M287.646 166.753L287.49 170.424C287.435 171.636 286.426 172.819 285.233 173.049L270.14 176.004C265.057 176.995 263.433 172.994 263.433 172.994L274.26 167.258C275.489 166.606 276.334 165.679 276.912 164.954L287.646 166.753Z" fill="black" />
            <path d="M276.912 164.954L282.637 168.212C283.738 168.845 285.435 168.671 286.389 167.845L287.646 166.753C287.601 164.633 285.857 164.036 287.38 159.594L279.49 157.952C279.49 157.952 279.104 161.825 276.902 164.963L276.912 164.954Z" fill="#BCD8BE" />
            <path d="M323.713 173.223L321.447 189.761C320.915 193.625 332.943 194.011 332.815 187.293L332.632 177.564C332.613 176.839 332.402 176.123 332.017 175.508C330.365 172.893 329.475 171.746 329.384 168.423" fill="black" />
            <path d="M287.49 170.424C287.435 171.636 286.426 172.819 285.233 173.049L270.14 176.004" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M321.447 189.77C320.915 193.634 332.943 194.02 332.815 187.302L332.632 177.573" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M323.135 177.436L327.888 177.408C330.218 177.399 330.879 179.464 332.732 179.354L332.641 177.179C332.549 175.141 329.952 172.48 329.714 169.809L323.557 169.451L323.145 177.436H323.135Z" fill="#BCD8BE" />
            <path d="M290.05 89.8813L278.664 161.136L287.389 162.77L296.473 137.733L295.436 130.446L309.584 107.732L312.281 89.6885L290.05 89.8813Z" fill="#15C237" />
            <path d="M301.482 36.3298L293.858 66.2213C292.849 70.1769 289.399 71.3425 286.197 68.8278L277.334 61.8528L273.563 61.6784L270.718 61.1461L271.177 55.456L273.581 55.4927L286.032 59.6226L291.261 33.6132C291.766 31.0802 294.207 28.4004 296.675 27.6478L303.776 25.4819L307.464 31.273L301.492 36.3482L301.482 36.3298Z" fill="white" />
            <path d="M329.585 91.9187C330.154 96.5167 330.127 101.289 328.842 107.64L327.347 126.17L329.943 136.091L329.714 169.8C327.429 172.122 324.025 172.131 321.548 170.966L310.978 115.735L306.18 88.0182L312.263 82.8053L329.585 91.9095V91.9187Z" fill="#15C237" />
            <path d="M312.263 82.8145L306.18 88.0273L307.18 93.8092L307.675 94.3507L321.475 99.5453L322.869 130.786L325.172 132.897C326.356 133.98 327.769 136.109 328.026 137.697L329.457 139.697L329.925 136.1L327.328 126.179L329.677 107.612C330.668 99.7655 330.007 95.4796 329.576 91.9278L312.254 82.8236L312.263 82.8145Z" fill="#15C237" />
            <path d="M293.61 41.1481L302.345 26.675L308.492 22.2881L329.659 35.4855C332.494 37.2476 334.155 41.35 333.347 44.5897L331.411 52.3907L325.897 56.1902L325.814 74.4078C327.475 79.3729 328.806 86.1827 329.567 91.9279C324.163 94.6445 313.52 95.8467 307.657 94.36L290.023 89.8813L292.069 75.4999L293.583 41.1481H293.61Z" fill="white" />
            <path d="M292.381 68.9654L293.454 44.7549" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M325.833 74.4078L325.879 64.8906L309.74 69.4243C309.639 71.5719 311.923 72.7925 314.593 73.8387L319.135 75.6284C327.053 78.7488 321.603 81.5938 322.31 84.6225L324.438 93.7267C324.438 93.7267 327.75 93.0016 329.585 91.9278C328.971 85.6045 327.007 77.9136 325.833 74.4078Z" fill="#00FF82" />
            <path d="M291.463 53.4278L300.198 52.6844L302.225 54.786L303.391 57.934L321.42 61.9446C320.016 55.7405 321.888 47.1044 321.888 47.1044L332.769 46.9484L326.53 69.0205C326.218 70.1127 325.447 71.0121 324.411 71.471C323.374 71.9298 322.19 71.9207 321.172 71.4251L300.62 61.5224L297.023 60.6964L295.399 54.786L291.637 54.6576L291.463 53.4369V53.4278Z" fill="#15C237" />
            <path d="M289.509 58.8609L265.7 46.4711L277.838 66.3682L301.648 78.7671L289.509 58.8609Z" fill="black" />
            <path d="M301.648 78.7671L289.509 58.8609L265.7 46.4711" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M318.759 28.6849L313.456 33.6959C309.235 37.679 302.959 37.4312 303.923 33.6224L305.106 28.9327L304.666 27.4367L302.446 27.3908C301.198 27.3633 299.684 26.4547 299.078 25.3626L295.152 18.3234L294.032 11.7613C293.335 7.6406 295.794 3.64833 299.776 2.40935L302.767 1.48241C309.758 -0.67433 317.373 4.77718 315.089 13.9456L314.052 18.0939C313.566 20.0671 313.144 22.5634 314.401 24.1511L316.428 27.244L318.741 28.6849H318.759Z" fill="#15C237" />
            <path d="M315.098 13.9364C317.383 4.76798 309.767 -0.692703 302.776 1.47322L299.785 2.40016C296.941 3.28121 294.885 5.56644 294.188 8.29219L299.959 10.4581C301.473 11.0271 302.776 12.8076 302.868 14.4228L302.941 15.8729C302.987 16.754 303.749 17.4147 304.629 17.323L305.795 17.2128L308.611 19.0208L313.85 19.0759C313.914 18.7363 313.997 18.3968 314.07 18.0847L315.107 13.9364H315.098Z" fill="black" />
            <path d="M304.666 27.4275L310.125 23.0039C310.125 23.0039 310.345 23.3251 308.455 25.28C306.556 27.2348 308.832 29.9789 308.832 29.9789L305.813 31.2546L304.675 27.4275H304.666Z" fill="black" />
            <path d="M272.581 57.7504L276.416 60.2009L278.315 63.6241L276.783 64.1656L270.718 61.1278L272.581 57.7504Z" fill="#15C237" />
            <path d="M305.299 58.3562C306.657 61.8804 303.675 62.9908 303.675 62.9908L306.455 64.3308L308.171 68.7452L320.264 72.9486C323.548 74.0958 326.53 72.2235 327.2 69.507L332.778 46.9484L319.355 44.0666C319.355 44.0666 317.924 53.4186 319.86 61.5132L305.299 58.3653V58.3562Z" fill="white" />
            <path d="M319.851 61.504C321.154 61.862 322.466 62.1006 323.062 64.0554C323.658 66.0103 326.126 65.1017 325.09 62.6512C324.062 60.2008 323.411 58.3928 323.411 58.3928" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M303.675 62.9908L305.831 64.0554C306.85 64.5602 308.162 64.1747 308.749 63.1927L309.767 61.4765" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M319.346 44.0574C319.346 44.0574 317.915 53.4094 319.851 61.504L306.574 58.6406" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M308.446 20.0762C309.905 20.0762 311.089 18.8928 311.089 17.4331C311.089 15.9733 309.905 14.7899 308.446 14.7899C306.987 14.7899 305.804 15.9733 305.804 17.4331C305.804 18.8928 306.987 20.0762 308.446 20.0762Z" fill="#15C237" />
            <path d="M319.548 97.875C319.548 97.875 320.236 104.822 327.328 106.649" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M293.995 131.906L289.573 137.476C289.573 137.476 290.206 139.945 292.271 138.954C294.335 137.963 295.115 138.982 295.115 138.982" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M326.87 128.014L325.347 135.549L323.438 137.118" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M318.484 142.359L322.144 142.974" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M303.152 11.4952V21.0124" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M305.299 13.5969V23.1232" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M307.4 11.0546V20.5718" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M314.025 13.5418L316.383 25.0505" stroke="#15C237" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M311.969 14.0925L314.584 26.8402" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M308.63 12.5781L311.327 25.7572" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M318.218 23.94L315.493 10.0451L315.438 9.65963C314.566 3.25365 308.795 -0.408221 302.629 1.51908L299.271 2.56533C296.766 3.34543 294.418 6.10789 294.041 8.69598L292.39 20.0762" stroke="#15C237" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M318.759 28.6849L329.659 35.4763C332.494 37.2384 334.155 41.3408 333.347 44.5805L327.686 67.4879" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M220.641 271.883L236.055 280.785V296.616L61.8397 397.231L150.727 448.57L249.442 391.568L296.133 418.532" stroke="#15C237" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M142.754 443.963L150.727 448.57L249.442 391.568L296.133 418.532" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M69.932 425.58V447.239L139.323 407.161V385.502L69.932 425.58Z" fill="#15C237" />
            <path d="M69.932 447.239L0.532104 407.161" stroke="#15C237" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M0.532104 385.502L69.9321 425.58L139.323 385.502" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M77.3088 386.144C77.6635 386.144 77.951 385.856 77.951 385.502C77.951 385.147 77.6635 384.859 77.3088 384.859C76.9541 384.859 76.6665 385.147 76.6665 385.502C76.6665 385.856 76.9541 386.144 77.3088 386.144Z" fill="#00FF82" />
            <path d="M69.932 390.402C70.2867 390.402 70.5743 390.115 70.5743 389.76C70.5743 389.405 70.2867 389.117 69.932 389.117C69.5773 389.117 69.2898 389.405 69.2898 389.76C69.2898 390.115 69.5773 390.402 69.932 390.402Z" fill="#00FF82" />
            <path d="M84.6855 390.402C85.0402 390.402 85.3277 390.115 85.3277 389.76C85.3277 389.405 85.0402 389.117 84.6855 389.117C84.3308 389.117 84.0432 389.405 84.0432 389.76C84.0432 390.115 84.3308 390.402 84.6855 390.402Z" fill="#00FF82" />
            <path d="M77.3088 394.661C77.6635 394.661 77.951 394.373 77.951 394.018C77.951 393.664 77.6635 393.376 77.3088 393.376C76.9541 393.376 76.6665 393.664 76.6665 394.018C76.6665 394.373 76.9541 394.661 77.3088 394.661Z" fill="#00FF82" />
            <path d="M69.932 398.928C70.2867 398.928 70.5743 398.641 70.5743 398.286C70.5743 397.931 70.2867 397.643 69.932 397.643C69.5773 397.643 69.2898 397.931 69.2898 398.286C69.2898 398.641 69.5773 398.928 69.932 398.928Z" fill="#00FF82" />
            <path d="M92.0622 394.661C92.4169 394.661 92.7045 394.373 92.7045 394.018C92.7045 393.664 92.4169 393.376 92.0622 393.376C91.7075 393.376 91.42 393.664 91.42 394.018C91.42 394.373 91.7075 394.661 92.0622 394.661Z" fill="#00FF82" />
            <path d="M84.6855 398.928C85.0402 398.928 85.3277 398.641 85.3277 398.286C85.3277 397.931 85.0402 397.643 84.6855 397.643C84.3308 397.643 84.0432 397.931 84.0432 398.286C84.0432 398.641 84.3308 398.928 84.6855 398.928Z" fill="#00FF82" />
            <path d="M77.3088 403.187C77.6635 403.187 77.951 402.899 77.951 402.544C77.951 402.19 77.6635 401.902 77.3088 401.902C76.9541 401.902 76.6665 402.19 76.6665 402.544C76.6665 402.899 76.9541 403.187 77.3088 403.187Z" fill="#00FF82" />
            <path d="M69.932 407.445C70.2867 407.445 70.5743 407.158 70.5743 406.803C70.5743 406.448 70.2867 406.16 69.932 406.16C69.5773 406.16 69.2898 406.448 69.2898 406.803C69.2898 407.158 69.5773 407.445 69.932 407.445Z" fill="#00FF82" />
            <path d="M92.0622 403.187C92.4169 403.187 92.7045 402.899 92.7045 402.544C92.7045 402.19 92.4169 401.902 92.0622 401.902C91.7075 401.902 91.42 402.19 91.42 402.544C91.42 402.899 91.7075 403.187 92.0622 403.187Z" fill="#00FF82" />
            <path d="M84.6855 407.445C85.0402 407.445 85.3277 407.158 85.3277 406.803C85.3277 406.448 85.0402 406.16 84.6855 406.16C84.3308 406.16 84.0432 406.448 84.0432 406.803C84.0432 407.158 84.3308 407.445 84.6855 407.445Z" fill="#00FF82" />
            <path d="M99.439 398.928C99.7937 398.928 100.081 398.641 100.081 398.286C100.081 397.931 99.7937 397.643 99.439 397.643C99.0842 397.643 98.7967 397.931 98.7967 398.286C98.7967 398.641 99.0842 398.928 99.439 398.928Z" fill="#00FF82" />
            <path d="M77.3088 411.713C77.6635 411.713 77.951 411.425 77.951 411.07C77.951 410.716 77.6635 410.428 77.3088 410.428C76.9541 410.428 76.6665 410.716 76.6665 411.07C76.6665 411.425 76.9541 411.713 77.3088 411.713Z" fill="#00FF82" />
            <path d="M69.932 415.971C70.2867 415.971 70.5743 415.684 70.5743 415.329C70.5743 414.974 70.2867 414.686 69.932 414.686C69.5773 414.686 69.2898 414.974 69.2898 415.329C69.2898 415.684 69.5773 415.971 69.932 415.971Z" fill="#00FF82" />
            <path d="M62.5461 386.144C62.9008 386.144 63.1884 385.856 63.1884 385.502C63.1884 385.147 62.9008 384.859 62.5461 384.859C62.1914 384.859 61.9039 385.147 61.9039 385.502C61.9039 385.856 62.1914 386.144 62.5461 386.144Z" fill="#00FF82" />
            <path d="M92.0622 377.618C92.4169 377.618 92.7045 377.33 92.7045 376.975C92.7045 376.621 92.4169 376.333 92.0622 376.333C91.7075 376.333 91.42 376.621 91.42 376.975C91.42 377.33 91.7075 377.618 92.0622 377.618Z" fill="#00FF82" />
            <path d="M84.6855 381.886C85.0402 381.886 85.3277 381.598 85.3277 381.243C85.3277 380.888 85.0402 380.601 84.6855 380.601C84.3308 380.601 84.0432 380.888 84.0432 381.243C84.0432 381.598 84.3308 381.886 84.6855 381.886Z" fill="#00FF82" />
            <path d="M99.439 381.886C99.7937 381.886 100.081 381.598 100.081 381.243C100.081 380.888 99.7937 380.601 99.439 380.601C99.0842 380.601 98.7967 380.888 98.7967 381.243C98.7967 381.598 99.0842 381.886 99.439 381.886Z" fill="#00FF82" />
            <path d="M92.0622 386.144C92.4169 386.144 92.7045 385.856 92.7045 385.502C92.7045 385.147 92.4169 384.859 92.0622 384.859C91.7075 384.859 91.42 385.147 91.42 385.502C91.42 385.856 91.7075 386.144 92.0622 386.144Z" fill="#00FF82" />
            <path d="M106.816 386.144C107.17 386.144 107.458 385.856 107.458 385.502C107.458 385.147 107.17 384.859 106.816 384.859C106.461 384.859 106.173 385.147 106.173 385.502C106.173 385.856 106.461 386.144 106.816 386.144Z" fill="#00FF82" />
            <path d="M99.439 390.402C99.7937 390.402 100.081 390.115 100.081 389.76C100.081 389.405 99.7937 389.117 99.439 389.117C99.0842 389.117 98.7967 389.405 98.7967 389.76C98.7967 390.115 99.0842 390.402 99.439 390.402Z" fill="#00FF82" />
            <path d="M106.816 394.661C107.17 394.661 107.458 394.373 107.458 394.018C107.458 393.664 107.17 393.376 106.816 393.376C106.461 393.376 106.173 393.664 106.173 394.018C106.173 394.373 106.461 394.661 106.816 394.661Z" fill="#00FF82" />
            <path d="M114.192 390.402C114.547 390.402 114.835 390.115 114.835 389.76C114.835 389.405 114.547 389.117 114.192 389.117C113.838 389.117 113.55 389.405 113.55 389.76C113.55 390.115 113.838 390.402 114.192 390.402Z" fill="#00FF82" />
            <path d="M99.439 373.36C99.7937 373.36 100.081 373.072 100.081 372.717C100.081 372.362 99.7937 372.075 99.439 372.075C99.0842 372.075 98.7967 372.362 98.7967 372.717C98.7967 373.072 99.0842 373.36 99.439 373.36Z" fill="#00FF82" />
            <path d="M106.816 377.618C107.17 377.618 107.458 377.33 107.458 376.975C107.458 376.621 107.17 376.333 106.816 376.333C106.461 376.333 106.173 376.621 106.173 376.975C106.173 377.33 106.461 377.618 106.816 377.618Z" fill="#00FF82" />
            <path d="M114.192 381.886C114.547 381.886 114.835 381.598 114.835 381.243C114.835 380.888 114.547 380.601 114.192 380.601C113.838 380.601 113.55 380.888 113.55 381.243C113.55 381.598 113.838 381.886 114.192 381.886Z" fill="#00FF82" />
            <path d="M121.569 386.144C121.924 386.144 122.211 385.856 122.211 385.502C122.211 385.147 121.924 384.859 121.569 384.859C121.214 384.859 120.927 385.147 120.927 385.502C120.927 385.856 121.214 386.144 121.569 386.144Z" fill="#00FF82" />
            <path d="M55.1694 390.402C55.5241 390.402 55.8117 390.115 55.8117 389.76C55.8117 389.405 55.5241 389.117 55.1694 389.117C54.8147 389.117 54.5272 389.405 54.5272 389.76C54.5272 390.115 54.8147 390.402 55.1694 390.402Z" fill="#00FF82" />
            <path d="M62.5461 394.661C62.9008 394.661 63.1884 394.373 63.1884 394.018C63.1884 393.664 62.9008 393.376 62.5461 393.376C62.1914 393.376 61.9039 393.664 61.9039 394.018C61.9039 394.373 62.1914 394.661 62.5461 394.661Z" fill="#00FF82" />
            <path d="M47.7927 394.661C48.1474 394.661 48.435 394.373 48.435 394.018C48.435 393.664 48.1474 393.376 47.7927 393.376C47.438 393.376 47.1505 393.664 47.1505 394.018C47.1505 394.373 47.438 394.661 47.7927 394.661Z" fill="#00FF82" />
            <path d="M55.1694 398.928C55.5241 398.928 55.8117 398.641 55.8117 398.286C55.8117 397.931 55.5241 397.643 55.1694 397.643C54.8147 397.643 54.5272 397.931 54.5272 398.286C54.5272 398.641 54.8147 398.928 55.1694 398.928Z" fill="#00FF82" />
            <path d="M62.5461 403.187C62.9008 403.187 63.1884 402.899 63.1884 402.544C63.1884 402.19 62.9008 401.902 62.5461 401.902C62.1914 401.902 61.9039 402.19 61.9039 402.544C61.9039 402.899 62.1914 403.187 62.5461 403.187Z" fill="#00FF82" />
            <path d="M47.7927 403.187C48.1474 403.187 48.435 402.899 48.435 402.544C48.435 402.19 48.1474 401.902 47.7927 401.902C47.438 401.902 47.1505 402.19 47.1505 402.544C47.1505 402.899 47.438 403.187 47.7927 403.187Z" fill="#00FF82" />
            <path d="M55.1694 407.445C55.5241 407.445 55.8117 407.158 55.8117 406.803C55.8117 406.448 55.5241 406.16 55.1694 406.16C54.8147 406.16 54.5272 406.448 54.5272 406.803C54.5272 407.158 54.8147 407.445 55.1694 407.445Z" fill="#00FF82" />
            <path d="M40.416 398.928C40.7707 398.928 41.0582 398.641 41.0582 398.286C41.0582 397.931 40.7707 397.643 40.416 397.643C40.0613 397.643 39.7737 397.931 39.7737 398.286C39.7737 398.641 40.0613 398.928 40.416 398.928Z" fill="#00FF82" />
            <path d="M62.5461 411.713C62.9008 411.713 63.1884 411.425 63.1884 411.07C63.1884 410.716 62.9008 410.428 62.5461 410.428C62.1914 410.428 61.9039 410.716 61.9039 411.07C61.9039 411.425 62.1914 411.713 62.5461 411.713Z" fill="#00FF82" />
            <path d="M47.7927 386.144C48.1474 386.144 48.435 385.856 48.435 385.502C48.435 385.147 48.1474 384.859 47.7927 384.859C47.438 384.859 47.1505 385.147 47.1505 385.502C47.1505 385.856 47.438 386.144 47.7927 386.144Z" fill="#00FF82" />
            <path d="M40.416 390.402C40.7707 390.402 41.0582 390.115 41.0582 389.76C41.0582 389.405 40.7707 389.117 40.416 389.117C40.0613 389.117 39.7737 389.405 39.7737 389.76C39.7737 390.115 40.0613 390.402 40.416 390.402Z" fill="#00FF82" />
            <path d="M55.1694 381.886C55.5241 381.886 55.8117 381.598 55.8117 381.243C55.8117 380.888 55.5241 380.601 55.1694 380.601C54.8147 380.601 54.5272 380.888 54.5272 381.243C54.5272 381.598 54.8147 381.886 55.1694 381.886Z" fill="#00FF82" />
            <path d="M33.0393 394.661C33.394 394.661 33.6815 394.373 33.6815 394.018C33.6815 393.664 33.394 393.376 33.0393 393.376C32.6846 393.376 32.397 393.664 32.397 394.018C32.397 394.373 32.6846 394.661 33.0393 394.661Z" fill="#00FF82" />
            <path d="M40.416 373.36C40.7707 373.36 41.0582 373.072 41.0582 372.717C41.0582 372.362 40.7707 372.075 40.416 372.075C40.0613 372.075 39.7737 372.362 39.7737 372.717C39.7737 373.072 40.0613 373.36 40.416 373.36Z" fill="#00FF82" />
            <path d="M33.0393 377.618C33.394 377.618 33.6815 377.33 33.6815 376.975C33.6815 376.621 33.394 376.333 33.0393 376.333C32.6846 376.333 32.397 376.621 32.397 376.975C32.397 377.33 32.6846 377.618 33.0393 377.618Z" fill="#00FF82" />
            <path d="M25.6625 381.886C26.0172 381.886 26.3048 381.598 26.3048 381.243C26.3048 380.888 26.0172 380.601 25.6625 380.601C25.3078 380.601 25.0203 380.888 25.0203 381.243C25.0203 381.598 25.3078 381.886 25.6625 381.886Z" fill="#00FF82" />
            <path d="M47.7927 377.618C48.1474 377.618 48.435 377.33 48.435 376.975C48.435 376.621 48.1474 376.333 47.7927 376.333C47.438 376.333 47.1505 376.621 47.1505 376.975C47.1505 377.33 47.438 377.618 47.7927 377.618Z" fill="#00FF82" />
            <path d="M40.416 381.886C40.7707 381.886 41.0582 381.598 41.0582 381.243C41.0582 380.888 40.7707 380.601 40.416 380.601C40.0613 380.601 39.7737 380.888 39.7737 381.243C39.7737 381.598 40.0613 381.886 40.416 381.886Z" fill="#00FF82" />
            <path d="M33.0393 386.144C33.394 386.144 33.6815 385.856 33.6815 385.502C33.6815 385.147 33.394 384.859 33.0393 384.859C32.6846 384.859 32.397 385.147 32.397 385.502C32.397 385.856 32.6846 386.144 33.0393 386.144Z" fill="#00FF82" />
            <path d="M25.6625 390.402C26.0172 390.402 26.3048 390.115 26.3048 389.76C26.3048 389.405 26.0172 389.117 25.6625 389.117C25.3078 389.117 25.0203 389.405 25.0203 389.76C25.0203 390.115 25.3078 390.402 25.6625 390.402Z" fill="#00FF82" />
            <path d="M18.2858 386.144C18.6405 386.144 18.9281 385.856 18.9281 385.502C18.9281 385.147 18.6405 384.859 18.2858 384.859C17.9311 384.859 17.6436 385.147 17.6436 385.502C17.6436 385.856 17.9311 386.144 18.2858 386.144Z" fill="#00FF82" />
            <path d="M114.192 347.148V357.4C114.192 367.293 121.569 366.302 121.569 375.241V385.492" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M77.3088 351.416V361.667C77.3088 371.561 84.6855 370.57 84.6855 379.509V389.76" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M33.0393 342.89V353.141C33.0393 363.035 25.6625 362.044 25.6625 370.983V381.234" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M92.0623 385.502V342.89" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M47.7927 385.502V342.89" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M55.1694 364.2V321.589" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M84.6855 347.148V357.4C84.6855 367.293 99.439 379.077 99.439 388.025V398.277" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M69.9321 355.674V365.926C69.9321 375.819 55.1786 379.086 55.1786 388.025V398.277" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M62.5461 394.018V351.416" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M69.9321 302.811L0.532104 342.89L69.9321 382.968L139.323 342.89L69.9321 302.811Z" fill="url(#paint0_linear_9336_36970)" />
            <path d="M139.323 342.89L69.9321 302.811L0.532104 342.89" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M69.932 339.274C70.2867 339.274 70.5743 338.986 70.5743 338.631C70.5743 338.277 70.2867 337.989 69.932 337.989C69.5773 337.989 69.2898 338.277 69.2898 338.631C69.2898 338.986 69.5773 339.274 69.932 339.274Z" fill="#00FF82" />
            <path d="M77.3088 343.532C77.6635 343.532 77.951 343.245 77.951 342.89C77.951 342.535 77.6635 342.247 77.3088 342.247C76.9541 342.247 76.6665 342.535 76.6665 342.89C76.6665 343.245 76.9541 343.532 77.3088 343.532Z" fill="#00FF82" />
            <path d="M69.932 347.791C70.2867 347.791 70.5743 347.503 70.5743 347.148C70.5743 346.793 70.2867 346.506 69.932 346.506C69.5773 346.506 69.2898 346.793 69.2898 347.148C69.2898 347.503 69.5773 347.791 69.932 347.791Z" fill="#00FF82" />
            <path d="M84.6855 347.791C85.0402 347.791 85.3277 347.503 85.3277 347.148C85.3277 346.793 85.0402 346.506 84.6855 346.506C84.3308 346.506 84.0432 346.793 84.0432 347.148C84.0432 347.503 84.3308 347.791 84.6855 347.791Z" fill="#00FF82" />
            <path d="M77.3088 352.058C77.6635 352.058 77.951 351.771 77.951 351.416C77.951 351.061 77.6635 350.773 77.3088 350.773C76.9541 350.773 76.6665 351.061 76.6665 351.416C76.6665 351.771 76.9541 352.058 77.3088 352.058Z" fill="#00FF82" />
            <path d="M69.932 356.317C70.2867 356.317 70.5743 356.029 70.5743 355.674C70.5743 355.319 70.2867 355.032 69.932 355.032C69.5773 355.032 69.2898 355.319 69.2898 355.674C69.2898 356.029 69.5773 356.317 69.932 356.317Z" fill="#00FF82" />
            <path d="M99.439 356.317C99.7937 356.317 100.081 356.029 100.081 355.674C100.081 355.319 99.7937 355.032 99.439 355.032C99.0842 355.032 98.7967 355.319 98.7967 355.674C98.7967 356.029 99.0842 356.317 99.439 356.317Z" fill="#00FF82" />
            <path d="M62.5461 343.532C62.9008 343.532 63.1884 343.245 63.1884 342.89C63.1884 342.535 62.9008 342.247 62.5461 342.247C62.1914 342.247 61.9039 342.535 61.9039 342.89C61.9039 343.245 62.1914 343.532 62.5461 343.532Z" fill="#00FF82" />
            <path d="M84.6855 330.748C85.0402 330.748 85.3277 330.46 85.3277 330.105C85.3277 329.751 85.0402 329.463 84.6855 329.463C84.3308 329.463 84.0432 329.751 84.0432 330.105C84.0432 330.46 84.3308 330.748 84.6855 330.748Z" fill="#00FF82" />
            <path d="M92.0622 335.015C92.4169 335.015 92.7045 334.728 92.7045 334.373C92.7045 334.018 92.4169 333.731 92.0622 333.731C91.7075 333.731 91.42 334.018 91.42 334.373C91.42 334.728 91.7075 335.015 92.0622 335.015Z" fill="#00FF82" />
            <path d="M84.6855 339.274C85.0402 339.274 85.3277 338.986 85.3277 338.631C85.3277 338.277 85.0402 337.989 84.6855 337.989C84.3308 337.989 84.0432 338.277 84.0432 338.631C84.0432 338.986 84.3308 339.274 84.6855 339.274Z" fill="#00FF82" />
            <path d="M99.439 339.274C99.7937 339.274 100.081 338.986 100.081 338.631C100.081 338.277 99.7937 337.989 99.439 337.989C99.0842 337.989 98.7967 338.277 98.7967 338.631C98.7967 338.986 99.0842 339.274 99.439 339.274Z" fill="#00FF82" />
            <path d="M92.0622 343.532C92.4169 343.532 92.7045 343.245 92.7045 342.89C92.7045 342.535 92.4169 342.247 92.0622 342.247C91.7075 342.247 91.42 342.535 91.42 342.89C91.42 343.245 91.7075 343.532 92.0622 343.532Z" fill="#00FF82" />
            <path d="M106.816 343.532C107.17 343.532 107.458 343.245 107.458 342.89C107.458 342.535 107.17 342.247 106.816 342.247C106.461 342.247 106.173 342.535 106.173 342.89C106.173 343.245 106.461 343.532 106.816 343.532Z" fill="#00FF82" />
            <path d="M99.439 347.791C99.7937 347.791 100.081 347.503 100.081 347.148C100.081 346.793 99.7937 346.506 99.439 346.506C99.0842 346.506 98.7967 346.793 98.7967 347.148C98.7967 347.503 99.0842 347.791 99.439 347.791Z" fill="#00FF82" />
            <path d="M106.816 352.058C107.17 352.058 107.458 351.771 107.458 351.416C107.458 351.061 107.17 350.773 106.816 350.773C106.461 350.773 106.173 351.061 106.173 351.416C106.173 351.771 106.461 352.058 106.816 352.058Z" fill="#00FF82" />
            <path d="M114.192 347.791C114.547 347.791 114.835 347.503 114.835 347.148C114.835 346.793 114.547 346.506 114.192 346.506C113.838 346.506 113.55 346.793 113.55 347.148C113.55 347.503 113.838 347.791 114.192 347.791Z" fill="#00FF82" />
            <path d="M77.3088 335.015C77.6635 335.015 77.951 334.728 77.951 334.373C77.951 334.018 77.6635 333.731 77.3088 333.731C76.9541 333.731 76.6665 334.018 76.6665 334.373C76.6665 334.728 76.9541 335.015 77.3088 335.015Z" fill="#00FF82" />
            <path d="M99.439 330.748C99.7937 330.748 100.081 330.46 100.081 330.105C100.081 329.751 99.7937 329.463 99.439 329.463C99.0842 329.463 98.7967 329.751 98.7967 330.105C98.7967 330.46 99.0842 330.748 99.439 330.748Z" fill="#00FF82" />
            <path d="M106.816 335.015C107.17 335.015 107.458 334.728 107.458 334.373C107.458 334.018 107.17 333.731 106.816 333.731C106.461 333.731 106.173 334.018 106.173 334.373C106.173 334.728 106.461 335.015 106.816 335.015Z" fill="#00FF82" />
            <path d="M114.192 339.274C114.547 339.274 114.835 338.986 114.835 338.631C114.835 338.277 114.547 337.989 114.192 337.989C113.838 337.989 113.55 338.277 113.55 338.631C113.55 338.986 113.838 339.274 114.192 339.274Z" fill="#00FF82" />
            <path d="M121.569 343.532C121.924 343.532 122.211 343.245 122.211 342.89C122.211 342.535 121.924 342.247 121.569 342.247C121.214 342.247 120.927 342.535 120.927 342.89C120.927 343.245 121.214 343.532 121.569 343.532Z" fill="#00FF82" />
            <path d="M92.0622 326.489C92.4169 326.489 92.7045 326.202 92.7045 325.847C92.7045 325.492 92.4169 325.205 92.0622 325.205C91.7075 325.205 91.42 325.492 91.42 325.847C91.42 326.202 91.7075 326.489 92.0622 326.489Z" fill="#00FF82" />
            <path d="M55.1694 347.791C55.5241 347.791 55.8117 347.503 55.8117 347.148C55.8117 346.793 55.5241 346.506 55.1694 346.506C54.8147 346.506 54.5272 346.793 54.5272 347.148C54.5272 347.503 54.8147 347.791 55.1694 347.791Z" fill="#00FF82" />
            <path d="M62.5461 352.058C62.9008 352.058 63.1884 351.771 63.1884 351.416C63.1884 351.061 62.9008 350.773 62.5461 350.773C62.1914 350.773 61.9039 351.061 61.9039 351.416C61.9039 351.771 62.1914 352.058 62.5461 352.058Z" fill="#00FF82" />
            <path d="M40.416 356.317C40.7707 356.317 41.0582 356.029 41.0582 355.674C41.0582 355.319 40.7707 355.032 40.416 355.032C40.0613 355.032 39.7737 355.319 39.7737 355.674C39.7737 356.029 40.0613 356.317 40.416 356.317Z" fill="#00FF82" />
            <path d="M47.7927 343.532C48.1474 343.532 48.435 343.245 48.435 342.89C48.435 342.535 48.1474 342.247 47.7927 342.247C47.438 342.247 47.1505 342.535 47.1505 342.89C47.1505 343.245 47.438 343.532 47.7927 343.532Z" fill="#00FF82" />
            <path d="M40.416 347.791C40.7707 347.791 41.0582 347.503 41.0582 347.148C41.0582 346.793 40.7707 346.506 40.416 346.506C40.0613 346.506 39.7737 346.793 39.7737 347.148C39.7737 347.503 40.0613 347.791 40.416 347.791Z" fill="#00FF82" />
            <path d="M62.5461 335.015C62.9008 335.015 63.1884 334.728 63.1884 334.373C63.1884 334.018 62.9008 333.731 62.5461 333.731C62.1914 333.731 61.9039 334.018 61.9039 334.373C61.9039 334.728 62.1914 335.015 62.5461 335.015Z" fill="#00FF82" />
            <path d="M55.1694 339.274C55.5241 339.274 55.8117 338.986 55.8117 338.631C55.8117 338.277 55.5241 337.989 55.1694 337.989C54.8147 337.989 54.5272 338.277 54.5272 338.631C54.5272 338.986 54.8147 339.274 55.1694 339.274Z" fill="#00FF82" />
            <path d="M69.932 330.748C70.2867 330.748 70.5743 330.46 70.5743 330.105C70.5743 329.751 70.2867 329.463 69.932 329.463C69.5773 329.463 69.2898 329.751 69.2898 330.105C69.2898 330.46 69.5773 330.748 69.932 330.748Z" fill="#00FF82" />
            <path d="M33.0393 352.058C33.394 352.058 33.6815 351.771 33.6815 351.416C33.6815 351.061 33.394 350.773 33.0393 350.773C32.6846 350.773 32.397 351.061 32.397 351.416C32.397 351.771 32.6846 352.058 33.0393 352.058Z" fill="#00FF82" />
            <path d="M47.7927 326.489C48.1474 326.489 48.435 326.202 48.435 325.847C48.435 325.492 48.1474 325.205 47.7927 325.205C47.438 325.205 47.1505 325.492 47.1505 325.847C47.1505 326.202 47.438 326.489 47.7927 326.489Z" fill="#00FF82" />
            <path d="M40.416 330.748C40.7707 330.748 41.0582 330.46 41.0582 330.105C41.0582 329.751 40.7707 329.463 40.416 329.463C40.0613 329.463 39.7737 329.751 39.7737 330.105C39.7737 330.46 40.0613 330.748 40.416 330.748Z" fill="#00FF82" />
            <path d="M33.0393 335.015C33.394 335.015 33.6815 334.728 33.6815 334.373C33.6815 334.018 33.394 333.731 33.0393 333.731C32.6846 333.731 32.397 334.018 32.397 334.373C32.397 334.728 32.6846 335.015 33.0393 335.015Z" fill="#00FF82" />
            <path d="M25.6625 339.274C26.0172 339.274 26.3048 338.986 26.3048 338.631C26.3048 338.277 26.0172 337.989 25.6625 337.989C25.3078 337.989 25.0203 338.277 25.0203 338.631C25.0203 338.986 25.3078 339.274 25.6625 339.274Z" fill="#00FF82" />
            <path d="M47.7927 335.015C48.1474 335.015 48.435 334.728 48.435 334.373C48.435 334.018 48.1474 333.731 47.7927 333.731C47.438 333.731 47.1505 334.018 47.1505 334.373C47.1505 334.728 47.438 335.015 47.7927 335.015Z" fill="#00FF82" />
            <path d="M40.416 339.274C40.7707 339.274 41.0582 338.986 41.0582 338.631C41.0582 338.277 40.7707 337.989 40.416 337.989C40.0613 337.989 39.7737 338.277 39.7737 338.631C39.7737 338.986 40.0613 339.274 40.416 339.274Z" fill="#00FF82" />
            <path d="M55.1694 330.748C55.5241 330.748 55.8117 330.46 55.8117 330.105C55.8117 329.751 55.5241 329.463 55.1694 329.463C54.8147 329.463 54.5272 329.751 54.5272 330.105C54.5272 330.46 54.8147 330.748 55.1694 330.748Z" fill="#00FF82" />
            <path d="M33.0393 343.532C33.394 343.532 33.6815 343.245 33.6815 342.89C33.6815 342.535 33.394 342.247 33.0393 342.247C32.6846 342.247 32.397 342.535 32.397 342.89C32.397 343.245 32.6846 343.532 33.0393 343.532Z" fill="#00FF82" />
            <path d="M25.6625 347.791C26.0172 347.791 26.3048 347.503 26.3048 347.148C26.3048 346.793 26.0172 346.506 25.6625 346.506C25.3078 346.506 25.0203 346.793 25.0203 347.148C25.0203 347.503 25.3078 347.791 25.6625 347.791Z" fill="#00FF82" />
            <path d="M77.3088 326.489C77.6635 326.489 77.951 326.202 77.951 325.847C77.951 325.492 77.6635 325.205 77.3088 325.205C76.9541 325.205 76.6665 325.492 76.6665 325.847C76.6665 326.202 76.9541 326.489 77.3088 326.489Z" fill="#00FF82" />
            <path d="M62.5461 317.973C62.9008 317.973 63.1884 317.685 63.1884 317.33C63.1884 316.975 62.9008 316.688 62.5461 316.688C62.1914 316.688 61.9039 316.975 61.9039 317.33C61.9039 317.685 62.1914 317.973 62.5461 317.973Z" fill="#00FF82" />
            <path d="M55.1694 322.231C55.5241 322.231 55.8117 321.943 55.8117 321.589C55.8117 321.234 55.5241 320.946 55.1694 320.946C54.8147 320.946 54.5272 321.234 54.5272 321.589C54.5272 321.943 54.8147 322.231 55.1694 322.231Z" fill="#00FF82" />
            <path d="M62.5461 326.489C62.9008 326.489 63.1884 326.202 63.1884 325.847C63.1884 325.492 62.9008 325.205 62.5461 325.205C62.1914 325.205 61.9039 325.492 61.9039 325.847C61.9039 326.202 62.1914 326.489 62.5461 326.489Z" fill="#00FF82" />
            <path d="M69.932 322.231C70.2867 322.231 70.5743 321.943 70.5743 321.589C70.5743 321.234 70.2867 320.946 69.932 320.946C69.5773 320.946 69.2898 321.234 69.2898 321.589C69.2898 321.943 69.5773 322.231 69.932 322.231Z" fill="#00FF82" />
            <path d="M84.6855 322.231C85.0402 322.231 85.3277 321.943 85.3277 321.589C85.3277 321.234 85.0402 320.946 84.6855 320.946C84.3308 320.946 84.0432 321.234 84.0432 321.589C84.0432 321.943 84.3308 322.231 84.6855 322.231Z" fill="#00FF82" />
            <path d="M69.932 313.705C70.2867 313.705 70.5743 313.417 70.5743 313.063C70.5743 312.708 70.2867 312.42 69.932 312.42C69.5773 312.42 69.2898 312.708 69.2898 313.063C69.2898 313.417 69.5773 313.705 69.932 313.705Z" fill="#00FF82" />
            <path d="M77.3088 317.973C77.6635 317.973 77.951 317.685 77.951 317.33C77.951 316.975 77.6635 316.688 77.3088 316.688C76.9541 316.688 76.6665 316.975 76.6665 317.33C76.6665 317.685 76.9541 317.973 77.3088 317.973Z" fill="#00FF82" />
            <path d="M18.2858 343.532C18.6405 343.532 18.9281 343.245 18.9281 342.89C18.9281 342.535 18.6405 342.247 18.2858 342.247C17.9311 342.247 17.6436 342.535 17.6436 342.89C17.6436 343.245 17.9311 343.532 18.2858 343.532Z" fill="#00FF82" />
            <path d="M69.932 325.48L39.7737 342.89L69.932 360.309L100.081 342.89L69.932 325.48Z" fill="black" />
            <path d="M39.7737 342.89L69.932 360.309L100.081 342.89" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M68.3998 348.479L70.7761 351.297L68.8677 352.398L58.6284 339.788L60.1789 338.898L82.0339 344.799L80.0979 345.918L75.2076 344.56L68.3998 348.488V348.479ZM61.9956 340.852L67.2345 347.084L72.7854 343.872L61.9864 340.843L61.9956 340.852Z" fill="white" />
            <path d="M69.5283 333.492L85.5663 342.752L83.7313 343.808L67.6933 334.547L69.5283 333.492Z" fill="white" />
            <path d="M0.532104 342.89L69.9321 382.968L139.323 342.89" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M179.5 238.678V287.87L248.9 247.792V198.6L179.5 238.678Z" fill="#15C237" />
            <path d="M179.5 287.87L110.1 247.792" stroke="#15C237" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M179.5 158.521L110.1 198.6L179.5 238.678L248.9 198.6L179.5 158.521Z" fill="url(#paint1_linear_9336_36970)" />
            <path d="M110.1 198.6L179.5 238.678L248.9 198.6" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M186.877 199.242C187.232 199.242 187.519 198.954 187.519 198.6C187.519 198.245 187.232 197.957 186.877 197.957C186.522 197.957 186.235 198.245 186.235 198.6C186.235 198.954 186.522 199.242 186.877 199.242Z" fill="#00FF82" />
            <path d="M179.5 203.5C179.855 203.5 180.143 203.213 180.143 202.858C180.143 202.503 179.855 202.216 179.5 202.216C179.146 202.216 178.858 202.503 178.858 202.858C178.858 203.213 179.146 203.5 179.5 203.5Z" fill="#00FF82" />
            <path d="M194.254 203.5C194.609 203.5 194.896 203.213 194.896 202.858C194.896 202.503 194.609 202.216 194.254 202.216C193.899 202.216 193.612 202.503 193.612 202.858C193.612 203.213 193.899 203.5 194.254 203.5Z" fill="#00FF82" />
            <path d="M186.877 207.759C187.232 207.759 187.519 207.471 187.519 207.116C187.519 206.762 187.232 206.474 186.877 206.474C186.522 206.474 186.235 206.762 186.235 207.116C186.235 207.471 186.522 207.759 186.877 207.759Z" fill="#00FF82" />
            <path d="M179.5 212.017C179.855 212.017 180.143 211.73 180.143 211.375C180.143 211.02 179.855 210.732 179.5 210.732C179.146 210.732 178.858 211.02 178.858 211.375C178.858 211.73 179.146 212.017 179.5 212.017Z" fill="#00FF82" />
            <path d="M201.631 207.759C201.985 207.759 202.273 207.471 202.273 207.116C202.273 206.762 201.985 206.474 201.631 206.474C201.276 206.474 200.988 206.762 200.988 207.116C200.988 207.471 201.276 207.759 201.631 207.759Z" fill="#00FF82" />
            <path d="M194.254 212.017C194.609 212.017 194.896 211.73 194.896 211.375C194.896 211.02 194.609 210.732 194.254 210.732C193.899 210.732 193.612 211.02 193.612 211.375C193.612 211.73 193.899 212.017 194.254 212.017Z" fill="#00FF82" />
            <path d="M186.877 216.285C187.232 216.285 187.519 215.997 187.519 215.642C187.519 215.288 187.232 215 186.877 215C186.522 215 186.235 215.288 186.235 215.642C186.235 215.997 186.522 216.285 186.877 216.285Z" fill="#00FF82" />
            <path d="M179.5 220.543C179.855 220.543 180.143 220.256 180.143 219.901C180.143 219.546 179.855 219.258 179.5 219.258C179.146 219.258 178.858 219.546 178.858 219.901C178.858 220.256 179.146 220.543 179.5 220.543Z" fill="#00FF82" />
            <path d="M201.631 216.285C201.985 216.285 202.273 215.997 202.273 215.642C202.273 215.288 201.985 215 201.631 215C201.276 215 200.988 215.288 200.988 215.642C200.988 215.997 201.276 216.285 201.631 216.285Z" fill="#00FF82" />
            <path d="M194.254 220.543C194.609 220.543 194.896 220.256 194.896 219.901C194.896 219.546 194.609 219.258 194.254 219.258C193.899 219.258 193.612 219.546 193.612 219.901C193.612 220.256 193.899 220.543 194.254 220.543Z" fill="#00FF82" />
            <path d="M209.016 212.017C209.371 212.017 209.659 211.73 209.659 211.375C209.659 211.02 209.371 210.732 209.016 210.732C208.662 210.732 208.374 211.02 208.374 211.375C208.374 211.73 208.662 212.017 209.016 212.017Z" fill="#00FF82" />
            <path d="M186.877 224.802C187.232 224.802 187.519 224.514 187.519 224.159C187.519 223.804 187.232 223.517 186.877 223.517C186.522 223.517 186.235 223.804 186.235 224.159C186.235 224.514 186.522 224.802 186.877 224.802Z" fill="#00FF82" />
            <path d="M179.5 229.069C179.855 229.069 180.143 228.782 180.143 228.427C180.143 228.072 179.855 227.784 179.5 227.784C179.146 227.784 178.858 228.072 178.858 228.427C178.858 228.782 179.146 229.069 179.5 229.069Z" fill="#00FF82" />
            <path d="M172.124 199.242C172.478 199.242 172.766 198.954 172.766 198.6C172.766 198.245 172.478 197.957 172.124 197.957C171.769 197.957 171.481 198.245 171.481 198.6C171.481 198.954 171.769 199.242 172.124 199.242Z" fill="#00FF82" />
            <path d="M201.631 190.716C201.985 190.716 202.273 190.428 202.273 190.074C202.273 189.719 201.985 189.431 201.631 189.431C201.276 189.431 200.988 189.719 200.988 190.074C200.988 190.428 201.276 190.716 201.631 190.716Z" fill="#00FF82" />
            <path d="M194.254 194.974C194.609 194.974 194.896 194.687 194.896 194.332C194.896 193.977 194.609 193.69 194.254 193.69C193.899 193.69 193.612 193.977 193.612 194.332C193.612 194.687 193.899 194.974 194.254 194.974Z" fill="#00FF82" />
            <path d="M209.016 194.974C209.371 194.974 209.659 194.687 209.659 194.332C209.659 193.977 209.371 193.69 209.016 193.69C208.662 193.69 208.374 193.977 208.374 194.332C208.374 194.687 208.662 194.974 209.016 194.974Z" fill="#00FF82" />
            <path d="M201.631 199.242C201.985 199.242 202.273 198.954 202.273 198.6C202.273 198.245 201.985 197.957 201.631 197.957C201.276 197.957 200.988 198.245 200.988 198.6C200.988 198.954 201.276 199.242 201.631 199.242Z" fill="#00FF82" />
            <path d="M216.393 199.242C216.748 199.242 217.035 198.954 217.035 198.6C217.035 198.245 216.748 197.957 216.393 197.957C216.038 197.957 215.751 198.245 215.751 198.6C215.751 198.954 216.038 199.242 216.393 199.242Z" fill="#00FF82" />
            <path d="M209.016 203.5C209.371 203.5 209.659 203.213 209.659 202.858C209.659 202.503 209.371 202.216 209.016 202.216C208.662 202.216 208.374 202.503 208.374 202.858C208.374 203.213 208.662 203.5 209.016 203.5Z" fill="#00FF82" />
            <path d="M216.393 207.759C216.748 207.759 217.035 207.471 217.035 207.116C217.035 206.762 216.748 206.474 216.393 206.474C216.038 206.474 215.751 206.762 215.751 207.116C215.751 207.471 216.038 207.759 216.393 207.759Z" fill="#00FF82" />
            <path d="M223.77 203.5C224.125 203.5 224.412 203.213 224.412 202.858C224.412 202.503 224.125 202.216 223.77 202.216C223.415 202.216 223.128 202.503 223.128 202.858C223.128 203.213 223.415 203.5 223.77 203.5Z" fill="#00FF82" />
            <path d="M209.016 186.458C209.371 186.458 209.659 186.17 209.659 185.815C209.659 185.46 209.371 185.173 209.016 185.173C208.662 185.173 208.374 185.46 208.374 185.815C208.374 186.17 208.662 186.458 209.016 186.458Z" fill="#00FF82" />
            <path d="M216.393 190.716C216.748 190.716 217.035 190.428 217.035 190.074C217.035 189.719 216.748 189.431 216.393 189.431C216.038 189.431 215.751 189.719 215.751 190.074C215.751 190.428 216.038 190.716 216.393 190.716Z" fill="#00FF82" />
            <path d="M223.77 194.974C224.125 194.974 224.412 194.687 224.412 194.332C224.412 193.977 224.125 193.69 223.77 193.69C223.415 193.69 223.128 193.977 223.128 194.332C223.128 194.687 223.415 194.974 223.77 194.974Z" fill="#00FF82" />
            <path d="M231.147 199.242C231.501 199.242 231.789 198.954 231.789 198.6C231.789 198.245 231.501 197.957 231.147 197.957C230.792 197.957 230.504 198.245 230.504 198.6C230.504 198.954 230.792 199.242 231.147 199.242Z" fill="#00FF82" />
            <path d="M164.747 203.5C165.102 203.5 165.389 203.213 165.389 202.858C165.389 202.503 165.102 202.216 164.747 202.216C164.392 202.216 164.105 202.503 164.105 202.858C164.105 203.213 164.392 203.5 164.747 203.5Z" fill="#00FF82" />
            <path d="M172.124 207.759C172.478 207.759 172.766 207.471 172.766 207.116C172.766 206.762 172.478 206.474 172.124 206.474C171.769 206.474 171.481 206.762 171.481 207.116C171.481 207.471 171.769 207.759 172.124 207.759Z" fill="#00FF82" />
            <path d="M157.37 207.759C157.725 207.759 158.012 207.471 158.012 207.116C158.012 206.762 157.725 206.474 157.37 206.474C157.016 206.474 156.728 206.762 156.728 207.116C156.728 207.471 157.016 207.759 157.37 207.759Z" fill="#00FF82" />
            <path d="M164.747 212.026C165.102 212.026 165.389 211.739 165.389 211.384C165.389 211.029 165.102 210.742 164.747 210.742C164.392 210.742 164.105 211.029 164.105 211.384C164.105 211.739 164.392 212.026 164.747 212.026Z" fill="#00FF82" />
            <path d="M172.124 216.285C172.478 216.285 172.766 215.997 172.766 215.642C172.766 215.288 172.478 215 172.124 215C171.769 215 171.481 215.288 171.481 215.642C171.481 215.997 171.769 216.285 172.124 216.285Z" fill="#00FF82" />
            <path d="M157.37 216.285C157.725 216.285 158.012 215.997 158.012 215.642C158.012 215.288 157.725 215 157.37 215C157.016 215 156.728 215.288 156.728 215.642C156.728 215.997 157.016 216.285 157.37 216.285Z" fill="#00FF82" />
            <path d="M164.747 220.543C165.102 220.543 165.389 220.256 165.389 219.901C165.389 219.546 165.102 219.258 164.747 219.258C164.392 219.258 164.105 219.546 164.105 219.901C164.105 220.256 164.392 220.543 164.747 220.543Z" fill="#00FF82" />
            <path d="M149.984 212.026C150.339 212.026 150.627 211.739 150.627 211.384C150.627 211.029 150.339 210.742 149.984 210.742C149.63 210.742 149.342 211.029 149.342 211.384C149.342 211.739 149.63 212.026 149.984 212.026Z" fill="#00FF82" />
            <path d="M172.124 224.802C172.478 224.802 172.766 224.514 172.766 224.159C172.766 223.804 172.478 223.517 172.124 223.517C171.769 223.517 171.481 223.804 171.481 224.159C171.481 224.514 171.769 224.802 172.124 224.802Z" fill="#00FF82" />
            <path d="M157.37 199.242C157.725 199.242 158.012 198.954 158.012 198.6C158.012 198.245 157.725 197.957 157.37 197.957C157.016 197.957 156.728 198.245 156.728 198.6C156.728 198.954 157.016 199.242 157.37 199.242Z" fill="#00FF82" />
            <path d="M149.984 203.5C150.339 203.5 150.627 203.213 150.627 202.858C150.627 202.503 150.339 202.216 149.984 202.216C149.63 202.216 149.342 202.503 149.342 202.858C149.342 203.213 149.63 203.5 149.984 203.5Z" fill="#00FF82" />
            <path d="M164.747 194.974C165.102 194.974 165.389 194.687 165.389 194.332C165.389 193.977 165.102 193.69 164.747 193.69C164.392 193.69 164.105 193.977 164.105 194.332C164.105 194.687 164.392 194.974 164.747 194.974Z" fill="#00FF82" />
            <path d="M142.608 207.759C142.962 207.759 143.25 207.471 143.25 207.116C143.25 206.762 142.962 206.474 142.608 206.474C142.253 206.474 141.965 206.762 141.965 207.116C141.965 207.471 142.253 207.759 142.608 207.759Z" fill="#00FF82" />
            <path d="M149.984 186.458C150.339 186.458 150.627 186.17 150.627 185.815C150.627 185.46 150.339 185.173 149.984 185.173C149.63 185.173 149.342 185.46 149.342 185.815C149.342 186.17 149.63 186.458 149.984 186.458Z" fill="#00FF82" />
            <path d="M142.608 190.716C142.962 190.716 143.25 190.428 143.25 190.074C143.25 189.719 142.962 189.431 142.608 189.431C142.253 189.431 141.965 189.719 141.965 190.074C141.965 190.428 142.253 190.716 142.608 190.716Z" fill="#00FF82" />
            <path d="M135.231 194.974C135.586 194.974 135.873 194.687 135.873 194.332C135.873 193.977 135.586 193.69 135.231 193.69C134.876 193.69 134.589 193.977 134.589 194.332C134.589 194.687 134.876 194.974 135.231 194.974Z" fill="#00FF82" />
            <path d="M157.37 190.716C157.725 190.716 158.012 190.428 158.012 190.074C158.012 189.719 157.725 189.431 157.37 189.431C157.016 189.431 156.728 189.719 156.728 190.074C156.728 190.428 157.016 190.716 157.37 190.716Z" fill="#00FF82" />
            <path d="M149.984 194.974C150.339 194.974 150.627 194.687 150.627 194.332C150.627 193.977 150.339 193.69 149.984 193.69C149.63 193.69 149.342 193.977 149.342 194.332C149.342 194.687 149.63 194.974 149.984 194.974Z" fill="#00FF82" />
            <path d="M142.608 199.242C142.962 199.242 143.25 198.954 143.25 198.6C143.25 198.245 142.962 197.957 142.608 197.957C142.253 197.957 141.965 198.245 141.965 198.6C141.965 198.954 142.253 199.242 142.608 199.242Z" fill="#00FF82" />
            <path d="M135.231 203.5C135.586 203.5 135.873 203.213 135.873 202.858C135.873 202.503 135.586 202.216 135.231 202.216C134.876 202.216 134.589 202.503 134.589 202.858C134.589 203.213 134.876 203.5 135.231 203.5Z" fill="#00FF82" />
            <path d="M127.854 199.242C128.209 199.242 128.496 198.954 128.496 198.6C128.496 198.245 128.209 197.957 127.854 197.957C127.499 197.957 127.212 198.245 127.212 198.6C127.212 198.954 127.499 199.242 127.854 199.242Z" fill="#00FF82" />
            <path d="M193.768 65.1294H186.593V72.3063H193.768V65.1294Z" fill="white" />
            <path d="M193.768 57.9525H186.593V65.1294H193.768V57.9525Z" fill="white" />
            <path d="M200.933 65.1294H193.758V72.3063H200.933V65.1294Z" fill="white" />
            <path d="M142.332 110.917H135.157V118.093H142.332V110.917Z" fill="white" />
            <path d="M149.507 103.74H142.332V110.917H149.507V103.74Z" fill="white" />
            <path d="M157.067 27.3727H149.893V34.5496H157.067V27.3727Z" fill="white" />
            <path d="M235.138 87.0181H227.963V94.195H235.138V87.0181Z" fill="white" />
            <path d="M235.138 79.8411H227.963V87.018H235.138V79.8411Z" fill="white" />
            <path d="M211.962 102.602H204.787V109.779H211.962V102.602Z" fill="white" />
            <path d="M179.5 94.0756L162.15 104.088L179.5 114.11L196.85 104.088L179.5 94.0756Z" fill="#00FF82" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M162.15 143.258V104.088L179.5 114.11V153.28L162.15 143.258Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M196.85 143.258V104.088L179.5 114.11V153.28L196.85 143.258Z" fill="black" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M127.45 123.563L110.1 133.585L127.45 143.598L144.8 133.585L127.45 123.563Z" fill="#00FF82" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M110.1 172.746V133.585L127.45 143.598V182.768L110.1 172.746Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M144.8 172.746V133.585L127.45 143.598V182.768L144.8 172.746Z" fill="black" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M144.8 76.3077L127.45 86.3205L144.8 96.3425L162.15 86.3205L144.8 76.3077Z" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M127.45 125.491V86.3205L144.8 96.3425V135.513L127.45 125.491Z" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M162.15 125.491V86.3205L144.8 96.3425V135.513L162.15 125.491Z" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M196.85 28.9696L179.5 38.9824L196.85 49.0044L214.2 38.9824L196.85 28.9696Z" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M179.5 78.1525V38.9824L196.85 49.0043V88.1744L179.5 78.1525Z" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M214.2 78.1525V38.9824L196.85 49.0043V88.1744L214.2 78.1525Z" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M179.5 89.0922L162.15 99.1141L179.5 109.136L196.85 99.1141L179.5 89.0922Z" fill="#00FF82" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M162.15 138.275V99.1141L179.5 109.136V148.297L162.15 138.275Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M196.85 138.275V99.1141L179.5 109.136V148.297L196.85 138.275Z" fill="black" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M144.8 123.563L127.45 133.585L144.8 143.598L162.15 133.585L144.8 123.563Z" fill="#00FF82" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M127.45 172.746V133.585L144.8 143.598V182.768L127.45 172.746Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M162.15 172.746V133.585L144.8 143.598V182.768L162.15 172.746Z" fill="black" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M214.2 114.11L196.85 124.132L214.2 134.154L231.55 124.132L214.2 114.11Z" fill="#00FF82" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M196.85 163.293V124.132L214.2 134.154V173.315L196.85 163.293Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M231.55 163.293V124.132L214.2 134.154V173.315L231.55 163.293Z" fill="black" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M196.85 124.132L179.5 134.154L196.85 144.167L214.2 134.154L196.85 124.132Z" fill="#00FF82" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M179.5 173.315V134.154L196.85 144.167V183.337L179.5 173.315Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M214.2 173.315V134.154L196.85 144.167V183.337L214.2 173.315Z" fill="black" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M179.5 125.913L162.15 135.926L179.5 145.948L196.85 135.926L179.5 125.913Z" fill="#00FF82" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M162.15 175.096V135.926L179.5 145.948V185.118L162.15 175.096Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M196.85 175.096V135.926L179.5 145.948V185.118L196.85 175.096Z" fill="black" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M162.15 144.167L144.8 154.189L162.15 164.211L179.5 154.189L162.15 144.167Z" fill="#00FF82" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M144.8 193.359V154.189L162.15 164.211V203.372L144.8 193.359Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M179.5 193.359V154.189L162.15 164.211V203.372L179.5 193.359Z" fill="black" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M231.55 124.132L214.2 134.154L231.55 144.167L248.9 134.154L231.55 124.132Z" fill="#00FF82" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M214.2 173.315V134.154L231.55 144.167V183.337L214.2 173.315Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M248.9 173.315V134.154L231.55 144.167V183.337L248.9 173.315Z" fill="black" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M214.2 129.143L196.85 139.156L214.2 149.178L231.55 139.156L214.2 129.143Z" fill="#00FF82" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M196.85 178.326V139.156L214.2 149.178V188.348L196.85 178.326Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M231.55 178.326V139.156L214.2 149.178V188.348L231.55 178.326Z" fill="black" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M196.85 144.167L179.5 154.189L196.85 164.211L214.2 154.189L196.85 144.167Z" fill="#00FF82" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M179.5 193.359V154.189L196.85 164.211V203.372L179.5 193.359Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M214.2 193.359V154.189L196.85 164.211V203.372L214.2 193.359Z" fill="black" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M179.5 163.376L162.15 173.389L179.5 183.411L196.85 173.389L179.5 163.376Z" fill="#00FF82" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M162.15 212.559V173.389L179.5 183.411V222.572L162.15 212.559Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M196.85 212.559V173.389L179.5 183.411V222.572L196.85 212.559Z" fill="black" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M248.9 173.315L231.55 183.337L227.21 180.832L214.2 188.348V193.359L196.85 203.372V212.559L179.5 222.572L162.15 212.559V203.372L144.8 193.359V182.768" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M285.123 303.256L226.532 269.418L215.723 275.659L285.123 315.737L354.523 275.659L343.715 269.418L285.123 303.256Z" fill="#085016" />
            <path d="M285.123 223.099L215.723 263.177L285.123 303.256L354.523 263.177L285.123 223.099Z" fill="url(#paint2_linear_9336_36970)" />
            <path d="M69.3999 345.33L0 385.409L69.3999 425.488L138.8 385.409L69.3999 345.33Z" fill="url(#paint3_linear_9336_36970)" />
            <path d="M332.723 399.309L263.323 439.388L332.723 479.466L402.123 439.388L332.723 399.309Z" fill="url(#paint4_linear_9336_36970)" />
            <path d="M354.523 263.177L285.123 303.256L215.723 263.177" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M354.523 263.177L285.123 303.256L215.723 263.177" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <g opacity="0.25">
                <path d="M285.123 259.561C285.478 259.561 285.766 259.274 285.766 258.919C285.766 258.564 285.478 258.276 285.123 258.276C284.769 258.276 284.481 258.564 284.481 258.919C284.481 259.274 284.769 259.561 285.123 259.561Z" fill="black" />
                <path d="M292.5 263.82C292.855 263.82 293.142 263.532 293.142 263.177C293.142 262.822 292.855 262.535 292.5 262.535C292.145 262.535 291.858 262.822 291.858 263.177C291.858 263.532 292.145 263.82 292.5 263.82Z" fill="black" />
                <path d="M285.123 268.078C285.478 268.078 285.766 267.79 285.766 267.436C285.766 267.081 285.478 266.793 285.123 266.793C284.769 266.793 284.481 267.081 284.481 267.436C284.481 267.79 284.769 268.078 285.123 268.078Z" fill="black" />
                <path d="M299.877 268.078C300.231 268.078 300.519 267.79 300.519 267.436C300.519 267.081 300.231 266.793 299.877 266.793C299.522 266.793 299.234 267.081 299.234 267.436C299.234 267.79 299.522 268.078 299.877 268.078Z" fill="black" />
                <path d="M292.5 272.336C292.855 272.336 293.142 272.049 293.142 271.694C293.142 271.339 292.855 271.052 292.5 271.052C292.145 271.052 291.858 271.339 291.858 271.694C291.858 272.049 292.145 272.336 292.5 272.336Z" fill="black" />
                <path d="M285.123 276.604C285.478 276.604 285.766 276.316 285.766 275.962C285.766 275.607 285.478 275.319 285.123 275.319C284.769 275.319 284.481 275.607 284.481 275.962C284.481 276.316 284.769 276.604 285.123 276.604Z" fill="black" />
                <path d="M307.253 272.336C307.608 272.336 307.896 272.049 307.896 271.694C307.896 271.339 307.608 271.052 307.253 271.052C306.899 271.052 306.611 271.339 306.611 271.694C306.611 272.049 306.899 272.336 307.253 272.336Z" fill="black" />
                <path d="M299.877 276.604C300.231 276.604 300.519 276.316 300.519 275.962C300.519 275.607 300.231 275.319 299.877 275.319C299.522 275.319 299.234 275.607 299.234 275.962C299.234 276.316 299.522 276.604 299.877 276.604Z" fill="black" />
                <path d="M292.5 280.862C292.855 280.862 293.142 280.575 293.142 280.22C293.142 279.865 292.855 279.578 292.5 279.578C292.145 279.578 291.858 279.865 291.858 280.22C291.858 280.575 292.145 280.862 292.5 280.862Z" fill="black" />
                <path d="M285.123 285.121C285.478 285.121 285.766 284.833 285.766 284.478C285.766 284.124 285.478 283.836 285.123 283.836C284.769 283.836 284.481 284.124 284.481 284.478C284.481 284.833 284.769 285.121 285.123 285.121Z" fill="black" />
                <path d="M307.253 280.862C307.608 280.862 307.896 280.575 307.896 280.22C307.896 279.865 307.608 279.578 307.253 279.578C306.899 279.578 306.611 279.865 306.611 280.22C306.611 280.575 306.899 280.862 307.253 280.862Z" fill="black" />
                <path d="M299.877 285.121C300.231 285.121 300.519 284.833 300.519 284.478C300.519 284.124 300.231 283.836 299.877 283.836C299.522 283.836 299.234 284.124 299.234 284.478C299.234 284.833 299.522 285.121 299.877 285.121Z" fill="black" />
                <path d="M314.639 276.604C314.994 276.604 315.282 276.316 315.282 275.962C315.282 275.607 314.994 275.319 314.639 275.319C314.285 275.319 313.997 275.607 313.997 275.962C313.997 276.316 314.285 276.604 314.639 276.604Z" fill="black" />
                <path d="M292.5 289.379C292.855 289.379 293.142 289.092 293.142 288.737C293.142 288.382 292.855 288.094 292.5 288.094C292.145 288.094 291.858 288.382 291.858 288.737C291.858 289.092 292.145 289.379 292.5 289.379Z" fill="black" />
                <path d="M285.123 293.647C285.478 293.647 285.766 293.359 285.766 293.004C285.766 292.65 285.478 292.362 285.123 292.362C284.769 292.362 284.481 292.65 284.481 293.004C284.481 293.359 284.769 293.647 285.123 293.647Z" fill="black" />
                <path d="M277.747 263.82C278.101 263.82 278.389 263.532 278.389 263.177C278.389 262.822 278.101 262.535 277.747 262.535C277.392 262.535 277.104 262.822 277.104 263.177C277.104 263.532 277.392 263.82 277.747 263.82Z" fill="black" />
                <path d="M299.877 251.035C300.231 251.035 300.519 250.748 300.519 250.393C300.519 250.038 300.231 249.75 299.877 249.75C299.522 249.75 299.234 250.038 299.234 250.393C299.234 250.748 299.522 251.035 299.877 251.035Z" fill="black" />
                <path d="M307.253 255.294C307.608 255.294 307.896 255.006 307.896 254.651C307.896 254.296 307.608 254.009 307.253 254.009C306.899 254.009 306.611 254.296 306.611 254.651C306.611 255.006 306.899 255.294 307.253 255.294Z" fill="black" />
                <path d="M299.877 259.561C300.231 259.561 300.519 259.274 300.519 258.919C300.519 258.564 300.231 258.276 299.877 258.276C299.522 258.276 299.234 258.564 299.234 258.919C299.234 259.274 299.522 259.561 299.877 259.561Z" fill="black" />
                <path d="M314.639 259.561C314.994 259.561 315.282 259.274 315.282 258.919C315.282 258.564 314.994 258.276 314.639 258.276C314.285 258.276 313.997 258.564 313.997 258.919C313.997 259.274 314.285 259.561 314.639 259.561Z" fill="black" />
                <path d="M307.253 263.82C307.608 263.82 307.896 263.532 307.896 263.177C307.896 262.822 307.608 262.535 307.253 262.535C306.899 262.535 306.611 262.822 306.611 263.177C306.611 263.532 306.899 263.82 307.253 263.82Z" fill="black" />
                <path d="M322.016 263.82C322.371 263.82 322.658 263.532 322.658 263.177C322.658 262.822 322.371 262.535 322.016 262.535C321.661 262.535 321.374 262.822 321.374 263.177C321.374 263.532 321.661 263.82 322.016 263.82Z" fill="black" />
                <path d="M314.639 268.078C314.994 268.078 315.282 267.79 315.282 267.436C315.282 267.081 314.994 266.793 314.639 266.793C314.285 266.793 313.997 267.081 313.997 267.436C313.997 267.79 314.285 268.078 314.639 268.078Z" fill="black" />
                <path d="M322.016 272.336C322.371 272.336 322.658 272.049 322.658 271.694C322.658 271.339 322.371 271.052 322.016 271.052C321.661 271.052 321.374 271.339 321.374 271.694C321.374 272.049 321.661 272.336 322.016 272.336Z" fill="black" />
                <path d="M329.393 268.078C329.748 268.078 330.035 267.79 330.035 267.436C330.035 267.081 329.748 266.793 329.393 266.793C329.038 266.793 328.751 267.081 328.751 267.436C328.751 267.79 329.038 268.078 329.393 268.078Z" fill="black" />
                <path d="M292.5 255.294C292.855 255.294 293.142 255.006 293.142 254.651C293.142 254.296 292.855 254.009 292.5 254.009C292.145 254.009 291.858 254.296 291.858 254.651C291.858 255.006 292.145 255.294 292.5 255.294Z" fill="black" />
                <path d="M314.639 251.035C314.994 251.035 315.282 250.748 315.282 250.393C315.282 250.038 314.994 249.75 314.639 249.75C314.285 249.75 313.997 250.038 313.997 250.393C313.997 250.748 314.285 251.035 314.639 251.035Z" fill="black" />
                <path d="M322.016 255.294C322.371 255.294 322.658 255.006 322.658 254.651C322.658 254.296 322.371 254.009 322.016 254.009C321.661 254.009 321.374 254.296 321.374 254.651C321.374 255.006 321.661 255.294 322.016 255.294Z" fill="black" />
                <path d="M329.393 259.561C329.748 259.561 330.035 259.274 330.035 258.919C330.035 258.564 329.748 258.276 329.393 258.276C329.038 258.276 328.751 258.564 328.751 258.919C328.751 259.274 329.038 259.561 329.393 259.561Z" fill="black" />
                <path d="M336.77 263.82C337.124 263.82 337.412 263.532 337.412 263.177C337.412 262.822 337.124 262.535 336.77 262.535C336.415 262.535 336.127 262.822 336.127 263.177C336.127 263.532 336.415 263.82 336.77 263.82Z" fill="black" />
                <path d="M307.253 246.777C307.608 246.777 307.896 246.489 307.896 246.134C307.896 245.779 307.608 245.492 307.253 245.492C306.899 245.492 306.611 245.779 306.611 246.134C306.611 246.489 306.899 246.777 307.253 246.777Z" fill="black" />
                <path d="M270.37 268.078C270.725 268.078 271.012 267.79 271.012 267.436C271.012 267.081 270.725 266.793 270.37 266.793C270.015 266.793 269.728 267.081 269.728 267.436C269.728 267.79 270.015 268.078 270.37 268.078Z" fill="black" />
                <path d="M277.747 272.336C278.101 272.336 278.389 272.049 278.389 271.694C278.389 271.339 278.101 271.052 277.747 271.052C277.392 271.052 277.104 271.339 277.104 271.694C277.104 272.049 277.392 272.336 277.747 272.336Z" fill="black" />
                <path d="M262.993 272.336C263.348 272.336 263.635 272.049 263.635 271.694C263.635 271.339 263.348 271.052 262.993 271.052C262.638 271.052 262.351 271.339 262.351 271.694C262.351 272.049 262.638 272.336 262.993 272.336Z" fill="black" />
                <path d="M270.37 276.604C270.725 276.604 271.012 276.316 271.012 275.962C271.012 275.607 270.725 275.319 270.37 275.319C270.015 275.319 269.728 275.607 269.728 275.962C269.728 276.316 270.015 276.604 270.37 276.604Z" fill="black" />
                <path d="M277.747 280.862C278.101 280.862 278.389 280.575 278.389 280.22C278.389 279.865 278.101 279.578 277.747 279.578C277.392 279.578 277.104 279.865 277.104 280.22C277.104 280.575 277.392 280.862 277.747 280.862Z" fill="black" />
                <path d="M262.993 280.862C263.348 280.862 263.635 280.575 263.635 280.22C263.635 279.865 263.348 279.578 262.993 279.578C262.638 279.578 262.351 279.865 262.351 280.22C262.351 280.575 262.638 280.862 262.993 280.862Z" fill="black" />
                <path d="M270.37 285.121C270.725 285.121 271.012 284.833 271.012 284.478C271.012 284.124 270.725 283.836 270.37 283.836C270.015 283.836 269.728 284.124 269.728 284.478C269.728 284.833 270.015 285.121 270.37 285.121Z" fill="black" />
                <path d="M255.607 276.604C255.962 276.604 256.249 276.316 256.249 275.962C256.249 275.607 255.962 275.319 255.607 275.319C255.253 275.319 254.965 275.607 254.965 275.962C254.965 276.316 255.253 276.604 255.607 276.604Z" fill="black" />
                <path d="M277.747 289.379C278.101 289.379 278.389 289.092 278.389 288.737C278.389 288.382 278.101 288.094 277.747 288.094C277.392 288.094 277.104 288.382 277.104 288.737C277.104 289.092 277.392 289.379 277.747 289.379Z" fill="black" />
                <path d="M262.993 263.82C263.348 263.82 263.635 263.532 263.635 263.177C263.635 262.822 263.348 262.535 262.993 262.535C262.638 262.535 262.351 262.822 262.351 263.177C262.351 263.532 262.638 263.82 262.993 263.82Z" fill="black" />
                <path d="M255.607 268.078C255.962 268.078 256.249 267.79 256.249 267.436C256.249 267.081 255.962 266.793 255.607 266.793C255.253 266.793 254.965 267.081 254.965 267.436C254.965 267.79 255.253 268.078 255.607 268.078Z" fill="black" />
                <path d="M277.747 255.294C278.101 255.294 278.389 255.006 278.389 254.651C278.389 254.296 278.101 254.009 277.747 254.009C277.392 254.009 277.104 254.296 277.104 254.651C277.104 255.006 277.392 255.294 277.747 255.294Z" fill="black" />
                <path d="M270.37 259.561C270.725 259.561 271.012 259.274 271.012 258.919C271.012 258.564 270.725 258.276 270.37 258.276C270.015 258.276 269.728 258.564 269.728 258.919C269.728 259.274 270.015 259.561 270.37 259.561Z" fill="black" />
                <path d="M285.123 251.035C285.478 251.035 285.766 250.748 285.766 250.393C285.766 250.038 285.478 249.75 285.123 249.75C284.769 249.75 284.481 250.038 284.481 250.393C284.481 250.748 284.769 251.035 285.123 251.035Z" fill="black" />
                <path d="M248.23 272.336C248.585 272.336 248.873 272.049 248.873 271.694C248.873 271.339 248.585 271.052 248.23 271.052C247.876 271.052 247.588 271.339 247.588 271.694C247.588 272.049 247.876 272.336 248.23 272.336Z" fill="black" />
                <path d="M262.993 246.777C263.348 246.777 263.635 246.489 263.635 246.134C263.635 245.779 263.348 245.492 262.993 245.492C262.638 245.492 262.351 245.779 262.351 246.134C262.351 246.489 262.638 246.777 262.993 246.777Z" fill="black" />
                <path d="M255.607 251.035C255.962 251.035 256.249 250.748 256.249 250.393C256.249 250.038 255.962 249.75 255.607 249.75C255.253 249.75 254.965 250.038 254.965 250.393C254.965 250.748 255.253 251.035 255.607 251.035Z" fill="black" />
                <path d="M248.23 255.294C248.585 255.294 248.873 255.006 248.873 254.651C248.873 254.296 248.585 254.009 248.23 254.009C247.876 254.009 247.588 254.296 247.588 254.651C247.588 255.006 247.876 255.294 248.23 255.294Z" fill="black" />
                <path d="M240.854 259.561C241.208 259.561 241.496 259.274 241.496 258.919C241.496 258.564 241.208 258.276 240.854 258.276C240.499 258.276 240.211 258.564 240.211 258.919C240.211 259.274 240.499 259.561 240.854 259.561Z" fill="black" />
                <path d="M262.993 255.294C263.348 255.294 263.635 255.006 263.635 254.651C263.635 254.296 263.348 254.009 262.993 254.009C262.638 254.009 262.351 254.296 262.351 254.651C262.351 255.006 262.638 255.294 262.993 255.294Z" fill="black" />
                <path d="M255.607 259.561C255.962 259.561 256.249 259.274 256.249 258.919C256.249 258.564 255.962 258.276 255.607 258.276C255.253 258.276 254.965 258.564 254.965 258.919C254.965 259.274 255.253 259.561 255.607 259.561Z" fill="black" />
                <path d="M270.37 251.035C270.725 251.035 271.012 250.748 271.012 250.393C271.012 250.038 270.725 249.75 270.37 249.75C270.015 249.75 269.728 250.038 269.728 250.393C269.728 250.748 270.015 251.035 270.37 251.035Z" fill="black" />
                <path d="M248.23 263.82C248.585 263.82 248.873 263.532 248.873 263.177C248.873 262.822 248.585 262.535 248.23 262.535C247.876 262.535 247.588 262.822 247.588 263.177C247.588 263.532 247.876 263.82 248.23 263.82Z" fill="black" />
                <path d="M240.854 268.078C241.208 268.078 241.496 267.79 241.496 267.436C241.496 267.081 241.208 266.793 240.854 266.793C240.499 266.793 240.211 267.081 240.211 267.436C240.211 267.79 240.499 268.078 240.854 268.078Z" fill="black" />
                <path d="M292.5 246.777C292.855 246.777 293.142 246.489 293.142 246.134C293.142 245.779 292.855 245.492 292.5 245.492C292.145 245.492 291.858 245.779 291.858 246.134C291.858 246.489 292.145 246.777 292.5 246.777Z" fill="black" />
                <path d="M277.747 238.251C278.101 238.251 278.389 237.963 278.389 237.608C278.389 237.253 278.101 236.966 277.747 236.966C277.392 236.966 277.104 237.253 277.104 237.608C277.104 237.963 277.392 238.251 277.747 238.251Z" fill="black" />
                <path d="M270.37 242.509C270.725 242.509 271.012 242.222 271.012 241.867C271.012 241.512 270.725 241.224 270.37 241.224C270.015 241.224 269.728 241.512 269.728 241.867C269.728 242.222 270.015 242.509 270.37 242.509Z" fill="black" />
                <path d="M277.747 246.777C278.101 246.777 278.389 246.489 278.389 246.134C278.389 245.779 278.101 245.492 277.747 245.492C277.392 245.492 277.104 245.779 277.104 246.134C277.104 246.489 277.392 246.777 277.747 246.777Z" fill="black" />
                <path d="M285.123 242.509C285.478 242.509 285.766 242.222 285.766 241.867C285.766 241.512 285.478 241.224 285.123 241.224C284.769 241.224 284.481 241.512 284.481 241.867C284.481 242.222 284.769 242.509 285.123 242.509Z" fill="black" />
                <path d="M299.877 242.509C300.231 242.509 300.519 242.222 300.519 241.867C300.519 241.512 300.231 241.224 299.877 241.224C299.522 241.224 299.234 241.512 299.234 241.867C299.234 242.222 299.522 242.509 299.877 242.509Z" fill="black" />
                <path d="M285.123 233.992C285.478 233.992 285.766 233.705 285.766 233.35C285.766 232.995 285.478 232.707 285.123 232.707C284.769 232.707 284.481 232.995 284.481 233.35C284.481 233.705 284.769 233.992 285.123 233.992Z" fill="black" />
                <path d="M292.5 238.251C292.855 238.251 293.142 237.963 293.142 237.608C293.142 237.253 292.855 236.966 292.5 236.966C292.145 236.966 291.858 237.253 291.858 237.608C291.858 237.963 292.145 238.251 292.5 238.251Z" fill="black" />
                <path d="M233.477 263.82C233.832 263.82 234.119 263.532 234.119 263.177C234.119 262.822 233.832 262.535 233.477 262.535C233.122 262.535 232.835 262.822 232.835 263.177C232.835 263.532 233.122 263.82 233.477 263.82Z" fill="black" />
            </g>
            <path d="M277.315 404.197L263.433 412.209L277.315 420.23L291.197 412.209L277.315 404.197Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M291.197 412.209L277.315 420.23L291.197 428.242L305.07 410.309L291.197 412.209Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M305.07 410.309L291.197 428.242L305.07 436.263L318.952 428.242L305.07 410.309Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M318.952 428.242L305.07 436.263L318.952 444.275L332.833 436.263L318.952 428.242Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M332.833 436.263L318.952 444.275L332.833 452.297L346.715 444.275L332.833 436.263Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M291.197 384.979L277.315 404.197L291.197 412.209L305.07 404.197L291.197 384.979Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M305.07 404.197L291.197 412.209L305.07 410.309L318.952 412.209L305.07 404.197Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M318.952 412.209L305.07 410.309L318.952 428.242L332.833 420.23L318.952 412.209Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M332.833 420.23L318.952 428.242L332.833 436.263L346.715 419.064L332.833 420.23Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M346.715 419.064L332.833 436.263L346.715 444.275L360.588 427.086L346.715 419.064Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M305.07 388.163L291.197 384.979L305.07 404.197L318.952 396.185L305.07 388.163Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M318.952 396.185L305.07 404.197L318.952 412.209L332.833 404.197L318.952 396.185Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M332.833 404.197L318.952 412.209L332.833 420.23L346.715 412.209L332.833 404.197Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M346.715 412.209L332.833 420.23L346.715 419.064L360.588 420.23L346.715 412.209Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M360.588 420.23L346.715 419.064L360.588 427.086L374.47 428.242L360.588 420.23Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M318.952 380.151L305.07 388.163L318.952 396.185L332.833 388.163L318.952 380.151Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M332.833 388.163L318.952 396.185L332.833 404.197L346.715 396.185L332.833 388.163Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M346.715 396.185L332.833 404.197L346.715 412.209L360.588 404.197L346.715 396.185Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M360.588 404.197L346.715 412.209L360.588 420.23L374.47 412.209L360.588 404.197Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M374.47 412.209L360.588 420.23L374.47 428.242L388.352 420.23L374.47 412.209Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M332.833 372.13L318.952 380.151L332.833 388.163L346.715 380.151L332.833 372.13Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M346.715 380.151L332.833 388.163L346.715 396.185L360.588 388.163L346.715 380.151Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M360.588 388.163L346.715 396.185L360.588 404.197L374.47 396.185L360.588 388.163Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M374.47 396.185L360.588 404.197L374.47 412.209L388.352 395.019L374.47 396.185Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M388.351 395.019L374.47 412.209L388.351 420.23L402.233 412.209L388.351 395.019Z" fill="#15C237" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M263.433 412.209L332.833 452.297L346.715 444.275L360.588 427.086L374.47 428.242L402.233 412.209" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M340.21 277.224C340.565 277.224 340.852 276.936 340.852 276.582C340.852 276.227 340.565 275.939 340.21 275.939C339.855 275.939 339.568 276.227 339.568 276.582C339.568 276.936 339.855 277.224 340.21 277.224Z" fill="#00FF82" />
            <path d="M332.833 281.482C333.188 281.482 333.476 281.195 333.476 280.84C333.476 280.485 333.188 280.198 332.833 280.198C332.479 280.198 332.191 280.485 332.191 280.84C332.191 281.195 332.479 281.482 332.833 281.482Z" fill="#00FF82" />
            <path d="M347.587 281.482C347.942 281.482 348.229 281.195 348.229 280.84C348.229 280.485 347.942 280.198 347.587 280.198C347.232 280.198 346.945 280.485 346.945 280.84C346.945 281.195 347.232 281.482 347.587 281.482Z" fill="#00FF82" />
            <path d="M340.21 285.741C340.565 285.741 340.852 285.453 340.852 285.098C340.852 284.744 340.565 284.456 340.21 284.456C339.855 284.456 339.568 284.744 339.568 285.098C339.568 285.453 339.855 285.741 340.21 285.741Z" fill="#00FF82" />
            <path d="M332.833 290.009C333.188 290.009 333.476 289.721 333.476 289.366C333.476 289.011 333.188 288.724 332.833 288.724C332.479 288.724 332.191 289.011 332.191 289.366C332.191 289.721 332.479 290.009 332.833 290.009Z" fill="#00FF82" />
            <path d="M354.964 285.741C355.318 285.741 355.606 285.453 355.606 285.098C355.606 284.744 355.318 284.456 354.964 284.456C354.609 284.456 354.321 284.744 354.321 285.098C354.321 285.453 354.609 285.741 354.964 285.741Z" fill="#00FF82" />
            <path d="M347.587 290.009C347.942 290.009 348.229 289.721 348.229 289.366C348.229 289.011 347.942 288.724 347.587 288.724C347.232 288.724 346.945 289.011 346.945 289.366C346.945 289.721 347.232 290.009 347.587 290.009Z" fill="#00FF82" />
            <path d="M340.21 294.267C340.565 294.267 340.852 293.979 340.852 293.624C340.852 293.27 340.565 292.982 340.21 292.982C339.855 292.982 339.568 293.27 339.568 293.624C339.568 293.979 339.855 294.267 340.21 294.267Z" fill="#00FF82" />
            <path d="M332.833 298.525C333.188 298.525 333.476 298.238 333.476 297.883C333.476 297.528 333.188 297.24 332.833 297.24C332.479 297.24 332.191 297.528 332.191 297.883C332.191 298.238 332.479 298.525 332.833 298.525Z" fill="#00FF82" />
            <path d="M354.964 294.267C355.318 294.267 355.606 293.979 355.606 293.624C355.606 293.27 355.318 292.982 354.964 292.982C354.609 292.982 354.321 293.27 354.321 293.624C354.321 293.979 354.609 294.267 354.964 294.267Z" fill="#00FF82" />
            <path d="M347.587 298.525C347.942 298.525 348.229 298.238 348.229 297.883C348.229 297.528 347.942 297.24 347.587 297.24C347.232 297.24 346.945 297.528 346.945 297.883C346.945 298.238 347.232 298.525 347.587 298.525Z" fill="#00FF82" />
            <path d="M362.349 290.009C362.704 290.009 362.992 289.721 362.992 289.366C362.992 289.011 362.704 288.724 362.349 288.724C361.995 288.724 361.707 289.011 361.707 289.366C361.707 289.721 361.995 290.009 362.349 290.009Z" fill="#00FF82" />
            <path d="M340.21 302.784C340.565 302.784 340.852 302.496 340.852 302.141C340.852 301.787 340.565 301.499 340.21 301.499C339.855 301.499 339.568 301.787 339.568 302.141C339.568 302.496 339.855 302.784 340.21 302.784Z" fill="#00FF82" />
            <path d="M332.833 307.051C333.188 307.051 333.476 306.764 333.476 306.409C333.476 306.054 333.188 305.766 332.833 305.766C332.479 305.766 332.191 306.054 332.191 306.409C332.191 306.764 332.479 307.051 332.833 307.051Z" fill="#00FF82" />
            <path d="M325.457 277.224C325.811 277.224 326.099 276.936 326.099 276.582C326.099 276.227 325.811 275.939 325.457 275.939C325.102 275.939 324.814 276.227 324.814 276.582C324.814 276.936 325.102 277.224 325.457 277.224Z" fill="#00FF82" />
            <path d="M354.964 268.698C355.318 268.698 355.606 268.41 355.606 268.056C355.606 267.701 355.318 267.413 354.964 267.413C354.609 267.413 354.321 267.701 354.321 268.056C354.321 268.41 354.609 268.698 354.964 268.698Z" fill="#00FF82" />
            <path d="M347.587 272.956C347.942 272.956 348.229 272.669 348.229 272.314C348.229 271.959 347.942 271.672 347.587 271.672C347.232 271.672 346.945 271.959 346.945 272.314C346.945 272.669 347.232 272.956 347.587 272.956Z" fill="#00FF82" />
            <path d="M362.349 272.956C362.704 272.956 362.992 272.669 362.992 272.314C362.992 271.959 362.704 271.672 362.349 271.672C361.995 271.672 361.707 271.959 361.707 272.314C361.707 272.669 361.995 272.956 362.349 272.956Z" fill="#00FF82" />
            <path d="M354.964 277.224C355.318 277.224 355.606 276.936 355.606 276.582C355.606 276.227 355.318 275.939 354.964 275.939C354.609 275.939 354.321 276.227 354.321 276.582C354.321 276.936 354.609 277.224 354.964 277.224Z" fill="#00FF82" />
            <path d="M369.726 277.224C370.081 277.224 370.368 276.936 370.368 276.582C370.368 276.227 370.081 275.939 369.726 275.939C369.372 275.939 369.084 276.227 369.084 276.582C369.084 276.936 369.372 277.224 369.726 277.224Z" fill="#00FF82" />
            <path d="M362.349 281.482C362.704 281.482 362.992 281.195 362.992 280.84C362.992 280.485 362.704 280.198 362.349 280.198C361.995 280.198 361.707 280.485 361.707 280.84C361.707 281.195 361.995 281.482 362.349 281.482Z" fill="#00FF82" />
            <path d="M369.726 285.741C370.081 285.741 370.368 285.453 370.368 285.098C370.368 284.744 370.081 284.456 369.726 284.456C369.372 284.456 369.084 284.744 369.084 285.098C369.084 285.453 369.372 285.741 369.726 285.741Z" fill="#00FF82" />
            <path d="M377.103 281.482C377.458 281.482 377.745 281.195 377.745 280.84C377.745 280.485 377.458 280.198 377.103 280.198C376.748 280.198 376.461 280.485 376.461 280.84C376.461 281.195 376.748 281.482 377.103 281.482Z" fill="#00FF82" />
            <path d="M362.349 264.44C362.704 264.44 362.992 264.152 362.992 263.797C362.992 263.442 362.704 263.155 362.349 263.155C361.995 263.155 361.707 263.442 361.707 263.797C361.707 264.152 361.995 264.44 362.349 264.44Z" fill="#00FF82" />
            <path d="M332.833 272.956C333.188 272.956 333.476 272.669 333.476 272.314C333.476 271.959 333.188 271.672 332.833 271.672C332.479 271.672 332.191 271.959 332.191 272.314C332.191 272.669 332.479 272.956 332.833 272.956Z" fill="#00FF82" />
            <path d="M347.587 264.44C347.942 264.44 348.229 264.152 348.229 263.797C348.229 263.442 347.942 263.155 347.587 263.155C347.232 263.155 346.945 263.442 346.945 263.797C346.945 264.152 347.232 264.44 347.587 264.44Z" fill="#00FF82" />
            <path d="M340.21 268.698C340.565 268.698 340.852 268.41 340.852 268.056C340.852 267.701 340.565 267.413 340.21 267.413C339.855 267.413 339.568 267.701 339.568 268.056C339.568 268.41 339.855 268.698 340.21 268.698Z" fill="#00FF82" />
            <path d="M354.964 260.181C355.318 260.181 355.606 259.894 355.606 259.539C355.606 259.184 355.318 258.896 354.964 258.896C354.609 258.896 354.321 259.184 354.321 259.539C354.321 259.894 354.609 260.181 354.964 260.181Z" fill="#00FF82" />
            <path d="M325.457 268.698C325.811 268.698 326.099 268.41 326.099 268.056C326.099 267.701 325.811 267.413 325.457 267.413C325.102 267.413 324.814 267.701 324.814 268.056C324.814 268.41 325.102 268.698 325.457 268.698Z" fill="#00FF82" />
            <path d="M340.21 260.181C340.565 260.181 340.852 259.894 340.852 259.539C340.852 259.184 340.565 258.896 340.21 258.896C339.855 258.896 339.568 259.184 339.568 259.539C339.568 259.894 339.855 260.181 340.21 260.181Z" fill="#00FF82" />
            <path d="M332.833 264.44C333.188 264.44 333.476 264.152 333.476 263.797C333.476 263.442 333.188 263.155 332.833 263.155C332.479 263.155 332.191 263.442 332.191 263.797C332.191 264.152 332.479 264.44 332.833 264.44Z" fill="#00FF82" />
            <path d="M347.587 255.914C347.942 255.914 348.229 255.626 348.229 255.271C348.229 254.916 347.942 254.629 347.587 254.629C347.232 254.629 346.945 254.916 346.945 255.271C346.945 255.626 347.232 255.914 347.587 255.914Z" fill="#00FF82" />
            <path d="M318.08 264.44C318.435 264.44 318.722 264.152 318.722 263.797C318.722 263.442 318.435 263.155 318.08 263.155C317.725 263.155 317.438 263.442 317.438 263.797C317.438 264.152 317.725 264.44 318.08 264.44Z" fill="#00FF82" />
            <path d="M332.833 255.914C333.188 255.914 333.476 255.626 333.476 255.271C333.476 254.916 333.188 254.629 332.833 254.629C332.479 254.629 332.191 254.916 332.191 255.271C332.191 255.626 332.479 255.914 332.833 255.914Z" fill="#00FF82" />
            <path d="M325.457 260.181C325.811 260.181 326.099 259.894 326.099 259.539C326.099 259.184 325.811 258.896 325.457 258.896C325.102 258.896 324.814 259.184 324.814 259.539C324.814 259.894 325.102 260.181 325.457 260.181Z" fill="#00FF82" />
            <path d="M340.21 251.655C340.565 251.655 340.852 251.368 340.852 251.013C340.852 250.658 340.565 250.37 340.21 250.37C339.855 250.37 339.568 250.658 339.568 251.013C339.568 251.368 339.855 251.655 340.21 251.655Z" fill="#00FF82" />
            <path d="M310.703 260.181C311.058 260.181 311.346 259.894 311.346 259.539C311.346 259.184 311.058 258.896 310.703 258.896C310.349 258.896 310.061 259.184 310.061 259.539C310.061 259.894 310.349 260.181 310.703 260.181Z" fill="#00FF82" />
            <path d="M325.457 251.655C325.811 251.655 326.099 251.368 326.099 251.013C326.099 250.658 325.811 250.37 325.457 250.37C325.102 250.37 324.814 250.658 324.814 251.013C324.814 251.368 325.102 251.655 325.457 251.655Z" fill="#00FF82" />
            <path d="M318.08 255.914C318.435 255.914 318.722 255.626 318.722 255.271C318.722 254.916 318.435 254.629 318.08 254.629C317.725 254.629 317.438 254.916 317.438 255.271C317.438 255.626 317.725 255.914 318.08 255.914Z" fill="#00FF82" />
            <path d="M332.833 247.397C333.188 247.397 333.476 247.109 333.476 246.754C333.476 246.4 333.188 246.112 332.833 246.112C332.479 246.112 332.191 246.4 332.191 246.754C332.191 247.109 332.479 247.397 332.833 247.397Z" fill="#00FF82" />
            <path d="M369.726 268.698C370.081 268.698 370.368 268.41 370.368 268.056C370.368 267.701 370.081 267.413 369.726 267.413C369.372 267.413 369.084 267.701 369.084 268.056C369.084 268.41 369.372 268.698 369.726 268.698Z" fill="#00FF82" />
            <path d="M377.103 272.956C377.458 272.956 377.745 272.669 377.745 272.314C377.745 271.959 377.458 271.672 377.103 271.672C376.748 271.672 376.461 271.959 376.461 272.314C376.461 272.669 376.748 272.956 377.103 272.956Z" fill="#00FF82" />
            <path d="M384.48 277.224C384.834 277.224 385.122 276.936 385.122 276.582C385.122 276.227 384.834 275.939 384.48 275.939C384.125 275.939 383.837 276.227 383.837 276.582C383.837 276.936 384.125 277.224 384.48 277.224Z" fill="#00FF82" />
            <path d="M318.08 281.482C318.435 281.482 318.722 281.195 318.722 280.84C318.722 280.485 318.435 280.198 318.08 280.198C317.725 280.198 317.438 280.485 317.438 280.84C317.438 281.195 317.725 281.482 318.08 281.482Z" fill="#00FF82" />
            <path d="M325.457 285.741C325.811 285.741 326.099 285.453 326.099 285.098C326.099 284.744 325.811 284.456 325.457 284.456C325.102 284.456 324.814 284.744 324.814 285.098C324.814 285.453 325.102 285.741 325.457 285.741Z" fill="#00FF82" />
            <path d="M310.703 285.741C311.058 285.741 311.346 285.453 311.346 285.098C311.346 284.744 311.058 284.456 310.703 284.456C310.349 284.456 310.061 284.744 310.061 285.098C310.061 285.453 310.349 285.741 310.703 285.741Z" fill="#00FF82" />
            <path d="M318.08 290.009C318.435 290.009 318.722 289.721 318.722 289.366C318.722 289.011 318.435 288.724 318.08 288.724C317.725 288.724 317.438 289.011 317.438 289.366C317.438 289.721 317.725 290.009 318.08 290.009Z" fill="#00FF82" />
            <path d="M325.457 294.267C325.811 294.267 326.099 293.979 326.099 293.624C326.099 293.27 325.811 292.982 325.457 292.982C325.102 292.982 324.814 293.27 324.814 293.624C324.814 293.979 325.102 294.267 325.457 294.267Z" fill="#00FF82" />
            <path d="M310.703 294.267C311.058 294.267 311.346 293.979 311.346 293.624C311.346 293.27 311.058 292.982 310.703 292.982C310.349 292.982 310.061 293.27 310.061 293.624C310.061 293.979 310.349 294.267 310.703 294.267Z" fill="#00FF82" />
            <path d="M318.08 298.525C318.435 298.525 318.722 298.238 318.722 297.883C318.722 297.528 318.435 297.24 318.08 297.24C317.725 297.24 317.438 297.528 317.438 297.883C317.438 298.238 317.725 298.525 318.08 298.525Z" fill="#00FF82" />
            <path d="M303.317 290.009C303.672 290.009 303.96 289.721 303.96 289.366C303.96 289.011 303.672 288.724 303.317 288.724C302.963 288.724 302.675 289.011 302.675 289.366C302.675 289.721 302.963 290.009 303.317 290.009Z" fill="#00FF82" />
            <path d="M325.457 302.784C325.811 302.784 326.099 302.496 326.099 302.141C326.099 301.787 325.811 301.499 325.457 301.499C325.102 301.499 324.814 301.787 324.814 302.141C324.814 302.496 325.102 302.784 325.457 302.784Z" fill="#00FF82" />
            <path d="M310.703 277.224C311.058 277.224 311.346 276.936 311.346 276.582C311.346 276.227 311.058 275.939 310.703 275.939C310.349 275.939 310.061 276.227 310.061 276.582C310.061 276.936 310.349 277.224 310.703 277.224Z" fill="#00FF82" />
            <path d="M303.317 281.482C303.672 281.482 303.96 281.195 303.96 280.84C303.96 280.485 303.672 280.198 303.317 280.198C302.963 280.198 302.675 280.485 302.675 280.84C302.675 281.195 302.963 281.482 303.317 281.482Z" fill="#00FF82" />
            <path d="M318.08 272.956C318.435 272.956 318.722 272.669 318.722 272.314C318.722 271.959 318.435 271.672 318.08 271.672C317.725 271.672 317.438 271.959 317.438 272.314C317.438 272.669 317.725 272.956 318.08 272.956Z" fill="#00FF82" />
            <path d="M295.941 285.741C296.295 285.741 296.583 285.453 296.583 285.098C296.583 284.744 296.295 284.456 295.941 284.456C295.586 284.456 295.298 284.744 295.298 285.098C295.298 285.453 295.586 285.741 295.941 285.741Z" fill="#00FF82" />
            <path d="M303.317 264.44C303.672 264.44 303.96 264.152 303.96 263.797C303.96 263.442 303.672 263.155 303.317 263.155C302.963 263.155 302.675 263.442 302.675 263.797C302.675 264.152 302.963 264.44 303.317 264.44Z" fill="#00FF82" />
            <path d="M295.941 268.698C296.295 268.698 296.583 268.41 296.583 268.056C296.583 267.701 296.295 267.413 295.941 267.413C295.586 267.413 295.298 267.701 295.298 268.056C295.298 268.41 295.586 268.698 295.941 268.698Z" fill="#00FF82" />
            <path d="M288.564 272.956C288.919 272.956 289.206 272.669 289.206 272.314C289.206 271.959 288.919 271.672 288.564 271.672C288.209 271.672 287.922 271.959 287.922 272.314C287.922 272.669 288.209 272.956 288.564 272.956Z" fill="#00FF82" />
            <path d="M310.703 268.698C311.058 268.698 311.346 268.41 311.346 268.056C311.346 267.701 311.058 267.413 310.703 267.413C310.349 267.413 310.061 267.701 310.061 268.056C310.061 268.41 310.349 268.698 310.703 268.698Z" fill="#00FF82" />
            <path d="M303.317 272.956C303.672 272.956 303.96 272.669 303.96 272.314C303.96 271.959 303.672 271.672 303.317 271.672C302.963 271.672 302.675 271.959 302.675 272.314C302.675 272.669 302.963 272.956 303.317 272.956Z" fill="#00FF82" />
            <path d="M295.941 277.224C296.295 277.224 296.583 276.936 296.583 276.582C296.583 276.227 296.295 275.939 295.941 275.939C295.586 275.939 295.298 276.227 295.298 276.582C295.298 276.936 295.586 277.224 295.941 277.224Z" fill="#00FF82" />
            <path d="M288.564 281.482C288.919 281.482 289.206 281.195 289.206 280.84C289.206 280.485 288.919 280.198 288.564 280.198C288.209 280.198 287.922 280.485 287.922 280.84C287.922 281.195 288.209 281.482 288.564 281.482Z" fill="#00FF82" />
            <path d="M281.187 277.224C281.542 277.224 281.829 276.936 281.829 276.582C281.829 276.227 281.542 275.939 281.187 275.939C280.832 275.939 280.545 276.227 280.545 276.582C280.545 276.936 280.832 277.224 281.187 277.224Z" fill="#00FF82" />
            <path d="M295.941 415.971V392.247" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M369.726 407.445V332.896" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M369.726 332.896V282.419" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M340.21 334.804V263.155" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M373.387 278.757H366.065V286.08H373.387V278.757Z" fill="white" />
            <path d="M321.741 303.389H314.419V310.713H321.741V303.389Z" fill="white" />
            <path d="M321.741 296.066H314.419V303.389H321.741V296.066Z" fill="white" />
            <path d="M329.062 288.733H321.741V296.057H329.062V288.733Z" fill="white" />
            <path d="M299.601 389.228H292.28V396.552H299.601V389.228Z" fill="white" />
            <path d="M340.21 433.014V382.675" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M343.871 379.656H336.549V386.979H343.871V379.656Z" fill="white" />
            <path d="M318.08 411.704V331.106" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M321.741 328.086H314.419V335.41H321.741V328.086Z" fill="white" />
            <path d="M347.587 420.23V369.891" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M351.248 366.871H343.926V374.195H351.248V366.871Z" fill="white" />
            <path d="M343.871 259.493H336.549V266.817H343.871V259.493Z" fill="white" />
            <path d="M332.833 316.66V396.818L402.233 356.739V276.582L332.833 316.66Z" fill="url(#paint5_linear_9336_36970)" />
            <path d="M332.833 316.66V396.818L263.433 356.739V276.582L332.833 316.66Z" fill="#15C237" />
            <path d="M263.433 356.739L332.833 396.818L402.233 356.739" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M332.742 370.9C335.76 369.156 337.448 368.184 340.467 366.44C343.486 364.696 345.174 369.11 348.192 367.376C351.211 365.632 352.899 349.828 355.918 348.094C358.936 346.35 360.625 354.803 363.643 353.059C366.662 351.315 368.35 317.358 371.369 315.614C374.387 313.87 376.075 336.062 379.094 334.327C382.113 332.584 383.801 327.306 386.819 325.563C389.838 323.819 391.526 333.96 394.545 332.226C397.563 330.482 399.251 311.943 402.27 310.199" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M381.158 295.35L385.168 293.037" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M388.15 291.312L397.215 286.081" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M289.996 325.102C294.876 322.283 295.369 313.999 291.097 306.598C286.826 299.198 279.407 295.483 274.527 298.301C269.647 301.119 269.154 309.403 273.426 316.804C277.697 324.205 285.116 327.92 289.996 325.102Z" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M282.38 298.314C285.609 299.728 288.802 302.637 291.096 306.602C293.711 311.135 294.546 316.009 293.665 319.744C293.115 322.103 291.885 324.012 289.995 325.104" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M310.737 330.641C312.093 329.858 312.229 327.554 311.041 325.495C309.853 323.437 307.79 322.402 306.434 323.186C305.078 323.969 304.942 326.272 306.131 328.331C307.319 330.39 309.381 331.424 310.737 330.641Z" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M320.398 336.221C321.754 335.438 321.89 333.134 320.702 331.076C319.513 329.017 317.451 327.983 316.095 328.766C314.739 329.549 314.603 331.853 315.791 333.912C316.979 335.97 319.042 337.004 320.398 336.221Z" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M278.581 316.192L284.912 319.845" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M305.418 334.869L310.18 337.622" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M270.617 336.3L284.252 344.175" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M270.617 340.853L284.252 348.727" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M270.617 345.405L284.252 353.279" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M270.617 349.966L279.187 354.913" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M288.197 346.451L301.831 354.325" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M288.197 351.003L301.831 358.877" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M288.197 355.555L301.831 363.43" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M288.197 360.116L291.197 361.842" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M314.951 340.375L319.713 343.119" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M438.768 335.456L443.448 360.951C443.695 362.301 443.695 364.503 443.448 365.852L437.952 395.404L426.639 378.361L428.768 342.844L438.777 335.456H438.768Z" fill="#00FF82" />
            <path d="M495.048 501.699L435.08 473.873L431.116 480.949L439.787 488.548L418.198 485.051L405.325 484.574L472.78 521L495.048 501.699Z" fill="black" />
            <path d="M423.428 473.772L423.96 483.454C423.07 483.574 422.07 483.84 421.345 484.446C419.684 485.804 418.895 484.335 418.253 482.344C417.611 480.352 416.656 477.902 416.656 477.902H416.647C417.39 477.058 416.078 474.956 416.179 473.772" fill="#BEE7D3" />
            <path d="M423.841 486.979C423.841 489.915 420.694 489.668 417.583 488.64L405.316 484.583C402.903 483.785 400.288 481.041 404.188 480.637L413.179 479.701C414.555 479.554 415.767 478.884 416.629 477.902H416.638C416.638 477.902 417.592 480.352 418.235 482.344C418.877 484.335 419.666 485.804 421.327 484.446C422.061 483.849 423.052 483.574 423.941 483.454L423.822 483.84V486.979H423.841Z" fill="black" />
            <path d="M423.841 486.979C423.841 489.915 420.694 489.668 417.583 488.64L405.316 484.583C404.426 484.29 403.509 483.73 402.903 483.124" stroke="#15C237" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M435.3 467.806L435.786 474.277C435.722 477.975 431.327 478.7 430.74 476.635C430.153 474.57 429.226 468.752 429.226 468.752" fill="#BEE7D3" />
            <path d="M435.786 474.277L436.374 478.462C436.887 482.078 431.777 482.555 430.511 480.16L426.061 471.698L429.226 468.743C429.226 468.743 430.153 474.561 430.74 476.626C431.327 478.691 435.722 477.966 435.786 474.267V474.277Z" fill="black" />
            <path d="M436.383 478.471C436.897 482.087 431.786 482.564 430.52 480.169L426.07 471.707" stroke="#15C237" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M416.216 475.883L405.5 409.795C404.619 405.389 403.995 398.112 404.123 393.615L404.224 390.109L404.564 386.346L415.565 385.887L422.62 392.091L420.299 414.686L422.134 424.222L423.492 475.727C419.877 479.627 416.225 475.883 416.225 475.883H416.216Z" fill="black" />
            <path d="M416.216 475.883L405.5 409.795C404.619 405.389 403.995 398.112 404.123 393.615L404.224 390.109" stroke="#15C237" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M437.291 379.132C439.906 389.677 440.594 396.983 438.732 403.82L433.337 423.58L436.08 434.152L437.099 467.265C434.722 471.138 431.896 470.496 429.245 468.752L420.244 422.038L418.739 387.475L425.107 380.399L437.291 379.132Z" fill="black" />
            <path d="M429.236 468.752L422.318 432.831" stroke="#15C237" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M404.041 354.077L402.454 346.533L405.94 340.036C406.903 338.237 409.078 335.86 410.785 334.74L425.538 325.039L433.3 327.077L439.915 342.78L435.897 367.458L437.3 379.132L420.024 389.723C416.501 391.889 408.867 392.394 404.564 386.346L404.041 354.077Z" fill="white" />
            <path d="M418.427 331.803L416.849 333.446L423.052 339.283L427.85 353.233C428.813 356.014 431.584 356.996 434.052 355.39L437.154 353.371L431.685 334.079L418.427 331.812V331.803Z" fill="#00FF82" />
            <path d="M435.263 362.245L426.263 374.158C424.611 376.342 421.06 377.746 418.372 377.288" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M433.016 369.808L427.355 377.012" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M412.207 358.629C412.207 358.629 414.188 363.044 412.427 365.201C410.665 367.348 406.839 370.863 408.032 374.085C409.225 377.306 405.335 382.28 405.335 382.28L404.555 365.43L412.198 358.629H412.207Z" fill="#00FF82" />
            <path d="M422.253 432.831L427.07 410.722" stroke="#15C237" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M422.253 432.831L416.776 443.045C416.776 443.045 420.271 444.743 421.712 440.136" stroke="#15C237" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M403.766 344.083L395.912 358.74L382.03 345.45L379.195 351.196L392.003 370.808C394.187 374.149 398.985 373.782 401.628 370.79L412.216 358.639L403.775 344.092L403.766 344.083Z" fill="white" />
            <path d="M381.736 355.096L392.003 370.817C394.187 374.158 398.985 373.791 401.628 370.799L412.216 358.648" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M382.03 345.45L377.075 348.176L379.718 351.93C379.718 351.93 380.094 347.947 384.003 347.332L382.03 345.45Z" fill="white" />
            <path d="M429.566 327.371L427.933 324.636C427.088 323.222 426.694 321.332 427.547 319.918L428.841 317.752L415.95 317.789L417.528 330.041L425.024 330.775L429.575 327.371H429.566Z" fill="#00FF82" />
            <path d="M420.969 321.506C426.568 321.506 431.107 316.966 431.107 311.365C431.107 305.764 426.568 301.223 420.969 301.223C415.369 301.223 410.83 305.764 410.83 311.365C410.83 316.966 415.369 321.506 420.969 321.506Z" fill="#00FF82" />
            <path d="M419.326 323.938L414.179 324.057C412.207 324.103 410.628 322.525 410.656 320.552L410.83 311.135L414.161 313.54L419.326 323.947V323.938Z" fill="#00FF82" />
            <path d="M422.62 301.361C418.115 300.82 414.5 302.417 412.207 306.253L413.941 308.887L413.812 311.365L415.014 312.108L414.812 317.596L419.005 317.477L424.272 322.213L427.547 319.927L428.841 317.762H428.832C429.905 316.44 430.667 314.834 430.969 313.035C431.887 307.51 428.18 302.04 422.62 301.37V301.361Z" fill="#15C237" />
            <path d="M416.959 314.503L408.665 312.108L410.885 310.263" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M410.775 314.65L408.775 315.632L408.665 312.108" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M377.066 348.176L375.378 346.478L371.671 344.468L370.479 341.99L371.644 337.769L371.277 333.795L372.396 333.868L374.286 338.806C374.745 339.999 376.158 340.898 377.433 340.788L378.589 340.696L378.121 337.649L379.195 336.998L380.562 340.072C381.415 341.899 380.36 343.844 382.03 345.441L377.075 348.167L377.066 348.176Z" fill="#00FF82" />
            <path d="M417.528 330.041L427.501 332.418L432.676 330.096L431.685 327.068L424.088 328.665L417.528 330.041Z" fill="white" />
            <path d="M414.812 317.596L414.629 320.854L414.198 327.517C414.014 330.372 416.051 333.556 418.712 334.593L426.859 337.75L433.126 353.628L438.365 353.389L441.925 344.835C442.567 343.284 442.493 340.77 441.759 339.255L439.2 334.006C439.2 334.006 442.09 329.215 440.099 325.526L430.236 307.271L414.812 317.587V317.596Z" fill="#15C237" />
            <path d="M395.912 358.74C395.912 358.74 394.077 360.924 393.939 363.631C393.801 366.339 390.728 364.944 390.957 361.842C391.187 358.74 391.141 356.647 388.847 354.077" stroke="#00FF82" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M423.373 334.804L427.235 344.881C427.667 346.019 429.015 347.075 430.217 347.24L432.41 347.534" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <defs>
                <linearGradient id="paint0_linear_9336_36970" x1="0.532104" y1="342.89" x2="139.323" y2="342.89" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="0.08" stop-color="#BEE7D3" />
                    <stop offset="0.17" stop-color="#BCD8BE" stop-opacity="0.53" />
                    <stop offset="0.25" stop-color="#BCD8BE" stop-opacity="0.34" />
                    <stop offset="0.33" stop-color="#BCD8BE" stop-opacity="0.19" />
                    <stop offset="0.4" stop-color="#00FF82" stop-opacity="0.09" />
                    <stop offset="0.46" stop-color="#00FF82" stop-opacity="0.02" />
                    <stop offset="0.51" stop-color="#00FF82" stop-opacity="0" />
                    <stop offset="0.57" stop-color="#00FF82" stop-opacity="0.17" />
                    <stop offset="0.65" stop-color="#00FF82" stop-opacity="0.42" />
                    <stop offset="0.74" stop-color="#00FF82" stop-opacity="0.63" />
                    <stop offset="0.81" stop-color="#00FF82" stop-opacity="0.79" />
                    <stop offset="0.89" stop-color="#00FF82" stop-opacity="0.91" />
                    <stop offset="0.95" stop-color="#00FF82" />
                    <stop offset="1" stop-color="#00FF82" />
                </linearGradient>
                <linearGradient id="paint1_linear_9336_36970" x1="149.535" y1="198.6" x2="137.736" y2="198.6" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#011906" />
                    <stop offset="0.05" stop-color="#011906" />
                    <stop offset="0.24" stop-color="#052C0D" />
                    <stop offset="0.42" stop-color="#0B641D" />
                    <stop offset="0.59" stop-color="#11902B" />
                    <stop offset="0.75" stop-color="#24BD42" />
                    <stop offset="0.89" stop-color="#15C237" />
                    <stop offset="1" stop-color="#15C237" />
                </linearGradient>
                <linearGradient id="paint2_linear_9336_36970" x1="215.723" y1="263.177" x2="354.523" y2="263.177" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00FF82" />
                    <stop offset="0.05" stop-color="#00FF82" stop-opacity="0.83" />
                    <stop offset="0.13" stop-color="#00FF82" stop-opacity="0.58" />
                    <stop offset="0.2" stop-color="#00FF82" stop-opacity="0.37" />
                    <stop offset="0.28" stop-color="#00FF82" stop-opacity="0.21" />
                    <stop offset="0.34" stop-color="#00FF82" stop-opacity="0.09" />
                    <stop offset="0.4" stop-color="#00FF82" stop-opacity="0.02" />
                    <stop offset="0.45" stop-color="#00FF82" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint3_linear_9336_36970" x1="0" y1="385.409" x2="138.8" y2="385.409" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00FF82" />
                    <stop offset="0.05" stop-color="#00FF82" stop-opacity="0.83" />
                    <stop offset="0.13" stop-color="#00FF82" stop-opacity="0.58" />
                    <stop offset="0.2" stop-color="#00FF82" stop-opacity="0.37" />
                    <stop offset="0.28" stop-color="#00FF82" stop-opacity="0.21" />
                    <stop offset="0.34" stop-color="#00FF82" stop-opacity="0.09" />
                    <stop offset="0.4" stop-color="#00FF82" stop-opacity="0.02" />
                    <stop offset="0.45" stop-color="#00FF82" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint4_linear_9336_36970" x1="263.323" y1="439.388" x2="402.123" y2="439.388" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00FF82" />
                    <stop offset="0.05" stop-color="#00FF82" stop-opacity="0.83" />
                    <stop offset="0.13" stop-color="#00FF82" stop-opacity="0.58" />
                    <stop offset="0.2" stop-color="#00FF82" stop-opacity="0.37" />
                    <stop offset="0.28" stop-color="#00FF82" stop-opacity="0.21" />
                    <stop offset="0.34" stop-color="#00FF82" stop-opacity="0.09" />
                    <stop offset="0.4" stop-color="#00FF82" stop-opacity="0.02" />
                    <stop offset="0.45" stop-color="#00FF82" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint5_linear_9336_36970" x1="381.296" y1="314.458" x2="390.456" y2="299.666" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#101901" />
                    <stop offset="0.05" stop-color="#011906" />
                    <stop offset="0.24" stop-color="#0A4A17" />
                    <stop offset="0.42" stop-color="#0C631D" />
                    <stop offset="0.59" stop-color="#0F7D25" />
                    <stop offset="0.75" stop-color="#0B9726" />
                    <stop offset="0.89" stop-color="#06AC27" />
                    <stop offset="1" stop-color="#15C237" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default HomeIllustration;