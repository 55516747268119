import { getResponseGet } from "../utils";

const getPercentagesOfUsersByDepartment = async () => {
    let percentagesOfUsers = {
        result: [],
        error: {}
    };

    try {
        const response = await getResponseGet("/department_risk_score_bellcurve/");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const lowRiskUsers = data[key].filter((riskScore) => riskScore <= 25);
                const moderateRiskUsers = data[key].filter((riskScore) => riskScore > 25 && riskScore <= 50);
                const highRiskUsers = data[key].filter((riskScore) => riskScore > 50 && riskScore <= 75);
                const severeRiskUsers = data[key].filter((riskScore) => riskScore > 75 && riskScore <= 100);

                const department = {
                    name: key,
                    lowRisk: lowRiskUsers.length / data[key].length,
                    moderateRisk: moderateRiskUsers.length / data[key].length,
                    highRisk: highRiskUsers.length / data[key].length,
                    severeRisk: severeRiskUsers.length / data[key].length
                };

                percentagesOfUsers.result.push(department);
            }
        }
    } catch (error) {
        percentagesOfUsers.error = error;
    }

    return percentagesOfUsers;
};

export default getPercentagesOfUsersByDepartment;