import { getResponseGet } from "../utils";

const getComplianceFrameworks = async () => {
    let complianceFrameworksData = {
        result: {
            complianceFrameworks: []
        },
        error: {}
    };

    try {
        const response = await getResponseGet("/compliance_frameworks/");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        if (data.compliance_frameworks && typeof data.compliance_frameworks === "object" && Object.keys(data.compliance_frameworks).length > 0) {
            for (const key in data.compliance_frameworks) {
                if (data.compliance_frameworks.hasOwnProperty(key)
                    && data.compliance_frameworks[key] !== null && data.compliance_frameworks[key] !== undefined) {
                    if (key === "SOC2_TYPE2") { // Just in case it is not "SOC_2_TYPE_2"
                        complianceFrameworksData.result.complianceFrameworks.push({
                            name: "SOC 2 Type 2"
                        });
                    } else {
                        complianceFrameworksData.result.complianceFrameworks.push({
                            name: key.replaceAll("_", " ")
                        });
                    }
                }
            }
        }
    } catch (error) {
        complianceFrameworksData.error = error;
    }

    return complianceFrameworksData;
};

export default getComplianceFrameworks;