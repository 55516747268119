import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Box,
  Grid,
  Stack,
  Typography,
  Tabs,
  Tab,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import {
  AdsClickOutlined,
  AnalyticsOutlined,
  AssignmentOutlined,
  FeedbackOutlined,
  Person,
  StarBorderOutlined,
  VerifiedUserOutlined,
  ViewInArOutlined,
} from "@mui/icons-material";

import { ButtonCTA, FadeInSection } from "../../../components";

import { createClient } from "contentful";

import { spacing } from "../../../styles";

import EcosystemDiagram from "./utils/ecosystem-diagram/ecosystem-diagram";

import AntlerLogo from "./utils/antler-logo/antler-logo";
import NetlifyLogo from "./utils/netlify-logo/netlify-logo";
import WarnerMusicGroupLogo from "./utils/warner-music-group-logo/warner-music-group-logo";
import SaveTimeLogo from "./utils/savetime-logo/savetime-logo";
import SaveMoneyLogo from "./utils/savemoney-logo/savemoney-logo";
import ReduceRiskLogo from "./utils/reducerisk-logo/reducerisk-logo";

import "./home.css";

function Home() {
  const customersDesktop = [
    {
      name: "Netlify",
      logo: <NetlifyLogo />,
    },
    {
      name: "Craft",
      logo: (
        <Box
          component="img"
          src="https://d3oo9a669kwmx7.cloudfront.net/logos/craft-logo.png"
          width="100%"
          height="100%"
        />
      ),
    },
    {
      name: "Warner Music Group",
      logo: <WarnerMusicGroupLogo />,
    },
    {
      name: "Antler",
      logo: <AntlerLogo />,
    },
  ];

  const customersMobile = [
    {
      name: "Warner Music Group",
      logo: <WarnerMusicGroupLogo />,
    },
    {
      name: "Netlify",
      logo: <NetlifyLogo />,
    },
    {
      name: "Craft",
      logo: (
        <Box
          component="img"
          src="https://d3oo9a669kwmx7.cloudfront.net/logos/craft-logo.png"
          width="100%"
          height="100%"
        />
      ),
    },
    {
      name: "Antler",
      logo: <AntlerLogo />,
    },
  ];

  const threats = [
    {
      title: "Increased Use of Generative AI for Attacks",
      icon: "https://d3oo9a669kwmx7.cloudfront.net/icons/connection.gif",
      description:
        "Cybercriminals use AI tools like ChatGPT to create highly convincing phishing attacks, increasing both sophistication and scale.",
    },
    {
      title: "Advanced Persistent Threats and State-Sponsored Attacks",
      icon: "https://d3oo9a669kwmx7.cloudfront.net/icons/cyberterrorism.gif",
      description:
        "APTs and state-sponsored attacks use complex social engineering techniques to gain and maintain long-term access to targeted systems.",
    },
    {
      title: "Rise of Business Email Compromise (BEC)",
      icon: "https://d3oo9a669kwmx7.cloudfront.net/icons/message.gif",
      description:
        "BEC attacks manipulate employees into transferring funds or disclosing sensitive information by impersonating executives or trusted partners.",
    },
  ];

  const categorySolutions = [
    {
      category: "Security Awareness Training",
      solutions: [
        {
          title: "User-Adaptation",
          icon: <Person />,
          description:
            "Training modules adjust based on user behavior and risk level, enhancing learning effectiveness and engagement.",
        },
        {
          title: "Real Time Feedback",
          icon: <FeedbackOutlined />,
          description:
            "Immediate, personalized interventions reinforce positive behaviors and address potential vulnerabilities, proactively mitigating risks.",
        },
        {
          title: "Comprehensive Reporting",
          icon: <AssignmentOutlined />,
          description:
            "Comprehensive analytics provide actionable intelligence on user progress, risk reduction trends, and overall security posture improvements, facilitating strategic decision-making and simplified compliance reporting.",
        },
        {
          title: "Automatic Compliance Training",
          icon: <VerifiedUserOutlined />,
          description:
            "Automate and optimize adherence to industry standards and regulations, ensuring thorough coverage while reducing administrative burden.",
        },
      ],
    },
    {
      category: "Red Team Social Engineering",
      solutions: [
        {
          title: "Hyper-Realistic Simulations",
          icon: <ViewInArOutlined />,
          description:
            "Test defenses with sophisticated, real-world attack scenarios customized to your organization, proactively identifying and addressing critical vulnerabilities.",
        },
        {
          title: "Sophisticated and Specific",
          icon: <StarBorderOutlined />,
          description:
            "Utilize advanced techniques like phishing, smishing, vishing, and deepfakes to evaluate responses and gain strategic insights.",
        },
        {
          title: "Comprehensive Assessments",
          icon: <AnalyticsOutlined />,
          description:
            "Detailed, actionable reports outline vulnerabilities and remediation strategies, enhancing overall security posture.",
        },
        {
          title: "Direct Engagement",
          icon: <AdsClickOutlined />,
          description:
            "Work with SOCs and CISOs to customize threat scenarios and enhance security measures, delivering tailored and impactful insights.",
        },
      ],
    },
  ];

  const aiPoints = [
    {
      id: 0,
      title: "Quantify employee risk",
      description:
        "Comprehensively quantify user risk using a variety of input source data, and aggregate the results at organizational, departmental, manager, and other levels.",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/ai-quantify.png",
    },
    {
      id: 1,
      title: "Reduce employee risk",
      description:
        "Quantifiably and automatically reduce risk for users through adaptive, timely training tailored to individual and departmental needs in real-time. Ensure compliance and robust protection by continuously enhancing employee awareness and behavior.",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/ai-reduce.png",
    },
    {
      id: 2,
      title: "Adapt security controls around risk",
      description:
        "For the highest-risk users, seamlessly integrate with your IDP and existing tech stack to dynamically apply restrictions, adjust permissions, and enhance security controls, such as email filters.",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/ai-adapt.png",
    },
  ];

  const benefitsIconClassName = "home-icon-benefit";
  const benefits = [
    {
      title: "Save Time",
      icon: <SaveTimeLogo />,
      description:
        "Automate cybersecurity and reduce manual security tasks like campaign scheduling, training assignments, security controls and reporting.",
    },
    {
      title: "Reduce Risk",
      icon: <ReduceRiskLogo />,
      description:
        "Leverage AI to detect and mitigate risks by analyzing user behavior, offering tailored training and dynamic security to prevent breaches.",
    },
    {
      title: "Save Money",
      icon: <SaveMoneyLogo className={benefitsIconClassName} />,
      description:
        "Lower security costs with targeted interventions for high-risk users, cutting down on breaches and inefficient training programs.",
    },
  ];

  const cisoAdvisoryCouncilQuotes = [
    {
      cisos: [
        {
          name: "Jeannine Gaudreau",
          title: "Technical CISO",
          companyName: "HealthEdge",
          profilePicture:
            "https://d3oo9a669kwmx7.cloudfront.net/headshots/jeannine-gaudreau.png",
          quote:
            "Finally! A learning platform that's as dynamic as the threat landscape.",
        },
        {
          name: "Bradley Schaufenbuel",
          title: "VP, CISO",
          companyName: "Paychex",
          profilePicture:
            "https://d3oo9a669kwmx7.cloudfront.net/headshots/bradley-schaufenbuel.png",
          quote:
            "I chose to join the Dune Security CISO Advisory Council because of Dune's holistic and context-sensitive approach to improving the security awareness of employees.",
        },
      ],
    },
    {
      cisos: [
        {
          name: "Benjamin Corll",
          title: "CISO Americas",
          companyName: "Zscaler",
          profilePicture:
            "https://d3oo9a669kwmx7.cloudfront.net/headshots/benjamin-corll.png",
          quote:
            "When it comes to Dune and their product, their vision, their mission, just yes. I am fully on board. They are taking a fresh approach to security awareness, using data, insights, and AI capabilities to provide advanced contextualized testing and training, which will actually solve this problem for enterprises.",
        },
        {
          name: "Diego Souza",
          title: "EVP, CISO",
          companyName: "Prog Holdings",
          profilePicture:
            "https://d3oo9a669kwmx7.cloudfront.net/headshots/diego-souza.png",
          quote:
            "Dune Security isn't just a provider; they're pioneers in safeguarding tomorrow's digital world. With their best-in-class solutions and forward-looking cyber awareness services, they're shaping the future of cybersecurity.",
        },
      ],
    },
    {
      cisos: [
        {
          name: "Alicia Lynch",
          title: "CISO",
          companyName: "Cognizant",
          profilePicture:
            "https://d3oo9a669kwmx7.cloudfront.net/headshots/alicia-lynch.png",
          quote:
            "Companies often wait until after a breach to invest in preventive products. Dune's powerful AI-driven platform modifies employee behavior by training them to identify and prevent threat actor actions, reducing catastrophic risks.",
        },
        {
          name: "Roy Foster",
          title: "CISO",
          companyName: "University of Chicago",
          profilePicture:
            "https://d3oo9a669kwmx7.cloudfront.net/headshots/leroy-foster.png",
          quote:
            "I joined the Dune Security CISO Advisory Council because Technology alone isn't enough; cybersecurity is a human endeavor. Dune Security empowers your team with the knowledge and tools to defend against phishing threats.",
        },
      ],
    },
    {
      cisos: [
        {
          name: "Jim Motes",
          title: "CISO",
          companyName: "Ryan",
          profilePicture:
            "https://d3oo9a669kwmx7.cloudfront.net/headshots/jim-motes.png",
          quote:
            "Dune offers a solution perfectly aligned with industry needs, focusing on the biggest threat: employee behavior. Their approach provides CISOs with precise tools to target and train at-risk employees.",
        },
        {
          name: "Dr. Tyrone Grandison",
          title: "CTO, Apps, Infra & Security",
          companyName: "Microsoft",
          profilePicture:
            "https://d3oo9a669kwmx7.cloudfront.net/headshots/dr-tyrone-grandison.png",
          quote:
            "I joined the Dune Security CISO Advisory Council because of the vision of tailored, AI-driven cybersecurity readiness.",
        },
      ],
    },
    {
      cisos: [
        {
          name: "Marcos Marrero",
          title: "CTO",
          companyName: "H.I.G Capital",
          profilePicture:
            "https://d3oo9a669kwmx7.cloudfront.net/headshots/marcos-marrero.png",
          quote:
            "I joined the CISO Advisory Council because I firmly believe in Dune's mission to revolutionize cybersecurity by accurately measuring and drastically reducing Human Cyber Risk. Dune is setting new standards in the industry.",
        },
        {
          name: "Murtaza Nisar",
          title: "VP, CISO",
          companyName: "Clario",
          profilePicture:
            "https://d3oo9a669kwmx7.cloudfront.net/headshots/murtaza-nisar.png",
          quote:
            "I chose to join the Dune CISO Advisory Council because in the fight against cyber threats, there's an opportunity to convert people from one of the key risks to biggest assets",
        },
      ],
    },
    {
      cisos: [
        {
          name: "Konrad Fellmann",
          title: "CISO",
          companyName: "Cubic",
          profilePicture:
            "https://d3oo9a669kwmx7.cloudfront.net/headshots/konrad-fellmann.png",
          quote:
            "Most breaches occur due to human error. I joined the CISO Advisory Council because Dune's innovative approach is transforming the human side of security, significantly reducing risks and enhancing protection.",
        },
        {
          name: "Thanh Thai",
          title: "CISO",
          companyName: "Consellis",
          profilePicture:
            "https://d3oo9a669kwmx7.cloudfront.net/headshots/thanh-thai.png",
          quote:
            "I joined the Dune Security Advisory Council after seeing their solution. Dune elevates phishing defense with personalized campaigns that effectively fill knowledge gaps. It's like having a white hat hacker on your team!",
        },
      ],
    },
  ];

  const investors = [
    {
      name: "Craft",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/logos/craft-logo.png",
    },
    {
      name: "Alumni Ventures",
      picture:
        "https://d3oo9a669kwmx7.cloudfront.net/logos/alumni-ventures-logo.png",
    },
    {
      name: "Antler",
      picture: "https://d3oo9a669kwmx7.cloudfront.net/logos/antler-logo.png",
    },
    {
      name: "Firestreak",
      picture:
        "https://d3oo9a669kwmx7.cloudfront.net/logos/firestreak-logo.png",
    },
    {
      name: "Sequoia Scout",
      picture:
        "https://d3oo9a669kwmx7.cloudfront.net/logos/sequoia-scout-logo.png",
    },
  ];

  const [selectedTabSolution, setSelectedTabSolution] = useState(
    categorySolutions[0].solutions[0]
  );
  const [selectedTabSolutionTitle, setSelectedTabSolutionTitle] = useState(
    categorySolutions[0].solutions[0].title
  );
  const [selectedAIPoint, setSelectedAIPoint] = useState(aiPoints[0]);
  const [resources, setResources] = useState([]);

  const productRef = useRef(null);

  const location = useLocation();

  const handleChangeTabSolution = (event, newValue) => {
    setSelectedTabSolutionTitle(newValue);

    const allSolutions = categorySolutions.flatMap(
      (category) => category.solutions
    );
    const filteredSolutions = allSolutions.filter(
      (solution) => solution.title === newValue
    );

    if (filteredSolutions.length > 0) {
      setSelectedTabSolution(filteredSolutions[0]);
    }

    // TO DELETE:
    console.log(resources);
  };

  useEffect(() => {
    const aiPointTimeout = setTimeout(() => {
      const currentAIPointID = selectedAIPoint.id;

      if (currentAIPointID === aiPoints.length - 1) {
        setSelectedAIPoint(aiPoints[0]);
      } else {
        const filteredAIPoints = aiPoints.filter(
          (aiPoint) => aiPoint.id === currentAIPointID + 1
        );

        if (filteredAIPoints.length > 0) {
          setSelectedAIPoint(filteredAIPoints[0]);
        }
      }
    }, 5000);

    return () => clearTimeout(aiPointTimeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiPoints]);

  useEffect(() => {
    if (location.pathname === "/product/") {
      productRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [location]);

  useEffect(() => {
    const fetchResources = async () => {
      if (process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
        let fetchedResourcesBootcamp = [];
        let fetchedResourcesTeam = [];

        const client = createClient({
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
          accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        });

        await client
          .getEntries({
            content_type: "resourcesBootcamp",
            limit: 3,
            order: "-fields.date,fields.title",
          })
          .then((response) => {
            fetchedResourcesBootcamp = response.items.map((item) => {
              const { fields, sys } = item;

              return {
                id: sys.id,
                title: fields.title,
                href: `/resources/bootcamp/${fields.resourceID}`,
                thumbnailURL: fields.thumbnail.fields.file.url,
                date: fields.date,
              };
            });
          })
          .catch(console.error);

        await client
          .getEntries({
            content_type: "resourcesTeam",
            limit: 3,
            order: "-fields.date,fields.title",
          })
          .then((response) => {
            fetchedResourcesTeam = response.items.map((item) => {
              const { fields, sys } = item;

              return {
                id: sys.id,
                title: fields.title,
                href: fields.resourceURL || "",
                thumbnailURL: fields.thumbnail.fields.file.url,
                date: fields.date,
              };
            });
          })
          .catch(console.error);

        const fetchedResourcesMostRecent = [
          ...fetchedResourcesBootcamp,
          ...fetchedResourcesTeam,
        ];

        fetchedResourcesMostRecent.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);

          if (dateA < dateB) {
            return 1;
          } else if (dateA > dateB) {
            return -1;
          }

          // Dates are the same, so sort by title.
          return a.title.localeCompare(b.title);
        });

        setResources(fetchedResourcesMostRecent.slice(0, 3));
      }
    };

    fetchResources();
  }, []);

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (percentage < 90) {
      const interval = setInterval(() => {
        setPercentage((prevPercentage) => prevPercentage + 1);
      }, 15);

      return () => clearInterval(interval);
    }
  }, [percentage]);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % aiPoints.length);
    };

    const interval = setInterval(nextSlide, 4000);

    return () => clearInterval(interval);
  }, [aiPoints.length]);

  return (
    <>
      <FadeInSection>
        <Box
          className="home-box-top"
          display="flex"
          justifyContent="center"
          marginTop="30px"
          padding="0 30px"
        >
          <Box maxWidth={spacing.MAX_WIDTH_WEBSITE} width="100%">
            <Box
              maxWidth={{
                xs: "100%",
                md: "612px",
              }}
              width="100%"
              padding="100px 0"
            >
              <Typography variant="h1" component="h1">
                Employee risk management,{" "}
                <Typography component="span" className="dune-text-italic">
                  powered by AI
                </Typography>
              </Typography>
              <Typography variant="body-gray" component="p" marginTop="24px">
                Prevent sophisticated social engineering attacks with
                user-adaptive security awareness training and red team analysis.
              </Typography>
              <Box marginTop="24px">
                <ButtonCTA text="Learn more" />
              </Box>
            </Box>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box padding="40px 30px">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            display={{
              xs: "none",
              md: "flex",
            }}
          >
            {customersDesktop.map((customer, index) => (
              <Box key={`customer-desktop-${index}`} height="54px">
                {customer.logo}
              </Box>
            ))}
          </Stack>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            display={{
              xs: "flex",
              md: "none",
            }}
          >
            {customersMobile.map((customer, index) => (
              <Box
                key={`customer-mobile-${index}`}
                height="54px"
                marginTop={index > 0 ? "50px" : 0}
              >
                {customer.logo}
              </Box>
            ))}
          </Stack>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          className="home-box-background-green"
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: "80px",
            md: "120px",
          }}
          padding="60px 30px"
          ref={productRef}
        >
          <Box
            maxWidth={{
              xs: "100%",
              md: spacing.MAX_WIDTH_WEBSITE,
            }}
            width="100%"
          >
            <Box>
              <Typography
                variant="h2"
                component="h2"
                className="home-text-header-secondary"
                textAlign="center"
              >
                <Typography
                  component="span"
                  className="home-text-header-title-italic"
                >
                  {percentage}%
                </Typography>{" "}
                of breaches start with human behavior
              </Typography>
            </Box>
            <Box maxWidth="650px" width="100%" mx="auto">
              <Typography
                variant="body-gray"
                component="p"
                marginTop="24px"
                textAlign="center"
              >
                Historically the biggest challenge for enterprise security
                teams, this trend is worsening with new trends that increase the
                sophistication and scale of attacks on end users.
              </Typography>
            </Box>
            <Stack
              direction={{
                xs: "column",
                md: "row",
              }}
              justifyContent="space-between"
              alignItems="center"
              marginTop="60px"
              width="100%"
            >
              {threats.map((threat, index) => (
                <Box
                  maxWidth="252px"
                  width="100%"
                  marginTop={{
                    xs: index > 0 ? "80px" : 0,
                    md: 0,
                  }}
                  key={`threat-${index}`}
                >
                  <Box display="flex" justifyContent="center">
                    <Box
                      component="img"
                      className="home-image-border-radius"
                      src={threat.icon}
                      alt={threat.title}
                      height="100px"
                      width="100px"
                    />
                  </Box>
                  <Box marginTop="30px">
                    <Typography
                      variant="h4"
                      component="h3"
                      className="home-text-header-tertiary"
                      textAlign="center"
                    >
                      {threat.title}
                    </Typography>
                  </Box>
                  <Box marginTop="10px">
                    <Typography
                      variant="description-gray"
                      component="p"
                      textAlign="center"
                    >
                      {threat.description}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: "80px",
            md: "120px",
          }}
          padding="0 30px"
        >
          <Box
            maxWidth={{
              xs: "100%",
              md: spacing.MAX_WIDTH_WEBSITE,
            }}
            width="100%"
          >
            <Box>
              <Typography
                variant="h2"
                component="h2"
                className="home-text-header-secondary"
                textAlign="center"
              >
                Dune Security identifies and resolves risky behavior,{" "}
                <Typography
                  component="span"
                  className="home-text-header-title-italic"
                >
                  automatically
                </Typography>{" "}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body-gray"
                component="p"
                textAlign="center"
                marginTop="12px"
              >
                With a suite of services built to combat the tactics of
                modern-day social engineers.
              </Typography>
            </Box>
            <Stack
              direction={{
                xs: "column",
                md: "row",
              }}
              justifyContent="center"
              marginTop="60px"
            >
              <Box
                className="home-box-solutions-tabs"
                maxWidth={{
                  xs: "100%",
                  md: "300px",
                }}
                padding="20px"
              >
                <Box display={{ xs: "none", md: "block" }}>
                  {categorySolutions.map((categoryItem, categoryItemIndex) => (
                    <>
                      <Box
                        marginTop={categoryItemIndex > 0 ? "30px" : 0}
                        key={`category-item-${categoryItemIndex}`}
                      >
                        <Typography
                          component="span"
                          className="home-text-category-solution-name"
                        >
                          {categoryItem.category}
                        </Typography>
                      </Box>
                      <Box marginTop="10px">
                        <Tabs
                          className="home-tabs"
                          orientation="vertical"
                          value={selectedTabSolution.title}
                          onChange={handleChangeTabSolution}
                        >
                          {categoryItem.solutions.map(
                            (solution, solutionIndex) => (
                              <Tab
                                className="home-tab"
                                icon={solution.icon}
                                iconPosition="start"
                                label={solution.title}
                                value={solution.title}
                                key={`category-item-${categoryItemIndex}-solution-${solutionIndex}`}
                              />
                            )
                          )}
                        </Tabs>
                      </Box>
                    </>
                  ))}
                </Box>
                <Box display={{ xs: "block", md: "none" }}>
                  <Select
                    backgroundColor="black"
                    value={selectedTabSolutionTitle}
                    onChange={(event) =>
                      handleChangeTabSolution(event, event.target.value)
                    }
                    fullWidth
                    variant="outlined"
                    displayEmpty
                    renderValue={(selected) => (
                      <Box display="flex" alignItems="center">
                        <ListItemIcon sx={{ color: "white", minWidth: "40px" }}>
                          {
                            categorySolutions
                              .flatMap((cat) => cat.solutions)
                              .find((sol) => sol.title === selected)?.icon
                          }
                        </ListItemIcon>
                        <ListItemText primary={selected} />
                      </Box>
                    )}
                    sx={{
                      color: "white",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    {categorySolutions
                      .map((categoryItem, categoryItemIndex) => [
                        <ListSubheader
                          key={`subheader-${categoryItemIndex}`}
                          sx={{
                            backgroundColor: "black",
                            color: "#00FF82",
                            fontSize: "14px",
                            fontWeight: 400,
                            textTransform: "uppercase",
                            letterSpacing: "0.1em",
                            padding: "16px 16px 8px",
                            lineHeight: "20px",
                          }}
                        >
                          {categoryItem.category}
                        </ListSubheader>,
                        ...categoryItem.solutions.map(
                          (solution, solutionIndex) => (
                            <MenuItem
                              value={solution.title}
                              className="dune-menu-item"
                              key={`mobile-category-item-${categoryItemIndex}-solution-${solutionIndex}`}
                              sx={{
                                backgroundColor: "black",
                                color: "white",
                                "&.Mui-selected": {
                                  backgroundColor: "#262525 !important",
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                                },
                              }}
                            >
                              <Box display="flex" alignItems="center">
                                <ListItemIcon
                                  sx={{ color: "white", minWidth: "40px" }}
                                >
                                  {solution.icon}
                                </ListItemIcon>
                                <ListItemText
                                  primary={solution.title}
                                  primaryTypographyProps={{ variant: "body1" }}
                                />
                              </Box>
                            </MenuItem>
                          )
                        ),
                      ])
                      .flat()}
                  </Select>
                </Box>
              </Box>
              <Box
                maxWidth="600px"
                width="100%"
                marginLeft={{
                  xs: 0,
                  md: "60px",
                }}
                marginTop={{
                  xs: "30px",
                  md: 0,
                }}
              >
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Box
                    className="home-box-background-gray"
                    height={{
                      xs: "230px",
                      md: "360px",
                    }}
                    width="100%"
                    sx={{
                      background: "inherit !important",
                    }}
                  >
                    <Box padding="4px">
                      <Box
                        component="img"
                        src="https://d3oo9a669kwmx7.cloudfront.net/home-dashboard-new.png"
                        width="100%"
                        height="100%"
                      />
                    </Box>
                  </Box>
                  <Box
                    className="home-box-background-gray"
                    padding="20px"
                    marginTop="10px"
                  >
                    <Box>
                      <Typography variant="h3" component="h3">
                        {selectedTabSolutionTitle}
                      </Typography>
                    </Box>
                    <Box marginTop="10px">
                      <Typography variant="body-gray" component="p">
                        {selectedTabSolution.description}
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Box>
      </FadeInSection>
      <EcosystemDiagram />
      <FadeInSection>
        <Grid
          container
          minWidth={{
            xs: "100%",
            md: spacing.MAX_WIDTH_WEBSITE,
          }}
          sx={{
            width: "100%",
            maxWidth: "900px",
            margin: "auto",
            alignItems: "center",
            flexDirection: {
              xs: "column",
              md: "row-reverse",
            },
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            maxWidth="500px"
            width="100%"
            sx={{
              position: "relative",
              overflow: "hidden",
              marginBottom: {
                xs: "16px",
                md: 0,
              },
            }}
          >
            {aiPoints.map((point, index) => (
              <Box
                key={point.id}
                sx={{
                  opacity: index === currentIndex ? 1 : 0,
                  transition: "opacity 1.5s ease-in-out",
                  position: index === currentIndex ? "relative" : "absolute",
                  width: "100%",
                  top: 0,
                  left: 0,
                }}
                marginTop="50px"
              >
                <img
                  src={point.picture}
                  alt={point.title}
                  style={{ width: "100%", height: "auto", display: "block" }}
                />
              </Box>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: {
                xs: "center",
                md: "flex-start",
              },
            }}
          >
            {aiPoints.map((point, index) => (
              <Box
                key={point.id}
                maxWidth="500px"
                width={{
                  xs: "80%",
                  md: "100%",
                }}
                sx={{
                  marginBottom: "16px",
                  backgroundColor:
                    index === currentIndex ? "#141414" : "transparent",
                  border:
                    index === currentIndex
                      ? "0.5px solid #E9E9E7"
                      : "0.5px solid transparent",
                  borderRadius: "8px",
                  padding: "20px",
                  color: index === currentIndex ? "white" : "inherit",
                }}
              >
                <Typography
                  variant="h4"
                  color="white"
                  component="h4"
                  marginBottom="8px"
                >
                  {point.title}
                </Typography>
                <Typography variant="body-gray">{point.description}</Typography>
              </Box>
            ))}
          </Grid>
        </Grid>
      </FadeInSection>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: "80px",
            md: "120px",
          }}
          padding="0 30px"
        >
          <Box
            maxWidth={{
              xs: "100%",
              md: spacing.MAX_WIDTH_WEBSITE,
            }}
            width="100%"
          >
            <Box>
              <Typography
                variant="h2"
                component="h2"
                className="home-text-header-secondary"
                textAlign="center"
              >
                <Typography
                  component="span"
                  className="home-text-header-title-italic"
                >
                  Secure
                </Typography>{" "}
                smarter,{" "}
                <Typography
                  component="span"
                  className="home-text-header-title-italic"
                >
                  operate
                </Typography>{" "}
                better,{" "}
                <Typography
                  component="span"
                  className="home-text-header-title-italic"
                >
                  empower
                </Typography>{" "}
                your team.
              </Typography>
              <Typography textAlign="center" paddingTop="10px" variant="body1">
                See how AI can personalize testing and training at the
                organization and user level.
              </Typography>
            </Box>
            <Box marginTop="36px" maxWidth={spacing.MAX_WIDTH_WEBSITE}>
              <Box
                display="flex"
                flexWrap={{
                  xs: "wrap",
                  md: "nowrap",
                }}
              >
                {benefits.map((benefit, index) => (
                  <Box
                    key={index}
                    display="flex"
                    flexWrap={{
                      xs: "wrap",
                      md: "nowrap",
                    }}
                    marginBottom={{
                      xs: "20px",
                      md: 0,
                    }}
                    className="home-box-background-gray"
                    sx={{
                      border: "1px solid #E9E9E7",
                      padding: "20px",
                      margin: "0 5px",
                      borderRadius: "8px",
                    }}
                  >
                    <Box
                      width="100%"
                      marginBottom={{
                        xs: "20px",
                        md: 0,
                      }}
                      marginRight={{
                        xs: 0,
                        md: "20px",
                      }}
                      className="home-box-benefit-icon"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      {benefit.icon}
                    </Box>
                    <Box>
                      <Typography variant="h3" component="h3">
                        {benefit.title}
                      </Typography>
                      <Typography
                        marginTop="12px"
                        variant="body-gray"
                        component="p"
                      >
                        {benefit.description}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: "80px",
            md: "120px",
          }}
          padding="0 30px"
        >
          <Box
            maxWidth={{
              xs: "100%",
              md: spacing.MAX_WIDTH_WEBSITE,
            }}
            width="100%"
          >
            <Box>
              <Typography
                variant="body-gray"
                component="p"
                className="home-text-ciso-advisory"
                textAlign="center"
              >
                The CISO Advisory Council
              </Typography>
            </Box>
            <Box marginTop="10px">
              <Typography
                variant="h2"
                component="h2"
                className="home-text-header-secondary"
                textAlign="center"
              >
                Built{" "}
                <Typography
                  component="span"
                  className="home-text-header-title-italic"
                >
                  for
                </Typography>{" "}
                enterprise CISOs,{" "}
                <Typography
                  component="span"
                  className="home-text-header-title-italic"
                >
                  by
                </Typography>{" "}
                enterprise CISOs.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" marginTop="60px">
          <Box
            maxWidth={{
              xs: "100%",
              md: "1600px",
            }}
            width="100%"
          >
            <Box className="home-box-ciso-scroll">
              <Stack
                direction="row"
                className="home-box-ciso-content"
                width="fit-content"
              >
                {cisoAdvisoryCouncilQuotes.map((quotesItem, quotesIndex) => (
                  <Box
                    width="300px"
                    marginLeft={quotesIndex > 0 ? "20px" : 0}
                    key={`quotes-item-${quotesIndex}`}
                  >
                    {quotesItem.cisos.map((ciso, cisoIndex) => (
                      <Box
                        className="home-box-background-gray"
                        marginTop={cisoIndex > 0 ? "20px" : 0}
                        padding="10px"
                        key={`quotes-item-${quotesIndex}-ciso-${cisoIndex}`}
                      >
                        <Box>
                          <Typography variant="body-gray" component="p">
                            {ciso.quote}
                          </Typography>
                        </Box>
                        <Box marginTop="30px">
                          <Stack direction="row" alignItems="center">
                            <Box>
                              <Box
                                component="img"
                                className="home-image-border-radius"
                                src={ciso.profilePicture}
                                alt={ciso.name}
                                height="40px"
                                width="40px"
                              />
                            </Box>
                            <Box marginLeft="12px">
                              <Box>
                                <Typography
                                  variant="description-emphasized-gray"
                                  component="span"
                                >
                                  {ciso.name}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="description-gray"
                                  component="span"
                                >
                                  {ciso.title} | {ciso.companyName}
                                </Typography>
                              </Box>
                            </Box>
                          </Stack>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ))}
                {cisoAdvisoryCouncilQuotes.map((quotesItem, quotesIndex) => (
                  <Box
                    width="300px"
                    marginLeft="20px"
                    key={`quotes-item-duplicate-${quotesIndex}`}
                  >
                    {quotesItem.cisos.map((ciso, cisoIndex) => (
                      <Box
                        className="home-box-background-gray"
                        marginTop={cisoIndex > 0 ? "20px" : 0}
                        padding="10px"
                        key={`quotes-item-duplicate-${quotesIndex}-ciso-${cisoIndex}`}
                      >
                        <Box>
                          <Typography variant="body-gray" component="p">
                            {ciso.quote}
                          </Typography>
                        </Box>
                        <Box marginTop="30px">
                          <Stack direction="row" alignItems="center">
                            <Box>
                              <Box
                                component="img"
                                className="home-image-border-radius"
                                src={ciso.profilePicture}
                                alt={ciso.name}
                                height="40px"
                                width="40px"
                              />
                            </Box>
                            <Box marginLeft="12px">
                              <Box>
                                <Typography
                                  variant="description-emphasized-gray"
                                  component="span"
                                >
                                  {ciso.name}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="description-gray"
                                  component="span"
                                >
                                  {ciso.title} | {ciso.companyName}
                                </Typography>
                              </Box>
                            </Box>
                          </Stack>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Stack>
            </Box>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: "80px",
            md: "120px",
          }}
          padding="0 30px"
        >
          <Box
            maxWidth={{
              xs: "100%",
              md: spacing.MAX_WIDTH_WEBSITE,
            }}
            width="100%"
          >
            <Box marginTop="10px">
              <Typography
                variant="h2"
                component="h2"
                className="home-text-header-secondary"
                textAlign="center"
              >
                Best-in-class technology{" "}
                <Typography
                  component="span"
                  className="home-text-header-title-italic"
                >
                  with
                </Typography>{" "}
                best-in-class investors.
              </Typography>
            </Box>
            <Box marginTop="30px">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                {investors.map((investor, index) => (
                  <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    md={4}
                    lg
                    justifyContent="center"
                    marginTop={{
                      xs: index > 0 ? "40px" : 0,
                      sm: index > 1 ? "40px" : 0,
                      md: index > 2 ? "40px" : 0,
                      lg: 0,
                    }}
                  >
                    <Box
                      component="img"
                      src={investor.picture}
                      alt={investor.name}
                      maxHeight="36px"
                      height="100%"
                      marginLeft={{
                        xs: 0,
                        sm: index % 2 !== 0 ? "28px" : 0,
                        md: index % 3 !== 0 ? "28px" : 0,
                        lg: index > 0 ? "28px" : 0,
                      }}
                      key={`investor-${index}`}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          className="home-box-top"
          display="flex"
          justifyContent="center"
          maxWidth={spacing.MAX_WIDTH_WEBSITE}
          margin="auto"
          marginTop="80px"
          borderRadius="8px"
          border="1px solid rgba(194, 191, 184, 0.35)"
        >
          <Box maxWidth={spacing.MAX_WIDTH_WEBSITE} width="100%">
            <Box
              maxWidth={{
                xs: "100%",
                md: "612px",
              }}
              width="100%"
              padding={{
                xs: "100px 20px",
                md: "100px 40px",
              }}
            >
              <Typography
                variant="h1"
                component="h1"
                className="home-text-header-title"
              >
                Meet the experts behind our cybersecurity solutions
              </Typography>
              <Typography
                variant="body"
                component="p"
                className="home-text-main-description"
                marginTop="24px"
                maxWidth={{
                  xs: "90%",
                }}
              >
                Meet the experts behind our success and learn more about our
                dedicated team.
              </Typography>
              <Box
                width={{
                  xs: "50%",
                  md: "fit-content",
                }}
                marginTop="24px"
              >
                <ButtonCTA text="Meet the team" />
              </Box>
            </Box>
          </Box>
        </Box>
      </FadeInSection>
    </>
  );
}

export default Home;
