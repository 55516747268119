import {
    formatRiskScore,
    getResponseGet
} from "../utils";

const getInsightsOrganizationIndustryRiskScore = async () => {
    let insightsOrganizationIndustryRiskScore = {
        result: {
            industryRiskScore: 0
        },
        error: {}
    };

    try {
        const response = await getResponseGet("/organization_data/?component=industry_risk_score");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        insightsOrganizationIndustryRiskScore.result = {
            industryRiskScore: formatRiskScore(data.industry_risk_score),
        };
    } catch (error) {
        insightsOrganizationIndustryRiskScore.error = error;
    }

    return insightsOrganizationIndustryRiskScore;
};

export default getInsightsOrganizationIndustryRiskScore;