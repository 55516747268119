import React, {
    useState
} from "react";

import {
    Box,
    Card,
    CardContent,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";

import SankeyChart from "./utils/sankey-chart/sankey-chart";
import UsersDialog from "./utils/users-dialog/users-dialog";

import { ButtonTooltip } from "../../common";

import "./card-interactions.css";

function CardInteractions({
    interactions,
    usersInteraction,
    selectedDays,
    onClickInteraction,
    email,
    tooltip
}) {
    // Users modal
    const [openDialog, setOpenModal] = useState(false);
    const [dialogHeader, setDialogHeader] = useState("");

    const handleClickInteraction = async (interaction) => {
        if (interaction === "engaged") {
            setDialogHeader("List of users who engaged with social engineering");
        } else if (interaction === "smtp_reply") {
            setDialogHeader("List of users who responded with social engineering");
        } else if (interaction === "phished") {
            setDialogHeader("List of users who clicked on social engineering");
        } else if (interaction === "key_down") {
            setDialogHeader("List of users who entered credentials");
        } else if (interaction === "data_entered") {
            setDialogHeader("List of users who submitted credentials");
        } else if (interaction === "no_action") {
            setDialogHeader("List of users who did not engage");
        } else if (interaction === "watchtower_reported") {
            setDialogHeader("List of users who reported to Watchtower");
        }

        setOpenModal(true);
        await onClickInteraction(interaction, selectedDays);
    };

    const handleCloseDialog = () => {
        setOpenModal(false)
    };

    return (
        <>
            <Card className="dune-card-dashboard card-interactions-card">
                <CardContent className="card-interactions-card-content">
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                        <Box display="flex" alignItems="center" width="100%" padding="28px 0 40px 28px">
                            <Typography component="h3" className="dune-text-header-card-dashboard">
                                User interactions
                            </Typography>
                        </Box>
                        <ButtonTooltip
                            tooltip={tooltip}
                            ariaLabel={tooltip}
                        />
                    </Stack>
                    <Box
                        display="flex"
                        flexDirection="column"
                        padding="0 28px"
                        height="100%"
                    >
                        {
                            (interactions
                                && interactions.totalInteractions !== null && interactions.totalInteractions !== undefined
                                && interactions.totalInteractionsEngaged !== null && interactions.totalInteractionsEngaged !== undefined
                                && interactions.totalInteractionsResponded !== null && interactions.totalInteractionsResponded !== undefined
                                && interactions.totalInteractionsClicked !== null && interactions.totalInteractionsClicked !== undefined
                                && interactions.totalInteractionsKeyDown !== null && interactions.totalInteractionsKeyDown !== undefined
                                && interactions.totalInteractionsDataEntered !== null && interactions.totalInteractionsDataEntered !== undefined
                                && interactions.totalInteractionsNoEngagement !== null && interactions.totalInteractionsNoEngagement !== undefined
                                && interactions.totalInteractionsReported !== null && interactions.totalInteractionsReported !== undefined) ?
                                <Stack
                                    direction={{
                                        sm: "column",
                                        lg: "row"
                                    }}
                                >
                                    {/* <Box>
                                        <Stack direction="column" justifyContent="space-between" height="100%">
                                            <Typography component="p">{interactions.totalInteractions}</Typography>
                                            <Typography component="p">total interactions</Typography>
                                        </Stack>
                                    </Box> */}
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        width="100%"
                                    >
                                        <Box width="100%">
                                            <SankeyChart
                                                totalInteractionsResponded={interactions.totalInteractionsResponded}
                                                totalInteractionsClicked={interactions.totalInteractionsClicked}
                                                totalInteractionsKeyDown={interactions.totalInteractionsKeyDown}
                                                totalInteractionsDataEntered={interactions.totalInteractionsDataEntered}
                                                totalInteractionsNoEngagement={interactions.totalInteractionsNoEngagement}
                                                totalInteractionsReported={interactions.totalInteractionsReported}
                                                onClickInteraction={handleClickInteraction}
                                            />
                                        </Box>
                                    </Box>
                                </Stack>
                                :
                                <Box display="flex" flexDirection="column" height="100%">
                                    <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                                </Box>
                        }
                    </Box>
                    {
                        interactions && interactions.totalInteractions !== null && interactions.totalInteractions !== undefined &&
                        <Typography
                            component="p"
                            className="card-interactions-text"
                            textAlign="end"
                            marginTop="8px"
                            padding="0 28px 0 0"
                        >
                            {interactions.totalInteractions} total interaction{interactions.totalInteractions !== 1 ? "s" : ""}
                        </Typography>
                    }
                    <Typography
                        component="p"
                        className="card-interactions-text-tip"
                        marginTop="20px"
                        textAlign="center"
                    >
                        Select a number to see a list of the users who completed that interaction.
                    </Typography>
                </CardContent>
            </Card>
            <UsersDialog
                users={usersInteraction}
                header={dialogHeader}
                openDialog={openDialog}
                email={email}
                onCloseDialog={handleCloseDialog}
            />
        </>
    )
}

export default CardInteractions;