import React, { useEffect, useState } from "react";

import {
    Box,
    Typography
} from "@mui/material";

import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <Box className="line-chart-card-tooltip">
                <Typography className="line-chart-card-tooltip-text">Score: {payload[0].value}</Typography>
                <Typography className="line-chart-card-tooltip-text">{label}</Typography>
            </Box>
        );
    }

    return null;
};

const CustomYAxisTick = ({ x, y, payload }) => {
    if (x !== null && y !== null && payload.value !== null) {
        return (
            <g transform={`translate(0, ${y + 4})`}>
                <text
                    x={0}
                    y={0}
                    fill="#C2BFB8"
                    fontSize="16px"
                    fontWeight="400"
                    textAnchor="start"
                >
                    {payload.value}
                </text>
            </g>
        );
    }
    return null;
};

function RiskScoreLineChart({
    riskScoreOverTime,
    color
}) {
    const [lineChartData, setLineChartData] = useState([]);

    const getXAxisTicks = () => {
        if (lineChartData.length === 0) {
            return [];
        }

        let ticks = [lineChartData[0].date];

        for (let i = 1; i < riskScoreOverTime.length; i++) {
            const matches = riskScoreOverTime[i].date.match(/\d+/)
            const date = matches ? parseInt(matches[0], 10) : null;

            if (date && date === 1) {
                ticks.push(riskScoreOverTime[i].date);
            }
        }

        return ticks;
    };

    useEffect(() => {
        if (riskScoreOverTime && lineChartData !== riskScoreOverTime) {
            setLineChartData(riskScoreOverTime);
        }
    }, [riskScoreOverTime, lineChartData]);

    return (
        <ResponsiveContainer width="100%" height="100%" minHeight="352px">
            <LineChart data={lineChartData}>
                <CartesianGrid
                    stroke="#232221"
                    vertical={false}
                />
                <XAxis
                    dataKey="date"
                    axisLine={false}
                    stroke="#C2BFB8"
                    tick={{
                        fontSize: "16px",
                        fontWeight: "400"
                    }}
                    tickLine={false}
                    ticks={getXAxisTicks()}
                    tickFormatter={(value) => {
                        const date = new Date(value + ", 2020");

                        return date.toLocaleString("en-US", {
                            month: "short"
                        });
                    }}
                />
                <YAxis
                    axisLine={false}
                    domain={[0, 100]}
                    tick={<CustomYAxisTick />}
                    tickLine={false}
                    tickCount={6}
                />
                <Tooltip
                    content={<CustomTooltip />}
                    cursor={{
                        stroke: color,
                        strokeDasharray: 8
                    }}
                />
                <Line
                    type="monotone"
                    dataKey="riskScore"
                    stroke={color}
                    strokeWidth="1.5px"
                    dot={false}
                    activeDot={{
                        stroke: color,
                        r: 5
                    }}
                    animationDuration={1000}
                    animationEasing="ease-in-out"
                />
            </LineChart>
        </ResponsiveContainer>
    );
}

export default RiskScoreLineChart;