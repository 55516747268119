import React, { useEffect, useState } from "react";

import { Dashboard } from "../../../components";

import { pagesPlatformManager } from "../../../constants";

function InsightsDirectReports({ isInitialLoading, email }) {
  // Insights
  const [riskScore, setRiskScore] = useState(null);
  const [comparedRiskScore, setComparedRiskScore] = useState(null);
  const [complianceFrameworks, setComplianceFrameworks] = useState(null);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    const loadInsightsYourReports = async () => {
      setRiskScore(42);

      setComparedRiskScore(46);

      setComplianceFrameworks([
        {
          name: "SOC 2 Type 2",
          progress: 0.75,
        },
        {
          name: "HIPAA",
          progress: 0.75,
        },
        {
          name: "ISO 27001",
          progress: 0.75,
        },
      ]);

      setUsers([
        {
          firstName: "Aaron",
          lastName: "Chavez (You)",
          email: "aaron.chavez@dune.demo",
          department: "Capital",
          role: "Chief Financial Officer",
          compliant: true,
          complianceFrameworks: [],
          riskScore: 14,
        },
        {
          firstName: "Lisa",
          lastName: "MD",
          email: "lisa.md@dune.demo",
          department: "Capital",
          role: "Head of ESG & Impact",
          compliant: true,
          complianceFrameworks: [],
          riskScore: 14,
        },
        {
          firstName: "Kyle",
          lastName: "Walker",
          email: "kyle.walker@dune.demo",
          department: "Capital",
          role: "Capital Manager",
          compliant: true,
          complianceFrameworks: [],
          riskScore: 43,
        },
        {
          firstName: "Duncan",
          lastName: "Idaho",
          email: "duncan.idaho@dune.demo",
          department: "Capital",
          role: "Capital Director",
          compliant: false,
          complianceFrameworks: [],
          riskScore: 97,
        },
      ]);
    };

    if (!isInitialLoading && email) {
      loadInsightsYourReports();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoading, email]);

  return (
    <Dashboard
      pageTitle={pagesPlatformManager.YOUR_REPORTS}
      riskScore={riskScore}
      comparedRiskScore={comparedRiskScore}
      complianceFrameworks={complianceFrameworks}
      users={users}
    />
  );
}

export default InsightsDirectReports;
