import React, { cloneElement, useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import {
  Box,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Circle } from "@mui/icons-material";

import { useDescope, useSession, useUser } from "@descope/react-sdk";

import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

import {
  getCurrentMSSPOrganization,
  getMSSPOrganizations,
  getUser,
} from "../../../api";

import { NavigationPlatformSide, NavigationPlatformTop } from "../../layout";

import { spacing } from "../../../styles";

import "./platform-container.css";

function PlatformContainer({
  title,
  mainPage,
  tracker,
  onFinishInitialLoading,
}) {
  const theme = useTheme();
  let isScreenDesktop = useMediaQuery(theme.breakpoints.up("md"));

  // Loading
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingLogOut, setIsLoadingLogOut] = useState(false);

  // Messages (error and success)
  const [messageError, setMessageError] = useState("");

  // Cognito user access
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperadmin, setIsSuperadmin] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  // User
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [initials, setInitials] = useState("");
  const [signedInEmail, setSignedInEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [isInCampaign, setIsInCampaign] = useState(false);
  const [hasBRAStarted, setHasBRAStarted] = useState(false);
  const [isBRACompleted, setIsBRACompleted] = useState(false);
  const [startTimestamp, setStartTimestamp] = useState(null);
  const [msspOrganizations, setMSSPOrganizations] = useState([]);
  const [selectedMSSPOrganization, setSelectedMSSPOrganization] = useState({});

  const [email, setEmail] = useState("");

  // Other
  const [pagePath, setPagePath] = useState([]);

  const { isAuthenticated, isSessionLoading } = useSession();
  const { user, isUserLoading } = useUser();
  const { logout } = useDescope();

  const getPagePath = () => {
    let pathname = window.location.pathname;

    if (pathname.charAt(pathname.length - 1) === "/") {
      pathname = pathname.substring(0, pathname.length - 1);
    }

    const pagePath = pathname.substring(1).split("/");

    return pagePath;
  };

  const handleClickLogOut = async () => {
    logOut();
  };

  const handleClickMSSPOrganization = async (msspOrganization) => {
    setSelectedMSSPOrganization(msspOrganization);

    if (email !== "david@dune.demo") {
      const currentMSSPOrganizationData = await getCurrentMSSPOrganization(
        msspOrganization.id
      );

      if (Object.keys(currentMSSPOrganizationData.error).length > 0) {
        console.error(currentMSSPOrganizationData.error.message);
      } else {
        setIsLoading(true);

        if (onFinishInitialLoading) {
          onFinishInitialLoading({
            isInitialLoading: true,
            email,
            isInCampaign,
          });
        }

        loadUserInfo().finally(() => {
          setIsLoading(false);

          if (onFinishInitialLoading) {
            onFinishInitialLoading({
              isInitialLoading: false,
              email,
              isInCampaign,
            });
          }
        });
      }
    }
  };

  const logOut = useCallback(async () => {
    setIsLoadingLogOut(true);
    await logout();
    setIsLoadingLogOut(false);
  }, [logout]);

  const loadDescopeUser = useCallback(async () => {
    const { email, roleNames, userTenants } = user;

    setSignedInEmail(email);

    let tenantRoles = [];

    if (userTenants && userTenants.length > 0 && userTenants[0].roleNames) {
      tenantRoles = userTenants[0].roleNames;
    }

    if (
      roleNames.includes("Superadmin") ||
      tenantRoles.includes("Superadmin")
    ) {
      setIsSuperadmin(true);
    }

    if (roleNames.includes("Admin") || tenantRoles.includes("Admin")) {
      setIsAdmin(true);
    }

    if (roleNames.includes("Manager") || tenantRoles.includes("Manager")) {
      setIsManager(true);
    }

    // Set the user ID for OpenReplay.
    if (tracker) {
      tracker.setUserID(email);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracker, isUserLoading, user]);

  const loadUserInfo = useCallback(async () => {
    const userInfo = await getUser();

    if (Object.keys(userInfo.error).length > 0) {
      setMessageError(userInfo.error.message);

      // Log out if the user is not in the database.
      await logOut();
    } else {
      const {
        firstName,
        lastName,
        email,
        initials,
        organization,
        isInCampaign,
        hasBRAStarted,
        isBRACompleted,
        startTimestamp,
      } = userInfo.result;

      setFirstName(firstName);
      setLastName(lastName);
      setInitials(initials);
      setOrganization(organization);
      setIsInCampaign(isInCampaign);
      setHasBRAStarted(hasBRAStarted);
      setIsBRACompleted(isBRACompleted);
      setStartTimestamp(startTimestamp);

      setEmail(email);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logOut, isUserLoading, user]);

  useEffect(() => {
    const updateComponent = async () => {
      // Remove isFederatedSignIn from localStorage.
      const isFederatedSignIn = localStorage.getItem("isFederatedSignIn");

      if (isFederatedSignIn) {
        localStorage.removeItem("isFederatedSignIn");
      }

      // Update page path if the main page has been updated.
      setPagePath(getPagePath());
    };

    updateComponent();
  }, [mainPage]);

  useEffect(() => {
    const loadMSSPOrganizations = async () => {
      if (email === "david@dune.demo") {
        // Demo account
        setMSSPOrganizations([
          {
            id: "0",
            name: "Dune Security",
            email: "david@dune.demo",
          },
          {
            id: "1",
            name: "Apple",
            email: "david@dune.demo",
          },
          {
            id: "2",
            name: "Amazon",
            email: "david@dune.demo",
          },
          {
            id: "3",
            name: "Google",
            email: "david@dune.demo",
          },
          {
            id: "4",
            name: "Microsoft",
            email: "david@dune.demo",
          },
          {
            id: "5",
            name: "Nvidia",
            email: "david@dune.demo",
          },
        ]);
      } else {
        const msspOrganizationsData = await getMSSPOrganizations();

        if (Object.keys(msspOrganizationsData.error).length > 0) {
          console.error(msspOrganizationsData.error.message);
        } else {
          const { msspOrganizations } = msspOrganizationsData.result;

          setMSSPOrganizations(msspOrganizations);
        }
      }
    };

    const loadFingerprintJS = async () => {
      const fpPromise = FingerprintJS.load({
        apiKey: process.env.REACT_APP_FINGERPRINTJS_KEY,
      });

      fpPromise
        .then((fp) =>
          fp.get({
            linkedId: email,
          })
        )
        .catch((error) => {
          console.error("Error tagging user with FingerprintJS:", error);
        });
    };

    if (!isLoadingLogOut) {
      if (!isSessionLoading && !isAuthenticated) {
        setIsLoggedIn(false);
      } else if (!isSessionLoading && !isUserLoading) {
        setIsLoading(true);

        loadDescopeUser().finally(() => {
          loadMSSPOrganizations().finally(() => {
            if (email === "david@dune.demo") {
              setSelectedMSSPOrganization({
                id: "0",
                name: "Dune Security",
                email: "david@dune.demo",
              });
            } else {
              const filteredMSSPOrganizationsByEmail = msspOrganizations.filter(
                (organization) => organization.email === email
              );

              if (filteredMSSPOrganizationsByEmail.length > 0) {
                setSelectedMSSPOrganization(
                  filteredMSSPOrganizationsByEmail[0]
                );
              } else if (msspOrganizations.length > 0) {
                setSelectedMSSPOrganization(msspOrganizations[0]);
              }
            }

            loadUserInfo().finally(() => {
              setIsLoading(false);

              loadFingerprintJS();

              if (onFinishInitialLoading) {
                onFinishInitialLoading({
                  isInitialLoading: false,
                  email,
                  isInCampaign,
                });
              }
            });
          });
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    isSessionLoading,
    isUserLoading,
    loadDescopeUser,
    loadUserInfo,
    onFinishInitialLoading,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pagePath]);

  if (!isLoggedIn) {
    if (messageError) {
      return <Navigate to="/error/" />;
    }

    return <Navigate to="/login/" />;
  }

  const routesWelcome = [
    "/welcome/",
    "/sso-settings/",
    "/compliance-onboarding/",
    "/employees/",
    "/campaigns/",
  ];

  if (
    !isLoading &&
    (isSuperadmin || isAdmin) &&
    !isBRACompleted &&
    !routesWelcome.includes(window.location.pathname)
  ) {
    return <Navigate to="/welcome/" />;
  }

  // List of pages only admins/superadmins are able to see
  const routesAdmin = [
    "/insights/organization/",
    "/insights/departments/",
    "/insights/users/",
    "/insights/search-user/",
    "/dashboard/insights/",
    "/dashboard/trainings/",
    "/dashboard/trainings/learning/",
    "/dashboard/trainings/compliance/",
    "/dashboard/trainings/completed/",
    "/compliance/",
    "/reporting/",
    "/configurations/",
    "/configurations/test-frequency/",
    "/welcome/",
    "/sso-settings/",
    "/compliance-onboarding/",
    "/employees/",
    "/campaigns/",
    "/settings/dune-nudge/",
  ];

  // List of pages only managers are able to see
  const routesManager = [
    "/insights/your-reports/",
    "/insights/users/",
    "/dashboard/insights/",
    "/dashboard/trainings/",
    "/dashboard/trainings/learning/",
    "/dashboard/trainings/compliance/",
    "/dashboard/trainings/completed/",
  ];

  const routesEndUser = [
    "/dashboard/insights/",
    "/dashboard/trainings/",
    "/dashboard/trainings/learning/",
    "/dashboard/trainings/compliance/",
    "/dashboard/trainings/completed/",
  ];

  if (
    !isLoading &&
    ((!isSuperadmin &&
      !isAdmin &&
      !isManager &&
      !routesEndUser.includes(window.location.pathname)) ||
      ((isSuperadmin || isAdmin) &&
        !routesAdmin.includes(window.location.pathname)) ||
      (isManager && !routesManager.includes(window.location.pathname)))
  ) {
    return <Navigate to="/error/" />;
  }

  const routesNotAllowedAfterBRA = [
    // Pages should not be accessible after the BRA is completed.
    "/welcome/",
    "/compliance-onboarding/",
    "/employees/",
    "/campaigns/",
  ];

  if (
    isBRACompleted &&
    routesNotAllowedAfterBRA.includes(window.location.pathname)
  ) {
    return (
      <Navigate
        to={
          isSuperadmin || isAdmin
            ? "/insights/organization/"
            : isManager
            ? "/insights/your-reports/"
            : "/dashboard/insights/"
        }
      />
    );
  }

  return (
    <Box width="100%" maxWidth="100vw">
      <Box display="flex" minHeight="100vh">
        {isScreenDesktop && (
          <Box
            className="platform-container-box-navigation-side"
            maxWidth="224px"
            width="100%"
            padding="0 30px"
          >
            <NavigationPlatformSide
              email={email}
              isBRACompleted={isBRACompleted}
              isAdmin={isSuperadmin || isAdmin}
              isManager={isManager}
              pagePath={pagePath}
            />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          minWidth={0}
          role="main"
        >
          <Box width="100%" className="platform-container-box-navigation-top">
            <Box>
              <NavigationPlatformTop
                email={signedInEmail}
                firstName={firstName}
                initials={initials}
                lastName={lastName}
                msspOrganizations={msspOrganizations}
                selectedMSSPOrganization={selectedMSSPOrganization}
                onClickMSSPOrganization={handleClickMSSPOrganization}
                onLogOut={handleClickLogOut}
              />
            </Box>
            <Box padding="0 30px 12px">
              <Box
                maxWidth={spacing.MAX_WIDTH_PLATFORM}
                width="100%"
                margin="0 auto"
              >
                <Stack direction="row" alignItems="center">
                  <Typography variant="h2" component="h1">
                    {title}
                  </Typography>
                  <Box marginLeft="8px">
                    <Circle className="platform-container-icon-circle" />
                  </Box>
                </Stack>
              </Box>
            </Box>
            <Divider className="dune-divider" />
          </Box>
          <Box width="100%">
            <Box
              maxWidth={spacing.MAX_WIDTH_PLATFORM}
              margin="0 auto"
              padding="0 30px 40px"
            >
              {cloneElement(mainPage, {
                isInitialLoading: isLoading,
                email,

                pagePath: pagePath,
                firstName: firstName,
                lastName: lastName,
                organization: organization,
                hasBRAStarted: hasBRAStarted, // Check
                isBRACompleted: isBRACompleted,
                isInCampaign: isInCampaign,
                startTimestamp: startTimestamp,
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PlatformContainer;
