import React, {
    useEffect,
    useState
} from "react";

import {
    Box,
    Card,
    CardContent,
    Grid,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";

import RiskScoreComparison from "./utils/risk-score-comparison/risk-score-comparison";
import Speedometer from "./utils/speedometer/speedometer";

import { ButtonTooltip } from "../../common";

import {
    pagesPlatform,
    pagesPlatformManager
} from "../../../constants";

import "./card-risk-score.css";

function CardRiskScore({
    riskScore,
    comparedRiskScore,
    firstName,
    pageTitle,
    tooltip,
    color,
}) {
    const [animatedRiskScore, setAnimatedRiskScore] = useState(0);

    const renderDescription = () => {
        if (!(riskScore && comparedRiskScore && pageTitle)) {
            return <></>;
        }

        const difference = comparedRiskScore - riskScore;

        if (pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION) {
            if (difference === 0) {
                return (
                    <Typography component="p" className="card-risk-score-text-description-comparison">
                        Your organization's risk score is the same as your industry average.
                    </Typography>
                );
            }

            return (
                <Typography component="p" className="card-risk-score-text-description-comparison">
                    Your organization's risk score is {Math.abs(difference)} {difference === 1 ? "point" : "points"} {difference < 0 ? <Typography component="span" className="card-risk-score-text-description-comparison-higher">higher</Typography> : <Typography component="span" className="card-risk-score-text-description-comparison-lower">lower</Typography>} than your industry average.
                </Typography>
            );
        }

        if (pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS) {
            if (difference === 0) {
                return (
                    <Typography component="p" className="card-risk-score-text-description-comparison">
                        This department's risk score is the same as the departmental average.
                    </Typography>
                );
            }

            return (
                <Typography component="p" className="card-risk-score-text-description-comparison">
                    This department's risk score is {Math.abs(difference)} {difference === 1 ? "point" : "points"} {difference < 0 ? <Typography component="span" className="card-risk-score-text-description-comparison-higher">higher</Typography> : <Typography component="span" className="card-risk-score-text-description-comparison-lower">lower</Typography>} than the departmental average.
                </Typography>
            );
        }

        if (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS) {
            if (difference === 0) {
                return (
                    <Typography component="p" className="card-risk-score-text-description-comparison">
                        {firstName ? `${firstName}'s` : "This user's"} risk score is the same as the average user.
                    </Typography>
                );
            }

            return (
                <Typography component="p" className="card-risk-score-text-description-comparison">
                    {firstName ? `${firstName}'s` : "This user's"} risk score is {Math.abs(difference)} {difference === 1 ? "point" : "points"} {difference < 0 ? <Typography component="span" className="card-risk-score-text-description-comparison-higher">higher</Typography> : <Typography component="span" className="card-risk-score-text-description-comparison-lower">lower</Typography>} than the average user.
                </Typography>
            );
        }

        if (pageTitle === pagesPlatformManager.YOUR_REPORTS) {
            if (difference === 0) {
                return (
                    <Typography component="p" className="card-risk-score-text-description-comparison">
                        Your reports' risk score is the same as your organization risk score.
                    </Typography>
                );
            }

            return (
                <Typography component="p" className="card-risk-score-text-description-comparison">
                    Your reports' risk score is {Math.abs(difference)} {difference === 1 ? "point" : "points"} {difference < 0 ? <Typography component="span" className="card-risk-score-text-description-comparison-higher">higher</Typography> : <Typography component="span" className="card-risk-score-text-description-comparison-lower">lower</Typography>} than your organization risk score.
                </Typography>
            );
        }

        if (difference === 0) {
            return (
                <Typography component="p" className="card-risk-score-text-description-comparison">
                    Your risk score is the same as the average user.
                </Typography>
            );
        }

        return (
            <Typography component="p" className="card-risk-score-text-description-comparison">
                Your risk score is {Math.abs(difference)} {difference === 1 ? "point" : "points"} {difference < 0 ? <Typography component="span" className="card-risk-score-text-description-comparison-higher">higher</Typography> : <Typography component="span" className="card-risk-score-text-description-comparison-lower">lower</Typography>} than the average user.
            </Typography>
        );
    };

    useEffect(() => {
        if (riskScore) {
            let start = 0;
            const duration = 1000;
            const increment = riskScore / (duration / 50);

            const interval = setInterval(() => {
                start += increment;

                if (start >= riskScore) {
                    setAnimatedRiskScore(riskScore);
                    clearInterval(interval);
                } else {
                    setAnimatedRiskScore(start);
                }
            }, 50);

            return () => clearInterval(interval);
        }
    }, [riskScore]);

    return (
        <Card className="dune-card-dashboard card-risk-score-card">
            <CardContent className="card-risk-score-card-content">
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" height="100%">
                    <Box width="100%" padding="28px 0 0 28px" height="100%">
                        <Grid container height="100%">
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                paddingRight={{
                                    xs: 0,
                                    sm: "30px"
                                }}
                            >
                                <Typography component="h3" className="dune-text-header-card-dashboard">
                                    Risk score
                                </Typography>
                                <Box marginTop="40px">
                                    <Speedometer riskScore={riskScore} />
                                </Box>
                                <Box display="flex" justifyContent="center" marginTop="-12px">
                                    <Typography
                                        component="p"
                                        className="card-risk-score-text-risk-score"
                                    >
                                        {Math.round(animatedRiskScore)}
                                    </Typography>
                                </Box>
                                {
                                    riskScore &&
                                    <Box display="flex" justifyContent="center">
                                        <Box
                                            className="card-risk-score-box-risk-level"
                                            sx={{
                                                background: riskScore > 75 ? "#FF2B00"
                                                    : (riskScore > 50 ? "#FFA100"
                                                        : (riskScore > 25 ? "#FDFF00"
                                                            : "#00FF82"))
                                            }}
                                        >
                                            <Typography component="p" className="card-risk-score-text-risk-level">
                                                {
                                                    riskScore > 75 ? "Severe risk"
                                                        : (riskScore > 50 ? "High risk"
                                                            : (riskScore > 25 ? "Moderate risk"
                                                                : "Low risk"))
                                                }
                                            </Typography>
                                        </Box>
                                    </Box>
                                }
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                marginTop={{
                                    xs: "60px",
                                    sm: 0
                                }}
                                paddingLeft={{
                                    xs: 0,
                                    sm: "30px"
                                }}
                            >
                                <Typography component="h3" className="dune-text-header-card-dashboard">
                                    Risk score comparison
                                </Typography>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    marginTop="20px"
                                    height="100%"
                                >
                                    <Box>
                                        {
                                            (riskScore && comparedRiskScore) ?
                                                <Typography component="p" className="card-risk-score-text-description-comparison">
                                                    {renderDescription()}
                                                </Typography>
                                                :
                                                <Box display="flex" flexDirection="column" height="42px">
                                                    <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                                                </Box>
                                        }
                                    </Box>
                                    <Box marginTop="28px">
                                        <RiskScoreComparison
                                            riskScore={riskScore}
                                            comparedRiskScore={comparedRiskScore}
                                            firstName={firstName}
                                            pageTitle={pageTitle}
                                            color={color}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <ButtonTooltip
                        tooltip={tooltip}
                        ariaLabel={tooltip}
                    />
                </Stack>
            </CardContent>
        </Card>
    );
}

export default CardRiskScore;