import React, {
    useState
} from "react";

import {
    Box,
    Card,
    CardContent,
    Grid,
    Popover,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";

import { ButtonTooltip } from "../../common";

import { pagesPlatformEndUser } from "../../../constants";

import "./card-areas-to-improve.css";

function CardAreasToImprove({
    areasToImprove,
    pageTitle,
    tooltip,
    color
}) {
    // Popover
    const [anchorElPopover, setAnchorElPopover] = useState(null);
    const [selectedArea, setSelectedArea] = useState({
        name: ""
    });

    // Overviews
    const overviewsNonUser = {
        "Link manipulation": "Monitor for altered URLs mimicking legitimate domains, a tactic used to phish credentials or install malware.",
        "C-suite impersonation": "Implement filters and training to catch emails where attackers impersonate executives, often for fraudulent financial requests.",
        "Impersonation of internal processes": "Validate communication authenticity, especially for requests seeming to come from internal roles like IT or HR, to prevent data breaches.",
        "Display name deception": "Enforce email authentication protocols to identify and block senders spoofing known contacts' names.",
        "Request for action": "Educate staff to recognize and report phishing attempts that urge immediate action or information verification on dubious websites.",
        "Unexpected financial transactions": "Set up alerts and verification processes for unusual financial transactions to prevent unauthorized financial dealings.",
        "Fake technical notifications": "Warn about and filter out fake technical alerts, often used to trick employees into downloading malware or revealing sensitive info.",
        "Pretexting": "Train staff to detect and report pretexting attempts where attackers fabricate scenarios to extract organizational information."
    };

    const overviewsUser = {
        "Link manipulation": "Beware of fake web links that mimic real sites to steal your information.",
        "C-suite impersonation": "Scammers pose as company executives to trick you into sharing confidential data.",
        "Impersonation of internal processes": "Be cautious of requests from 'internal' contacts asking for sensitive information; they could be imposters.",
        "Display name deception": "Check emails from familiar names carefully; they might be faked to trick you.",
        "Request for action": "Ignore urgent requests to enter personal details on websites, as they may be phishing scams.",
        "Unexpected financial transactions": "Verify any unexpected financial transaction alerts directly with your bank to avoid scams.",
        "Fake technical notifications": "Distrust alerts about sudden technical problems; they could be attempts to install malware.",
        "Pretexting": "Be skeptical of strangers asking for information based on convincing, yet false, stories."
    };

    const handleClosePopover = () => {
        setAnchorElPopover(null);
    };

    const handleMouseEnter = (event, areaToImprove) => {
        setSelectedArea(areaToImprove);
        setAnchorElPopover(event.currentTarget);
    };

    return (
        <>
            <Card className="dune-card-dashboard card-areas-to-improve-card">
                <CardContent className="card-areas-to-improve-card-content">
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                        <Box width="100%" padding="28px 0 40px 28px">
                            <Typography component="h3" className="dune-text-header-card-dashboard">
                                Areas to improve
                            </Typography>
                        </Box>
                        <ButtonTooltip
                            tooltip={tooltip}
                            ariaLabel={tooltip}
                        />
                    </Stack>
                    <Box
                        display="flex"
                        flexDirection="column"
                        padding="0 28px"
                        height="100%"
                    >
                        {
                            (areasToImprove && Array.isArray(areasToImprove) && color) ?
                                <>
                                    <Grid container>
                                        {
                                            areasToImprove.map((areaToImprove, index) =>
                                                <Grid
                                                    item
                                                    className="card-areas-to-improve-grid-item-area"
                                                    xs={12}
                                                    md={6}
                                                    lg={3}
                                                    marginTop={{
                                                        xs: index >= 1 ? "40px" : 0,
                                                        md: index >= 2 ? "40px" : 0,
                                                        lg: index >= 4 ? "40px" : 0
                                                    }}
                                                    paddingLeft={{
                                                        xs: 0,
                                                        md: index % 2 === 1 ? "30px" : 0,
                                                        lg: index % 4 === 1 ? `${(60 / 4)}px`
                                                            : (index % 4 === 2 ? `${(60 / 4) * 2}px`
                                                                : (index % 4 === 3 ? `${(60 / 4) * 3}px`
                                                                    : 0))
                                                    }}
                                                    paddingRight={{
                                                        xs: 0,
                                                        md: index % 2 === 0 ? "30px" : 0,
                                                        lg: index % 4 === 2 ? `${(60 / 4)}px`
                                                            : (index % 4 === 1 ? `${(60 / 4) * 2}px`
                                                                : (index % 4 === 0 ? `${(60 / 4) * 3}px`
                                                                    : 0))
                                                    }}
                                                    onMouseEnter={(event) => handleMouseEnter(event, areaToImprove)}
                                                    onMouseLeave={handleClosePopover}
                                                    key={`area-to-improve-${index}`}
                                                >
                                                    <Box
                                                        className="card-areas-to-improve-box-number"
                                                        sx={{ color: `${color} !important` }}
                                                    >
                                                        {index + 1}
                                                    </Box>
                                                    <Typography
                                                        component="p"
                                                        className="card-areas-to-improve-text-name"
                                                        marginTop="20px"
                                                    >
                                                        {areaToImprove.name}
                                                    </Typography>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                    <Typography
                                        component="p"
                                        className="card-areas-to-improve-text-tip"
                                        textAlign="center"
                                        marginTop="40px"
                                    >
                                        Hover over each number for more details.
                                    </Typography>
                                </>
                                :
                                <Box display="flex" flexDirection="column" height="100%">
                                    <Skeleton variant="rectangular" className="dune-skeleton-rectangular" width="100%" height="100%" />
                                </Box>
                        }
                    </Box>
                </CardContent>
            </Card>
            <Popover
                className="card-areas-to-improve-popover"
                open={Boolean(anchorElPopover)}
                anchorEl={anchorElPopover}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                disableRestoreFocus
            >
                <Typography component="p" className="card-areas-to-improve-popover-text">
                    {
                        pageTitle === pagesPlatformEndUser.INSIGHTS
                            ? (overviewsUser[selectedArea.name] || selectedArea.name)
                            : (overviewsNonUser[selectedArea.name] || selectedArea.name)
                    }
                </Typography>
            </Popover>
        </>
    );
}

export default CardAreasToImprove;