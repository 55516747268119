import { getResponseGet } from "../utils";

/**
 * Fetches a list of departments.
 * @returns {Object} An object containing the list of departments or an error.
 *   - The 'result' field is an array of departments.
 *   - The 'error' field contains an error object if the request fails.
 */
const getDepartments = async () => {
    let departments = {
        result: [],
        error: {}
    };

    try {
        const response = await getResponseGet("/list_departments/");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        const departmentsData = (data.departments || []).sort(); // Sort the departments in alphabetical order.
        departments.result = departmentsData.map(department => ({ name: department }));
    } catch (error) {
        departments.error = error;
    }

    return departments;
};

export default getDepartments;