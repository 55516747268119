import React, {
    useState
} from "react";

import {
    Box,
    IconButton
} from "@mui/material";
import { PlayArrowOutlined } from "@mui/icons-material";

import "./training-video.css";

function TrainingVideo({
    selectedVideo,
    pathname,
    onEndedVideo
}) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [supposedCurrentTime, setSupportedCurrentTime] = useState(0);

    const getThumbnailFileName = (isCompliance = false) => {
        if (!(selectedVideo && selectedVideo.fileName)) {
            return "";
        }

        let extension = ".jpg"; // Default is .jpg.

        if (module.startDate) {
            const dateUpdate = new Date("2024-06-04T00:00:00");
            const dateModule = new Date(module.startDate);

            if (dateModule < dateUpdate) {
                extension = ".png";
            }
        }

        return `https://d36cattz2ccgpt.cloudfront.net/${!isCompliance ? "general/" : ""}${selectedVideo.fileName.slice(0, -4)}${extension}`;
    };

    const handleClickPlay = () => {
        setIsPlaying(true);

        const video = document.getElementById("training-video-video");
        video.play();
    };

    const handleEndedVideo = async () => {
        onEndedVideo();
    };

    const handlePlayVideo = () => {
        window.addEventListener("blur", () => {
            const video = document.getElementById("training-video-video");

            if (video) {
                video.pause();
            }
        });
    };

    const handleRateChangeVideo = (event) => {
        const maxPlaybackRate = 1.25
        const newRate = event.target.playbackRate;

        if (newRate > maxPlaybackRate) {
            event.target.playbackRate = maxPlaybackRate;
        }
    };

    const handleSeekingVideo = () => {
        const video = document.getElementById("training-video-video");

        const delta = video.currentTime - supposedCurrentTime;

        if (delta > 0 && Math.abs(delta) > 0.01) {
            video.currentTime = supposedCurrentTime;
        }
    };

    const handleTimeUpdateVideo = () => {
        const video = document.getElementById("training-video-video");

        if (!video.seeking) {
            setSupportedCurrentTime(video.currentTime);
        }
    };

    const handleVolumeChangeVideo = () => {
        const video = document.getElementById("training-video-video");

        // The user should not be able to mute the video.
        video.muted = false;
        video.volume = 1;
    };

    return (
        <Box position="relative" width="100%" height="100%">
            {
                !isPlaying && (
                    <Box
                        className="training-video-box-thumbnail-main"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="absolute"
                        top={0}
                        left={0}
                        height="100%"
                        width="100%"
                        zIndex={1}
                        aria-label="Play the video."
                        title="Play the video."
                        style={{
                            backgroundImage: `url(${getThumbnailFileName()})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                        }}
                        onClick={handleClickPlay}
                    >
                        <Box
                            className="training-video-box-thumbnail-background"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            width="100%"
                        >
                            <IconButton
                                className="training-video-icon-button-play"
                                aria-label="Play the video."
                                title="Play the video."
                            >
                                <PlayArrowOutlined className="training-video-icon-play" />
                            </IconButton>
                        </Box>
                    </Box>
                )
            }
            <video
                id="training-video-video"
                width="100%"
                height="100%"
                controls
                onEnded={handleEndedVideo}
                onSeeking={handleSeekingVideo}
                onTimeUpdate={handleTimeUpdateVideo}
                onVolumeChange={handleVolumeChangeVideo}
                onPlay={handlePlayVideo}
                onRateChange={handleRateChangeVideo}
                controlsList="nodownload"
                disablePictureInPicture
                key={selectedVideo.id}
                style={{ display: isPlaying ? "block" : "none" }}
            >
                <source
                    src={
                        pathname === "/dashboard/trainings/compliance/"
                            ? `https://d2v6yf73kbwpa3.cloudfront.net/${selectedVideo.fileName}`
                            : `https://d2v6yf73kbwpa3.cloudfront.net/general/${selectedVideo.fileName}`
                    }
                    type="video/mp4"
                />
                Your browser does not support this video.
            </video>
        </Box>
    );
}

export default TrainingVideo;