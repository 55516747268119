import {
    CampaignOutlined,
    DonutLargeOutlined,
    HomeOutlined,
    PeopleAltOutlined,
    SettingsOutlined
} from "@mui/icons-material";

const pagesPlatformBeforeBRA = {
    WELCOME: {
        name: "Welcome",
        path: "/welcome/",
        icon: <HomeOutlined />,
        tooltip: "Go to the compliance page."
    },
    SSO_SETTINGS: {
        name: "SSO settings",
        path: "/sso-settings/",
        icon: <SettingsOutlined />,
        tooltip: "Go to the SSO settings page."
    },
    COMPLIANCE: {
        name: "Compliance",
        path: "/compliance-onboarding/",
        icon: <DonutLargeOutlined />,
        tooltip: "Go to the compliance page."
    },
    EMPLOYEES: {
        name: "Employees",
        path: "/employees/",
        icon: <PeopleAltOutlined />,
        tooltip: "Go to the employees page."
    },
    CAMPAIGNS: {
        name: "Campaigns",
        path: "/campaigns/",
        icon: <CampaignOutlined />,
        tooltip: "Go to the campaign page."
    }
};

export default pagesPlatformBeforeBRA;