import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Grid, Stack, Typography } from "@mui/material";

import "./welcome.css";

function Welcome({ isInitialLoading, email }) {
  const navigate = useNavigate();

  const handleClickStep = (path) => {
    navigate(path);
  };

  const steps = [
    {
      header: "Review SSO settings",
      description:
        "Copy the application and IDP details into our platform to get started.",
      path: "/sso-settings/",
    },
    {
      header: "Choose compliance frameworks",
      description:
        "Choose the compliance standards that match your organization.",
      path: "/compliance-onboarding/",
    },
    {
      header: "Provision employees",
      description:
        "Add your employees to the system to ensure a personalized defense strategy.",
      path: "/employees/",
    },
    {
      header: "Launch baseline risk assessment",
      description:
        "Kickstart protection by identifying high-risk employees and their vulnerabilities. ",
      path: "/campaigns/",
    },
  ];

  useEffect(() => {
    const updateComponent = async () => {};

    if (!isInitialLoading && email) {
      updateComponent();
    }
  }, [isInitialLoading, email]);

  return (
    <Grid
      container
      columnSpacing={{
        xs: 0,
        md: 3,
      }}
    >
      <Grid item xs={12} marginTop="80px">
        <Typography component="h2" className="dune-text-header-dashboard">
          Let's set up your security
        </Typography>
        <Typography component="p" marginTop="20px">
          Complete these three steps to get your company protected.
        </Typography>
      </Grid>
      <Grid item xs={12} marginTop="80px">
        {steps.map((step, index) => (
          <Button
            variant="contained"
            className="dune-button-contained-gray welcome-button"
            fullWidth
            onClick={() => handleClickStep(step.path)}
            key={`step-${index}`}
          >
            <Stack direction="row" alignItems="center">
              <Box className="welcome-box-step-number">
                <Typography component="p" className="welcome-text-step-number">
                  {index + 1}
                </Typography>
              </Box>
              <Box>
                <Typography component="h3" className="welcome-text-header-step">
                  {step.header}
                </Typography>
                <Typography component="p" marginTop="20px">
                  {step.description}
                </Typography>
              </Box>
            </Stack>
          </Button>
        ))}
      </Grid>
    </Grid>
  );
}

export default Welcome;
