import React from 'react';

const BasicUserDataLogo = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 28 20" fill="none">
      <path
        d="M20.0003 10.1722C21.8403 10.1722 23.3203 8.67887 23.3203 6.83887C23.3203 4.99887 21.8403 3.50553 20.0003 3.50553C19.1163 3.50553 18.2684 3.85672 17.6433 4.48184C17.0182 5.10697 16.667 5.95481 16.667 6.83887C16.667 7.72292 17.0182 8.57077 17.6433 9.19589C18.2684 9.82101 19.1163 10.1722 20.0003 10.1722ZM10.0003 8.83887C12.2137 8.83887 13.987 7.0522 13.987 4.83887C13.987 2.62553 12.2137 0.838867 10.0003 0.838867C7.78699 0.838867 6.00033 2.62553 6.00033 4.83887C6.00033 7.0522 7.78699 8.83887 10.0003 8.83887ZM20.0003 12.8389C17.5603 12.8389 12.667 14.0655 12.667 16.5055V18.1722C12.667 18.9055 13.267 19.5055 14.0003 19.5055H26.0003C26.7337 19.5055 27.3337 18.9055 27.3337 18.1722V16.5055C27.3337 14.0655 22.4403 12.8389 20.0003 12.8389ZM10.0003 11.5055C6.89366 11.5055 0.666992 13.0655 0.666992 16.1722V18.1722C0.666992 18.9055 1.26699 19.5055 2.00033 19.5055H10.0003V16.5055C10.0003 15.3722 10.4403 13.3855 13.1603 11.8789C12.0003 11.6389 10.8803 11.5055 10.0003 11.5055Z"
        fill="#00FF82"
      />
    </svg>
  );
};

export default BasicUserDataLogo;
