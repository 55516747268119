import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";

import {
  fetchComplianceFrameworks,
  postComplianceFrameworks,
} from "../../../constants/apis";

import "./compliance-onboarding.css";

function ComplianceOnboarding({ isInitialLoading, email }) {
  // Adding compliance frameworks
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isSelectNone, setIsSelectNone] = useState(false);
  const [selectedComplianceFramework, setSelectedComplianceFramework] =
    useState(""); // Used for viewing data for a specific compliance framework.

  // Onboarding flow
  const [isReadyForNextStep, setIsReadyForNextStep] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState("");

  // Other
  const [currentComplianceFrameworks, setCurrentComplianceFrameworks] =
    useState([]);
  const [selectedComplianceFrameworks, setSelectedComplianceFrameworks] =
    useState([]); // Used for adding new compliance frameworks.

  const complianceFrameworks = [
    "GDPR",
    "GLBA",
    "HIPAA",
    "ISO27001",
    "NIST",
    "PCI DSS",
    "SOC 2 Type 2",
  ];

  const handleClickComplianceFramework = (framework) => {
    const updatedSelectedComplianceFrameworks = [
      ...selectedComplianceFrameworks,
    ];

    if (selectedComplianceFrameworks.includes(framework)) {
      updatedSelectedComplianceFrameworks.splice(
        selectedComplianceFrameworks.indexOf(framework),
        1
      );

      if (updatedSelectedComplianceFrameworks.length === 0) {
        setIsSelectNone(true);
      }
      if (isSelectAll) {
        setIsSelectAll(false);
      }
    } else {
      updatedSelectedComplianceFrameworks.push(framework);

      setIsSelectNone(false);

      if (
        updatedSelectedComplianceFrameworks.length ===
        complianceFrameworks.length
      ) {
        setIsSelectAll(true);
      }
    }

    setSelectedComplianceFrameworks([...updatedSelectedComplianceFrameworks]);
    console.log(selectedComplianceFramework);
  };

  const handleClickSave = async () => {
    if (email !== "info@dunesecurity.io") {
      // Demo account for onboarding
      const requestBody = {
        compliance: {},
      };

      for (let i = 0; i < selectedComplianceFrameworks.length; i++) {
        const complianceFramework = selectedComplianceFrameworks[i].replaceAll(
          " ",
          "_"
        );
        requestBody.compliance[complianceFramework] = true;
      }

      const complianceFrameworksData = await postComplianceFrameworks(
        requestBody
      );

      if (Object.keys(complianceFrameworksData.error).length > 0) {
        console.error(complianceFrameworksData.error.message);
      } else {
        setMessageSuccess("Changes saved");
        await loadComplianceFrameworks();
      }

      setSelectedComplianceFrameworks([]);
      setIsSelectAll(false);
      setIsSelectNone(false);
    } else {
      setMessageSuccess("Changes saved");
    }

    setTimeout(() => {
      setIsReadyForNextStep(true);
    }, 2000);
  };

  const handleClickSelectNone = () => {
    if (isSelectNone) {
      setSelectedComplianceFrameworks(complianceFrameworks);
      setIsSelectAll(true);
      setIsSelectNone(false);
    } else {
      setSelectedComplianceFrameworks([]);
      setIsSelectAll(false);
      setIsSelectNone(true);
    }
  };

  const handleClickSelectAll = () => {
    if (isSelectAll) {
      setSelectedComplianceFrameworks([]);
      setIsSelectAll(false);
      setIsSelectNone(true);
    } else {
      setSelectedComplianceFrameworks(complianceFrameworks);
      setIsSelectAll(true);
      setIsSelectNone(false);
    }
  };

  const loadComplianceFrameworks = async () => {
    const complianceFrameworksData = await fetchComplianceFrameworks();

    if (Object.keys(complianceFrameworksData.error).length > 0) {
      console.error(complianceFrameworksData.error.message);
    } else {
      const { complianceFrameworks } = complianceFrameworksData.result;

      const selectedComplianceFramework = complianceFrameworks[0] || "";

      setSelectedComplianceFramework(selectedComplianceFramework);
      setCurrentComplianceFrameworks(complianceFrameworks);

      if (complianceFrameworks.length === 0) {
        setIsSelectNone(true);
      }
    }
  };

  useEffect(() => {
    const updateComponent = async () => {
      await loadComplianceFrameworks();
    };

    if (!isInitialLoading && email) {
      updateComponent();
    }
  }, [isInitialLoading, email]);

  if (isReadyForNextStep) {
    return <Navigate to="/employees/" />;
  }

  return (
    <Grid container>
      <Grid item xs={12} marginTop="80px">
        <Typography component="h2" className="dune-text-header-dashboard">
          Choose compliance frameworks
        </Typography>
        <Typography component="p" marginTop="20px">
          Add compliance standards relevant to your organization. Once saved,
          additions are possible but deletions aren't.
        </Typography>
      </Grid>
      <Grid item xs={12} marginTop="80px">
        <FormGroup className="compliance-onboarding-form-group">
          {complianceFrameworks.map((framework, index) => {
            const isSelected = selectedComplianceFrameworks.includes(framework);
            const isCurrent = currentComplianceFrameworks.includes(framework);

            return (
              <Button
                variant="contained"
                className={
                  isSelected || isCurrent
                    ? "dune-button-contained-gray compliance-onboarding-button-compliance compliance-onboarding-button-compliance-selected"
                    : "dune-button-contained-gray compliance-onboarding-button-compliance"
                }
                disabled={isCurrent}
                onClick={() => handleClickComplianceFramework(framework)}
                key={`compliance-framework-${index}`}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      className="dune-checkbox-green"
                      checked={isSelected || isCurrent}
                    />
                  }
                  label={framework}
                  onClick={() => handleClickComplianceFramework(framework)}
                />
              </Button>
            );
          })}
          {currentComplianceFrameworks.length !==
            complianceFrameworks.length && (
            <Button
              variant="contained"
              className={
                isSelectAll
                  ? "dune-button-contained-gray compliance-onboarding-button-compliance compliance-onboarding-button-compliance-selected"
                  : "dune-button-contained-gray compliance-onboarding-button-compliance"
              }
              onClick={handleClickSelectAll}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    className="dune-checkbox-green"
                    checked={isSelectAll}
                  />
                }
                label="Select all"
                onClick={handleClickSelectAll}
              />
            </Button>
          )}
          {currentComplianceFrameworks.length === 0 && (
            <Button
              variant="contained"
              className={
                isSelectNone
                  ? "dune-button-contained-gray compliance-onboarding-button-compliance compliance-onboarding-button-compliance-selected"
                  : "dune-button-contained-gray compliance-onboarding-button-compliance"
              }
              onClick={handleClickSelectNone}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    className="dune-checkbox-green"
                    checked={isSelectNone}
                  />
                }
                label="None"
                onClick={handleClickSelectNone}
              />
            </Button>
          )}
          {selectedComplianceFrameworks.length > 0 && (
            <Button
              variant="outlined"
              className="dune-button-outlined-white compliance-onboarding-button-save"
              onClick={handleClickSave}
            >
              Save
            </Button>
          )}
          {messageSuccess && (
            <Typography component="p" className="dune-text-success">
              Changes saved
            </Typography>
          )}
        </FormGroup>
      </Grid>
    </Grid>
  );
}

export default ComplianceOnboarding;
